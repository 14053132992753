import {IconButton} from 'quickstart/components/controls/IconButton'
import {Opt} from 'quickstart/types'
import {ComponentProps} from 'react'

export const ClearButton = (
  props: Opt<ComponentProps<typeof IconButton>, 'icon'>,
) => (
  <IconButton
    color="textColorLight"
    hoverable={false}
    icon="close"
    size="xs"
    title="Clear"
    {...props}
  />
)
