import {useIsomorphicLayoutEffect} from 'quickstart/hooks/useIsomorphicLayoutEffect'
import * as SC from 'quickstart/styled-components/theme/global'
import * as T from 'quickstart/theme'
import * as CM from 'quickstart/theme/css-modules'
import {truthy} from 'quickstart/types'
import {logger, magicSort, storage, TREE} from 'tizra'

const log = logger('ThemeBlock/globalStyles')

const makeFontUrl = ({name, weights}: {name: string; weights: string[]}) => {
  const italics = [0, 1]
  const usp = new URLSearchParams({
    family: `${name}:ital,wght@${italics
      .flatMap(i => weights.map(w => `${i},${w}`))
      .join(';')}`,
    display: 'swap',
  })
  return `https://fonts.googleapis.com/css2?${usp}`
}

type Stringified<T extends string | number> = `${T}`

const sortedUniqueValues = <T extends string | number>(
  x: Record<string, T>,
): Stringified<T>[] => {
  const values = Object.values(x)
    .filter(truthy)
    .map(x => `${x}`) as Stringified<T>[]
  const unique = Array.from(new Set(values))
  return magicSort<Stringified<T>>(x => x)(unique)
}

/**
 * Return a partial copy of obj containing only the properties that are
 * different from base.
 */
const objDiff = <T extends object, U extends object>(
  obj: T,
  base: U,
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => v !== base[k as keyof U]),
  ) as Partial<T>
}

export const BaseGlobalStyle = () => {
  const theme = T.useTheme()

  useIsomorphicLayoutEffect(() => {
    const derived = T.baseVars(theme)
    for (const [k, v] of Object.entries(derived)) {
      document.body.style.setProperty(k, v)
    }
    // Script snippet in index.html restores these cached theme vars before any
    // Evergreen HTML is rendered, whether CSR or SSR, so that any theme
    // flashing only happens on the very first render of a site per client.
    const defaults = T.baseVars(T.defaultTheme)
    const overrides = objDiff(derived, defaults)
    storage.put(`${TREE}baseVars`, JSON.stringify(overrides))
  }, [theme])

  const fontNames = sortedUniqueValues(theme.baseFonts)
  const urls = fontNames
    .filter(name => name in T.fontSpecs)
    .map(name => {
      const weights = sortedUniqueValues(T.fontSpecs[name].weights)
      return makeFontUrl({name, weights})
    })

  return (
    <>
      {urls.map((url, i) => (
        <link href={url} rel="stylesheet" key={i} />
      ))}
    </>
  )
}

export const LinariaGlobalStyle = () => null

export const StyledComponentsGlobalStyle = () => <SC.GlobalStyle />

export const CssModulesGlobalStyle = () => {
  const theme = T.useTheme()

  useIsomorphicLayoutEffect(() => {
    const derived = CM.themeVars(theme)
    for (const [k, v] of Object.entries(derived)) {
      document.body.style.setProperty(k, v!)
    }
    // Script snippet in index.html restores these cached theme vars before any
    // Evergreen HTML is rendered, whether CSR or SSR, so that any theme
    // flashing only happens on the very first render of a site per client.
    const defaults = CM.defaultThemeVars
    const overrides = objDiff(derived, defaults)
    storage.put(`${TREE}cssVars`, JSON.stringify(overrides))
  }, [theme])

  return null
}
