import {Link} from 'quickstart/components/content/Link'
import {MetaValue} from 'quickstart/components/tizra/MetaValue'
import {useMetaObj} from 'quickstart/hooks'
import {MetaProps, partitionMetaProps} from 'quickstart/utils'
import {ComponentProps, ReactNode} from 'react'
import {MetaObject, TocEntry, isPdfPage, isTocEntry, meta} from 'tizra'

interface MetaLinkProps extends MetaProps<MetaObject | TocEntry> {
  children?: ReactNode
  prop?: ComponentProps<typeof MetaValue>['prop']
  bypass?: 'default' | 'unlink' | 'elide'
  reader?: boolean
  variant?: ComponentProps<typeof Link>['variant']
}

export const MetaLink = ({
  children,
  prop,
  bypass,
  reader,
  variant,
  ...restWithMetaProps
}: MetaLinkProps) => {
  const [metaProps, rest] = partitionMetaProps(restWithMetaProps)
  const metaObj = useMetaObj(metaProps)

  children ||=
    isTocEntry(metaObj) ?
      metaObj.title
    : <MetaValue
        prop={prop || '_name'}
        {...metaProps} // pass through metaType for early useTypeDefs
        metaObj={metaObj} // reassure TS that metaObj is not a TocEntry
      />

  if (
    metaObj &&
    (isPdfPage(metaObj) || isTocEntry(metaObj) || metaObj.props.BypassToC)
  ) {
    if (bypass === 'elide') return null
    if (bypass === 'unlink') return <span {...rest}>{children}</span>
  }

  return (
    <Link
      href={meta.href(metaObj, {reader}) || undefined}
      variant={variant}
      {...rest}
    >
      {children ||
        (isTocEntry(metaObj) ?
          metaObj.title
        : <MetaValue
            prop={prop || '_name'}
            {...metaProps} // pass through metaType for early useTypeDefs
            metaObj={metaObj} // reassure TS that metaObj is not a TocEntry
          />)}
    </Link>
  )
}
