import {deepMerge} from 'tizra'
import * as A from '../admin'

type AuthAgent = 'tizra' | 'remote'

export interface SignInBlockConfig extends A.BlockConfig {
  authAgent: AuthAgent | ''
  heading: string
  message: string
}

export const defaultConfig: SignInBlockConfig = {
  authAgent: '',
  heading: 'Sign In',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

const authAgentOptions: {[k in AuthAgent]: string} = {
  tizra: 'Tizra (including SSO by customLoginUrl)',
  remote: 'remoteAuthenticationAgent',
}

export const SignInAdmin: A.Admin<SignInBlockConfig> = () => (
  <>
    <A.Group title="Content">
      <A.Input label="Heading" name="heading" />
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <A.Group title="Authentication">
      <A.Radio
        label="Override authentication agent"
        name="authAgent"
        options={A.options({
          '': 'Default',
          ...authAgentOptions,
        })}
        hint="Use this to override the global sign-in config only for this block"
      />
    </A.Group>
    <p>
      Global sign-in configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)

export interface SignInBlockGlobalConfig extends A.GlobalBlockConfig {
  authAgent: AuthAgent
  modal: {enabled: boolean}
  passwordReset: {enabled: boolean; url: string}
  registration: {enabled: boolean; url: string}
  signin: {url: string}
}

export const defaultGlobalConfig: SignInBlockGlobalConfig = {
  authAgent: 'tizra',
  modal: {
    enabled: true,
  },
  passwordReset: {
    enabled: true,
    url: '/~passwordHelp',
  },
  registration: {
    enabled: true,
    url: '/~registerUser',
  },
  signin: {
    url: '/signin',
  },
}

export const globalMigrate: A.GlobalMigrate<SignInBlockGlobalConfig> = ({
  globalConfig: unknownGlobalConfig,
}) => {
  let globalConfig = {
    ...(unknownGlobalConfig as any),
  } as Partial<SignInBlockGlobalConfig>

  // The defaultGlobalConfig originally had signin.url="/login" which is
  // a reserved path in Tizra. If we find that in the config, change it to the
  // new default.
  if (globalConfig?.signin?.url === 'login') {
    globalConfig = {
      ...globalConfig,
      signin: {
        ...globalConfig.signin,
        url: defaultGlobalConfig.signin.url,
      },
    }
  }

  return deepMerge(defaultGlobalConfig)(globalConfig)
}

export const SignInGlobalAdmin: A.Admin<SignInBlockGlobalConfig> = ({
  values,
}) => (
  <A.Group>
    <A.YesNo
      label="Preferred authentication flow"
      name="modal.enabled"
      yes="Modal dialog"
      no="Pages and blocks"
      hint={
        <span>
          For information about these options, see the Authentication section in
          the{' '}
          <a
            href="https://github.com/Tizra/Tizra-Customer-Tracker/wiki/Evergreen-guide#authentication"
            target="_blank"
            rel="noreferrer"
          >
            Evergreen Guide.
          </a>
        </span>
      }
    />
    <A.Radio
      label="Default authentication agent"
      name="authAgent"
      options={A.options(authAgentOptions)}
    />
    <A.Input label="Path to static page for login" name="signin.url" />
    <A.SubGroup title="Tizra authentication options">
      <A.CheckRight
        label="Enable password reset"
        name="passwordReset.enabled"
      />
      <A.Input
        label="Path for password reset"
        name="passwordReset.url"
        hint="This path is required by the system and cannot be changed."
        disabled
        hidden={!values.passwordReset?.enabled}
      />
      <A.CheckRight
        label="Enable user registration"
        name="registration.enabled"
      />
      <A.Input
        label="Path for user registration"
        name="registration.url"
        hint="This path is required by the system and cannot be changed."
        disabled
        hidden={!values.registration?.enabled}
      />
    </A.SubGroup>
  </A.Group>
)
