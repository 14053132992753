import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {IconButton} from 'quickstart/components/controls/IconButton'
import {FlexGrid} from 'quickstart/components/layout/FlexGrid'
import {SearchTermsInput} from 'quickstart/components/tizra/SearchTermsInput'
import {useSearchConfig} from 'quickstart/hooks'
import {ComponentProps, JSX, JSXElementConstructor, useId} from 'react'
import * as Final from 'react-final-form'
import {logger} from 'tizra'

const log = logger('QuickSearch')

type UseQuickSearchParams = {
  params?: Record<string, string>
  relative?: boolean
}

type UseQuickSearchReturn = {
  formId: string
  params: Record<string, string>
  relative: boolean
  searchConfig: ReturnType<typeof useSearchConfig>
}

export const useQuickSearch = ({
  params = {},
  relative = false,
}: UseQuickSearchParams): UseQuickSearchReturn => {
  const formId = useId()
  const searchConfig = useSearchConfig({mode: 'quick'})
  return {
    formId,
    params,
    relative,
    searchConfig,
  }
}

type ComponentPropsWithRenderFn<
  X extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
> = Omit<ComponentProps<X>, 'children'> & {
  children:
    | ComponentProps<X>['children']
    | ((renderProps: Omit<ComponentProps<X>, 'children'>) => JSX.Element)
}

type ComponentPropsWithRenderFnOnly<
  X extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
> = Omit<ComponentProps<X>, 'children'> & {
  children?: (renderProps: Omit<ComponentProps<X>, 'children'>) => JSX.Element
}

interface QuickSearchValues {
  terms: string
}

type QuickSearchFormProps = UseQuickSearchReturn &
  ComponentPropsWithRenderFn<'form'>

export const QuickSearchForm = ({
  formId,
  params,
  relative,
  searchConfig,
  children,
  ref,
  ...props
}: QuickSearchFormProps) => {
  const onSubmit = ({terms}: QuickSearchValues) => {
    const usp = new URLSearchParams()
    if (terms || params.terms) {
      usp.set('s', [terms, params.terms].filter(Boolean).join(' '))
    }
    for (const k of Object.keys(params).sort()) {
      if (k !== 'terms') {
        usp.set(`searchParam-${k}`, params[k] as string)
      }
    }
    const queryString = usp.toString()

    // https://github.com/Tizra/evergreen/issues/457
    const {pathname} = window.location
    const base =
      !relative ? '/'
      : /^[/][^/]+$/.test(pathname) ? `${pathname}/`
      : ''

    window.location.href =
      base + '~searchResults' + (queryString && '?') + queryString
  }

  const render =
    typeof children === 'function' ? children : (
      (renderProps: ComponentProps<'form'>) => (
        <form {...renderProps} {...props}>
          {children}
        </form>
      )
    )

  return (
    <Final.Form<QuickSearchValues>
      onSubmit={onSubmit}
      render={({handleSubmit}) =>
        render({id: formId, onSubmit: handleSubmit, ref})
      }
    />
  )
}

type QuickSearchInputProps = UseQuickSearchReturn &
  ComponentPropsWithRenderFnOnly<any>

export const QuickSearchInput = ({
  formId,
  params,
  relative,
  searchConfig,
  children,
  ref,
  ...props
}: QuickSearchInputProps) => {
  const render =
    children ||
    ((renderProps: any) => (
      <ConnectedField
        component={SearchTermsInput}
        {...renderProps}
        {...props}
      />
    ))
  return render({form: formId, name: 'terms', search: searchConfig, ref})
}

type QuickSearchButtonProps = UseQuickSearchReturn &
  ComponentPropsWithRenderFnOnly<any>

export const QuickSearchButton = ({
  formId,
  params,
  relative,
  searchConfig,
  children,
  ref,
  ...props
}: QuickSearchButtonProps) => {
  const render =
    children ||
    ((renderProps: any) => (
      <IconButton icon="search" {...renderProps} {...props} />
    ))
  return render({form: formId, type: 'submit', ref})
}

type QuickSearchProps = UseQuickSearchParams &
  ComponentPropsWithRenderFnOnly<any>

const _QuickSearch = ({
  params,
  relative,
  button = true,
  placeholder,
  children,
  ref,
  ...props
}: QuickSearchProps) => {
  const state = useQuickSearch({params, relative})
  const render =
    children ||
    ((renderProps: any) => {
      const input = (
        <QuickSearchInput
          {...renderProps}
          placeholder={placeholder}
          size="md"
        />
      )
      return button ?
          <FlexGrid gutter="0px">
            <FlexGrid.Col>{input}</FlexGrid.Col>
            <FlexGrid.Col col="auto">
              <QuickSearchButton {...renderProps} size="md" />
            </FlexGrid.Col>
          </FlexGrid>
        : input
    })
  return (
    <QuickSearchForm {...state} {...props} ref={ref}>
      {render(state)}
    </QuickSearchForm>
  )
}

export const QuickSearch = Object.assign(_QuickSearch, {
  Form: QuickSearchForm,
  Input: QuickSearchInput,
  Button: QuickSearchButton,
})
