import {useMemo} from 'react'
import {User, logger} from 'tizra'
import {useApi} from './useApi'
import * as R from 'rambdax'

const log = logger('useUserData')

interface UseUserDataProps {
  enabled?: boolean
}

/**
 * Get the UserData records for logged-in users.
 *
 * @return UserDatas (might be empty) or null (don't know yet)
 */
export const useUserDatas: (props?: UseUserDataProps) => null | User[] = ({
  enabled = true,
} = {}) => {
  const allUsers = useApi.loggedInUsers(enabled).data

  return useMemo<null | User[]>(() => {
    if (!allUsers) {
      return null
    }
    const [admins, normals] = R.partition(u => u.isAdmin, allUsers)
    return [...normals, ...admins]
  }, [allUsers])
}

/**
 * Get the UserData record for the main logged-in user, preferring non-admins.
 *
 * @return UserData or false (not signed in) or null (don't know yet)
 */
export const useUserData: (props?: UseUserDataProps) => null | false | User = (
  props = undefined,
) => {
  const users = useUserDatas(props)
  return users && (users[0] || false)
}
