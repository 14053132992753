import {AttachmentIcon} from 'quickstart/components/content/AttachmentIcon'
import {Link} from 'quickstart/components/content/Link'
import {Text} from 'quickstart/components/content/Text'
import {Grid} from 'quickstart/components/layout/Grid'
import {useThemeGetter} from 'quickstart/hooks/useThemeGetter'
import {ComponentProps, ReactNode} from 'react'
import {Attachment} from 'tizra'

const iconSizes = {
  small: 1,
  medium: 1.5,
  large: 2,
}

interface FilesItemProps extends ComponentProps<typeof Grid> {
  iconEnabled?: boolean
  iconSize?: number | keyof typeof iconSizes
  item: Attachment
  label: ReactNode
  textVariant?: ComponentProps<typeof Text>['variant']
}

/**
 * Individual item in a FilesList. This receives label from the parent instead
 * of using item.props.DisplayName directly so that sorting remains in sync with
 * labeling.
 */
export const FilesItem = ({
  iconEnabled = true,
  iconSize: _iconSize = 1, // line-height multiplier
  item,
  label,
  textVariant = 'attachment',
  ...rest
}: FilesItemProps) => {
  const th = useThemeGetter()

  // Convert string iconSize to numeric.
  const iconSize =
    typeof _iconSize === 'number' ? _iconSize : iconSizes[_iconSize]

  // Icon takes the full line-height, which is more than 1em.
  const size = `calc(
      ${iconSize} *
      ${th(`prose.${textVariant}.fontSize`)} *
      ${th(`prose.${textVariant}.lineHeight`)}
    )`

  // Add padding to the top of text so it aligns properly with the bottom of
  // the icon.
  const pt =
    iconEnabled ?
      `calc(
      ${Math.max(0, iconSize - 1)} *
      ${th(`prose.${textVariant}.fontSize`)} *
      ${th(`prose.${textVariant}.lineHeight`)}
    )`
    : undefined

  return (
    <Link
      href={item.props.isUrlName ? item.name : item.url}
      target={item.props.target || undefined}
      variant="ui"
    >
      <Grid
        colGap={`${iconSize / 2}rem`}
        gridTemplateColumns="auto minmax(0, 1fr)"
        {...rest}
      >
        {iconEnabled ?
          <AttachmentIcon attachment={item} size={size} />
        : null}
        <Text
          prose
          variant={textVariant}
          style={{
            paddingTop: pt,
            overflowWrap: 'anywhere',
            wordBreak: 'break-word',
          }}
        >
          {label}
        </Text>
      </Grid>
    </Link>
  )
}
