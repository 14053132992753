import {useAdminApi} from 'quickstart/hooks/useApi'
import * as R from 'rambdax'
import * as React from 'react'
import {PropDef, PropType, logger, tizraCamelize} from 'tizra'
import {makeOptions} from '../utils'
import {useAdminContext} from './useAdminContext'

const log = logger('blocks/admin/hooks/usePropOptions')

const hardReject = (x: {name?: string}) => x.name === 'CoverImage'

export interface UsePropOptionsProps {
  publishedDate?: boolean
  requireJsonVisible?: boolean
  requireUserVisible?: boolean
  filter?: (def: PropDef) => any // TODO kill in favor of pred
  format?: (def: PropDef) => string
  metaType?: string
  namePropName?: true | string
  pred?: (o: PropOption) => any
  sort?: any
  spec?: 'prop-defs' | 'sub-prop-defs'
  types?: PropType[]
}

interface PropOption {
  def?: PropDef
  value: string
  label: string
}

interface UsePropOptionsReturn extends Array<PropOption> {
  namePropName: true | string | undefined
}

export const usePropOptions = ({
  publishedDate = false,
  requireJsonVisible = true,
  requireUserVisible = false,
  filter,
  format = x => `${x.displayName} (${x.name})`,
  metaType: _metaType,
  namePropName,
  pred,
  sort,
  spec = 'prop-defs',
  types,
}: UsePropOptionsProps = {}): UsePropOptionsReturn => {
  const context = useAdminContext() as any
  const metaType = (log.assert(
    _metaType || context.metaType,
    'No metaType in AdminContext!',
  ) || 'AdminTagged') as string

  const metaTypes = useAdminApi.types(namePropName === true).data
  if (namePropName === true) {
    namePropName = metaTypes?.[metaType]?.namePropName
  }

  const _propDefs = useAdminApi.GET(`types/${metaType}/${spec}`).data?.data
  const propDefs = React.useMemo(
    () => tizraCamelize(_propDefs) as PropDef[] | undefined,
    [_propDefs],
  )

  const defsForOptions = propDefs
    ?.filter(R.complement(hardReject))
    .filter(def => !types || types.includes(def.type))
    .map(def =>
      namePropName && def.name === namePropName ?
        {...def, isJsonVisible: true}
      : def,
    )
    .filter(
      def => !requireJsonVisible || def.isJsonVisible || def.isUserVisible,
    )
    .filter(def => !requireUserVisible || def.isUserVisible)
    .filter(filter || R.always(true))

  let options: PropOption[] = makeOptions({
    valueKey: 'name',
    labelKey: format,
    sort,
  })(defsForOptions).map(opt =>
    namePropName && opt.value === namePropName ? {...opt, value: '_name'} : opt,
  )

  if (publishedDate) {
    options = [
      {value: 'publishedDate', label: 'Last published date'},
      ...options,
    ]
  }

  if (pred) {
    options = options.filter(pred)
  }

  // hack for CoverAdmin otherPropOptions
  const optionsReturn: UsePropOptionsReturn = Object.assign(options, {
    namePropName,
  })

  const memo = React.useRef<UsePropOptionsReturn>(optionsReturn)
  if (!R.equals(memo.current, optionsReturn)) {
    memo.current = optionsReturn
  }
  return memo.current
}

export const HEADING_TYPES: PropType[] = ['keyword', 'string']

export const SUB_HEADING_TYPES: PropType[] = [
  'boolean-list', // keyword checklist
  'date',
  'html',
  'keyword',
  'keyword-list',
  'string',
  'string-list',
  'text',
]

export const DETAIL_TYPES: PropType[] = [
  'boolean-list', // keyword checklist
  'date',
  'float',
  'float-list',
  'html',
  'integer',
  'integer-list',
  'ISBN',
  'ISBN-13',
  'keyword',
  'keyword-list',
  'string',
  'string-list',
]

export const FILTER_TYPES: PropType[] = [
  'boolean-list', // keyword checklist
  'float',
  'float-list',
  'keyword',
  'keyword-list',
  'integer',
  'integer-list',
  'string',
  'string-list',
]

export const LINK_TYPES: PropType[] = ['keyword', 'string']
