import createAutoComplete from 'quickstart/lib/search/autocomplete'
import {SearchConfig} from 'quickstart/lib/search/config'
import {useMemo} from 'react'

/**
 * React hook for getting an auto-completer that persists for the lifetime of
 * the component.
 */
export const useAutoComplete = (searchConfig?: SearchConfig) =>
  useMemo(
    () => (searchConfig ? createAutoComplete(searchConfig) : async () => []),
    [searchConfig],
  )
