import {themeGetter} from 'quickstart/styled-components/system'

const getTiles = (theme: any) => {
  const th = themeGetter(theme)
  return {
    default: {
      tile: {
        backgroundColor: th('colors.backgroundColorWhite'),
        // Following work with conditional stretch in styles.js
        display: 'flex',
        flexDirection: 'column',
      },
      cover: {
        borderTopLeftRadius: th('radii.none'),
        borderTopRightRadius: th('radii.none'),
      },
      label: {
        ...th('prose.tileLabel'),
        color: th('colors.textColorMuted'),
      },
      heading: {
        ...th('fonts.heading'),
        ...th('prose.tileHeadLg'),
      },
      meta: {
        ...th('fonts.body'),
        ...th('ui.tileMeta'),
      },
      description: {
        ...th('fonts.body'),
        ...th('ui.tileDescription'),
      },
      callToAction: {
        ...th('ui.tileCta'),
        color: th('colors.linkColor'),
      },
    },
    small: {
      heading: {
        ...th('prose.tileHeadSm'),
      },
    },
    medium: {
      heading: {
        ...th('prose.tileHeadMd'),
      },
    },
  }
}

export default getTiles
