import {darken, lighten} from 'polished'
import {PartialDeep} from 'type-fest'

const DEFAULT_BUTTON_COLOR = '#222955'
const DEFAULT_LINK_COLOR = '#4c779e'

export const getColorScales = (
  theme: PartialDeep<{
    branding: {buttonColor: string; linkColor: string}
  }>,
) => {
  // Make a copy so we can modify locally without affecting the theme object
  // that was passed in.
  const branding = {...theme.branding}

  // Empty brand colors aren't valid, use the defaults in that case.
  branding.buttonColor = branding.buttonColor || DEFAULT_BUTTON_COLOR
  branding.linkColor = branding.linkColor || DEFAULT_LINK_COLOR

  return {
    alphaBlack: {
      90: 'rgba(0, 0, 0, 0.85)',
      60: 'rgba(0, 0, 0, 0.6)',
      40: 'rgba(0, 0, 0, 0.4)',
      20: 'rgba(0, 0, 0, 0.2)',
    },
    alphaWhite: {
      90: 'rgba(255, 255, 255, 0.9)', // e6
      60: 'rgba(255, 255, 255, 0.8)', // cc
      40: 'rgba(255, 255, 255, 0.7)', // b3
      20: 'rgba(255, 255, 255, 0.2)', // 33
    },
    nude: {
      80: '#333333',
      60: '#666666',
      40: '#999999',
      30: '#b3b3b3',
      20: '#cccccc',
      10: '#e6e6e6',
      5: '#f2f2f2',
      0: '#ffffff',
    },
    brandForm: {
      100: lighten(0.27, branding.buttonColor),
      200: lighten(0.137, branding.buttonColor),
      500: branding.buttonColor,
      700: darken(0.05, branding.buttonColor),
    },
    brandText: {
      100: lighten(0.27, branding.linkColor),
      200: lighten(0.137, branding.linkColor),
      500: branding.linkColor,
      700: darken(0.05, branding.linkColor),
    },
    success: {
      100: '#C5F3C9',
      200: '#52B45A',
      500: '#2F9237',
    },
    danger: {
      100: '#FFD8D9',
      200: '#E02F32',
      500: '#BB1316',
      700: '#94080A',
    },
    warning: {
      100: '#FFD0B4',
      200: '#F17D39',
      500: '#D35E1A',
      700: '#B34607',
    },
    info: {
      100: '#C5DBFB',
      200: '#3E7BD7',
      500: '#1B57B2',
    },
  } as const
}

export const getColors = (theme: {
  colorScales: ReturnType<typeof getColorScales>
}) => {
  const {colorScales: sc} = theme
  return {
    // typography
    textColor: sc.alphaBlack[90],
    textColorMuted: sc.alphaBlack[60],
    textColorLight: sc.alphaBlack[40],
    textError: sc.danger[700],
    textRequired: sc.danger[200],
    textInvertedColor: sc.alphaWhite[90],
    textInvertedColorMuted: sc.alphaWhite[60],
    textUnderline: sc.alphaBlack[60], // textColorMuted
    linkColor: sc.brandText[500],
    selectionBgColor: sc.brandText[100],
    selectionColor: sc.alphaBlack[90],
    // backgrounds
    backgroundColor: 'transparent',
    backgroundColorWhite: sc.nude[0],
    backgroundColorLight: sc.nude[5],
    backgroundColorMedium: sc.nude[10],
    backgroundColorDark: sc.nude[80],
    backgroundColorOverlay: sc.alphaWhite[60],
    backgroundError: sc.danger[100],
    // borders
    borderColor: sc.nude[10],
    borderColorLight: sc.nude[5],
    borderHoverColor: sc.nude[20],
    borderSelectedColor: sc.nude[30],
    borderFocusColor: sc.nude[30],
    borderError: sc.danger[500],
    // buttons and tags - TODO
    buttonSolidColor: sc.brandForm[500],
    buttonSolidHover: sc.brandForm[700],
    buttonSolidFocus: sc.brandForm[700],
    buttonOutlineColor: sc.brandForm[500],
    buttonOutlineHover: sc.brandForm[500],
    buttonOutlineFocus: sc.brandForm[500],
    buttonNeutralColor: sc.alphaBlack[90],
    buttonNeutralHover: sc.alphaBlack[90],
    buttonNeutralFocus: sc.alphaBlack[90],
    buttonDisabled: sc.nude[20],
    buttonDisabledColor: sc.alphaBlack[60],
    tagNeutralColor: sc.nude[10],
    tagNeutralHover: sc.nude[20],
    // cart indicator
    notificationBubble: sc.danger[200],
  } as const
}
