import {ComponentProps} from 'react'
import {AuthChangePassword} from './AuthChangePassword'
import {AuthRegister} from './AuthRegister'
import {AuthResetPassword} from './AuthResetPassword'
import {AuthSignIn} from './AuthSignIn'

type AuthProps =
  | ({view: 'resetPassword'} & ComponentProps<typeof AuthResetPassword>)
  | ({view: 'register'} & ComponentProps<typeof AuthRegister>)
  | ({view: 'changePassword'} & ComponentProps<typeof AuthChangePassword>)
  | ({view: 'signin'} & ComponentProps<typeof AuthSignIn>)

export type AuthView = AuthProps['view']

// Ideally we'd use {view, ...props} here, but TS doesn't track the typing well
// enough and complains about what is passed to the components. So use
// props.view and don't worry about passing it to the component.
// https://www.developerway.com/posts/advanced-typescript-for-react-developers-discriminated-unions
export const Auth = (props: AuthProps) => {
  switch (props.view) {
    case 'resetPassword':
      return <AuthResetPassword autoFocus={true} {...props} />
    case 'register':
      return <AuthRegister autoFocus={true} {...props} />
    case 'changePassword':
      return <AuthChangePassword autoFocus={true} {...props} />
    case 'signin':
      return <AuthSignIn autoFocus={true} {...props} />
  }
}
