import {dataProps} from 'quickstart/theme'
import {UnprefixDataProps} from 'quickstart/types'
import {ComponentProps, Fragment, ReactNode} from 'react'
import {Container} from '../Container'
import * as S from './styles'

type SectionProps = Partial<
  UnprefixDataProps<ComponentProps<typeof S.Section>>
> & {
  bleed?: boolean
  heading?: ReactNode
}

const _Section = ({
  bleed = false,
  children,
  heading,
  variant = 'default',
  ...props
}: SectionProps) => {
  if (heading && typeof heading === 'string') {
    heading = <S.SectionHeading>{heading}</S.SectionHeading>
  }

  const MaybeContainer = bleed ? Fragment : Container

  return (
    <S.Section {...dataProps({variant})} {...props}>
      <MaybeContainer>
        {heading}
        {children}
      </MaybeContainer>
    </S.Section>
  )
}

export const Section = Object.assign(_Section, {Heading: S.SectionHeading})
