import * as B from '../block'
import {Admin, defaultConfig} from './admin'

export const PostBlock = B.blockWithInfo(
  {
    name: 'post-content',
    displayName: 'PostBlock',
    title: 'Post Content',
    defaultConfig,
    Admin,
  },
  ({config}) => {
    const hasContent = config.staticText.trim() ? true : null
    return (
      <B.Scene>
        {hasContent && (
          <B.Section>
            <B.StaticContent {...config} />
          </B.Section>
        )}
      </B.Scene>
    )
  },
)
