import * as R from 'rambdax'
import {formErrors} from 'quickstart/utils/final-form-errors'
import {ComponentPropsWithoutRef, useEffect, useRef} from 'react'
import * as Final from 'react-final-form'
import {api, ApiError, FormErrors, isEmptyObj, logger} from 'tizra'
import * as B from '../../block'
import {StepComponent} from '../types'
import {countries, states} from './cokesbury'

const log = logger('CartBlock/UmphBillingStep')

type SK = keyof typeof states

export const UmphBillingStep: StepComponent = ({
  active,
  checkoutMethod: _checkoutMethod,
  step: _step,
  nextSteps,
  proceed,
  setNextSteps,
  userData,
}) => {
  const checkoutMethod = _checkoutMethod!
  const step = _step!
  const infoRequired = nextSteps[step.step]?.infoRequired || {}

  log.log({nextSteps, infoRequired})

  const submit = async (values: any) => {
    const {status, ...data} = await api
      .checkoutStep({
        methodName: checkoutMethod.name,
        stepName: step.step,
        values,
      })
      .catch(e => {
        // Only here for 500, not 40x per entry in info.ts
        log.error(e)
        return {
          status: (e instanceof ApiError && e.status) || 0,
          reason: 'exception',
          message: `${e}`,
          errors: undefined,
        }
      })

    log.log({data, values, status})

    if (status >= 200 && status < 300) {
      // TODO: final
      if ('nextSteps' in data && data.nextSteps) {
        setNextSteps(data.nextSteps)
        proceed()
      }
      return // React Final Form will set submitSucceeded
    }

    let {errors, message, reason} = data as FormErrors

    // If the API returns permission denied, this means the session expired
    // between the time we rendered the form and now, so we need to reload the
    // page.
    if (status === 403 && userData) {
      window.location.reload()
    }

    if (reason === 'validation' && errors && !isEmptyObj(errors)) {
      message = reason = undefined
    }

    return formErrors({errors, message, reason, status})
  }

  const firstInputRef = useRef<HTMLInputElement>(undefined)
  const firstInput = firstInputRef.current
  useEffect(() => void (active && firstInput?.focus()), [active, firstInput])

  const inp = (
    name: string,
    {
      autoComplete,
      label,
      ...props
    }: ComponentPropsWithoutRef<'input'> & {label?: string; ref?: any} = {},
  ) => {
    const field = infoRequired[name]
    log.log({name, infoRequired, field})
    return (
      field && (
        <B.ConnectedField
          key={name}
          component={B.Input}
          name={name}
          label={label || field.prompt}
          required={field.required ?? !field.optional}
          {...props}
          autoComplete={autoComplete && `billing ${autoComplete}`}
        />
      )
    )
  }

  const sel = (
    name: string,
    {
      label,
      ...props
    }: Omit<ComponentPropsWithoutRef<typeof B.Select> & {ref?: any}, 'name'> & {
      label?: string
    },
  ) => {
    const field = infoRequired[name]
    return (
      field && (
        <B.ConnectedField
          key={name}
          component={B.Select}
          name={name}
          label={label || field.prompt}
          required={field.required ?? !field.optional}
          {...props}
        />
      )
    )
  }

  const extraInfoRequired = R.omit(
    [
      'first-name',
      'last-name',
      'email',
      'address1',
      'address2',
      'country',
      'city',
      'state',
      'postal-code',
      'phone',
    ],
    infoRequired,
  )

  return (
    <Final.Form
      onSubmit={submit}
      render={({handleSubmit, values, values: {country}}) => {
        const c = country || 'USA'
        return (
          <>
            <B.AddressSummary
              values={{
                ...values,
                firstName: values['first-name'],
                lastName: values['last-name'],
                zip: values['postal-code'],
                country: values['country'],
              }}
              style={{display: !active ? undefined : 'none'}}
            />
            <form
              onSubmit={handleSubmit}
              style={{display: active ? undefined : 'none'}}
            >
              <B.FormShell
                variant="fluid"
                primaryButton={{type: 'submit', children: 'Continue'}}
              >
                <B.Flex>
                  {inp('first-name', {
                    autoComplete: 'given-name',
                    ref: firstInputRef,
                  })}
                  {inp('last-name', {autoComplete: 'family-name'})}
                </B.Flex>
                {sel('country', {
                  //autoComplete: 'country', // B.Select doesn't accept
                  options: countries,
                })}
                {inp('address1', {
                  autoComplete: 'address-line1',
                  label: 'Street address',
                })}
                {inp('address2', {
                  autoComplete: 'address-line2',
                  label: 'Apartment, suite, etc. (optional)',
                })}
                <B.Flex>
                  {inp('city')}
                  {c in states ?
                    sel('state', {
                      label: c === 'CAN' ? 'Province' : 'State',
                      options: states[c as SK],
                    })
                  : inp('state')}
                  {inp('postal-code', {
                    label: c === 'USA' ? 'Zip code' : 'Postal code',
                  })}
                </B.Flex>
                {inp('email', {type: 'email', autoComplete: 'email'})}
                {inp('phone', {type: 'tel', autoComplete: 'tel'})}
                {Object.keys(extraInfoRequired).map(name => inp(name))}
              </B.FormShell>
            </form>
          </>
        )
      }}
    />
  )
}
