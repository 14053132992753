import {Html} from 'quickstart/components/content/Html'
import {Button} from 'quickstart/components/controls/Button'
import {Modal} from 'quickstart/components/layout/Modal'
import {Stack} from 'quickstart/components/layout/Stack'
import {useBlockContext, useMobile} from 'quickstart/hooks'
import {SessionError} from 'quickstart/types'
import * as S from './styles'

const _SessionErrorsModal = ({
  sessionErrors,
  store,
}: {
  sessionErrors?: SessionError[]
  store: ReturnType<typeof Modal.useStore>
}) => {
  const context = useBlockContext()
  sessionErrors ||= context.sessionErrors
  const thisError = sessionErrors[0] as SessionError | undefined

  const errorMessage = <Html variant="raw" html={thisError?.message} />

  const errorKey = <S.Key>{thisError?.key && `Key: ${thisError.key}`}</S.Key>

  /*
  const prevButtonProps: ComponentProps<typeof Button> = {
    icon: 'arrowLeft',
    onClick: () => setIndex(i => Math.max(0, i - 1)),
    disabled: index <= 0,
    variant: 'secondary',
  }

  const nextButtonProps: ComponentProps<typeof Button> = {
    icon: 'arrowRight',
    onClick: () => setIndex(i => Math.min(sessionErrors.length - 1, i + 1)),
    disabled: index >= sessionErrors.length - 1,
    variant: 'secondary',
  }
  */

  const mobile = useMobile()

  return (
    <Modal
      size="lg"
      aria-label="session errors"
      hideOnEscape={false}
      hideOnInteractOutside={false}
      store={store}
    >
      <Modal.Title>Whoops</Modal.Title>
      <Modal.Content>{errorMessage}</Modal.Content>
      <Modal.Footer>
        <Stack
          row
          style={{justifyContent: 'space-between', alignItems: 'baseline'}}
        >
          {errorKey}
          {!mobile && <Button onClick={() => store.hide()}>Close</Button>}
        </Stack>
      </Modal.Footer>
    </Modal>
  )
}

export const SessionErrorsModal = Object.assign(_SessionErrorsModal, {
  useStore: Modal.useStore,
})
