import {fonts, gaps, typography} from 'quickstart/theme'
import {atBreakpoints, styled} from 'quickstart/theme/linaria'

type GroupProps = {
  $background?: string
  $foreground?: string
  'data-size': 'small' | 'medium' | 'large'
}

export const Group = styled.div<GroupProps>`
  display: grid;
  gap: ${gaps.sideNav};
  background: ${props => props.$background || ''};
  color: ${props => props.$foreground || ''};

  &[data-size='small'] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    ${atBreakpoints({
      sm: {gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'},
      md: {gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'},
      lg: {gridTemplateColumns: 'repeat(5, minmax(0, 1fr))'},
    })};
  }

  &[data-size='medium'] {
    grid-template-columns: minmax(0, 1fr);
    ${atBreakpoints({
      sm: {gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'},
      md: {gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'},
      lg: {gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'},
    })};
  }

  &[data-size='large'] {
    grid-template-columns: minmax(0, 1fr);
    ${atBreakpoints({
      sm: {gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'},
    })};
  }
`

export const Digest = styled.div`
  display: flex; // for <Digest as={UniversalLink} />
  flex-direction: column;
  word-break: break-word;
`

export const Heading = styled.div`
  text-wrap: balance;
  ${fonts.heading};
  // Default outside of Group, mainly for stories
  ${typography.prose.digestHeadLg};
  ${Group}[data-size='small'] & {
    ${typography.prose.digestHeadSm};
  }
  ${Group}[data-size='medium'] & {
    ${typography.prose.digestHeadMd};
  }
`

export const Snippet = styled.div`
  ${fonts.body};
  // Default outside of Group, mainly for stories
  ${typography.ui.digestSnippetLg};
  ${Group}[data-size='small'] & {
    ${typography.ui.digestSnippetSm};
  }
  ${Group}[data-size='medium'] & {
    ${typography.ui.digestSnippetMd};
  }
`
