import {notNullish} from 'quickstart/types'
import {useMemo} from 'react'
import {License} from 'tizra'
import {useApi} from './useApi'

interface UseLicensesProps {
  enabled?: boolean
  sampling?: false // TODO
}

type UseLicensesReturn = License[] | null | undefined

/**
 * Fetch a flat list of licenses, excluding sampling licenses (for now).
 *
 * Returns null while fetching, or undefined if disabled.
 */
export const useLicenses = ({
  enabled = true,
}: UseLicensesProps = {}): UseLicensesReturn => {
  const licenses = useApi.licenses(enabled).data
  return useMemo<UseLicensesReturn>(
    () =>
      licenses ?
        [
          ...(licenses.allUserSets?.flatMap(us => us?.licenses) || []),
          ...(licenses.licenseBindings?.flatMap(b => b?.licenses) || []),
          ...Object.values(licenses.sessionLicenses || {}),
          ...(licenses.userLicenses?.flatMap(
            ul => ul?.userLicenseInfo?.userlicenses,
          ) || []),
        ].filter(notNullish)
      : enabled ? null
      : undefined,
    [enabled, licenses],
  )
}

type UseLicensedIdsReturn = Set<string> | null | undefined

/**
 * Fetch a set of licensed controlled ids.
 *
 * Returns null while fetching, or undefined if disabled.
 */
export const useLicensedIds = (
  props?: UseLicensesProps,
): UseLicensedIdsReturn => {
  const licenses = useLicenses(props)
  return useMemo<UseLicensedIdsReturn>(
    () => licenses && new Set(licenses.map(l => l.controlled)),
    [licenses],
  )
}
