import * as A from '../admin'

const modes = {
  current: 'Current object',
  all: 'Everything',
  specific: 'Specific collection or publication',
} as const

type MODE = keyof typeof modes

export interface Config extends A.BlockConfig {
  heading: string
  placeholder: string
  mode: MODE
  specificId: string
}

export const defaultConfig: Config = {
  heading: '',
  placeholder: 'Search within…',
  mode: 'current',
  specificId: '',
  FREEMARKER: {
    heading: 'markdown',
    placeholder: 'markdown',
  },
}

export const Admin: A.Admin<Config> = ({values}) => (
  <A.Group title="Content">
    <A.Input label="Heading" name="heading" />
    <A.Input label="Placeholder" name="placeholder" />
    <A.Select label="Search context" name="mode" options={A.options(modes)} />
    <A.Input
      label="Specific Tizra ID to search"
      name="specificId"
      hidden={values.mode !== 'specific'}
    />
  </A.Group>
)
