import styled from 'quickstart/styled-components/system'

export const AdminWrapper = styled.div`
  && {
    font-family: verdana, sans-serif;
    font-size: 0.68rem;
    button,
    p {
      font-family: verdana, sans-serif;
      font-size: 0.68rem;
    }
  }
`
