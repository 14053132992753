import {Dict} from 'quickstart/types'
import * as R from 'rambdax'
import {
  createContext,
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {logger} from 'tizra'

const log = logger('Scene')

interface SceneContext {
  _enter: undefined | ((n: number, v?: boolean) => void)
  _exit: undefined | ((n: number) => void)
  show: boolean
  style: undefined | CSSProperties
}

const SC = createContext<SceneContext | undefined>(undefined)

let takeANumber = 1

interface RenderProps extends Omit<SceneContext, '_enter' | '_exit'> {
  enter: (v?: boolean) => void
  exit: () => void
}

interface SceneProps {
  children?: ReactNode | ((p: RenderProps) => ReactNode)
  onChange?: (v: boolean) => void
  reveal?: boolean
  root?: boolean
}

/**
 * Track inner component content to make higher-level show/hide decisions.
 *
 * There are four ways to consume the Scene tracking:
 * - refer to show boolean renderProp
 * - refer to style object renderProp (undefined or {display: 'none'})
 * - either of the above, except from useScene hook
 * - register an onChange handler
 *
 * and there are four ways to influence the Scene tracking:
 * - pass or render null child
 * - pass simple children, default depends on reveal
 * - call the enter/exit renderProps (from an effect!)
 * - render child Scene(s), parent will OR child statuses
 *
 * Initial states:
 *
 * +----------+-----------------------------+-----------------+
 * |          |           default           |      reveal     |
 * +----------+---------------+-------------+------+----------+
 * |          | null          | non-null    | null | non-null |
 * +----------+---------------+-------------+------+----------+
 * | show     | false         | true        | false           |
 * +----------+---------------+-------------+-----------------+
 * | style    | display: none | undefined   | display: none   |
 * +----------+---------------+-------------+-----------------+
 * | parent   | enter(false)  | enter(true) | enter(false)    |
 * +----------+---------------+-------------+-----------------+
 * | onChange | false         | not called  | not called      |
 * +----------+---------------+-------------+-----------------+
 */
export const Scene = ({
  children,
  onChange,
  reveal = false,
  root = false,
}: SceneProps) => {
  const [n] = useState<number>(() => takeANumber++)
  const [onStage, setOnStage] = useState<Dict<boolean>>({})

  const show: boolean =
    R.isNil(children) ? false
    : R.isEmpty(onStage) ?
      reveal ? false
      : true
    : R.any(Boolean, R.values(onStage))

  // onChange handling
  const changed = useRef(!reveal)
  useEffect(() => {
    if (onChange && changed.current !== show) {
      changed.current = show
      onChange(show)
    }
  }, [onChange, show])

  // parent enter/exit handling
  const {enter, exit} = useScene(n)
  useEffect(() => {
    if (!root) {
      enter(show)
    }
  }, [enter, root, show])
  useEffect(() => {
    if (!root) {
      return exit
    }
  }, [exit, root])

  // subcontext and renderProps
  const _enter = useCallback(
    (k: number, v = true) => setOnStage(onStage => ({...onStage, [k]: v})),
    [setOnStage],
  )
  const _exit = useCallback(
    (k: number) => setOnStage(onStage => ({...onStage, [k]: false})),
    [setOnStage],
  )
  const context = useMemo<SceneContext>(
    () => ({
      _enter,
      _exit,
      show,
      style: show ? undefined : {display: 'none'},
    }),
    [_enter, _exit, show],
  )
  const renderProps = {
    enter: useCallback((v?: boolean) => _enter(n, v), [_enter, n]),
    exit: useCallback(() => _exit(n), [_exit, n]),
    show: context.show,
    style: context.style,
  }

  return (
    <SC.Provider value={context}>
      {typeof children === 'function' ? children(renderProps) : children}
    </SC.Provider>
  )
}

type UseSceneReturn = RenderProps

export const useScene = (x: number | boolean): UseSceneReturn => {
  const [n] = useState<number>(() =>
    typeof x === 'number' ? x : takeANumber++,
  )

  const context = useContext(SC)
  log.assert(
    context || typeof x === 'number',
    'missing SceneContext in useScene, missing <Scene> wrapper?',
  )

  const {_enter, _exit} = context || {}
  const enter = useCallback((v?: boolean) => _enter?.(n, v), [_enter, n])
  const exit = useCallback(() => _exit?.(n), [_exit, n])

  useEffect(() => {
    if (typeof x === 'boolean') {
      enter(x)
    }
  }, [x, enter])

  return useMemo(
    () => ({
      enter,
      exit,
      show: context?.show ?? true,
      style: context?.style,
    }),
    [context, enter, exit],
  )
}
