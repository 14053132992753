import * as R from 'rambdax'
import {deepMerge, logger} from 'tizra'
import * as A from '../admin'
import * as v0 from './v0'

const log = logger('HeaderBlock/admin')

export interface ActionButton {
  label: string
  action: 'READ' | 'DOWNLOAD' | 'LOGIN' | 'CONTACT'
  customLabel: string
  linkProp: string
}

export const buttons = R.map(
  ({button: label}, action) => ({label, action, customLabel: '', linkProp: ''}),
  v0.actions,
) as {[k in keyof typeof v0.actions]: ActionButton}

export interface Config extends A.BlockConfig {
  VERSION: 1
  alignment: 'left' | 'center' | 'right'
  headingSize: 'small' | 'normal' | 'large'
  layout: 'above' | 'below' | 'left' | 'right' | 'none'
  subHeadingProp: string
  headingProp: string
  otherProps: string[]
  callsToAction: {
    canAccess: {
      message: string
      buttons: ActionButton[]
    }
    noAccessSignedOut: {
      message: string
      buttons: ActionButton[]
    }
    noAccessSignedIn: {
      message: string
      buttons: ActionButton[]
    }
  }
}

export const defaultConfig: Config = {
  VERSION: 1,
  alignment: 'center', // only applies to single column
  headingSize: 'normal',
  layout: 'right',
  subHeadingProp: 'Authors',
  headingProp: '_name',
  otherProps: [],
  callsToAction: {
    canAccess: {
      message: '',
      buttons: [buttons.READ, buttons.DOWNLOAD],
    },
    noAccessSignedOut: {
      message: 'Already have an account? Please sign in.',
      buttons: [buttons.LOGIN],
    },
    noAccessSignedIn: {
      message:
        'This content is for paid subscribers only. Get in touch to learn more.',
      buttons: [buttons.CONTACT],
    },
  },
  FREEMARKER: {
    'callsToAction.*.message': 'markdown',
  },
}

export const migrate: A.Migrate<Config> = props => {
  const unknown = props.config as any

  if ((unknown?.VERSION ?? 0) > 1) {
    log.error(`VERSION=${unknown.VERSION} in v1.migrate`)
    return unknown as Config
  }

  let config: Partial<Config> | undefined =
    unknown?.VERSION === 1 ? (unknown as Config) : undefined

  if (!config) {
    const c0: v0.Config = v0.migrate(props)

    void `
    +---------------------------------------------------------------------------------------
    | v0: {                                | v1: {
    |   callsToAction: {                   |   callsToAction: {
    |     readOnline: {label},             |     canAccess: {
    |     download: {label},               |       message,
    |     signIn: {label, description},    |       buttons: [
    |     hmm: {label, description},       |         {label, action, customLabel, linkProp},
    |   },                                 |         {label, action, customLabel, linkProp},
    |   customCallsToAction: {             |       ],
    |     canAccess: [                     |     },
    |       {label, description, linkProp} |     noAccessSignedOut: {
    |     ],                               |       message,
    |     noAccessSignedOut: [             |       buttons: [
    |       {label, description, linkProp} |         {label, action, customLabel, linkProp}
    |     ],                               |       ],
    |     noAccessSignedIn: [              |     },
    |       {label, description, linkProp} |     noAccessSignedIn: {
    |     ],                               |       message,
    |   },                                 |       buttons: [
    | }                                    |         {label, action, customLabel, linkProp}
    |                                      |       ],
    |                                      |     },
    |                                      |   },
    |                                      | }
    +---------------------------------------------------------------------------------------`

    /**
     * Migrate v0 descriptions to v1 message. In some cases (noAccessSignedOut
     * and noAccessSignedIn), v0 allowed for completely separate messages.
     * Initially we tried to retain this with generated Freemarker, but
     * Freemarker runs on the un-migrated config, at least until someone clicks
     * "Save" in the admin dialog. So instead, drop custom.description since
     * nobody's using it anyway.
     */
    const migrateMessage = ({cta: {description = ''}, custom}: any) => {
      if (custom && custom.label && custom.linkProp && custom.description) {
        log.browser.warn(
          'migrate dropping custom description, it was:',
          custom.description,
        )
      }
      return description
    }

    /**
     * Migrate v0 separate callsToAction and customCallsToAction to v1 single
     * structure.
     */
    const migrateButton = ({action, cta, custom}: any) => ({
      action,
      label: cta.label,
      customLabel: custom?.label || '',
      linkProp: custom?.linkProp || '',
    })

    // Migrate v0 to v1
    config = {
      ...(R.pick(R.keys(defaultConfig), c0) as Partial<Config>),
      VERSION: 1,

      callsToAction: {
        canAccess: {
          message: migrateMessage({
            cta: c0.callsToAction.readOnline,
            custom: c0.customCallsToAction.canAccess[0],
          }),
          buttons: [
            // The v0 config was ([A, B] || [A']) and the v1 config is
            // [A || A', B || B']. There is no proper migration path at the data
            // level, because we can't conditionalize B on A' (because we don't
            // know what the linkProp will return). However if we migrate to
            // [A || A', B || A'] then the HeaderBlock will deduplicate, and the
            // effect is the same.
            migrateButton({
              action: 'READ',
              cta: c0.callsToAction.readOnline, // A
              custom: c0.customCallsToAction.canAccess[0], // A'
            }),
            migrateButton({
              action: 'DOWNLOAD',
              cta: c0.callsToAction.download, // B
              custom: c0.customCallsToAction.canAccess[0], // A' -- intentional!
            }),
          ],
        },

        noAccessSignedOut: {
          message: migrateMessage({
            cta: c0.callsToAction.signIn,
            custom: c0.customCallsToAction.noAccessSignedOut[0],
          }),
          buttons: [
            migrateButton({
              action: 'LOGIN',
              cta: c0.callsToAction.signIn,
              custom: c0.customCallsToAction.noAccessSignedOut[0],
            }),
          ],
        },

        noAccessSignedIn: {
          message: migrateMessage({
            cta: c0.callsToAction.hmm,
            custom: c0.customCallsToAction.noAccessSignedIn[0],
          }),
          buttons: [
            migrateButton({
              action: 'CONTACT',
              cta: c0.callsToAction.hmm,
              custom: c0.customCallsToAction.noAccessSignedIn[0],
            }),
          ],
        },
      },
    }
  }

  return deepMerge(defaultConfig)(config)
}
