import {AdminPreRenderProps, AdminRender} from 'quickstart/types'
import {AdminWrapper} from './AdminWrapper'
import {createRoot} from 'react-dom/client'

/**
 * Block admin renderer. This is provided in the block's info structure as
 * `admin`, so that renderBlockAdmin in src/entry-client.ts can render into the
 * block config dialog in the admin.
 */
export const adminRenderer = <C,>({
  Admin,
  ...preProps
}: AdminPreRenderProps<C>) => {
  const renderAdmin: AdminRender = ({root: container, render, ...props}) => {
    const wrappedAdmin = (
      <AdminWrapper component={Admin} {...preProps} {...props} />
    )
    // Under test, pass back whatever the render function returns.
    // Note that the test still needs to pass in a textarea.
    if (render) {
      return render(wrappedAdmin)
    }
    // In normal operation, render and return the cleanup function.
    const root = createRoot(container)
    root.render(wrappedAdmin)
    return () => {
      root.unmount()
    }
  }
  return renderAdmin
}
