import {useLocation} from 'quickstart/hooks'
import {useState} from 'react'
import {useMatch, useNavigate} from 'react-router-dom'

import {logger} from 'tizra'

const log = logger('SearchBlock/useSearchRouter')

export const TILDE_ADVANCED = '~advancedSearch'
export const TILDE_RESULTS = '~searchResults'

export const useSearchRouter = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const routedAdvanced = [
    useMatch(`/:urlId/${TILDE_ADVANCED}`),
    useMatch(`/${TILDE_ADVANCED}`),
  ].some(Boolean)
  const routedResults = [
    useMatch(`/:urlId/${TILDE_RESULTS}`),
    useMatch(`/${TILDE_RESULTS}`),
  ].some(Boolean)
  const isRoutedSearch = routedAdvanced || routedResults

  const [unroutedAdvanced, setUnroutedAdvanced] = useState(false)

  const onAdvancedSearch = isRoutedSearch ? routedAdvanced : unroutedAdvanced

  const switchToAdvanced = () =>
    onAdvancedSearch ? log.warn('tried switchToAdvanced while onAdvancedSearch')
    : isRoutedSearch ?
      navigate({
        ...location,
        pathname: location.pathname.replace(TILDE_RESULTS, TILDE_ADVANCED),
        hash: '',
      })
    : setUnroutedAdvanced(true)

  const switchToResults = () =>
    !onAdvancedSearch ? log.warn('tried switchToResults while onSearchResults')
    : isRoutedSearch ?
      navigate({
        ...location,
        pathname: location.pathname.replace(TILDE_ADVANCED, TILDE_RESULTS),
        hash: '',
      })
    : setUnroutedAdvanced(false)

  return {
    isRoutedSearch,
    onAdvancedSearch,
    switchToAdvanced,
    switchToResults,
  }
}
