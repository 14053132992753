import styled, {
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import * as R from 'rambdax'

interface GridProps
  extends Pick<
    SystemProps,
    | 'gridGap'
    | 'gridColumnGap'
    | 'gridRowGap'
    | 'gridTemplateColumns'
    | 'p'
    | 'px'
    | 'pl'
    | 'pr'
  > {
  cols?: any
  colGap?: any
  rowGap?: any
  inset?: any
}

// @ts-expect-error
const resolve = (theme, scale, fn = R.identity) =>
  // @ts-expect-error
  function _resolve(x) {
    return typeof x === 'object' ?
        R.map(_resolve, x)
      : fn(th(scale)({theme})?.[x] ?? x)
  }

// @ts-expect-error
const mult = (theme, scale, plier, x) =>
  // @ts-expect-error
  resolve(theme, scale, x => `calc((${x}) * (${plier}))`)(x)

const gridColumns = ({cols, gridTemplateColumns}: GridProps) => {
  return cols && !gridTemplateColumns ?
      {
        gridTemplateColumns: R.pipe(
          R.when(x => typeof x !== 'object', R.objOf('xs')),
          // minmax ref https://css-tricks.com/preventing-a-grid-blowout/
          R.map(c => `repeat(${c}, minmax(0, 1fr))`),
          R.mergeRight({xs: 'minmax(0, 1fr)'}),
        )(cols) as any,
      }
    : {}
}

const gridGaps = ({
  theme,
  colGap,
  rowGap,
  gridGap,
  gridColumnGap,
  gridRowGap,
}: GridProps & {theme: any}) => ({
  gridColumnGap: gridColumnGap ?? colGap ?? gridGap ?? th('colGap')({theme}),
  gridRowGap: gridRowGap ?? rowGap ?? gridGap ?? th('rowGap')({theme}),
})

const gridInset = ({
  inset,
  theme,
  gridColumnGap,
  p,
  px = p,
  pl = px,
  pr = px,
}: GridProps & {theme: any}) => {
  if (inset === true) {
    inset = 0.5
  }
  if (typeof inset === 'number' && gridColumnGap) {
    inset = mult(theme, 'space', inset, gridColumnGap)
  }
  return inset ?
      {
        pl: pl ?? inset,
        pr: pr ?? inset,
      }
    : {}
}

export const Grid = styled.div
  .attrs<GridProps>(gridColumns)
  .attrs(gridGaps)
  .attrs(gridInset)<GridProps>`
    display: grid;
    ${system};
  `
