import {
  Box,
  ConnectedField,
  Link,
  SearchFacets,
  SearchForm,
  SearchResults,
  SearchTags,
  SearchTermsInput,
  Section,
  Sidebar,
  Stack,
  Text,
} from 'quickstart/components'
import {UseSearchReturn} from 'quickstart/hooks'
import {formFields} from 'quickstart/lib/search/config'
import * as R from 'rambdax'
import {useMemo} from 'react'
import {logger} from 'tizra'

const log = logger('SearchBlock/Results')

interface ResultsProps {
  search: UseSearchReturn
  showPageThumbnails: boolean
  switchToAdvanced: () => void
}

export const Results = ({
  search,
  search: {config},
  showPageThumbnails,
  switchToAdvanced,
}: ResultsProps) => {
  // Technically the terms field can be configured out, though this would need
  // some UX design before it would be a usable configuration.
  const termsField = useMemo(
    () => formFields(config, 'desktopFilters').find(R.propEq('terms', 'name')),
    [config],
  )

  return (
    <Section>
      <Box row>
        <Sidebar>
          <SearchFacets search={search} />
        </Sidebar>
        <Box col>
          <Stack spacing="xl" wrapChildren={false}>
            <Stack wrapChildren={false}>
              <Box row alignItems="baseline">
                <Text variant="h1">Search</Text>
                <Link ml="lg" variant="ui" onClick={switchToAdvanced}>
                  Use advanced search
                </Link>
              </Box>
              {termsField && (
                <SearchForm search={search}>
                  <ConnectedField
                    autoFocus
                    component={SearchTermsInput}
                    name="terms"
                    search={search}
                    tips={termsField.tips}
                  />
                </SearchForm>
              )}
            </Stack>
            <SearchTags search={search} />
            <SearchResults
              search={search}
              showPageThumbnails={showPageThumbnails}
            />
          </Stack>
        </Box>
      </Box>
    </Section>
  )
}
