import {withTheme} from 'quickstart/theme'
import {Rethemable, retheme, styled, theme} from 'quickstart/theme/linaria'

type FlexProps = {
  $columnGap?: Rethemable
  $gap?: Rethemable
  $rowGap?: Rethemable
}

export const Flex = withTheme(styled.div<FlexProps>`
  display: flex;
  flex-flow: row wrap;
  column-gap: ${retheme(['$columnGap', '$gap'], theme.space.xl)};
  row-gap: ${retheme(['$rowGap', '$gap'], theme.space.xxl)};
  container-type: inline-size;
  :where(& > *) {
    flex: 1 1 100%;
    @container (min-width: 400px) {
      flex: 1 1 0;
    }
  }
`)
