import {FORM_ERROR} from 'final-form'
import {Image} from 'quickstart/components/content/Image'
import {Prose, Text} from 'quickstart/components/content/Text'
import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {Input} from 'quickstart/components/controls/Input'
import {FormShell, FormShellProps} from 'quickstart/components/layout/FormShell'
import {focusError, formErrors} from 'quickstart/utils/final-form-errors'
import {ReactNode, useState} from 'react'
import * as Final from 'react-final-form'
import {ApiError, api, logger} from 'tizra'
import {AuthLink, AuthLinkProps} from './AuthLink'
import {remoteLogin} from './remoteLogin'

const log = logger('AuthSignIn')

export const SIGN_IN = 'Sign in'

interface AuthSignInProps {
  authAgent: 'tizra' | 'remote'
  passwordReset: {enabled: boolean; url: string}
  registration: {enabled: boolean; url: string}
  autoFocus?: boolean
  heading?: string | null
  logoUrl?: string
  message?: ReactNode
  modal?: boolean
  show?: AuthLinkProps['show']
  successUrl?: string
  variant?: FormShellProps['variant'] | 'checkout'
}

export const AuthSignIn = ({
  authAgent,
  autoFocus,
  logoUrl,
  message,
  modal = false,
  passwordReset,
  registration,
  show,
  successUrl,
  variant = FormShell.defaultVariant(modal),
  heading = variant === 'checkout' ? null : 'Sign In',
}: AuthSignInProps) => {
  const formShellVariant = variant === 'checkout' ? 'fluid' : variant
  const [needTotp, setNeedTotp] = useState(false)

  log.assert(
    authAgent === 'tizra' || authAgent === 'remote',
    `unknown authAgent: ${authAgent}`,
  )

  const submit = async (values: any) => {
    let {status, errors, message, reason} = await (
      authAgent === 'remote' ?
        remoteLogin(values)
      : api.login(values)).catch(e => {
      log.error(e)
      // Only here for 500, not 40x per entry in info.ts
      return {
        status: (e instanceof ApiError && e.status) || 0,
        reason: 'exception',
        message: `${e}`,
        errors: undefined,
      }
    })

    if (status === 200) {
      // Don't default successUrl here, because we might be in the modal, or
      // otherwise on the intended destination already. If the caller
      // (SignInBlock) wants to redirect elsewhere, they can pass it in.
      if (successUrl) {
        window.location.href = successUrl
      } else {
        window.location.reload()
      }
      return // React Final Form will set submitSucceeded
    }

    if (status === 400 && errors && 'code' in errors) {
      setNeedTotp(true)
    }

    const messages = {
      [FORM_ERROR]: {
        canceled: <Text>Your account has been closed.</Text>,
        email: (
          <Text>
            Account has not been activated yet. Please click the activation link
            in your email.
          </Text>
        ),
        pending: (
          <Text>
            Your account is currently pending. Please check back shortly.
          </Text>
        ),
        invalid: (
          <Prose>
            <Text>
              {(needTotp && message) || 'Incorrect username or password.'}
            </Text>
            {passwordReset.enabled && (
              <Text>
                If you do not remember your password, you can{' '}
                <AuthLink
                  href={passwordReset.url}
                  show={show}
                  successUrl={successUrl}
                  to="resetPassword"
                >
                  reset it here.
                </AuthLink>
              </Text>
            )}
          </Prose>
        ),
      },
    }

    return formErrors({errors, reason, message, messages, status})
  }

  return (
    <Final.Form
      onSubmit={submit}
      decorators={[focusError]}
      render={({handleSubmit, submitError, submitSucceeded}) =>
        submitSucceeded ?
          <FormShell
            header={heading && 'Success!'}
            modal={modal}
            variant={formShellVariant}
          >
            <Text>Redirecting...</Text>
          </FormShell>
        : <FormShell
            modal={modal}
            variant={formShellVariant}
            onSubmit={handleSubmit}
            errorMessage={submitError}
            helpMessage={message}
            header={
              variant === 'modalish' && logoUrl ?
                <Image
                  loading="eager"
                  src={logoUrl}
                  style={{
                    maxHeight: '50px',
                    width: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              : heading
            }
            primaryButton={{
              type: 'submit',
              children: SIGN_IN,
            }}
            footer={
              registration.enabled && (
                <Text
                  style={{
                    textAlign:
                      variant === 'modal' || variant === 'modalish' ?
                        'center'
                      : undefined,
                  }}
                >
                  No account?{' '}
                  <AuthLink
                    href={registration.url}
                    variant="ui"
                    show={show}
                    successUrl={successUrl}
                    to="register"
                  >
                    Create one here
                  </AuthLink>
                </Text>
              )
            }
          >
            <ConnectedField
              autoFocus={autoFocus && !needTotp}
              component={Input}
              label="Username"
              name="username"
              autoComplete="username"
            />
            <ConnectedField
              component={Input}
              label="Password"
              rightLabel={
                !passwordReset.enabled ? undefined : (
                  <AuthLink
                    href={passwordReset.url}
                    variant="ui"
                    // See XXX in Link/theme.js
                    lineHeight="inherit"
                    show={show}
                    successUrl={successUrl}
                    to="resetPassword"
                  >
                    Forgot your password?
                  </AuthLink>
                )
              }
              name="password"
              type="password"
              autoComplete="current-password"
            />
            {needTotp && (
              <ConnectedField
                autoFocus={autoFocus && needTotp}
                component={Input}
                label="Two-factor code"
                name="code"
                autoComplete="one-time-code"
              />
            )}
          </FormShell>
      }
    />
  )
}
