import {Prose, Text} from 'quickstart/components/content/Text'
import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {Input} from 'quickstart/components/controls/Input'
import {FormShell, FormShellProps} from 'quickstart/components/layout/FormShell'
import {FCx} from 'quickstart/types'
import {focusError, formErrors} from 'quickstart/utils/final-form-errors'
import {ReactNode} from 'react'
import * as Final from 'react-final-form'
import {api} from 'tizra'
import {AuthLink, AuthLinkProps} from './AuthLink'

interface AuthResetPasswordProps {
  autoFocus?: boolean
  heading?: string | null
  logoUrl?: string
  message?: ReactNode
  modal?: boolean
  show?: AuthLinkProps['show']
  signin: {url: string}
  successUrl?: string
  variant?: FormShellProps['variant'] | 'checkout'
}

export const AuthResetPassword: FCx<AuthResetPasswordProps> = ({
  autoFocus,
  message,
  modal = false,
  show,
  signin,
  successUrl,
  variant = FormShell.defaultVariant(modal),
  heading = variant === 'checkout' ? null : 'Reset Password',
}) => {
  const formShellVariant = variant === 'checkout' ? 'fluid' : variant

  const submit = async (values: any) => {
    const {status, errors, message, reason} = await api.passwordReset({
      successUrl,
      ...values,
    })
    if (status === 200) {
      return // React Final Form will set submitSucceeded
    }
    return formErrors({errors, reason, message, status})
  }

  return (
    <Final.Form
      onSubmit={submit}
      decorators={[focusError]}
      render={({
        handleSubmit,
        submitError,
        submitSucceeded,
        values: {email} = {},
      }) =>
        submitSucceeded ?
          <FormShell
            header={heading && 'Reset Initiated'}
            modal={modal}
            variant={formShellVariant}
          >
            <Prose>
              <Text>A password reset link has been sent to {email}.</Text>
              <Text>Check your spam folder if it doesn't arrive shortly.</Text>
            </Prose>
          </FormShell>
        : <FormShell
            modal={modal}
            variant={formShellVariant}
            header={heading}
            onSubmit={handleSubmit}
            errorMessage={submitError}
            helpMessage={message}
            preface={
              variant !== 'checkout' &&
              "Enter your email and we'll send a link for changing your password."
            }
            primaryButton={{
              type: 'submit',
              children: 'Start reset process',
            }}
            footer={
              <Text
                style={{
                  textAlign:
                    variant === 'modal' || variant === 'modalish' ?
                      'center'
                    : undefined,
                }}
              >
                Did you remember?{' '}
                <AuthLink
                  href={signin.url}
                  variant="ui"
                  // See XXX in Link/theme.js
                  lineHeight="inherit"
                  show={show}
                  successUrl={successUrl}
                  to="signin"
                >
                  Go back to sign in
                </AuthLink>
              </Text>
            }
          >
            <ConnectedField
              autoComplete="username"
              autoFocus={autoFocus}
              component={Input}
              label="Email address"
              name="email"
            />
          </FormShell>
      }
    />
  )
}
