import {Children, ReactNode} from 'react'
import * as S from './styles'

type GridProps = React.ComponentProps<typeof S.Grid> & {
  children?: ReactNode
  cols?: any
  inset?: any
  reversed?: boolean
  theme?: any
}

export const Grid = ({
  children,
  reversed = false,

  // XXX work around Linaria passing incompatible theme
  theme,

  ...props
}: GridProps) => (
  <S.Grid {...props}>
    {reversed ? Children.toArray(children).reverse() : children}
  </S.Grid>
)
