import {dataProps} from 'quickstart/theme'
import {ComponentProps, createContext, ReactNode, useContext} from 'react'
import {Error} from './Error'
import {Label} from './Label'
import * as S from './styles'

// copy-pasta from ariakit
interface StringLike {
  toString: () => string
  valueOf: () => string
}

export const defaultSize = 'lg'

export type FieldSize = 'sm' | 'md' | 'lg'

// This exists to push field size information into the SelectPopover portal,
// since we can't get there with CSS.
export const FieldContext = createContext<{size: FieldSize}>({
  size: defaultSize,
})

export interface FieldProps extends ComponentProps<typeof S.Field> {
  name: StringLike
  label?: ReactNode
  rightLabel?: ReactNode
  hint?: ReactNode
  size?: FieldSize
}

// TODO: hint
export const Field = ({
  name,
  label,
  rightLabel,
  hint,
  children,
  size,
  ...props
}: FieldProps) => {
  // Get the wrapping FieldContext provided by Form
  const fieldContext = useContext(FieldContext)
  size ||= fieldContext.size

  return (
    <FieldContext value={{size}}>
      <S.Field {...dataProps({size})} {...props}>
        {(label || rightLabel) && (
          <S.LabelWrapper>
            <Label name={name}>{label}</Label>
            <S.RightLabel>{rightLabel}</S.RightLabel>
          </S.LabelWrapper>
        )}
        {children}
        <Error name={name} />
        <S.Hint>{hint}</S.Hint>
      </S.Field>
    </FieldContext>
  )
}
