import {SyntheticEvent} from 'react'

export const preventDefault = <T extends SyntheticEvent>(
  onClick: undefined | ((ev: T) => void),
) =>
  onClick &&
  ((ev: T) => {
    ev.preventDefault()
    return onClick(ev)
  })
