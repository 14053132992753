import {logger, MetaObject, SearchTypes} from 'tizra'
import {useBlockContext} from './useBlockContext'
import {useMetaObj, UseMetaObjProps} from './useMetaObj'
import {useTypeDefs} from './useTypeDefs'

const log = logger('useMetaObjInfo')

type UseMetaObjInfoProps<T extends MetaObject> = UseMetaObjProps<T>

type TrueResult = {
  metaObj: MetaObject | undefined
  metaType: string
  tizraId: string
  typeDefs: SearchTypes | undefined
}

type FalseResult = {
  metaObj: undefined
  metaType: undefined
  tizraId: undefined
  typeDefs: undefined
}

/**
 * Fetch a metaObj with its typeDefs and metaType from the API.
 */
export function useMetaObjInfo<T extends MetaObject>(
  props: UseMetaObjInfoProps<T>,
): {
  metaObj: T
  metaType: string
  tizraId: string
  typeDefs: SearchTypes | undefined
}
export function useMetaObjInfo(props: false): FalseResult
export function useMetaObjInfo(props: true): TrueResult
export function useMetaObjInfo(props: boolean): FalseResult | TrueResult
export function useMetaObjInfo<T extends MetaObject>(
  _props: UseMetaObjInfoProps<T> | boolean,
) {
  const contextProps = useBlockContext(['metaType', 'tizraId'])
  const props =
    !_props ? {enabled: false}
    : _props === true ? contextProps
    : _props

  const metaObj = useMetaObj(props)
  const metaType = (metaObj || props).metaType
  const tizraId = (metaObj || props).tizraId
  const typeDefs = useTypeDefs(metaType && [metaType])

  return {metaObj, metaType, tizraId, typeDefs}
}
