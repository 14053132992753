import {RefObject, useEffect, useId} from 'react'
import {logger} from 'tizra'

const log = logger('useScriptRunner')

interface UseScriptRunnerProps {
  html: string
  ref: RefObject<HTMLElement | null>
  _eval?: (x: string) => any // for tests
}

export const useScriptRunner = ({
  html,
  ref,
  _eval = eval,
}: UseScriptRunnerProps): string => {
  const sentinalScriptId = useId()

  // Append a script that runs if this HTML content is rendered during SSR. This
  // avoids running any scripts embedded in the HTML twice.
  const sentinalScript = `<script id="${sentinalScriptId}">
if (!window.tizra) {window.tizra = {}}
if (!window.tizra.quickstart) {window.tizra.quickstart = {}}
if (!window.tizra.quickstart._ranScripts) {window.tizra.quickstart._ranScripts = new Set()}
window.tizra.quickstart._ranScripts.add('${sentinalScriptId}')
</script>`

  // Run any embedded scripts when this component renders on the client.
  useEffect(() => {
    if (sentinalScriptId && ref.current) {
      const ranScripts: Set<string> = (window.tizra.quickstart._ranScripts ||=
        new Set())

      if (ranScripts.has(sentinalScriptId)) {
        // We've already run the scripts in this component.
        return
      }

      // Avoid querySelector etc. because the id returned by React.useId
      // contains characters that don't work in selectors. At the same time,
      // I'd rather not use document.getElementById because I don't fully
      // trust it will be unique, so it's better to constrain traversal to the
      // interior of this component.
      const scripts = Array.from(
        ref.current.getElementsByTagName('script'),
      ).filter(el => !el.src)
      if (!scripts.some(el => el.id === sentinalScriptId)) {
        // If the sentinal script doesn't appear, that either means there are
        // no scripts, or the variant stage is stripping them.
        return
      }

      // Eval each of the embedded scripts, including the sentinal. Note that
      // this calls eval indirectly via the _eval prop, and this serves two
      // purposes. First, it evals in global context, search for "indirect" at
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
      // Second, it allows tests to pass an alternate _eval.
      for (const script of scripts) {
        log.log('running script', script)
        _eval(script.innerHTML)
      }

      // ranScripts should now contain the sentinal id, courtesy of the
      // sentinal script.
      log.assert(
        ranScripts.has(sentinalScriptId),
        `missing ${sentinalScriptId} from _ranScripts`,
      )
    }
  }, [_eval, html, ref, sentinalScriptId])

  // To avoid a memory leak, remove the id from
  // window.tizra.quickstart._ranScripts when this component unmounts.
  useEffect(
    () => () => {
      sentinalScriptId &&
        window.tizra.quickstart._ranScripts?.delete(sentinalScriptId)
    },
    [sentinalScriptId],
  )

  return /<script/i.test(html) ? `${html}${sentinalScript}` : html
}
