import styled, {
  SystemProps,
  system,
  th,
} from 'quickstart/styled-components/system'

export const List = styled.dl<SystemProps>`
  margin: 0;
  ${system};
`

// If we ever want to implement spacing on ListDefinition, then do it here:
//
//   margin-top: ${th(`space.${space}`)};
//   &:first-child: {
//     margin-top: 0;
//   }
//
// This surprisingly works well with <List columnCount={2}>. The margin on the
// top of the first item in the second column is swallowed automatically by the
// browser.
export const Item = styled.div<SystemProps>`
  break-inside: avoid-column;
  ${system};
`

export const Term = styled.dt<SystemProps>`
  display: inline;
  margin: 0;
  ${th('listDefinitions.term')};
  ${system};
`

export const Details = styled.dd<SystemProps>`
  display: inline;
  margin: 0;
  ${th('listDefinitions.details')};
  ${system};
`
