import * as B from '../block'
import {defaultConfig, TocAdmin} from './admin'

export const TocBlock = B.blockWithInfo(
  {
    name: 'content-toc',
    displayName: 'TocBlock',
    title: 'Table of Contents',
    Admin: TocAdmin,
    defaultConfig,
  },
  ({config: {heading, labelFreeContent, showPageNums, style}}) => {
    const metaObj = B.useMetaObj(true)
    const content =
      B.useMetaToc({
        metaObj,
        labelFreeContent,
        showPageNums,
        variant: style,
      }) || null

    return (
      <B.Scene>
        {content && <B.Section heading={heading}>{content}</B.Section>}
      </B.Scene>
    )
  },
)
