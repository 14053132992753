import styled, {css, th} from 'quickstart/styled-components/system'

interface ImageProps {
  contain?: boolean
  fluid?: boolean
  hoverable?: boolean
  bordered?: boolean
  shadowed?: boolean
}

export const Image = styled.img<ImageProps>(
  ({contain, fluid, hoverable, bordered, shadowed}) => css`
    display: block;
    ${fluid &&
    css`
      height: auto;
      width: 100%;
    `};
    ${contain &&
    css`
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    `};
    ${bordered && th('images.bordered')};
    ${shadowed && th('images.shadowed')};
    ${hoverable && th('images.hoverable')};
  `,
)

export const Caption = styled.div`
  ${th('images.caption')};
`
