import styled, {css, th} from 'quickstart/styled-components/system'

export const Results = styled.div``

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 0.75rem 0.5rem;
  border-bottom: ${th('borders.fine')};
`

export const Body = styled.div``

interface ItemProps {
  divided: boolean
  lastItem: boolean
}

export const Item = styled.div<ItemProps>(
  ({divided, lastItem}) => css`
    padding: 1.5rem 0.75rem;
    ${divided &&
    !lastItem &&
    css`
      border-bottom: ${th('borders.fine')};
    `};
    .t-search-snippet-highlight {
      font-weight: ${th('fontWeights.bold')};
    }
  `,
)
