import {UsePropOptionsProps} from 'quickstart/blocks/admin/base/hooks/usePropOptions'
import {MetaThumb} from 'quickstart/components/tizra/MetaThumb'
import {useMetaValue} from 'quickstart/components/tizra/MetaValue'
import {ComponentProps} from 'react'
import {meta as M, MetaObject, PropType, TocEntry, semiSplit} from 'tizra'

export const imageCroppings = [
  'full',
  'landscape',
  'square',
  'portrait',
] as const

export type ImageCropping = (typeof imageCroppings)[number]

export const imageFocuses = [
  '',
  'n',
  'ne',
  'e',
  'se',
  's',
  'sw',
  'w',
  'nw',
] as const

export type ImageFocus = (typeof imageFocuses)[number]

// Global for all Browse displays.
interface SlotDef {
  label: string
  propOptions: UsePropOptionsProps
}

// Specific for each Browse display.
export interface SlotProps {
  force?: boolean
}

export type SlotName =
  | 'cover'
  | 'label'
  | 'title'
  | 'meta'
  | 'snippet' // Cards have this
  | 'description' // Tiles have this
  | 'cta'
  | 'link'

const stringTypes: PropType[] = ['keyword', 'string']

const textTypes: PropType[] = [...stringTypes, 'html', 'text', 'markdown']

export const slotDefs: {[k in SlotName]: SlotDef} = {
  cover: {
    label: 'Cover Image',
    propOptions: {types: stringTypes},
  },
  label: {
    label: 'Label',
    propOptions: {types: textTypes},
  },
  title: {
    label: 'Title',
    propOptions: {types: textTypes},
  },
  meta: {
    label: 'Meta',
    propOptions: {types: textTypes},
  },
  snippet: {
    label: 'Snippet',
    propOptions: {types: textTypes},
  },
  description: {
    label: 'Description',
    propOptions: {types: textTypes},
  },
  cta: {
    label: 'Call to Action',
    propOptions: {types: textTypes},
  },
  link: {
    label: 'Link to',
    propOptions: {types: stringTypes},
  },
}

export type SlotConfig = {enabled: boolean; prop: string}

export const defaultSlots: {[k in SlotName]: SlotConfig} = {
  cover: {
    enabled: true,
    prop: 'CardCoverImage',
  },
  label: {
    enabled: true,
    prop: 'CardMediaType',
  },
  title: {
    enabled: true,
    prop: 'CardTitle',
  },
  meta: {
    enabled: true,
    prop: 'Authors',
  },
  snippet: {
    enabled: true,
    prop: 'Authors',
  },
  description: {
    enabled: true,
    prop: '',
  },
  cta: {
    enabled: true,
    prop: 'CardCTA',
  },
  link: {
    enabled: true,
    prop: 'CardLink',
  },
}

export const autoMediaType = (metaObj?: MetaObject | TocEntry) => {
  if (metaObj) {
    return metaObj.metaType === 'PageRange' ? 'Excerpt' : metaObj.metaType
  }
}

interface UseMetadataProps {
  metaObj?: MetaObject
  slots?: {[k in SlotName]?: SlotConfig}
  thumbProps?: ComponentProps<typeof MetaThumb>
}

export const useMetadata = ({metaObj, slots, thumbProps}: UseMetadataProps) => {
  const href =
    slots?.link?.enabled &&
    (M.string(metaObj, slots.link.prop) || M.href(metaObj))

  const cover = slots?.cover?.enabled && (
    <MetaThumb
      metaObj={metaObj}
      fallback
      coverImageProp={slots.cover.prop}
      {...thumbProps}
    />
  )

  const label =
    useMetaValue({metaObj, prop: slots?.label?.enabled && slots.label.prop}) ||
    (slots?.label?.enabled && autoMediaType(metaObj)) ||
    undefined

  const title = useMetaValue({
    metaObj,
    prop: slots?.title?.enabled && [...semiSplit(slots.title.prop), '_name'],
  })

  const meta = useMetaValue({
    metaObj,
    prop: slots?.meta?.enabled && slots.meta.prop,
  })

  const snippet = useMetaValue({
    metaObj,
    prop: slots?.snippet?.enabled && slots.snippet.prop,
  })

  const description = useMetaValue({
    metaObj,
    prop: slots?.description?.enabled && slots.description.prop,
  })

  const cta =
    useMetaValue({metaObj, prop: slots?.cta?.enabled && slots.cta.prop}) ||
    (slots?.cta?.enabled && 'Learn more') ||
    undefined

  return {href, cover, label, title, meta, snippet, description, cta}
}
