import * as R from 'rambdax'
import {useCallback, useMemo, useEffect, DependencyList} from 'react'
import debounce from 'lodash/debounce'
import {useEqualBy} from './useEqualBy'
import {DebounceSettings, DebouncedFunc} from 'lodash'
import {nullish} from 'tizra'

interface UseDebouncedCallbackOptions extends DebounceSettings {
  wait?: number
}

/**
 * Conditionally debounced callback using lodash.debounce. Will only debounce if
 * wait is defined and not null.
 */
export const useDebouncedCallback = <T extends (...args: any) => void>(
  fn: T,
  {wait, ...options}: UseDebouncedCallbackOptions = {},
  deps: DependencyList,
): T | DebouncedFunc<T> => {
  // Raw undebounced callback, only updates when deps change
  const callback = useCallback(fn, deps) // eslint-disable-line react-hooks/exhaustive-deps

  // Stable object ref for options, to avoid triggering useMemo
  options = useEqualBy(R.equals, options)

  // Stable debounced callback function
  const debouncedCallback = useMemo(
    () =>
      !callback || nullish(wait) ? callback : debounce(callback, wait, options),
    [callback, wait, options],
  )

  // Cancel callback whenever it updates, including unmount
  useEffect(
    () =>
      debouncedCallback && 'cancel' in debouncedCallback ?
        debouncedCallback.cancel
      : undefined,
    [debouncedCallback],
  )

  return debouncedCallback
}
