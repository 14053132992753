import {ServerDefinition} from 'quickstart/lib/search/config'
import {deepMerge, meta} from 'tizra'
import * as A from '../admin'

// TODO Refactor using A.BrowseSpec and A.BrowseConfig. The challenges are:
//
// 1. BookshelfBlock has its own useBookshelfSearch. This means that useBrowse
//    will need to either (a) accept a replacement search argument, or (b)
//    support the special workarounds that are currently in useBookshelfSearch.
//
// 2. BookshelfBlock provides a sort control that we don't have on any other
//    Browse implementation so far. That will need to be rolled into useBrowse.

export interface BookshelfBlockSortProp {
  name: string
  defaultDirection: 'ascending' | 'descending'
  label: string
}

export interface Config {
  display: 'covers' | 'images'
  metaTypes: string[]
  requiredAdminTags: string
  excludedAdminTags: string
  sort: {
    props: BookshelfBlockSortProp[]
  }
  addContent: {
    enabled: boolean
  }
}

export const defaultConfig: Config = {
  display: 'covers',
  metaTypes: ['Book', 'Video'],
  requiredAdminTags: '',
  excludedAdminTags: '',
  sort: {
    props: [
      {name: 'Title', defaultDirection: 'ascending', label: 'Title'},
      {name: 'Authors', defaultDirection: 'ascending', label: 'Author'},
    ],
  },
  addContent: {
    enabled: false,
  },
}

export const Migrate: A.Migrate<Config> = ({config: unknownConfig}) => {
  let config = {...(unknownConfig as any)}

  // Accidentally allowed cards for BookshelfBlock, should always be either
  // covers with text, or just covers.
  if (config?.display === 'cards') {
    config = {...config, display: 'covers'}
  }

  return deepMerge(defaultConfig)(config)
}

const Sorting = () => {
  const sortPropOptions = A.usePropOptions({
    metaType: 'AdminTagged',
    publishedDate: true,
    spec: 'sub-prop-defs',
  }) as unknown as Array<{def?: ServerDefinition; value: string; label: string}>

  // Whenever one of the sort props changes, set the associated label and
  // default direction.
  const deriveSortLabel = ({value}: any) => {
    if (!value) {
      return ''
    }
    const displayName = meta.singular(
      sortPropOptions.find(o => o.value === value)?.def?.displayName || '',
    )
    return (
      displayName || (value === 'publishedDate' && 'Recently updated') || value
    )
  }
  const deriveSortDirection = ({value}: any) =>
    value === 'publishedDate' ? 'descending' : 'ascending'

  return (
    <A.FieldArray name="sort.props" orderable>
      {({fields}) =>
        fields.map((name, i) => (
          <A.Row key={i} alignItems="center">
            <A.RowItem>⣶</A.RowItem>
            <A.RowItem maxWidth="30%">
              <A.Select
                name={`${name}.name`}
                options={sortPropOptions}
                placeholder="Property"
                noneOption={true}
              />
            </A.RowItem>
            <A.RowItem width="4.5em">
              <A.Select
                name={`${name}.defaultDirection`}
                options={A.options({
                  ascending: 'Ascending by default',
                  descending: 'Descending by default',
                })}
                cascade={{
                  from: `${name}.name`,
                  derive: deriveSortDirection,
                }}
              />
            </A.RowItem>
            <A.RowItem>
              <A.Input
                placeholder="Label"
                name={`${name}.label`}
                cascade={{
                  from: `${name}.name`,
                  derive: deriveSortLabel,
                }}
              />
            </A.RowItem>
            <A.Button onClick={() => fields.remove(i)}>X</A.Button>
          </A.Row>
        ))
      }
      {({fields}) => (
        <A.Button
          mt="1rem"
          onClick={() => {
            const restoreDefault = defaultConfig.sort.props[
              fields.value.length
            ] as BookshelfBlockSortProp | undefined
            fields.push({
              defaultDirection: 'ascending',
              ...restoreDefault,
            })
          }}
        >
          Add sort option
        </A.Button>
      )}
    </A.FieldArray>
  )
}

export const BookshelfAdmin: A.Admin<Config> = () => {
  const metaTypeOptions = A.useMetaTypeOptions()
  const adminTagOptions = A.easyOptions(A.usePropValues('AdminTags'))

  return (
    <A.Tabs>
      <A.Tab title="Content">
        <A.Checklist
          label="Meta-types to include"
          name="metaTypes"
          options={metaTypeOptions}
        />
        <A.Input
          label="Required admin tags"
          name="requiredAdminTags"
          options={adminTagOptions}
        />
        <A.Input
          label="Excluded admin tags"
          name="excludedAdminTags"
          options={adminTagOptions}
        />
        <A.CheckRight
          label="Show add content button"
          name="addContent.enabled"
        />
      </A.Tab>
      <A.Tab title="Sorting">
        <Sorting />
      </A.Tab>
      <A.Tab title="Presentation">
        <A.Radio
          label="Display as"
          name="display"
          options={A.options({
            covers: 'Covers with titles',
            images: 'Covers only',
          })}
        />
      </A.Tab>
    </A.Tabs>
  )
}

export interface BookshelfBlockGlobalConfig {
  title: string
  url: string
}

export const defaultGlobalConfig: BookshelfBlockGlobalConfig = {
  title: 'My Bookshelf',

  // Don't change this default here. Change it in the pattern site instead. If
  // you change it here, it will apply to any sites that haven't saved their
  // global config, and that will break existing bookshelves on ~userLicenses
  url: '',
}

export const BookshelfGlobalAdmin: A.Admin<BookshelfBlockGlobalConfig> = () => (
  <A.Group>
    <A.Input
      label="Title for bookshelf"
      name="title"
      hint="This is used anywhere that Evergreen refers to the bookshelf automatically, including the heading on the bookshelf itself."
    />
    <A.Input
      label="Custom path for bookshelf"
      name="url"
      hint="Overrides the default of /user-id/~userLicenses"
    />
  </A.Group>
)
