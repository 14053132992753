import {useCallback, useEffect, useMemo} from 'react'
import {IS_STORYBOOK, logger} from 'tizra'
import * as B from '../block'
import {Advanced} from './Advanced'
import {Results} from './Results'
import {SearchAdmin, SearchGlobalAdmin, defaultGlobalConfig} from './admin'
import {TILDE_ADVANCED, TILDE_RESULTS, useSearchRouter} from './useSearchRouter'

const log = logger('SearchBlock')

const PREFIX = 'searchParam-'

export const SearchBlock = B.blockWithInfo(
  {
    name: 'search',
    displayName: 'SearchBlock',
    title: 'Search',
    Admin: SearchAdmin,
    GlobalAdmin: SearchGlobalAdmin,
    defaultGlobalConfig,
    ssrEnabled: false,
  },
  () => {
    const config = B.useGlobalConfig(SearchBlock)
    const searchConfig = B.useGlobalSearchConfig(config)

    const {isRoutedSearch, onAdvancedSearch, switchToAdvanced} =
      useSearchRouter()

    const [urlParams, _setUrlParams] = B.useSearchParams(
      searchConfig.config,
      PREFIX,
    )
    const setUrlParams = useCallback(
      (params: Parameters<typeof _setUrlParams>[0]) =>
        _setUrlParams(params, {
          location: loc => ({
            ...loc,
            pathname: loc.pathname?.replace(
              `/${TILDE_ADVANCED}`,
              `/${TILDE_RESULTS}`,
            ),
          }),
        }),
      [_setUrlParams],
    )

    const boostable = B.useHack('boost')

    const search = B.useSearch(
      {
        ...searchConfig,
        ...(isRoutedSearch && {
          urlParams,
          setUrlParams,
        }),
      },
      {
        boostable,
        enabled: !onAdvancedSearch,
        snippet: true,
      },
    )

    // Server sets title to "autotest: Search Results" or "autotest Advanced
    // Search" (yes, one has colon and the other doesn't, so we repair to always
    // include colon). Append the current search terms.
    const siteName = useMemo(() => {
      const siteName =
        typeof document === 'undefined' ? undefined : (
          document.title.match(
            /^(.*?):*\s+(search result|advanced search).*$/i,
          )?.[1]
        )
      // In storybook, document.title is "blocks-search--site-search"
      // so it won't match above.
      return siteName || (IS_STORYBOOK && 'storybook') || ''
    }, [])
    useEffect(() => {
      if (siteName) {
        const pageName = onAdvancedSearch ? 'Advanced Search' : 'Search Results'
        const forTerms =
          search.params.terms ? ` for ‘${search.params.terms}’` : ''
        document.title = `${siteName}: ${pageName}${forTerms}`
      }
      // This will only track virtual page views when we switch to/from advanced
      // search, or when terms change. I think that's probably good.
      try {
        window.tizra.trackPageview()
      } catch (e) {
        log.error('failed trackPageview', e)
      }
    }, [onAdvancedSearch, siteName, search.params.terms])

    return onAdvancedSearch ?
        <Advanced search={search} />
      : <Results
          search={search}
          showPageThumbnails={config.showPageThumbnails}
          switchToAdvanced={switchToAdvanced}
        />
  },
)
