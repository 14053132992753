import classNames from 'classnames'
import styled, {
  css,
  system,
  SystemProps,
} from 'quickstart/styled-components/system'

/*
const styled = tag => (...args) => baseStyled(tag)`
  .${ADMIN_WRAPPER_CLASSNAME} & {
    ${typeof args[0] === 'function' ? args[0] : css(...args)};
    ${system};
  }
`

Object.keys(baseStyled).forEach(k => {
  styled[k] = styled(k)
})
*/

export const P = styled.div<SystemProps>`
  margin: 1rem 0;
  ${system};
`

export const Table = styled.table<SystemProps>`
  margin: 0.5rem 0;
  ${system};
`

export const Thead = styled.thead<SystemProps>`
  ${system};
`

export const Tbody = styled.tbody<SystemProps>`
  ${system};
`

export const Tfoot = styled.tfoot<SystemProps>`
  ${system};
`

export const Tr = styled.tr<SystemProps>`
  ${system};
`

export const Th = styled.th<SystemProps>`
  padding: 0.25rem 0.5rem;
  background: #e6e6e6;
  text-align: left;
  font-weight: bold;
  && > * {
    margin: 0;
  }
  ${system};
`

export const Td = styled.td<SystemProps>`
  padding: 0.25rem 0.5rem;
  && > * {
    margin: 0;
  }
  ${system};
`

export const Label = styled.label<SystemProps>`
  // inline-block makes marginBottom effective, and also allows enclosed items
  // with vertical-align: middle to line up properly (checkboxes, radios)
  display: inline-block;
  margin-bottom: 0.3rem;
  margin-right: 0.75em;
  ${system}
`

export const GroupWrapper = styled.div<SystemProps>`
  // Hide hr on global configs
  hr {
    display: none;
    .dialogbox & {
      display: block;
    }
  }
  ${system}
`

const groupPadding = '20px'

export const Group = styled.div<SystemProps>`
  margin-bottom: 1rem;
  padding-left: ${groupPadding};
  padding-right: ${groupPadding};
  // Don't apply top margin to first group on global configs
  .dialogbox &,
  &:not(:first-child) {
    margin-top: 1rem;
  }
  ${system}
`

export const GroupTitle = styled.div<SystemProps>`
  font-weight: bold;
  margin-bottom: 0.5rem;
  ${system}
`

// Hack to allow Subgroup to reference itself without blowing up TS
const SubGroupInner = styled.div``

export const SubGroup = styled(SubGroupInner)<
  SystemProps & {outlined?: boolean; shaded?: boolean}
>(
  ({outlined, shaded}) => css`
    ${outlined || shaded ?
      css`
        padding: 0.75rem;
        ${outlined && {border: '1px solid #7c7c7c'}};
        ${shaded && {background: '#e6e6e6'}};
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      `
    : css`
        margin-top: 1rem;
        margin-bottom: 1rem;
        ${SubGroupInner} > & {
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
        }
      `};
    & > *:first-child {
      margin-top: 0;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
    ${system}
  `,
)

export const SubGroupTitle = styled.div<SystemProps>`
  font-weight: bold;
  ${system}
`

export const AdminField = styled.div<{hidden?: boolean; snug?: boolean}>(
  ({hidden, snug}) => css`
    ${hidden && {display: 'none'}};

    ${!snug &&
    css`
      margin-top: 0.68rem;
      margin-bottom: 0.68rem;
      ${SubGroup} > & {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
      }
    `};

    &,
    div,
    input,
    select {
      max-width: 100%;
    }
  `,
)

export const Option = styled.div<SystemProps>`
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  ${system}
`

export const OptionLabel = styled.span<SystemProps & {left?: boolean}>(
  ({left}) => css`
    vertical-align: middle;
    ${left ? {marginRight: '0.5em'} : {marginLeft: '0.5em'}}
    ${system}
  `,
)

export const Input = styled.input.attrs<{long?: boolean}>(
  ({className, long}) => ({
    className: classNames(long && 'input-long', className),
  }),
)<SystemProps>(
  ({type}) => css`
    ${(type === 'checkbox' || type === 'radio') && {verticalAlign: 'middle'}}
    ${system}
  `,
)

export const TabStrip = styled.div<SystemProps>`
  background: #b3b3b3;
  border-top: 2px solid #b3b3b3;
  margin-bottom: 1rem;
  magin-top: 0.5rem;
  ${system}
`

export const Button = styled.input.attrs(props => ({
  type: 'button',
  value: `${props.children}`,
  ...props,
  className: classNames('button', props.className),
  children: undefined,
}))<SystemProps>`
  ${system}
`

const BellyButton = styled.button.attrs({type: 'button'})``

export const UnstyledButton = styled(BellyButton)<SystemProps>`
  background-color: inherit;
  ${system}
`

interface TabButtonProps {
  active?: boolean
}

export const TabButton = styled(BellyButton)<SystemProps & TabButtonProps>(
  ({active}) => css`
    background: ${active ? 'white' : 'inherit'};
    border: none;
    border-right: 2px solid #777;
    font-weight: bold;
    padding-top: calc(0.5rem - 2px); // top border
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    &:first-child {
      padding-left: ${groupPadding};
    }
    ${system}
  `,
)

export const TabPane = styled.div<SystemProps & {active?: boolean}>(
  ({active}) => css`
    ${!active && {display: 'none'}}
    margin: 1rem 0;
    ${system}
  `,
)

export const SubTabStrip = styled.div<SystemProps>`
  display: flex;
  margin-bottom: 1rem;
  magin-top: 0.5rem;
`

interface SubTabButtonProps {
  active?: boolean
  onClick?: any
}

export const SubTabButton = styled.a.attrs(
  ({active, onClick}: SubTabButtonProps) => ({
    ...(active ? {as: 'span'} : {href: '#'}),
    onClick: (e: any) => {
      e.preventDefault()
      if (onClick) {
        return onClick(e)
      }
    },
  }),
)<SystemProps & SubTabButtonProps>(
  ({active}) => css`
    font-weight: bold;
    padding: 0 0.5rem;
    &:first-child {
      padding-left: ${groupPadding};
    }
    ${active ?
      css`
        span {
          text-decoration: underline 2px;
          text-underline-offset: 4px;
        }
      `
    : css`
        &:active,
        &:hover {
          text-decoration: none; /* cancel tizra.css */
          span {
            text-decoration: underline 2px;
            text-underline-offset: 4px;
          }
        }
      `}
    ${system};
  `,
)

export const SubTabPane = styled.div<SystemProps & {active?: boolean}>(
  ({active}) => css`
    ${!active && {display: 'none'}}
    margin: 1rem 0;
    ${system}
  `,
)

export const Hint = styled.div<SystemProps>`
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  font-style: italic;
  ${system}
`

export const Row = styled.div<SystemProps>`
  display: flex;
  gap: 0.75rem;
  ${system};
`

export const Column = styled.div<SystemProps>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  ${system};
`

export const Stack = styled.div<SystemProps & {row?: boolean}>(
  ({row}) => css`
    display: flex;
    flex-direction: ${row ? 'row' : 'column'};
    gap: 1rem;
    flex: 1 1 auto;
    align-self: stretch;
    ${system};
  `,
)

export const Vr = styled.div<SystemProps>`
  display: inline-block;
  align-self: stretch;
  flex-grow: 0;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
  ${system};
`

export const Box = styled.div<SystemProps>`
  ${system}
`

export const Menu = styled.div`
  position: relative;
  z-index: 50;
  display: flex;
  max-height: var(--popover-available-height);
  flex-direction: column;
  overflow: auto;
  overscroll-behavior: contain;
  border: 1px solid black;
  background-color: white;
  padding: 0.2rem;
  color: black;
  outline: none !important;
`

export const MenuItem = styled.div`
  display: flex;
  cursor: default;
  scroll-margin: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.15rem;
  padding: 0.3rem;
  outline: none !important;

  &[aria-disabled='true'] {
    opacity: 0.25;
  }

  &[data-active-item] {
    background-color: hsl(204 100% 40%);
    color: hsl(204 20% 100%);
  }

  &:active,
  &[data-active] {
    background-color: hsl(204 100% 32%);
  }
`
