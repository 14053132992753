import {Image} from 'quickstart/components/content/Image'
import {useTheme} from 'quickstart/theme'
import {CSSProperties, ComponentProps} from 'react'
import {toLongId} from 'tizra'

interface FakeCoverProps extends ComponentProps<typeof Image> {
  tizraId: string
  aspectRatio?: CSSProperties['aspectRatio']
}

export const FakeCover = ({aspectRatio, tizraId, ...props}: FakeCoverProps) => {
  const {
    fakeCovers: {backgroundColors},
  } = useTheme()

  // Default to zero in case toLongId returns NaN.
  const i = ((tizraId && toLongId(tizraId)) || 0) % backgroundColors.length

  return (
    <div
      style={{
        aspectRatio,
        backgroundColor: backgroundColors[i],
      }}
    >
      <Image
        src="/aa_shared/quickstart/assets/diamonds.png"
        {...props}
        style={{
          width: '100%',
          height: aspectRatio ? '100%' : 'auto',
          objectFit: 'cover',
          ...props.style,
        }}
      />
    </div>
  )
}
