const TYPES = {
  Checkbox: 'checkbox',
  InputText: 'text',
  Radio: 'radio',
  RadioTab: 'radio',
  Select: 'text',
  Toggle: 'checkbox',
  WelcomeCheckbox: 'checkbox',
  WelcomeRadio: 'radio',
} as const

export const getBaseType = (type: keyof typeof TYPES) => TYPES[type] || 'text'

export const getVariant = ({
  connected,
  error,
  isCheckbox,
  isRadio,
  modified,
  touched,
}: {
  connected?: boolean
  error?: any
  isCheckbox?: boolean
  isRadio?: boolean
  modified?: boolean
  touched?: boolean
}) => {
  if (
    ((isCheckbox || isRadio) && !modified) ||
    (!isCheckbox && !isRadio && connected && !touched)
  ) {
    return undefined
  }
  if (error) {
    return 'error'
  }

  return undefined
}
