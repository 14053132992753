import * as R from 'rambdax'
import {useMemo} from 'react'
import {PropDef} from 'tizra'
import * as B from '../block'
import {DetailsAdmin, defaultConfig, propertyFilter} from './admin'

export const DetailsBlock = B.blockWithInfo(
  {
    name: 'content-details',
    displayName: 'DetailsBlock',
    title: 'Details',
    Admin: DetailsAdmin,
    defaultConfig,
  },
  ({config: {heading, excludes}}) => {
    const {metaObj, metaType, typeDefs} = B.useMetaObjInfo(true)
    const {propDefsIncludingSubtypes} = (metaType && typeDefs?.[metaType]) || {}

    const propNames = useMemo(() => {
      if (!propDefsIncludingSubtypes) return []
      const excludeSet = new Set(excludes)
      return R.piped(
        R.values(propDefsIncludingSubtypes),
        R.filter(propertyFilter),
        R.map<PropDef, string>(R.prop('name')),
        R.filter(name => !excludeSet.has(name)),
      )
    }, [excludes, propDefsIncludingSubtypes])

    const content = B.useMetaList({
      metaObj,
      propNames,
      columnCount: {md: 2},
      columnGap: 'lg',
    })

    return (
      <B.Scene>
        {content && <B.Section heading={heading}>{content}</B.Section>}
      </B.Scene>
    )
  },
)
