import {useState} from 'react'
import {logger, meta} from 'tizra'
import * as B from '../block'
import {ImageAdmin, defaultConfig} from './admin'

const log = logger('ImageBlock')

export const ImageBlock = B.blockWithInfo(
  {
    name: 'image',
    displayName: 'ImageBlock',
    title: 'Image',
    Admin: ImageAdmin,
    defaultConfig,
    dividers: false,
  },
  ({config: {tizraId, imageUrl, altText, linkUrl, width}}) => {
    const [errored, setErrored] = useState<string>('')
    const metaObj = B.useMetaObj({tizraId})

    imageUrl = tizraId ? `/${tizraId}/~stream` : imageUrl

    if (!imageUrl || errored === imageUrl) {
      return null
    }

    altText = tizraId ? meta.string(metaObj, 'Description') || '' : altText

    const image = (
      <B.Image
        src={imageUrl}
        alt={altText}
        fluid
        onError={() => setErrored(imageUrl)}
        {...B.tid('ImageBlock-img')}
      />
    )

    const clickableImage =
      linkUrl ?
        <B.UniversalLink href={linkUrl} {...B.tid('ImageBlock-link')}>
          {image}
        </B.UniversalLink>
      : image

    return (
      <B.Section
        bleed={width === 'bleed'}
        variant="abutting"
        {...B.tid('ImageBlock-section')}
      >
        {clickableImage}
      </B.Section>
    )
  },
)
