import {Link} from 'quickstart/components/content/Link'
import {preventDefault} from 'quickstart/utils'
import {FC, ReactNode} from 'react'

export interface AuthLinkProps {
  children: ReactNode
  href: string
  show?: (s: 'resetPassword' | 'register' | 'signin') => void
  successUrl?: string
  to: 'resetPassword' | 'register' | 'signin'
  variant?: any // XXX
  lineHeight?: any // XXX
}

/**
 * Wrapper around Link to preserve successUrl and call show (for modal)
 */
export const AuthLink: FC<AuthLinkProps> = ({
  href,
  show,
  successUrl,
  to,
  ...rest
}) => {
  if (successUrl) {
    const [lhs, rhs = ''] = (href || '').split('?', 2)
    const params = new URLSearchParams(rhs)
    params.set('successUrl', successUrl)
    href = lhs + '?' + params
  }

  // Avoid TS from whining about passing onClick={undefined}
  const onClickProps = show && {
    onClick: preventDefault(() => show(to)),
  }

  return <Link href={href} {...onClickProps} {...rest} />
}
