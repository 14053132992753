import {Falsy} from 'quickstart/types'

/**
 * Convert options from text to label, and remove extraneous fields so they
 * don't appear on HTML elements. The conversion is because our old code used
 * "text" (as did Semantic) but our new code uses "label."
 */
interface CleanOption {
  value: string
  label: string
}
interface OldOption {
  value: string
  text?: string // optional because this is how SearchField defines it
}
const cleanOption = (opt: CleanOption | OldOption): CleanOption => {
  const {label, text, value} = opt as CleanOption & OldOption
  return {value, label: label ?? text ?? ''}
}
export const cleanOptions = (options: unknown): CleanOption[] =>
  (Array.isArray(options) &&
    options
      .filter(
        (o: unknown) =>
          o && typeof o === 'object' && 'value' in o && o.value !== undefined,
      )
      .map(cleanOption)) ||
  []

/**
 * Remove options with no hits, and update label with parenthesized hitcount.
 * This should run after cleanOptions so that options are already converted.
 *
 * hitCounts should be null or the object returned by useHitCounts.
 */
export const relabelWithHits = <T extends CleanOption>(
  options: T[],
  hitCounts: Record<string, number> | Falsy,
  value?: Set<string>,
): T[] =>
  !hitCounts ? options : (
    options
      .filter(({value: v}) => hitCounts[v] || value?.has(v))
      .map(o => ({...o, label: `${o.label} (${hitCounts[o.value]})`}))
  )
