import {withTheme} from 'quickstart/theme'
import {atBreakpoints, styled, theme} from 'quickstart/theme/linaria'

export const SideNav = styled.div`
  ${atBreakpoints({
    lg: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      alignItems: 'start',
      columnGap: theme.gaps.sideNav,
    },
  })}
`

export const SideNavItem = withTheme(styled.div`
  padding: ${theme.space.xl} ${theme.space.lg};
  border-bottom: ${theme.borders.fine};
  &:first-of-type {
    border-top: ${theme.borders.fine};
  }
`)

export const SideNavContent = styled.div``
