import {useRef} from 'react'

export function useGeneration(deps: unknown[]) {
  const prevDeps = useRef(deps)
  const generation = useRef(1)
  if (deps !== prevDeps.current) {
    if (
      deps.length !== prevDeps.current.length ||
      deps.some((v, i) => v !== prevDeps.current[i])
    ) {
      generation.current++
    }
  }
  return generation.current
}
