import {SparseField, isOptions} from 'quickstart/lib/search/config'
import {truthy} from 'quickstart/types'
import * as R from 'rambdax'
import {useEffect, useMemo, useRef} from 'react'
import {
  GenericMetaObject,
  isCollection,
  logger,
  magicSort,
  meta,
  toLongId,
} from 'tizra'
import {useApis} from './useApi'
import {useBlockContext} from './useBlockContext'
import {useDeferred} from './useDeferred'
import {useEqualBy} from './useEqualBy'
import {useLocation} from './useLocation'
import {useMetaObj} from './useMetaObj'

const log = logger('SearchBlock/useWithinField')

export const CURRENT_OBJ_VALUE = '-1'
const ALL_CONTENT_VALUE = '-2'

export interface UseWithinFieldConfig {
  mode: 'collections' | 'context'
  allOptionLabel: string
}

interface UseWithinFieldProps {
  config: UseWithinFieldConfig
  name: string
  urlPrefix: string
}

export const useWithinField = ({
  config,
  name,
  urlPrefix,
}: UseWithinFieldProps) => {
  // Like the old context dropdown, there are only three valid values:
  //
  // | OLD        | NEW         | MEANING                |
  // | ---------- | ----------- | ---------------------- |
  // | -2         | (blank)     | unconstrained          |
  // | -1         | -1          | current doc            |
  // | numeric id | tizra id(s) | collection or title(s) |
  //
  // The field can only be in one mode or the other, collection or titles,
  // depending on config. It's not dynamic because we'd have to look up every
  // tizraId to know how to call the API, and whether we can handle multiple
  // values. (Also, titles mode isn't implemented yet, except in field-types.js
  // under titleGroup.)
  //
  // The special case is -1, or current doc. If it's not a collection, or if
  // this field isn't normally enabled as a facet, then we need to insert the
  // current doc info into the list of options.
  const initialLocation = useRef(useLocation()).current
  const usp = new URLSearchParams(initialLocation.search)
  const initialValue = usp.get(`${urlPrefix}${name}`) ?? usp.get('context') // compat

  // The initialValue is special because we don't know what it is, a collection
  // or a document. We need to resolve it in order to make the search query
  // properly. This might result in an API call if we were given a tizraId
  // instead of CURRENT_OBJ_VALUE.
  const context = useBlockContext(['metaType', 'tizraId'])
  const initialTizraId =
    initialValue === CURRENT_OBJ_VALUE || initialValue === ALL_CONTENT_VALUE ?
      context.tizraId
    : typeof initialValue === 'string' && toLongId(initialValue) > 0 ?
      initialValue
    : undefined
  const initialMetaObj = useMetaObj({
    enabled: !!initialTizraId,
    ...(initialTizraId === context.tizraId ?
      context
    : {tizraId: initialTizraId}),
  })
  const initialMetaType =
    initialValue === CURRENT_OBJ_VALUE || initialValue === ALL_CONTENT_VALUE ?
      context.metaType
    : initialMetaObj?.metaType

  // For collections mode, we need the list of collections to fill in the
  // options.
  const collections = (
    useApis([
      [
        'collections',
        config.mode === 'collections' && {
          fields: ['name', 'tizra-id', 'meta-type'],
        },
      ],
    ])[0].data as
      | {
          result: Array<{name: string; tizraId: string; metaType: string}>
          size: number
        }
      | undefined
  )?.result

  // We can't directly update our return value, because that would force
  // useSearchConfig to run again, restarting any internal promises. Instead
  // return a stable promise to conform to the search config engine.
  // The deps list here is for for Storybook; we don't normally expect config to
  // change dynamically in production.
  config = useEqualBy(R.equals, config)
  const {promise, resolve} = useDeferred<Array<{value: string; text?: string}>>(
    [config],
  )

  useEffect(() => {
    if (config.mode === 'context' && !initialValue) {
      resolve([])
    } else if (
      initialTizraId &&
      initialMetaType !== 'SiteDisplay' &&
      !initialMetaObj
    ) {
      return // not ready yet
    } else if (config.mode === 'collections' && !collections) {
      return // not ready yet
    } else {
      const collectionOptions =
        collections &&
        magicSort(
          o => o.text,
          collections.map(c => ({
            value: c.tizraId,
            text: meta.name(c as GenericMetaObject),
          })),
        )
      const initialOption = initialValue &&
        initialMetaObj &&
        (!collections ||
          collections.every(c => c.tizraId !== initialTizraId)) && {
          value: initialValue,
          text: meta.name(initialMetaObj),
        }
      const options = [
        (initialOption || collectionOptions) && {
          value: ALL_CONTENT_VALUE,
          text: config.allOptionLabel,
        },
        initialOption,
        collectionOptions,
      ]
        .flat()
        .filter(truthy)
      resolve(options)
    }
  }, [
    collections,
    config,
    initialMetaObj,
    initialMetaType,
    initialTizraId,
    initialValue,
    promise,
    resolve,
  ])

  // Capture current values in a mutable ref, so we can return a field that
  // doesn't change.
  const current = {
    value: initialValue,
    metaType: initialMetaType,
    metaObj: initialMetaObj,
    tizraId: initialTizraId,
  }
  const initial = useRef(current)
  initial.current = current

  return useMemo<SparseField<string, false>>(() => {
    return {
      options: () => promise,
      defaultValue: ALL_CONTENT_VALUE,
      hits: false, // TODO worked for APA
      label: 'Within',
      show: ({options}) => Array.isArray(options) && !!options.length,
      sort: false,
      filterTags: {label: 'within'},
      api: {
        contribute: ({
          field: {options},
          params: {depth},
          sp,
          utils: {log},
          value,
        }) => {
          if (
            !value ||
            value === ALL_CONTENT_VALUE ||
            (value === initial.current.value &&
              initial.current.metaType === 'SiteDisplay')
          ) {
            return
          }
          if (value === initial.current.value && !initial.current.metaObj) {
            log.error('running with initialValue but no initialMetaObj')
            return
          }
          if (
            value !== CURRENT_OBJ_VALUE &&
            isOptions(options) &&
            !options.some(o => o.value === value)
          ) {
            log.warn("value doesn't appear in options:", value)
            return
          }
          const filter =
            (
              value !== initial.current.value ||
              isCollection(initial.current.metaObj)
            ) ?
              'filterCollectionId'
            : depth === 'metadata' ?
              null // don't search metadata in book
            : 'filterTitleGroup'
          const tizraId =
            value === CURRENT_OBJ_VALUE ? initial.current.tizraId : value
          return (
            filter && {
              sp: {...sp, [filter]: tizraId},
              haveProps: false,
            }
          )
        },
      },
    }
  }, [initial, promise])
}
