import {UseSearchReturn} from 'quickstart/hooks'
import {ReactNode} from 'react'
import * as Final from 'react-final-form'
import {SearchResultTypes} from 'tizra'

interface SearchFormProps<T = SearchResultTypes> {
  children: ReactNode | ((props: Final.FormRenderProps) => ReactNode)
  instant?: boolean
  search: UseSearchReturn<T>
}

export const SearchForm = <T = SearchResultTypes,>({
  children,
  instant = false,
  search: {onChange, onSubmit, params},
}: SearchFormProps<T>) => (
  <Final.Form
    initialValues={params}
    onSubmit={onSubmit}
    render={renderProps => (
      <form onSubmit={renderProps.handleSubmit}>
        {instant ?
          <Final.FormSpy onChange={onChange} />
        : null}
        {typeof children === 'function' ? children(renderProps) : children}
      </form>
    )}
  />
)
