import * as AK from '@ariakit/react'
import styled, {
  css,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {fieldStyles} from 'quickstart/utils'

export const Item = styled.div`
  margin-top: ${th('space.xs')};
  &:first-of-type {
    margin-top: 0;
  }
`

export const CheckField = styled.label`
  display: flex;
`

const checkboxSvg = ({theme}: any) => {
  const fillColor = theme.checkboxes.checkbox.checked.color.replace('#', '%23')
  return `<svg fill="${fillColor}" stroke-width="0" viewBox="0 0 512 512" height="0.875em" width="0.875em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`
}

// TODO - don't hardcode checkmark size in svg
export const Checkbox = styled(AK.Checkbox)<
  Omit<SystemProps, 'size'> & {checked?: boolean; size?: never}
>(
  ({order = '-1'}) => css`
    ${fieldStyles};
    ${th('checkboxes.checkbox.default')};

    position: relative;
    top: 2px;
    padding: 0;
    order: ${order};
    cursor: pointer;
    transition: ${th('transitions.medium')};

    &:after {
      content: url('data:image/svg+xml; utf8, ${checkboxSvg}');
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: ${th('transitions.medium')};
    }

    &:checked:after {
      opacity: 1;
    }

    :disabled {
      cursor: not-allowed;
    }

    ${system};
  `,
)

export const Label = styled.div<{checked?: boolean}>(
  ({checked}) => css`
    ${th('checkboxes.label.default')}
    ${checked && th('checkboxes.label.checked')}
    vertical-align: text-top;
    transition: ${th('transitions.medium')};

    :disabled {
      cursor: not-allowed;
    }
  `,
)
