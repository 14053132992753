import {th} from 'quickstart/styled-components/system'
import {Variant} from 'quickstart/types'

export const VARIANTS = {
  error: 'colors.borderError', // TODO 'colors.danger.500',
  info: null, // TODO 'colors.info.500',
  success: 'green', // TODO 'colors.success.500',
  valid: null,
  warning: 'purple', // TODO 'colors.warning.500'
} satisfies {[k in Variant]: string | null}

export const getVariantColor = (variant: Variant) => {
  const key = VARIANTS[variant]
  return key ? th(key) : null
}
