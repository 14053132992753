import styled, {
  css,
  shouldForwardProp,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {UniversalLink} from '../UniversalLink'

type StyledLinkProps = {
  $variant?:
    | 'breadcrumb'
    | 'default'
    | 'nav'
    | 'sidebar'
    | 'sidebar-current'
    | 'stealth'
    | 'ui'
    | 'unstyled'
} & SystemProps

export const Link = styled(UniversalLink).withConfig({
  shouldForwardProp: (prop: string) => shouldForwardProp(prop, 'a'),
})<StyledLinkProps>(
  ({$variant = 'default'}) => css`
    ${th(`links.${$variant}`)};

    // Space between icon and text
    & > *:not(:only-child):not(:last-child) {
      margin-right: ${th('space.xs')};
    }

    ${system};
  `,
)
