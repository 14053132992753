import {containers} from 'quickstart/theme'
import {atBreakpointsWith, styled} from 'quickstart/theme/linaria'

/**
 * OuterContainer provides padding to avoid bumping up against screen edge.
 */
export const OuterContainer = styled.div`
  /* Consume up to max-width in flex container. This allows positioning within
   * the site container to work consistently, regardless of content width.
   */
  width: 100%;

  ${atBreakpointsWith((v: string) => ({paddingLeft: v, paddingRight: v}))(
    containers.default.paddings,
  )};
`

/**
 * InnerContainer provides max-width. This is separate from OuterContainer so it
 * doesn't need to do extra calculations for the padding.
 */
export const InnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${atBreakpointsWith((v: string) => ({maxWidth: v}))(
    containers.default.maxWidths,
  )};
`
