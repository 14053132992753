import {Fragment} from 'react'
import {logger} from 'tizra'
import * as A from '../admin'
import {ActionSpec, ActionViewSpec, Config} from './v2'

const log = logger('HeaderBlock/admin')

export * from './v2'

const ActionViewSelector = ({
  name,
  value,
}: {
  name: string
  value: ActionViewSpec
}) => {
  const modeOptions = A.options({
    auto: 'automatic',
    custom: 'custom',
    prop: 'property',
  })
  const propOptions = A.usePropOptions({
    spec: 'sub-prop-defs',
    types: ['keyword', 'string'],
  })
  return (
    <>
      <A.Select fluid name={`${name}.mode`} options={modeOptions} />
      {value.mode === 'custom' && <A.Input fluid name={`${name}.custom`} />}
      {value.mode === 'prop' && (
        <A.Select fluid name={`${name}.prop`} options={propOptions} />
      )}
    </>
  )
}

const ActionStack = ({name, value}: {name: string; value: ActionSpec[]}) => {
  return (
    <A.Table>
      <A.Thead>
        <A.Tr>
          <A.Th></A.Th>
          <A.Th>View</A.Th>
          <A.Th>Label</A.Th>
          <A.Th>Destination</A.Th>
          <A.Th></A.Th>
          <A.Td />
        </A.Tr>
      </A.Thead>
      <A.FieldArray
        name={name}
        orderable
        droppableAs={A.Tbody}
        draggableAs={A.Tr}
      >
        {({fields}) =>
          fields.map((name, i) => (
            <Fragment key={name}>
              <A.Td verticalAlign="middle" width="24px">
                ⣶
              </A.Td>
              <A.Td verticalAlign="middle" width="25%">
                <A.Input fluid name={`${name}.view`} />
              </A.Td>
              <A.Td verticalAlign="middle" width="25%">
                <ActionViewSelector
                  name={`${name}.label`}
                  value={value[i].label}
                />
              </A.Td>
              <A.Td verticalAlign="middle" width="25%">
                <ActionViewSelector
                  name={`${name}.dest`}
                  value={value[i].dest}
                />
              </A.Td>
              <A.Td verticalAlign="middle" width="30px">
                <A.Button onClick={() => fields.remove(i)}>X</A.Button>
              </A.Td>
              <A.Td />
            </Fragment>
          ))
        }
        {({fields}) => (
          <A.Tfoot>
            <A.Tr>
              <A.Td colSpan={6}>
                <A.Button
                  onClick={() => {
                    const a: ActionSpec = {
                      view: '',
                      label: {mode: 'auto', prop: '', custom: ''},
                      dest: {mode: 'auto', prop: '', custom: ''},
                    }
                    fields.push(a)
                  }}
                  mt="0.5rem"
                >
                  Add action
                </A.Button>
              </A.Td>
            </A.Tr>
          </A.Tfoot>
        )}
      </A.FieldArray>
    </A.Table>
  )
}

const PublicationContent: A.Admin<Config> = ({
  values: {headingProp, subHeadingProp},
}) => {
  const headingOptions = A.usePropOptions({
    namePropName: true,
    spec: 'sub-prop-defs',
    types: A.HEADING_TYPES,
  })

  const subHeadingOptions = A.usePropOptions({
    namePropName: true,
    spec: 'sub-prop-defs',
    types: A.SUB_HEADING_TYPES,
  })

  const otherPropOptions = A.usePropOptions({
    namePropName: true,
    spec: 'sub-prop-defs',
    types: A.DETAIL_TYPES,
    pred: ({def, value}) => {
      return (
        // Require isUserVisible (omit properties that are only isJsonVisible)
        // since these will be directly user-visible similar to the Details
        // Block.
        def?.isUserVisible &&
        // Exclude the currently-selected heading and sub-heading properties
        // from the list of additional properties. (Note we have to do this
        // again in the block, since taking it out of the list doesn't directly
        // take it out of the value.)
        value !== headingProp &&
        value !== subHeadingProp
      )
    },
  })

  return (
    <>
      <A.Select
        label="Heading property"
        name="headingProp"
        options={headingOptions}
        noneOption={true}
      />
      <A.Select
        label="Sub-heading property"
        name="subHeadingProp"
        options={subHeadingOptions}
        noneOption={true}
      />
      {!!otherPropOptions.length && (
        <A.Checklist
          label="Additional properties"
          name="otherProps"
          options={otherPropOptions}
        />
      )}
      <A.MarkdownEditor label="Message" name="message" />
    </>
  )
}

const PostContent: A.Admin<Config> = ({
  values: {
    post: {authors, tags},
  },
}) => {
  const headingOptions = A.usePropOptions({
    namePropName: true,
    spec: 'sub-prop-defs',
    types: A.HEADING_TYPES,
  })

  const authorOptions = A.usePropOptions({
    spec: 'sub-prop-defs',
    types: ['boolean-list', 'keyword', 'keyword-list', 'string', 'string-list'],
  })

  const dateOptions = A.usePropOptions({
    spec: 'sub-prop-defs',
    types: ['date', 'string'],
  })

  const tagsOptions = A.usePropOptions({
    spec: 'sub-prop-defs',
    types: ['boolean-list', 'keyword', 'keyword-list', 'string', 'string-list'],
  })

  const metaTypeOptions = A.useMetaTypeOptions()

  const authorObjectPropOptions = A.usePropOptions({
    metaType: authors.object.metaType,
    types: ['keyword', 'string'],
  })

  return (
    <>
      <A.Select
        label="Heading property"
        name="post.heading.prop"
        options={headingOptions}
      />
      <A.Select
        label="Author property"
        name="post.authors.prop"
        options={authorOptions}
        noneOption={true}
      />
      {!!authors.prop && (
        <A.SubGroup pl="20px">
          <A.Select
            label="Authors linking strategy"
            name="post.authors.strategy"
            options={A.options({
              none: 'none',
              browse: 'filtered browse',
              search: 'search results',
              object: 'meta-object by matching property',
            })}
          />
          {authors.strategy === 'browse' && (
            <>
              <A.Input label="Browse URL" name="post.authors.browse.url" />
              <A.Input label="Browse param" name="post.authors.browse.param" />
            </>
          )}
          {authors.strategy === 'object' && (
            <>
              <A.Select
                label="Authors meta-type"
                name="post.authors.object.metaType"
                options={metaTypeOptions}
                noneOption={true}
              />
              <A.Select
                label="Matching prop"
                name="post.authors.object.prop"
                disabled={!authors.object.metaType}
                options={authorObjectPropOptions}
                noneOption={true}
              />
            </>
          )}
        </A.SubGroup>
      )}
      <A.Select
        label="Date property"
        name="post.dateProp"
        options={dateOptions}
        noneOption={true}
      />
      <A.Select
        label="Tags property"
        name="post.tags.prop"
        options={tagsOptions}
        noneOption={true}
      />
      {!!tags.prop && (
        <A.SubGroup pl="20px">
          <A.Select
            label="Tags linking strategy"
            name="post.tags.strategy"
            options={A.options({
              none: 'none',
              browse: 'filtered browse',
              search: 'search results',
            })}
          />
          {tags.strategy === 'browse' && (
            <>
              <A.Input label="Browse URL" name="post.tags.browse.url" />
              <A.Input label="Browse param" name="post.tags.browse.param" />
            </>
          )}
        </A.SubGroup>
      )}
    </>
  )
}

export const HeaderAdmin: A.Admin<Config> = props => {
  const {
    values: {mode, callsToAction},
  } = props

  const postEnabled = A.useHack('post')

  const sortPropOptions = A.usePropOptions({
    metaType: 'Offer',
    spec: 'sub-prop-defs',
  })

  return (
    <A.Tabs>
      <A.Tab title="Content">
        {postEnabled ?
          <>
            <A.Radio
              label="Content type"
              name="mode"
              options={A.options({
                publication: 'publication',
                post: 'post',
              })}
            />
            {mode === 'publication' && (
              <A.SubGroup title="Publication properties">
                <PublicationContent {...props} />
              </A.SubGroup>
            )}
            {mode === 'post' && (
              <A.SubGroup title="Post properties">
                <PostContent {...props} />
              </A.SubGroup>
            )}
          </>
        : <PublicationContent {...props} />}
      </A.Tab>
      {mode === 'publication' && (
        <>
          <A.Tab title="Calls to Action">
            <A.SubTabs>
              <A.SubTab title="Gain access">
                <A.P fontStyle="italic" maxWidth="80%">
                  Ways that the user can gain access to the content.
                </A.P>
                <A.SubGroup title="Offers">
                  <A.Input
                    name="callsToAction.gain.requiredTags"
                    label="Required tags"
                  />
                  <A.Row alignItems="center">
                    <A.RowItem>
                      <label>Sort by:</label>
                    </A.RowItem>
                    <A.RowItem>
                      <A.Select
                        name="callsToAction.gain.sortBy.prop"
                        options={sortPropOptions}
                        placeholder="Property"
                        noneOption={true}
                      />
                    </A.RowItem>
                    <A.RowItem>
                      <A.Select
                        name="callsToAction.gain.sortBy.dir"
                        options={A.options({
                          ascending: 'Ascending',
                          descending: 'Descending',
                        })}
                      />
                    </A.RowItem>
                  </A.Row>
                </A.SubGroup>
              </A.SubTab>
              <A.SubTab title="Exercise access">
                <A.P fontStyle="italic" maxWidth="80%">
                  Ways that the user can access their content.
                </A.P>
                <A.SubGroup title="Actions">
                  <ActionStack
                    name="callsToAction.exercise.actions"
                    value={callsToAction?.exercise?.actions || []}
                  />
                </A.SubGroup>
              </A.SubTab>
            </A.SubTabs>
          </A.Tab>
          <A.Tab title="Presentation">
            <A.Radio
              label="Layout"
              name="layout"
              options={A.options({
                above: 'Heading above image',
                below: 'Heading below image',
                left: 'Heading left of image',
                right: 'Heading right of image',
                none: 'Heading only',
              })}
            />
            <A.Radio
              label="Alignment"
              name="alignment"
              options={A.options({
                left: 'Left-aligned',
                center: 'Centered',
                right: 'Right-aligned',
              })}
            />
            {/*
        <A.Radio
          label="Heading size"
          name="headingSize"
          options={A.options({
            small: 'Small',
            normal: 'Normal',
            large: 'Large',
          })}
        />
        */}
          </A.Tab>
        </>
      )}
    </A.Tabs>
  )
}
