import styled, {
  css,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {cssVars} from 'quickstart/styled-components/theme/base/fonts'
import {Size} from 'quickstart/types'
import {centerContent, fieldStyles, overflowEllipsis} from 'quickstart/utils'
import {logger} from 'tizra'

const log = logger('Select/styles')

export const Wrapper = styled.div<SystemProps>`
  position: relative;
  ${system};
`

export const InputWrapper = styled.div`
  position: relative;
`

interface InputProps {
  hasClearButton: boolean
  size: Size
}

export const Input = styled.div<InputProps>(
  ({hasClearButton}) => css`
    position: relative;
    display: block;
    ${fieldStyles};
    ${overflowEllipsis};
    text-align: left;
    cursor: default;

    font-weight: var(${cssVars.weights.bold});
    line-height: 1em;

    padding-right: calc(${hasClearButton ? 2 : 1} * ${th('space.xxxl')});

    br {
      display: none;
    }

    &:empty {
      &::after {
        content: attr(placeholder);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        ${overflowEllipsis};
        padding: inherit;
        opacity: 1;
      }
      &::before {
        height: auto;
      }
    }
  `,
)

interface MenuProps {
  isOpen: boolean
}

export const Menu = styled.ul<MenuProps>(
  ({isOpen}) => css`
    ${th('fields.select.default')};
    /* start welcome-ui cardStyles */
    background-color: ${th('colors.backgroundColorWhite')};
    border-radius: ${th('radii.none')};
    border-style: solid;
    border-width: ${th('borderWidths.md')};
    border-color: ${th('colors.borderColor')};
    /* end welcome-ui cardStyles */
    border-top: none;
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0;
    transition: ${th('transitions.medium')};
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    ${!isOpen &&
    css`
      display: none;
    `}
  `,
)

export const Item = styled.li<{
  disabled?: boolean
  isHighlighted?: boolean
  isSelected?: boolean
  multiple?: boolean
}>(
  ({disabled, isHighlighted, isSelected, multiple}) => css`
    color: ${th('colors.textColor')};
    padding: ${th('space.lg')} ${th('space.xl')};
    list-style: none;
    text-decoration: none;
    ${th('ui.form')};
    line-height: 1.6em; /* XXX fix after typography merge */
    border-top-width: ${th('borderWidths.sm')};
    border-top-style: solid;
    border-top-color: ${th('colors.borderColorLight')};
    transition: background ${th('transitions.medium')};

    ${disabled ?
      th('fields.select.disabled')
    : css`
        ${isHighlighted && th('fields.select.highlighted')};
        ${isSelected && !multiple && th('fields.select.selected')};
        ${isSelected && multiple && th('fields.select.existing')};
        &:hover {
          background-color: ${th('colors.backgroundColorLight')};
        }
      `}
  `,
)

// TODO size?
export const Icon = styled.div<{size?: Size}>`
  position: absolute;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  ${centerContent}
`

export const Indicators = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
`

const BellyButton = styled.button.attrs({type: 'button'})``

export const IndicatorButton = styled(BellyButton)`
  position: relative;
  appearance: none;
  outline: none !important; /* important for firefox */
  cursor: pointer;
  border: none;
  background: transparent;
  color: currentcolor;
  ${centerContent};
  width: ${th('space.xxxl')};
`

// HACK -- so I don't have to remove the ${StyledIcon} styling below, since it
// seems like something we might want eventually, but we don't have StyledIcon
// as a component (yet).
const StyledIcon = styled.div``

export const DropDownIndicator = styled(IndicatorButton)<{
  isOpen?: boolean
}>(
  ({isOpen}) => css`
    ${StyledIcon} {
      transform: ${isOpen ? 'rotate3d(0, 0, 1, 180deg)' : 'rotate3d(0)'};
      transition: ${th('transitions.medium')};
    }
  `,
)

export const Tags = styled.div`
  margin-top: ${th('space.lg')};

  & > *:not(:last-child) {
    margin-right: ${th('space.sm')};
    margin-bottom: ${th('space.sm')};
  }

  &:empty {
    display: none;
  }
`
