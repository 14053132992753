import {formErrors} from 'quickstart/utils/final-form-errors'
import * as Final from 'react-final-form'
import {CheckoutMetaMethod, logger} from 'tizra'
import * as B from '../block'
import {StepComponent} from './types'

const log = logger('CartBlock/PickCheckoutMethod')

export const PickCheckoutMethod: StepComponent = ({
  active,
  proceed,
  checkoutMeta: {checkoutMethods},
  checkoutMethod,
  setMethodName,
}) => {
  const checkoutLabel = (x: CheckoutMetaMethod) => x.displayName || x.name

  if (!active) {
    if (!checkoutMethod) return null
    return <p>{checkoutLabel(checkoutMethod)}</p>
  }

  if (!checkoutMethods.length) {
    return <p>There are no checkout methods configured.</p>
  }

  const options =
    checkoutMethods.map(m => ({value: m.name, label: checkoutLabel(m)})) || []

  const submit = async ({method}: {method: string}) => {
    if (checkoutMethods.find(m => m.name === method)) {
      setMethodName(method)
      proceed()
      return
    }
    return formErrors({errors: {method: {reason: 'required'}}})
  }

  return (
    <Final.Form
      onSubmit={submit}
      initialValues={{method: checkoutMethod?.name}}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <B.FormShell variant="fluid">
            <B.ConnectedField
              component={B.Radios}
              name="method"
              options={options}
            />
            <B.Button type="submit">Continue</B.Button>
          </B.FormShell>
        </form>
      )}
    />
  )
}
