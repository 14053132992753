import {getIn} from 'final-form'
import {Falsish, Writeable} from 'quickstart/types'
import * as R from 'rambdax'
import {useFormState} from 'react-final-form'
import {magicSort} from 'tizra'

type MakeOptionsProps<T> = {
  valueKey: string | ((x: T) => string | undefined)
  labelKey: string | ((x: T) => string | undefined)
  sort?: (xs: T[]) => T[]
}

type Option<T> = {
  value: string
  label: string
  def: T
}

/**
 * This accepts Readonly<T[]> because that's the type of
 * const arr = ['foo', 'bar'] as const.
 * Then, to smooth things over, we cast it to Writeable even though we don't
 * write to it.
 */
export const makeOptions =
  <T>({valueKey, labelKey, sort}: MakeOptionsProps<T>) =>
  (arr: Readonly<T[]> | T[] | Falsish): Option<T>[] => {
    const valueFn = typeof valueKey === 'function' ? valueKey : R.prop(valueKey)
    const labelFn = typeof labelKey === 'function' ? labelKey : R.prop(labelKey)
    return !arr ?
        []
      : R.piped(
          arr as Writeable<typeof arr>,
          sort || R.identity,
          R.chain(def => {
            const value = valueFn(def)
            const label = labelFn(def)
            return (
                typeof value === 'string' && label && typeof label === 'string'
              ) ?
                [{value, label, def}]
              : []
          }),
          R.uniqBy(o => o.value),
          sort ? R.identity : magicSort<Option<T>>(R.prop('label')),
        )
  }

export const easyOptions = makeOptions<string>({
  valueKey: R.identity,
  labelKey: R.identity,
})

export const namely = (prefix: string) => {
  if (prefix && !prefix.endsWith('.')) {
    prefix += '.'
  }
  return (name: string) => {
    return `${prefix}${name}`
  }
}

export const useValueGetter = (prefix: string) => {
  const {values} = useFormState()
  const _ = namely(prefix)
  return (name: string) => getIn(values, _(name))
}
