import {FilesItem} from 'quickstart/components/content/FilesItem'
import {Stack} from 'quickstart/components/layout/Stack'
import * as R from 'rambdax'
import {ComponentProps} from 'react'
import {Attachment, magicSort, sortBySortField} from 'tizra'

const itemLabel = (item: Attachment) => item.props.DisplayName || item.name

interface FilesListProps extends ComponentProps<typeof Stack> {
  iconsEnabled?: boolean
  iconSize?: 'auto' | ComponentProps<typeof FilesItem>['iconSize']
  items: Attachment[]
}

export const FilesList = ({
  iconsEnabled,
  iconSize: _iconSize = 'auto',
  items: unsortedItems,
  ...rest
}: FilesListProps) => {
  const items = R.pipe(magicSort(itemLabel), sortBySortField)(unsortedItems)

  const iconSize =
    _iconSize !== 'auto' ? _iconSize
    : items.length < 4 ? 'medium'
    : 'small'

  return (
    <Stack spacing="0" {...rest}>
      {items.map((item, i) => (
        <FilesItem
          key={i}
          item={item}
          label={itemLabel(item)}
          iconEnabled={iconsEnabled}
          iconSize={iconSize}
        />
      ))}
    </Stack>
  )
}
