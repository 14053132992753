import {InnerContainer, OuterContainer, Section} from 'quickstart/components'
import {useHack, useMetaObj} from 'quickstart/hooks'
import {CSSProperties, ReactNode} from 'react'
import {deepMerge, logger, meta, truthy} from 'tizra'
import {PartialDeep} from 'type-fest'
import {BackgroundSpec, Just, TwoD, defaultBackgroundSpec} from './admin'

const log = logger(`Background`)

const long = {
  '': 'center',
  n: 'center',
  ne: 'end',
  e: 'end',
  se: 'end',
  s: 'center',
  sw: 'start',
  w: 'start',
  nw: 'start',
  left: 'start',
  center: 'center',
  right: 'end',
} as const satisfies Record<TwoD | Just, 'start' | 'end' | 'center'>

const lat = {
  '': 'center',
  n: 'start',
  ne: 'start',
  e: 'center',
  se: 'end',
  s: 'end',
  sw: 'end',
  w: 'center',
  nw: 'start',
  left: 'center',
  center: 'center',
  right: 'center',
} as const satisfies Record<TwoD | Just, 'start' | 'end' | 'center'>

const pos = {
  '': 'center',
  n: 'top',
  ne: 'top right',
  e: 'right',
  se: 'bottom right',
  s: 'bottom',
  sw: 'bottom left',
  w: 'left',
  nw: 'top left',
} as const satisfies Record<TwoD, string>

const useBackgroundImageUrl = (bg: BackgroundSpec) => {
  const fallbackHack = useHack('coverFallback')
  const metaObj = useMetaObj(bg.image.source === 'cover')
  switch (bg.image.source) {
    case 'url':
      return bg.image.url.trim() || undefined
    case 'cover':
      return meta.coverImage(metaObj, {excerptPageImages: false, fallbackHack})
    default: {
      const exhaustiveCheck: '' = bg.image.source
      if (exhaustiveCheck !== '') {
        log.error('unhandled case:', exhaustiveCheck)
      }
    }
  }
}

const escapeDoubleQuotes = (s: string) => s.replace(/\\|"/g, m => `\\${m}`)

const useBackgroundStyle = (bg: BackgroundSpec): CSSProperties | undefined => {
  const imgUrl = useBackgroundImageUrl(bg)
  const {
    color,
    foreground,
    image: {focus},
  } = bg
  const background = [
    color.source === 'config' &&
      color.color.trim() &&
      `linear-gradient(${color.color}, ${color.color})`,
    color.source === 'custom' && color.custom.trim(),
    imgUrl &&
      `no-repeat ${pos[focus]}/cover url("${escapeDoubleQuotes(imgUrl)}")`,
  ]
    .filter(truthy)
    .join(', ')
  return background ?
      {
        background,
        color: foreground.color,
        ...({'--eg-markdown-color': foreground.color} as CSSProperties),
      }
    : undefined
}

export const Background = ({
  background,
  content,
}: {
  background?: PartialDeep<BackgroundSpec>
  content: ReactNode
}) => {
  const bg =
    background ?
      deepMerge(defaultBackgroundSpec)(background)
    : defaultBackgroundSpec
  const imgUrl = useBackgroundImageUrl(bg)

  // Styles to apply
  const bgStyle = useBackgroundStyle(bg)
  const outerStyle = bg.bleed ? bgStyle : undefined
  const innerStyle = bg.bleed ? undefined : bgStyle
  const ifFullImg = (style: CSSProperties) =>
    imgUrl && !bg.image.crop ? style : undefined

  // If the background isn't full-bleed, then reapply OuterContainer padding on
  // the inside, so that the text isn't hard against the edge.
  const SectionPadding = bg.bleed ? 'div' : OuterContainer

  // Instead of allowing Section to provide the container, wrap the container
  // around Section so we can control where the background is applied.
  let ret = (
    <OuterContainer
      style={{
        ...ifFullImg({flex: '0 0 100vw', display: 'flex'}),
        ...outerStyle,
      }}
    >
      <InnerContainer
        style={{
          ...ifFullImg({flex: '0 0 100%', display: 'flex'}),
          ...innerStyle,
        }}
      >
        <Section bleed style={ifFullImg({flex: '0 0 100%', display: 'flex'})}>
          <SectionPadding
            style={ifFullImg({flex: '0 0 100%', display: 'flex'})}
          >
            <div
              style={{
                ...ifFullImg({flex: '0 0 100%'}),
                display: 'flex',
                alignItems: bg && lat[bg.foreground.position],
                justifyContent: bg && long[bg.foreground.position],
              }}
            >
              {content}
            </div>
          </SectionPadding>
        </Section>
      </InnerContainer>
    </OuterContainer>
  )

  // For a non-cropped image, wrap in the hack that forces the min-height.
  if (imgUrl && !bg.image.crop) {
    ret = (
      <div style={{width: '100vw', overflowX: 'hidden'}}>
        <div style={{display: 'flex', width: '200vw'}}>
          {ret}
          <div style={{flex: '0 0 100vw'}}>
            <img src={imgUrl} style={{width: '100%', height: 'auto'}} />
          </div>
        </div>
      </div>
    )
  }

  return ret
}
