import {Html} from 'quickstart/components/content/Html'
import {Tag} from 'quickstart/components/controls/Tag'
import type * as T from 'tizra'
import {logger, meta} from 'tizra'
import * as S from './styles'

const log = logger('Toc')

interface EnhancedTocEntry extends T.TocEntry {
  isFree?: boolean
}

interface TocEntryProps {
  entry: EnhancedTocEntry
  showPageNums: boolean
  variant: S.Variant
  labelFreeContent: boolean
}

export const TocEntry = ({
  entry,
  labelFreeContent,
  variant,
  showPageNums,
}: TocEntryProps) => {
  const href = meta.href(entry)
  let title = (
    <>
      <Html as="span" html={entry.title} variant="toc" />
      {labelFreeContent && entry.isFree && (
        <>
          &nbsp;&nbsp;
          <Tag variant="pill" color="accent" inline>
            Free content
          </Tag>
        </>
      )}
    </>
  )

  if (variant === 'plain' && showPageNums) {
    title = (
      <S.TocEntryTitleSpan>
        {title}
        &nbsp;&nbsp;
      </S.TocEntryTitleSpan>
    )
    const pageNum = (
      <S.TocEntryPageNum $variant={variant} $level={entry.level}>
        &nbsp;&nbsp;{entry.logicalPage}
      </S.TocEntryPageNum>
    )
    return (
      <S.TocEntryRow $variant={variant} $level={entry.level} href={href}>
        <S.TocEntryTitle $variant={variant} $level={entry.level}>
          {title}
          <S.TocEntryDots />
          <S.TocEntryTitle $variant={variant} $level={entry.level} $positioned>
            {title}
          </S.TocEntryTitle>
        </S.TocEntryTitle>
        {pageNum}
      </S.TocEntryRow>
    )
  }

  return (
    <S.TocEntryRow $variant={variant} $level={entry.level} href={href}>
      <S.TocEntryTitle $variant={variant} $level={entry.level}>
        {title}
      </S.TocEntryTitle>
      {showPageNums && (
        <S.TocEntryPageNum $variant={variant} $level={entry.level}>
          {entry.logicalPage}
        </S.TocEntryPageNum>
      )}
    </S.TocEntryRow>
  )
}

interface TocProps {
  entries: EnhancedTocEntry[]
  variant: S.Variant
  showPageNums: boolean
  labelFreeContent: boolean
}

export const Toc = ({
  entries,
  labelFreeContent,
  showPageNums,
  variant,
}: TocProps) => (
  // Wrapping in TocEntryText instead of plain div is an optimization, since
  // the inner Text components won't need to re-apply all their inline styles.
  <S.TocEntryText>
    {entries.map(entry => (
      <TocEntry
        key={entry.id}
        entry={entry}
        labelFreeContent={labelFreeContent}
        showPageNums={showPageNums}
        variant={variant}
      />
    ))}
  </S.TocEntryText>
)
