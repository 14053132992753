import {Html} from 'quickstart/components/content/Html'
import {Prose, Text} from 'quickstart/components/content/Text'
import {Section} from 'quickstart/components/layout/Section'
import {useMarkdown} from 'quickstart/hooks/useMarkdown'
import {CSSProperties, ReactNode} from 'react'

type EmptyBlockProps = {
  heading?: ReactNode
} & (
  | {children?: never; message?: string | ReactNode}
  | {children?: string | ReactNode; message?: never}
)

export const EmptyBlock = ({
  heading,
  children,
  message = children,
}: EmptyBlockProps) => {
  const style: CSSProperties = {
    textAlign: 'center',
    textWrap: 'balance',
  }

  const wrappedHeading = heading && (
    <Text variant="h1" style={style}>
      {heading}
    </Text>
  )

  const messageHtml = useMarkdown(typeof message === 'string' && message)
  const wrappedMessage =
    typeof message === 'string' ?
      messageHtml && (
        <Html as="div" variant="customer" html={messageHtml} style={style} />
      )
    : message

  return (
    <Section>
      <Prose style={{paddingTop: '4rem'}}>
        {wrappedHeading || null}
        {wrappedMessage || null}
      </Prose>
    </Section>
  )
}
