import * as A from '../admin'

interface Config {
  heading: string
  excludedSources: string[]
  filterByUserLicenses: boolean
  iconsEnabled: boolean
  iconSize: 'auto' | 'small' | 'medium' | 'large'
}

export const defaultConfig: Config = {
  heading: 'Resources',
  excludedSources: [],
  filterByUserLicenses: false,
  iconsEnabled: true,
  iconSize: 'auto',
}

export const AttachmentsAdmin: A.Admin<Config> = ({values}) => {
  const sourceOptions = A.useSourceOptions()
  return (
    <>
      <A.Group title="Content">
        <A.Input label="Heading" name="heading" />
        <A.Unchecklist
          label="Included sources"
          name="excludedSources"
          options={sourceOptions}
        />
        <A.YesNo label="Filter by user licenses?" name="filterByUserLicenses" />
      </A.Group>
      <A.Group title="Presentation">
        <A.YesNo label="Show icons?" name="iconsEnabled" />
        {values.iconsEnabled && (
          <A.Radio
            label="Icon size"
            name="iconSize"
            options={A.options({
              auto: 'Auto based on number of attachments',
              small: 'Small',
              medium: 'Medium',
              large: 'Large',
            })}
          />
        )}
      </A.Group>
    </>
  )
}
