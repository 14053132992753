import {MetaObject, logger} from 'tizra'
import * as B from '../block'
import {Config} from './admin'
import {CSSProperties} from 'react'

const log = logger('HeaderBlock')

type TitleProps = Pick<
  Config,
  'alignment' | 'headingProp' | 'otherProps' | 'subHeadingProp'
> & {
  metaObj: MetaObject | undefined
}

export const Title = ({
  alignment,
  headingProp,
  otherProps,
  subHeadingProp,
  metaObj,
}: TitleProps) => {
  const headingStyle: CSSProperties = {
    textAlign: alignment,
    textWrap: 'balance',
  }
  return (
    <B.Stack wrapChildren={false} style={{alignItems: B.flexAlign(alignment)}}>
      <B.MetaValue
        as={B.H1}
        prop={headingProp}
        metaObj={metaObj}
        style={headingStyle}
      />
      <B.MetaValue
        as={B.Text}
        prop={subHeadingProp}
        metaObj={metaObj}
        style={headingStyle}
      />
      <B.MetaList
        propNames={otherProps}
        textAlign={alignment}
        metaObj={metaObj}
      />
    </B.Stack>
  )
}

Title.displayName = 'HeaderBlock.Title'
