import {ComponentProps} from 'react'
import * as S from './styles'
import {UnprefixStyledProps} from 'quickstart/types'
import {SetOptional} from 'type-fest'

export type ShapeProps = SetOptional<
  UnprefixStyledProps<ComponentProps<typeof S.Shape>>,
  'shape'
>

export const Shape = ({shape = 'rectangle', ...props}: ShapeProps) => (
  <S.Shape $shape={shape} {...props} />
)
