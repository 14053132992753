import {iso31661} from 'iso-3166' // TODO: dynamic import
import {ComponentProps, ReactNode} from 'react'
import {truthy} from 'tizra'
import {Text} from '../Text'

interface Address {
  firstName?: string
  lastName?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

type AddressSummaryProps = ComponentProps<typeof Text> & {values: Address}

const br = (x: any, sep: ReactNode = <br />): ReactNode => {
  const s = typeof x === 'string' && x.trim()
  return (
    !s ? null
    : typeof x === 'string' && typeof sep === 'string' ? `${x}${sep}`
    : <>
        {s}
        {sep}
      </>
  )
}

const fullName = (...names: Array<string | undefined>): string =>
  names
    .map(x => x?.trim())
    .filter(truthy)
    .join(' ')

const countries = Object.fromEntries(
  iso31661.map(({alpha3, name}) => [alpha3, name]),
)

export const AddressSummary = ({
  values: {firstName, lastName, address1, address2, city, state, zip, country},
  ...props
}: AddressSummaryProps) => {
  return (
    <Text {...props}>
      {br(fullName(firstName, lastName))}
      {br(address1)}
      {br(address2)}
      {br(city, state?.trim() ? ', ' : ' ')}
      {br(state, ' ')}
      {br(zip)}
      {br(country === 'USA' ? '' : (country && countries[country]) || country)}
    </Text>
  )
}
