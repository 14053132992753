import {ReactNode} from 'react'
import {DropdownMenu, DropdownMenuProps} from '../DropdownMenu'

interface DropdownSelectOption {
  value: string
  label: ReactNode
}

type DropdownSelectProps = Omit<DropdownMenuProps, 'children'> & {
  children: ReactNode
  options: DropdownSelectOption[]
  onChange: (value: string) => void
}

export const DropdownSelect = ({
  children,
  options,
  onChange,
  ...rest
}: DropdownSelectProps) => {
  return (
    <DropdownMenu label={children} {...rest}>
      {({store}) => (
        <>
          {options.map(({value, label}) => (
            <DropdownMenu.Item
              key={value}
              onClick={() => {
                store.hide()
                onChange(value)
              }}
            >
              {label}
            </DropdownMenu.Item>
          ))}
        </>
      )}
    </DropdownMenu>
  )
}
