import {Stack} from 'quickstart/components/layout/Stack'
import {useMergedRefs, useResizeObserver} from 'quickstart/hooks'
import {Children, ComponentProps, useEffect, useRef, useState} from 'react'
import {logger} from 'tizra'

const log = logger('BrowseGallery/FitStack')

type FitStackProps = ComponentProps<typeof Stack> & {
  wrapperProps?: Omit<ComponentProps<'div'>, 'children'>
}

const useLiveRef = <T extends HTMLElement = HTMLDivElement>() => {
  const ref = useRef<T>(null)
  const [entry, callbackRef] = useResizeObserver()
  return {ref, entry, mergedRef: useMergedRefs([ref, callbackRef])}
}

export const FitStack = ({children, wrapperProps, ...props}: FitStackProps) => {
  const wrapper = useLiveRef()
  const stack = useLiveRef()
  const [howManyFit, setHowManyFit] = useState<number>(0)
  const childArray = Children.toArray(children)

  const {
    divided = false,
    capped = false,
    startCapped = capped,
    endCapped = capped,
  } = props
  const wrapperHeight = wrapper.entry?.contentRect?.height
  const stackHeight = stack.entry?.contentRect?.height
  const stackEl = stack.ref.current

  useEffect(() => {
    if (!stackEl || !stackHeight || !wrapperHeight) {
      return
    }
    let kids = [...stackEl.children] as HTMLElement[]
    if (startCapped) {
      kids.shift()
    }
    if (divided) {
      kids = kids.filter(el => (el.tagName === 'HR') === endCapped)
    }
    if (!kids.length) {
      return
    }
    const firstOffscreen = kids.findIndex(el => {
      const htmlEl = el as HTMLElement
      const actualBottom =
        htmlEl.offsetTop +
        htmlEl.offsetHeight -
        parseInt(
          window.getComputedStyle(htmlEl).getPropertyValue('padding-bottom'),
        )
      return actualBottom > wrapperHeight
    })
    setHowManyFit(firstOffscreen < 1 ? kids.length : firstOffscreen)
  }, [wrapperHeight, stackHeight, stackEl, startCapped, divided, endCapped])

  const absProps = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'hidden',
  } as const

  return (
    <>
      <div
        style={{...absProps, marginLeft: '-200vw', marginRight: '200vw'}}
        ref={wrapper.mergedRef}
      >
        <Stack {...props} ref={stack.mergedRef}>
          {childArray}
        </Stack>
      </div>
      <div {...wrapperProps} style={{...absProps, ...wrapperProps?.style}}>
        <Stack
          {...props}
          style={{
            ...absProps,
            justifyContent: howManyFit > 3 ? 'space-between' : undefined,
            ...props.style,
          }}
        >
          {childArray.slice(0, howManyFit)}
        </Stack>
      </div>
    </>
  )
}
