import {Tile} from 'quickstart/components/content/Tile'
import {UniversalLink} from 'quickstart/components/content/UniversalLink'
import * as R from 'rambdax'
import {ComponentProps} from 'react'
import {MetaObject, deepMerge, logger} from 'tizra'
import {
  ImageCropping,
  ImageFocus,
  SlotConfig,
  SlotName,
  SlotProps,
  defaultSlots,
  useMetadata,
} from './common'

const log = logger('MetaTile')

export const metaTileSizes = ['small', 'medium', 'large'] as const

export type TileSize = (typeof metaTileSizes)[number]

export const metaTileSlotsPerSize: {
  [k in TileSize]: {[k in SlotName]?: SlotProps}
} = {
  small: {cover: {force: true}, title: {}, link: {}},
  medium: {cover: {force: true}, title: {}, link: {}},
  large: {cover: {force: true}, title: {}, description: {}, link: {}},
}

type MetaTileProps = ComponentProps<typeof Tile> & {
  metaObj?: MetaObject
  size?: TileSize
  slots?: {[k in SlotName]?: SlotConfig}
  imageFocus?: ImageFocus
  imageCropping?: ImageCropping
}

const orNull = (v: any) => v || null

const cropRatios: {[k in ImageCropping]?: number} = {
  landscape: 4 / 3,
  portrait: 8.5 / 11,
  square: 1,
}

const focusPositions = {
  nw: 'top left',
  n: 'top',
  ne: 'top right',
  w: 'left',
  '': 'center',
  e: 'right',
  sw: 'bottom left',
  s: 'bottom',
  se: 'bottom right',
} as const

const _MetaTile = ({
  metaObj,
  size = 'large',
  imageCropping = 'landscape',
  imageFocus = 'n',
  slots: _slots,
  ...props
}: MetaTileProps) => {
  const slots = R.pick(
    Object.keys(metaTileSlotsPerSize[size]),
    deepMerge(defaultSlots)(_slots),
  ) as Exclude<typeof _slots, undefined>

  const {href, cover, label, title, meta, description, cta} = useMetadata({
    metaObj,
    slots,
    thumbProps: {
      bordered: true,
      cover: imageCropping !== 'full',
      ratio: cropRatios[imageCropping],
      position:
        imageCropping === 'full' ? 'bottom left' : focusPositions[imageFocus],
    },
  })

  const asProps = orNull(href) && {as: UniversalLink, href}

  return (
    <Tile {...asProps} {...props}>
      <Tile.Cover>{cover}</Tile.Cover>
      <Tile.Body>
        {label && <Tile.Label>{label}</Tile.Label>}
        {title && <Tile.Heading>{title}</Tile.Heading>}
        {meta && <Tile.Meta>{meta}</Tile.Meta>}
        {description && <Tile.Description>{description}</Tile.Description>}
      </Tile.Body>
      <Tile.Footer>
        {cta && <Tile.CallToAction>{cta}</Tile.CallToAction>}
      </Tile.Footer>
    </Tile>
  )
}

export const MetaTile = Object.assign(_MetaTile, {Group: Tile.Group})
