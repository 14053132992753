import {dataProps, withProps} from 'quickstart/theme'
import {ComponentProps} from 'react'
import {Text} from 'quickstart/components/content/Text'
import * as S from './styles'

interface CardProps extends ComponentProps<typeof S.Card> {
  stretch?: boolean
  clickable?: boolean
}

const _Card = ({clickable, stretch, ...props}: CardProps) => (
  <S.Card {...props} {...dataProps({clickable, stretch})} />
)

export const Card = Object.assign(_Card, {
  Cover: S.Cover,
  Body: S.Body,
  Footer: S.Footer,
  Meta: S.Meta,
  Heading: S.Heading,
  Description: withProps({variant: 'cardSnippet'})(Text),
  CallToAction: S.CallToAction,
})
