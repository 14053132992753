import * as A from '../admin'
import {ThemeBlockGlobalConfig} from './globalConfig'

export const GlobalAdmin: A.Admin<ThemeBlockGlobalConfig> = () => {
  const fontOptions = A.options({
    sans: 'Sans Serif',
    serif: 'Serif',
  })
  return (
    <>
      <A.Group title="Colors">
        <A.Input label="Button color" name="buttonColor" />
        <A.Input label="Link color" name="linkColor" />
      </A.Group>
      <A.Group title="Logo">
        <A.Input label="Logo Image URL" name="logoUrl" />
        <A.Input label="Logo links to" name="logoLink" />
      </A.Group>
      <A.Group title="Typography">
        <A.Select label="Headings" name="headingFont" options={fontOptions} />
        <A.Select label="Body" name="bodyFont" options={fontOptions} />
      </A.Group>
    </>
  )
}

export const Admin = () => null
