import {useState} from 'react'
import {CheckoutMeta, logger} from 'tizra'
import * as B from '../block'
import {CheckoutWizard} from './CheckoutWizard'
import {Config} from './admin'
import {OrderSummary} from './OrderSummary'
import {
  FULFILLER_BUTTONS_METHOD,
  FULFILLER_BUTTONS_STEP,
} from './FulfillerButtons'

const log = logger('CartBlock/Checkout')

interface CheckoutProps {
  config: Config
}

const classicCheckoutMeta: CheckoutMeta = {
  checkoutMethods: [
    {
      displayName: 'Classic checkout',
      name: FULFILLER_BUTTONS_METHOD,
      steps: [
        {
          displayName: 'Payment',
          final: true,
          optional: false,
          step: FULFILLER_BUTTONS_STEP,
        },
      ],
    },
  ],
}

export const Checkout = ({config}: CheckoutProps) => {
  const userData = B.useUserData()

  const checkoutApiEnabled = B.useHack('checkoutApi')
  const _checkoutMeta = B.useApi.checkoutMeta(checkoutApiEnabled).data
  const checkoutMeta = checkoutApiEnabled ? _checkoutMeta : classicCheckoutMeta

  const _checkoutMethods = checkoutMeta?.checkoutMethods
  const [_methodName, setMethodName] = useState<string>()
  const checkoutMethod =
    _checkoutMethods &&
    (_checkoutMethods.length === 1 ?
      _checkoutMethods[0]
    : _checkoutMethods.find(m => m.name === _methodName))

  return (
    <B.Section>
      <B.Grid cols={{_: 1, lg: 2}} colGap="48px">
        {userData === null || !checkoutMeta ?
          <div />
        : <CheckoutWizard
            key={checkoutMethod ? `method=${checkoutMethod.name}` : 'pick'}
            config={config}
            userData={userData}
            checkoutMeta={checkoutMeta}
            checkoutMethod={checkoutMethod}
            setMethodName={setMethodName}
          />
        }
        <OrderSummary config={config} />
      </B.Grid>
    </B.Section>
  )
}
