import {useCombobox, useMultipleSelection} from 'downshift'
import {matchSorter} from 'match-sorter'
import {Icon} from 'quickstart/components/content/Icon'
import {Tag} from 'quickstart/components/controls/Tag'
import {useMergedRefs} from 'quickstart/hooks'
import {createEvent} from 'quickstart/utils'
import * as R from 'rambdax'
import {
  ComponentProps,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'
import {ensureArray, logger} from 'tizra'
import * as S from './styles'

const log = logger('Select')

interface RenderMultipleProps {
  getSelectedItemProps: (gsipProps: {
    index: number
    onClick: any
    selectedItem: any
  }) => ComponentProps<typeof Tag>
  handleClick: (item: any) => void
  items: any[]
  itemToValue: (item: any) => string
  renderItem: (item: any) => ReactNode
}

const DefaultRenderMultiple = ({
  getSelectedItemProps,
  handleClick,
  items,
  itemToValue,
  renderItem,
}: RenderMultipleProps) => (
  <S.Tags>
    {items.map((item, index) => (
      <Tag
        {...getSelectedItemProps({
          index,
          onClick: (e: MouseEvent) => {
            e.stopPropagation()
            handleClick(item)
          },
          selectedItem: item,
        })}
        key={itemToValue(item)}
      >
        {renderItem(item)}
      </Tag>
    ))}
  </S.Tags>
)

const defaultItemToString = (x: any) => x.label as string

const defaultItemToValue = (x: any) => x.value as string

type InputProps = ComponentProps<typeof S.Input>

type MultiSelectProps =
  | {multiple: true; value?: string[]}
  | {multiple?: false; value?: string}

type SelectProps = MultiSelectProps & {
  autoFocus?: boolean
  'data-testid'?: string
  disabled?: boolean
  clearable?: boolean
  noResults?: any
  search?: any | ((items: string[], value: string) => any[])
  name: string
  options: any[]
  placeholder?: string
  renderItem?: any
  renderMultiple?: (props: RenderMultipleProps) => any
  variant?: 'error' | 'info' | 'success' | 'valid' | 'warning'
  itemToString?: (item: any) => string
  itemToValue?: (item: any) => string
  onBlur?: InputProps['onBlur']
  onChange?: InputProps['onChange']
  onClick?: InputProps['onClick']
  onFocus?: InputProps['onFocus']
  size?: InputProps['size']
  ref?: React.RefObject<any>
}

export const Select = ({
  ref: forwardedRef,
  autoFocus = false,
  clearable = false,
  'data-testid': dataTestId,
  disabled = false,
  itemToString = defaultItemToString,
  itemToValue = defaultItemToValue,
  multiple = false,
  name,
  noResults = 'No results found.',
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder = 'Choose from…',
  renderItem = itemToString,
  renderMultiple: RenderMultiple = DefaultRenderMultiple,
  search = false,
  size = 'lg',
  value,
  variant,
  ...rest
}: SelectProps) => {
  const tid = (s?: string) =>
    dataTestId ?
      {'data-testid': [dataTestId, s].filter(Boolean).join('-')}
    : null

  const [inputValue, setInputValue] = useState<string>('')

  const [selectedItems, remainingItems] = useMemo(() => {
    const va = ensureArray(value)
    return R.partition(
      R.compose(v => va.includes(v), itemToValue),
      options,
    )
  }, [itemToValue, options, value])

  const unfilteredItems = multiple ? remainingItems : options

  const filteredItems = useMemo(
    () =>
      typeof search === 'function' ? search(unfilteredItems, inputValue)
      : inputValue ?
        matchSorter(unfilteredItems, inputValue, {keys: [itemToString]})
      : unfilteredItems,
    [inputValue, itemToString, unfilteredItems, search],
  )

  const {addSelectedItem, getDropdownProps, getSelectedItemProps} =
    useMultipleSelection({
      itemToString,
      onStateChange: changes => {
        log.debug?.('useMultipleSelection.onStateChange', changes)
        switch (changes.type) {
          case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
            if (multiple) {
              // This little dance keeps the selected items in the same order as
              // options, which is presumably the preferred order.
              const selectedValues = new Set(
                changes.selectedItems!.map(itemToValue),
              )
              onChange?.(
                // @ts-expect-error
                createEvent({
                  name,
                  value: options
                    .map(itemToValue)
                    .filter(v => selectedValues.has(v)),
                }),
              )
            } else {
              // Even though we're using useMultipleSelection, this is not
              // actually a multiple select, so update the controlled value to the
              // most recently selected item.
              onChange?.(
                // @ts-expect-error
                createEvent({
                  name,
                  value: itemToValue(R.last(changes.selectedItems!)),
                }),
              )
            }
            break

          default:
            break
        }
      },
      selectedItems,
    })

  // useMultipleSelection provides removeSelectedItem, but it doesn't fire when
  // the same index is clicked twice in a row, even when selectedValues have
  // changed (so the duplicate index refers to different items).
  const removeSelectedItem = useCallback(
    (item: any) => {
      log.assert(multiple, 'removeSelectedItem for non-multiple Select')
      const removedValue = itemToValue(item)
      if (onChange && Array.isArray(value)) {
        onChange(
          // @ts-expect-error
          createEvent({
            name,
            value: value.filter(v => v !== removedValue),
          }),
        )
      }
    },
    [itemToValue, multiple, name, onChange, value],
  )

  // useMultipleSelection provides reset, but this is easier.
  const clear = useCallback(
    (e: MouseEvent) => {
      e?.stopPropagation()
      onChange?.(
        // @ts-expect-error
        createEvent({
          name,
          value: multiple ? [] : '',
        }),
      )
      setInputValue('')
    },
    [name, multiple, onChange],
  )

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    openMenu,
    selectItem,
    toggleMenu,
  } = useCombobox({
    id: useId(),
    inputValue,
    items: filteredItems,
    onStateChange: changes => {
      log.debug?.('useCombobox.onStateChange', changes)
      const {type, inputValue, selectedItem} = changes
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '')
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            setInputValue('')
            addSelectedItem(selectedItem)
            selectItem(null)
          }
          break
        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue('')
          break
        default:
          break
      }
    },
  })

  // Autofocus
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus, inputRef])

  // Merge refs for passing through
  const ref = useMergedRefs([inputRef, forwardedRef])

  const hasClearButton = clearable && !!selectedItems.length

  const inputProps = getInputProps(
    getDropdownProps({
      autoComplete: 'off',
      disabled,
      onBlur,
      onFocus,
      ref,
      tabIndex: 0,
      ...(search ?
        {
          as: 'input',
          onClick: openMenu,
          placeholder:
            selectedItems.length ?
              multiple ? 'Specific values'
              : itemToString(selectedItems[0])
            : placeholder,
        }
      : {
          as: 'button',
          type: 'button',
          children:
            selectedItems.length ?
              multiple ? 'Specific values'
              : itemToString(selectedItems[0])
            : placeholder,
          onClick: toggleMenu,
        }),
    }),
  )

  return (
    <S.Wrapper {...rest}>
      <S.InputWrapper {...getComboboxProps()}>
        <S.Input
          {...inputProps}
          hasClearButton={hasClearButton}
          size={size}
          {...tid()}
        />
        <S.Indicators>
          {hasClearButton && (
            <S.IndicatorButton onClick={clear}>
              <Icon icon="close" size="1.25em" />
            </S.IndicatorButton>
          )}
          <S.DropDownIndicator
            {...getToggleButtonProps({
              disabled,
              tabIndex: -1,
            })}
            isOpen={isOpen}
            {...tid('arrow-icon')}
          >
            <Icon icon="chevronDown" size="1.25em" />
          </S.DropDownIndicator>
        </S.Indicators>
      </S.InputWrapper>
      <S.Menu {...getMenuProps()} isOpen={isOpen}>
        {isOpen &&
          (filteredItems.length ?
            filteredItems.map((item: any, index: number) => (
              <S.Item
                {...getItemProps({
                  index,
                  item,
                  multiple,
                })}
                isHighlighted={highlightedIndex === index}
                key={itemToValue(item)}
              >
                {renderItem(item)}
              </S.Item>
            ))
          : noResults ? <S.Item disabled>{noResults}</S.Item>
          : null)}
      </S.Menu>
      {multiple && (
        <RenderMultiple
          getSelectedItemProps={getSelectedItemProps}
          handleClick={removeSelectedItem}
          items={selectedItems}
          itemToValue={itemToValue}
          renderItem={renderItem}
        />
      )}
    </S.Wrapper>
  )
}
