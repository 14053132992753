import {NavLinkProps} from 'quickstart/components/layout/NavLink'
import {useUserData} from 'quickstart/hooks/useUserData'
import {Nullish, truthy} from 'quickstart/types'
import * as R from 'rambdax'
import {ReactNode} from 'react'
import {falsish, logger, meta} from 'tizra'

const log = logger('Nav/hooks')

type FilterFn = (item: NavLinkProps, index: number, arr: NavLinkProps[]) => any

const deepFilter = (fn: FilterFn) => {
  const df = (items: NavLinkProps[]): NavLinkProps[] =>
    items
      .map(item =>
        'items' in item && item.items ? {...item, items: df(item.items)} : item,
      )
      .filter(fn)
  return df
}

type MapFn = (item: NavLinkProps) => NavLinkProps | Nullish

const deepMap = (fn: MapFn) => {
  const dm = (items: NavLinkProps[]): NavLinkProps[] =>
    items
      .map(fn)
      .filter(truthy)
      .map(item =>
        'items' in item && item.items ? {...item, items: dm(item.items)} : item,
      )
  return dm
}

const isEmptyReactNode = (x: ReactNode) =>
  falsish(x) || (typeof x === 'string' && !x.trim())

/**
 * Test if a NavLink is empty for display purposes, meaning an empty label or
 * a link that doesn't function, or a menu with nothing inside.
 */
const isEmptyNavLink: FilterFn = item =>
  item.type === 'account' || item.type === 'bookshelf' || item.type === 'link' ?
    (isEmptyReactNode(item.label) && isEmptyReactNode(item.children)) ||
    (!item.href?.trim() && !item.onClick)
  : item.type === 'menu' ? !item.items?.length
  : false

/**
 * Test if a NavLink is a separator that abuts another separator, or the
 * start/end of a menu.
 */
const isSpuriousSeparator: FilterFn = (item, i, arr) =>
  item.type === 'separator' &&
  (i === 0 || i === arr.length - 1 || arr[i - 1].type === 'separator')

const deepFilterEmpty = (items: NavLinkProps[]): NavLinkProps[] => {
  // Filter repeatedly until settled.
  for (let i = 0; ; i++) {
    if (i > 9) {
      log.error('filter loop')
      break
    }
    const newItems = R.piped(
      items,
      deepFilter(R.complement(isEmptyNavLink)),
      deepFilter(R.complement(isSpuriousSeparator)),
    )
    if (R.equals(newItems, items)) break
    items = newItems
  }
  return items
}

export const useNavItems = ({items}: {items: NavLinkProps[]}) => ({
  items: R.piped(
    items,
    deepFilter(
      item =>
        item &&
        // Filter user-specific stuff out of the general nav. We didn't support
        // this prior to the nested nav, and it's better UX to keep general and
        // personalized nav separate, so don't open the door unnecessarily now.
        item.type !== 'account' &&
        item.type !== 'bookshelf',
    ),
    deepFilterEmpty,
  ),
})

export type UseProfileItemsProps = {
  profileItems: NavLinkProps[]
  bookshelfConfig: {title: string; url: string}
  includeSignIn: boolean
}

export const useProfileItems = ({
  profileItems,
  bookshelfConfig,
  includeSignIn,
}: UseProfileItemsProps): {
  profileName: string
  profileItems: NavLinkProps[]
} => {
  const userData = useUserData({enabled: includeSignIn})
  const profileName = meta.userProfileName(userData)

  if (!includeSignIn || userData === null) {
    return {profileName, profileItems: []}
  }

  if (!userData) {
    return {
      profileName,
      profileItems: [
        {
          type: 'link',
          label: 'Sign in',
          onClick: () => window.tizra.login(),
          // @ts-expect-error HACK
          _signin: true,
        },
      ],
    }
  }

  const mapFn: MapFn = item => {
    if (item.type === 'account') {
      return !userData ? null : (
          {
            ...item,
            href: item.href || meta.userInfoHref(userData),
          }
        )
    }
    if (item.type === 'bookshelf') {
      return !userData ? null : (
          {
            ...item,
            label: item.label === 'AUTO' ? bookshelfConfig.title : item.label,
            href: bookshelfConfig.url,
          }
        )
    }
    return item
  }

  profileItems = R.piped(
    profileItems,
    // Map first, since this might return links with empty labels.
    deepMap(mapFn),
    deepFilter(item => item.type !== 'expando'),
    deepFilterEmpty,
  )

  if (userData) {
    if (profileItems.length) {
      profileItems.push({type: 'separator'})
    }
    profileItems.push({
      type: 'link',
      label: 'Sign out',
      onClick: () => window.tizra.logout(),
    })
  }

  return {profileName, profileItems}
}
