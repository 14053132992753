import * as A from '../admin'

export interface Config {
  staticText: string
  staticType: 'markdown' | 'html' | 'text' | string
  container: 'default' | 'wider' | 'none'
}

export const defaultConfig: Config = {
  staticText: '',
  staticType: '',
  container: 'default',
}

export const Admin: A.Admin<Config> = () => {
  const containerOptions = A.useOptions<Config['container']>(A.containerOptions)
  return (
    <A.Tabs>
      <A.Tab title="Presentation">
        <A.Radio
          label="Text container"
          name="container"
          options={containerOptions}
        />
      </A.Tab>
    </A.Tabs>
  )
}
