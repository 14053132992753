import * as T from 'quickstart/theme'
import {PartialDeep} from 'type-fest'

export const getFonts = (
  theme: PartialDeep<{
    branding: {bodyFont: T.FontName; headingFont: T.FontName}
  }>,
) => {
  const branding = T.getBranding({
    fonts: {
      // These are Open Sans for test stability since our styled-components
      // theme historically defaulted this way. Customer sites should default to
      // Merriweather Sans because branding info should always be available
      // courtesy of ThemeBlock global config migration.
      body: theme.branding?.bodyFont || 'Open Sans',
      heading: theme.branding?.headingFont || 'Open Sans',
    },
  })
  return T.getFonts(branding.fonts)
}

// compat
export {cssVars, fontSpecs} from 'quickstart/theme'
export type {FontKey} from 'quickstart/theme'
