import {Text} from 'quickstart/components/content/Text'
import {Section} from 'quickstart/components/layout/Section'
import {Children, ComponentProps, ReactNode} from 'react'
import {IS_TEST, logger} from 'tizra'
import * as S from './styles'

const log = logger('SideNav')

interface SideNavProps extends ComponentProps<typeof S.SideNav> {
  heading: ReactNode
}

export const SideNav = ({heading, children, ...props}: SideNavProps) => (
  <S.SideNav {...props}>
    <Section as="nav" bleed>
      <Text prose variant="subnavHead">
        {heading}
      </Text>
      <div>
        {Children.map(children, child => (
          <S.SideNavItem>{child}</S.SideNavItem>
        ))}
      </div>
    </Section>
    <S.SideNavContent ref={rearrange} />
  </S.SideNav>
)

function rearrange(target: HTMLElement | null) {
  if (target) {
    if (target.children.length !== 0) {
      log.error('target has children?!')
      return
    }

    const blockDiv = target.closest('.t-quickstart-block')
    if (!blockDiv) {
      // Don't trip on fake refs in storyshots
      log.assert(IS_TEST, "can't find surrounding .t-quickstart-block")
      return
    }

    const stackDiv = blockDiv.closest('.t-stacked')
    if (!stackDiv) {
      log.error("can't find surrounding stack")
      return
    }
    if (stackDiv.firstElementChild !== blockDiv) {
      log.error('must be first block in stack')
      return
    }

    const siblings = Array.from(stackDiv.childNodes).filter(
      node => node !== blockDiv,
    )
    log.debug?.('moving siblings into target')
    siblings.forEach(sib => {
      target.append(sib)
    })
  }
}
