import * as R from 'rambdax'
import {useMemo} from 'react'
import {MetaObject, logger} from 'tizra'
import {useApi} from './useApi'
import {useEqualBy} from './useEqualBy'

const log = logger('useAccess')

interface UseAccessProps {
  metaObj?: MetaObject // TocEntry need not apply
  tizraId?: string
  views: string[]
}

interface UseAccessReturn {
  [k: string]: boolean | null
}

export const useAccess = ({
  metaObj,
  tizraId = metaObj?.tizraId,
  ...props
}: UseAccessProps): UseAccessReturn => {
  const {sessionViewConfig: canAccess} =
    useApi.canAccess(tizraId && !metaObj?.isFree && {tizraId}).data || {}

  const views = useEqualBy(R.equals, props.views)

  return useMemo(
    () =>
      Object.fromEntries(
        views.map(v => {
          const isFree =
            metaObj?.isFree ||
            (canAccess ?
              canAccess.free.includes(v) ||
              (canAccess.free.includes('*') &&
                !canAccess.restricted.includes(v))
            : null)
          const isRestricted =
            canAccess ?
              canAccess.restricted.includes(v) ||
              (canAccess.restricted.includes('*') &&
                !canAccess.free.includes(v))
            : null
          if (isFree && isRestricted) {
            log.error(`${tizraId} is both free and restricted`)
            return [v, false]
          }
          if (isFree === false && isRestricted === false) {
            log.error(`${tizraId} is neither free nor restricted`)
            return [v, false]
          }
          return (
            isFree ? [v, true]
            : canAccess ? [v, false]
            : [v, null]
          )
        }),
      ),
    [canAccess, metaObj, tizraId, views],
  )
}
