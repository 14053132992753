import {Dict, StrictReactNode} from 'quickstart/types'
import {MetaObject, logger, meta} from 'tizra'
import * as B from '../block'
import {ActionSpec, Config} from './admin'

const log = logger('HeaderBlock')
const {browser} = log

// TODO additional actions

type ActionProps = {label: StrictReactNode; linkProps: Dict}

type ExerciseProps = Pick<Config, 'alignment'> & {actions: ActionProps[]}

export const Exercise = ({alignment, actions}: ExerciseProps) => {
  return actions.length === 0 ?
      null
    : <B.Stack spacing="lg">
        <B.Button.Group style={{justifyContent: B.flexAlign(alignment)}}>
          {actions.slice(0, 2).map(({label, linkProps}, i) => (
            <B.Button key={i} as="a" {...linkProps}>
              {label}
            </B.Button>
          ))}
        </B.Button.Group>
        {/*
      actions.length > 2 && (
        <B.Prose>
          <B.Text>You can also:</B.Text>
          {actions.slice(2).map(({label, linkProps}, i) => (
            <B.Link key={i} {...linkProps}>
              {label}
            </B.Link>
          ))}
        </B.Prose>
      )
      */}
      </B.Stack>
}

type UseActionsProps = Pick<Config, 'callsToAction' | 'v1'> & {
  metaObj: MetaObject | undefined
}

type UseActionsReturn = null | ActionProps[]

export const useActions = ({
  callsToAction,
  metaObj,
  v1,
}: UseActionsProps): UseActionsReturn => {
  const views = callsToAction.exercise.actions.map(a => a.view).concat('player')
  const canAccess = B.useAccess({metaObj, views})

  // Wait for API calls to complete before rendering anything, so it doesn't
  // flip flop between Gain and Exercise.
  if (!metaObj || !canAccess) {
    return null
  }

  const {metaType} = metaObj
  const actions = callsToAction.exercise.actions
    .map(action => {
      if (!canAccess[action.view]) {
        return null
      }

      // Compatibility shim: HeaderBlock v1 ignored read/download buttons for
      // playable video. We only do this until the v2 config has been saved in
      // the admin, which will remove v1 from the JSON.
      if (v1 && metaType === 'Video' && canAccess.player) {
        browser.log(
          `ignoring ${action.view} button for unmigrated config on ${metaType}`,
        )
        return null
      }

      const label = actionLabel({action, metaObj})
      const linkProps = actionLinkProps({action, metaObj})
      return label && linkProps ? {label, linkProps} : null
    })
    .filter(Boolean) as ActionProps[] // TS doesn't grok .filter(Boolean)

  return actions
}

const AutoLabel = ({
  metaObj,
  view,
}: {
  metaObj?: any
  view: ActionSpec['view']
}) => {
  if (!metaObj) {
    return null
  }
  switch (view) {
    case 'page':
      return <>Read online</>
    case 'sourceDownload':
      return <>Download</>
    default:
      return <>{view}</>
  }
}

function actionLabel({
  action: {
    label: {mode, custom, prop},
    view,
  },
  metaObj,
}: {
  action: ActionSpec
  metaObj?: any
}): StrictReactNode {
  switch (mode) {
    case 'custom':
      return custom
    case 'prop':
      return metaObj ? <B.MetaValue metaObj={metaObj} prop={prop} /> : null
    case 'auto':
      return metaObj ? <AutoLabel metaObj={metaObj} view={view} /> : null
  }
}

const actionLinkProps = ({
  action: {
    dest: {mode, custom, prop},
    view,
  },
  metaObj,
}: {
  action: ActionSpec
  metaObj?: MetaObject
}): Dict | null => {
  switch (mode) {
    case 'custom':
    case 'prop': {
      const href = mode === 'custom' ? custom : meta.string(metaObj, prop)
      if (!href) {
        return null
      }
      const linkProps: Dict = {href}
      if (href.includes('://')) {
        linkProps.target = '_blank'
      }
      return linkProps
    }
    case 'auto':
      switch (view) {
        case 'page': {
          const href = meta.readerHref(metaObj)
          return href ? {href} : null
        }
        case 'sourceDownload': {
          const href =
            metaObj?.pdfSourceFileName ?
              meta.href(metaObj, {source: 'PdfSource/0'})
            : null
          return href ? {href} : null
        }
        default:
          return null
      }
  }
}
