import * as B from '../block'
import {useBookshelfConfig} from '../BookshelfBlock'
import {useAuthConfig} from '../SignInBlock'
import {
  defaultConfig,
  defaultGlobalConfig,
  RedemptionAdmin,
  RedemptionGlobalAdmin,
} from './admin'

interface TizraWindow extends Window {
  tizra: any
}

declare let window: TizraWindow

export const RedemptionBlock = B.blockWithInfo(
  {
    name: 'redemption',
    displayName: 'RedemptionBlock',
    title: 'Redemption Code',
    Admin: RedemptionAdmin,
    GlobalAdmin: RedemptionGlobalAdmin,
    defaultConfig,
    defaultGlobalConfig,
  },
  ({config: {heading, message}}) => {
    const authConfig = useAuthConfig()
    const bookshelfConfig = useBookshelfConfig()
    const userData = B.useUserData()

    // Unlike the auth forms, never default successUrl for redemption, because
    // RedemptionForm will do better defaulting depending on what is added to
    // the account.
    const successUrl = B.useSuccessUrl()

    // Handle ?redemptionCode=XXX by attempting to submit immediately.
    const {redemptionCode} = B.useRedemptionParams()

    const defaultMessage = `Your content will be displayed on successful submission of a valid code. NOTE: The code must be entered exactly, without spaces.`

    const signInMessage = (
      <>
        Please <B.Link onClick={() => window.tizra.login()}>sign in</B.Link> to
        redeem your access code.
        {authConfig.registration.enabled && (
          <>
            <br />
            Don't have an account?{' '}
            <B.Link onClick={() => window.tizra.login('register')}>
              Register here.
            </B.Link>{' '}
          </>
        )}
      </>
    )

    return (
      <B.Section>
        {userData ?
          <B.RedemptionForm
            autoFocus={true}
            bookshelfConfig={bookshelfConfig}
            heading={heading}
            message={message || defaultMessage}
            redemptionCode={redemptionCode}
            successUrl={successUrl}
          />
        : userData === false ?
          <B.FormShell header={heading}>
            <B.Text>{signInMessage}</B.Text>
          </B.FormShell>
        : null}
      </B.Section>
    )
  },
)

export const useRedemptionConfig = () => B.useGlobalConfig(RedemptionBlock)
