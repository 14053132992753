import {
  AriaBreadcrumbsProps,
  useBreadcrumbItem,
  useBreadcrumbs,
} from '@react-aria/breadcrumbs'
import {Link} from 'quickstart/components/content/Link'
import {
  Children,
  ComponentProps,
  Fragment,
  ReactElement,
  cloneElement,
  useRef,
} from 'react'
import {truish} from 'tizra'
import S from './styles.module.css'

type BreadcrumbsItemProps = Parameters<typeof useBreadcrumbItem>[0] & {
  href?: string
  linkVariant?: ComponentProps<typeof Link>['variant']
}

const BreadcrumbsItem = ({
  href,
  linkVariant,
  ...props
}: BreadcrumbsItemProps) => {
  const ref = useRef<any>(null)
  const {itemProps} = useBreadcrumbItem(
    {elementType: href ? 'a' : 'span', ...props},
    ref,
  )
  return (
    <li className={S.item}>
      {href ?
        <Link
          {...props}
          {...itemProps}
          href={href}
          variant={linkVariant}
          ref={ref}
        />
      : <span {...props} {...itemProps} ref={ref} />}
    </li>
  )
}

type BreadcrumbsProps = AriaBreadcrumbsProps &
  ComponentProps<'div'> &
  Pick<BreadcrumbsItemProps, 'linkVariant'>

const _Breadcrumbs = ({
  children,
  linkVariant = 'breadcrumb',
  ...props
}: BreadcrumbsProps) => {
  const {navProps} = useBreadcrumbs(props)
  const childArray = (
    Children.toArray(children).filter(
      truish,
    ) as ReactElement<BreadcrumbsItemProps>[]
  ).map((child, i) => (
    <Fragment key={i}>
      {!!i && (
        <span className={S.separator} aria-hidden="true">
          &gt;
        </span>
      )}
      {cloneElement(child, {linkVariant})}
    </Fragment>
  ))
  return (
    <div {...props} {...navProps}>
      <ol className={S.list}>{childArray}</ol>
    </div>
  )
}

export const Breadcrumbs = Object.assign(_Breadcrumbs, {Item: BreadcrumbsItem})
