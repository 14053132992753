import {CURRENT_OBJ_VALUE} from 'quickstart/hooks/useWithinField'
import * as B from '../block'
import {Admin, defaultConfig} from './admin'

export const SearchWithinBlock = B.blockWithInfo(
  {
    name: 'search-within',
    displayName: 'SearchWithinBlock',
    title: 'Search Within',
    Admin,
    defaultConfig,
  },
  ({config: {heading, mode, placeholder, specificId}}) => (
    <B.Section>
      <B.TextContainer
        variant="strict"
        style={{marginLeft: 'auto', marginRight: 'auto'}}
      >
        {heading && (
          <B.Section.Heading variant="sectionHeadTight">
            {heading}
          </B.Section.Heading>
        )}
        <B.QuickSearch
          button={false}
          placeholder={placeholder}
          params={
            mode === 'current' ? {within: CURRENT_OBJ_VALUE}
            : mode === 'specific' && specificId ?
              {within: specificId}
            : undefined
          }
          relative={mode === 'current'}
        />
      </B.TextContainer>
    </B.Section>
  ),
)
