import {useMoo} from './useMoo'

export const useMergedRefs = (refs: any[]) =>
  useMoo(
    () => (current: unknown) => {
      refs.forEach(ref => {
        if (typeof ref === 'function') {
          ref(current)
        } else if (ref && !Object.isFrozen(ref)) {
          ref.current = current
        }
      })
    },
    refs,
  )
