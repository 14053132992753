import {themeGetter} from 'quickstart/styled-components/system'

const getSummaries = (theme: any) => {
  const th = themeGetter(theme)
  return {
    default: {
      summary: {
        backgroundColor: th('colors.backgroundColorWhite'),
        borderWidth: 0,
        gap: '1.5em',
      },
      body: {},
      cover: {
        borderTopLeftRadius: th('radii.none'),
        borderTopRightRadius: th('radii.none'),
      },
      heading: {
        ...th('fonts.heading'),
        ...th('prose.summaryHead'),
        color: th('colors.linkColor'),
      },
      description: {
        ...th('fonts.body'),
        ...th('prose.summarySnippet'),
      },
      pageInfo: {
        ...th('prose.summaryMeta'),
        color: th('colors.textColorMuted'),
      },
      footer: {
        ...th('ui.summaryMeta'),
        color: th('colors.textColorMuted'),
      },
    },
  }
}

export default getSummaries
