import {styled} from 'quickstart/theme/linaria'
import {CSSProperties} from 'react'

export const Wrapper = styled.div<{
  alignItems: NonNullable<CSSProperties['alignItems']>
  justifyContent: NonNullable<CSSProperties['justifyContent']>
}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`
