import {Image} from 'quickstart/components/content/Image'
import {Prose, Text} from 'quickstart/components/content/Text'
import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {Input} from 'quickstart/components/controls/Input'
import {FormShell, FormShellProps} from 'quickstart/components/layout/FormShell'
import {useLocation} from 'quickstart/hooks'
import {focusError, formErrors} from 'quickstart/utils/final-form-errors'
import {ReactNode, useState} from 'react'
import * as Final from 'react-final-form'
import {useHref} from 'react-router-dom'
import {api} from 'tizra'
import {AuthLink, AuthLinkProps} from './AuthLink'

interface AuthRegisterProps {
  autoFocus?: boolean
  heading?: string | null
  logoUrl?: string
  message?: ReactNode
  modal?: boolean
  show?: AuthLinkProps['show']
  signin: {url: string}
  successUrl?: string
  variant?: FormShellProps['variant'] | 'checkout'
}

// Differentiate between the different kinds of registration success.
type SuccessReason = null | 'sent' | 'success'

export const AuthRegister = ({
  autoFocus,
  logoUrl,
  message,
  modal = false,
  show,
  signin,
  successUrl,
  variant = FormShell.defaultVariant(modal),
  heading = variant === 'checkout' ? null : 'Create Account',
}: AuthRegisterProps) => {
  const formShellVariant = variant === 'checkout' ? 'fluid' : variant

  const [successReason, setSuccessReason] = useState<SuccessReason>(null)

  // This is different from window.location.href because it's host-relative,
  // meaning it omits scheme://hostname
  const defaultSuccessUrl = useHref(useLocation())

  const submit = async (values: any) => {
    const {status, errors, message, reason} = await api.registerUser({
      ...values,
      // Why send the successUrl to the API? If the site has the
      // RequireActivationEmail advanced site property turned on, then the
      // validation link needs to include a successUrl, otherwise the user
      // will land on the change password screen after validation. #385
      successUrl: successUrl || defaultSuccessUrl,
    })

    if (status === 200) {
      setSuccessReason(reason as SuccessReason)
      return // React Final Form will set submitSucceeded
    }

    return formErrors({errors, reason, message, status})
  }

  return (
    <Final.Form
      onSubmit={submit}
      decorators={[focusError]}
      render={({
        handleSubmit,
        submitSucceeded,
        submitError,
        values: {email, FirstName} = {},
      }) =>
        submitSucceeded ?
          successReason === 'sent' ?
            <FormShell
              header={heading && 'Activation Required'}
              modal={modal}
              variant={formShellVariant}
            >
              <Prose>
                <Text>An activation link has been sent to {email}.</Text>
                <Text>
                  You must click the link to activate your account. Check your
                  spam folder if it doesn't arrive shortly.
                </Text>
              </Prose>
            </FormShell>
          : <FormShell
              header={heading && 'Success!'}
              modal={modal}
              variant={formShellVariant}
              primaryButton={{
                children: 'Continue',
                onClick: () => {
                  // Don't default successUrl here, because we might be in the
                  // modal, or otherwise on the intended destination already. If
                  // the caller (RegisterUserBlock) wants to redirect elsewhere,
                  // they can pass it in.
                  if (successUrl) {
                    window.location.href = successUrl
                  } else {
                    window.location.reload()
                  }
                },
              }}
            >
              <Text>
                Welcome{FirstName ? `, ${FirstName}` : ''}! Your account has
                been created.
              </Text>
            </FormShell>
        : <FormShell
            modal={modal}
            variant={formShellVariant}
            header={
              variant === 'modalish' && logoUrl ?
                <Image
                  loading="eager"
                  src={logoUrl}
                  style={{
                    maxHeight: '50px',
                    width: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              : heading
            }
            onSubmit={handleSubmit}
            helpMessage={message}
            errorMessage={submitError}
            primaryButton={{
              type: 'submit',
              children: 'Create Account',
            }}
            footer={
              <Text
                style={{
                  textAlign:
                    variant === 'modal' || variant === 'modalish' ?
                      'center'
                    : undefined,
                }}
              >
                Have an account?{' '}
                <AuthLink
                  href={signin.url}
                  variant="ui"
                  // See XXX in Link/theme.js
                  lineHeight="inherit"
                  show={show}
                  successUrl={successUrl}
                  to="signin"
                >
                  Sign in here
                </AuthLink>
              </Text>
            }
          >
            <FormShell.Grid gridTemplateColumns={{_: '1fr', md: '1fr 1fr'}}>
              <ConnectedField
                autoComplete="given-name"
                autoFocus={autoFocus}
                component={Input}
                label="First Name"
                // Upper-cased because this is applied directly to UserData
                // which uses this casing by default.
                name="FirstName"
              />
              <ConnectedField
                autoComplete="family-name"
                component={Input}
                label="Last Name"
                // Upper-cased because this is applied directly to UserData
                // which uses this casing by default.
                name="LastName"
              />
            </FormShell.Grid>
            <ConnectedField
              autoComplete="email"
              component={Input}
              label="Email"
              name="email"
            />
            <ConnectedField
              autoComplete="new-password"
              component={Input}
              label="Password"
              name="password"
              type="password"
            />
          </FormShell>
      }
    />
  )
}
