import {useAdminApi} from 'quickstart/hooks/useApi'
import {GenericMetaObject, logger} from 'tizra'
import {makeOptions} from '../utils'

const log = logger('blocks/admin/hooks/useCollectionOptions')

export const useCollectionOptions = ({
  format = (x: GenericMetaObject) => `${x.name} (${x.tizraId})`,
}: {format?: (x: GenericMetaObject) => string} = {}) => {
  // TODO how to omit VirtualCollectionDefinitions here?
  const {data} = useAdminApi.list({
    metaType: ['SubjectCollection'],
    limit: 1000,
    fields: ['tizra-id', 'name'],
  })
  const collections = data?.result ?? []
  return makeOptions({valueKey: 'tizraId', labelKey: format})(collections)
}
