import {ReactNode, useCallback, useMemo, useState} from 'react'
import {
  CheckoutMeta,
  CheckoutMetaMethod,
  CheckoutMetaStep,
  logger,
  truthy,
} from 'tizra'
import * as B from '../block'
import {CheckoutStep} from './CheckoutStep'
import {PickCheckoutMethod} from './PickCheckoutMethod'
import {SignIn} from './SignIn'
import {Config} from './admin'
import * as S from './styles'
import {NextSteps, StepComponent} from './types'
import {
  FULFILLER_BUTTONS_METHOD,
  FULFILLER_BUTTONS_STEP,
  FulfillerButtons,
} from './FulfillerButtons'

const log = logger('CartBlock/CheckoutWizard')

const CIRCLED_NUMBERS = [
  '⓪',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
] as const

const NumberedHeading = ({
  number,
  children,
}: {
  children: ReactNode
  number: number
}) => (
  <S.NumberedHeading>
    <div>{CIRCLED_NUMBERS[number]}</div>
    <div>{children}</div>
  </S.NumberedHeading>
)

type CheckoutWizardProps = {
  config: Config
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
  checkoutMeta: CheckoutMeta
  checkoutMethod: CheckoutMetaMethod | undefined
  setMethodName: (name: string) => void
}

export const CheckoutWizard = ({
  config,
  checkoutMeta,
  checkoutMethod,
  setMethodName,
  userData,
  ...props
}: CheckoutWizardProps) => {
  const showMethodPicker = checkoutMeta.checkoutMethods.length !== 1

  const [currentIndex, setIndex] = useState<number>(
    !userData ? 0
    : showMethodPicker && checkoutMethod ? 2
    : 1,
  )
  const proceed = useCallback(() => setIndex(i => i + 1), [setIndex])

  const firstSteps: NextSteps | undefined = B.useApi.checkoutMethod(
    checkoutMethod &&
      checkoutMethod.name !== FULFILLER_BUTTONS_METHOD && {
        name: checkoutMethod.name,
      },
  ).data?.firstSteps
  const [_nextSteps, _setNextSteps] = useState<NextSteps>({})
  const setNextSteps = useCallback(
    (ns: NextSteps) => _setNextSteps(ons => ({...ons, ...ns})),
    [_setNextSteps],
  )
  const nextSteps = useMemo(
    () => ({...firstSteps, ..._nextSteps}),
    [firstSteps, _nextSteps],
  )

  const steps = useMemo<
    Array<{title: string; Component: StepComponent; step?: CheckoutMetaStep}>
  >(() => {
    // Make sure that steps listed in firstSteps are above the others.
    const firstStepsFirst =
      !checkoutMethod ? []
      : !firstSteps ? checkoutMethod.steps
      : checkoutMethod.steps.slice().sort((a, b) => {
          const af = a.step in firstSteps
          const bf = b.step in firstSteps
          return (
            af === bf ? 0
            : af ? -1
            : 1
          )
        })

    const methodSteps = firstStepsFirst.map(step => ({
      title: step.displayName,
      Component:
        step.step === FULFILLER_BUTTONS_STEP ? FulfillerButtons : CheckoutStep,
      step,
    }))

    return [
      {title: 'Sign in', Component: SignIn},
      showMethodPicker && {
        title: 'Checkout method',
        Component: PickCheckoutMethod,
      },
      ...methodSteps,
    ].filter(truthy)
  }, [checkoutMethod, firstSteps, showMethodPicker])

  return (
    <B.Stack divided endCapped spacing="lg" {...props}>
      {steps.map(({title, Component, step}, myIndex) => {
        const active = currentIndex === myIndex
        const canEdit = currentIndex > myIndex
        return (
          <B.Stack key={myIndex}>
            <B.LeftRight>
              <NumberedHeading number={myIndex + 1}>{title}</NumberedHeading>
              <B.Text variant="textMd">
                {canEdit ?
                  <B.Link onClick={() => setIndex(myIndex)}>Edit</B.Link>
                : <span style={{visibility: 'hidden'}}>Edit</span>}
              </B.Text>
            </B.LeftRight>
            <Component
              active={active}
              userData={userData}
              config={config}
              setIndex={setIndex}
              myIndex={myIndex}
              currentIndex={currentIndex}
              proceed={proceed}
              checkoutMeta={checkoutMeta}
              checkoutMethod={checkoutMethod}
              setMethodName={setMethodName}
              step={step}
              nextSteps={nextSteps}
              setNextSteps={setNextSteps}
            />
          </B.Stack>
        )
      })}
    </B.Stack>
  )
}
