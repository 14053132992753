import {ComponentProps} from 'react'
import * as S from './styles'

const _Headline = (props: ComponentProps<typeof S.Wrapper>) => (
  <S.Wrapper {...props} />
)

export const Headline = Object.assign(_Headline, {
  Cover: S.Cover,
  Body: S.Body,
  Heading: S.Heading,
})
