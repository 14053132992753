import {retheme, styled, theme} from 'quickstart/theme/linaria'
import {withTheme} from 'quickstart/theme'
import {ComponentProps} from 'react'

type BK = keyof typeof theme.borders
type BV = (typeof theme.borders)[BK]

interface XDividerProps extends ComponentProps<'hr'> {
  $border: BV | string
}

export const RowDivider = withTheme(styled.hr<XDividerProps>`
  /* Do not set height:100% here because it results in 0.6667px. The default of
   * align-items:stretch in a flex container has the desired result.
   */
  width: 0;
  margin: 0;
  border-left: ${retheme('$border')};
`)

export const ColDivider = withTheme(styled.hr<XDividerProps>`
  width: 100%;
  height: 0;
  margin: 0;
  border-top: ${retheme('$border')};
`)

type SK = keyof typeof theme.space
type SV = (typeof theme.space)[SK]

interface StackProps {
  'data-row': boolean
  'data-divided': boolean
  'data-start-capped': boolean
  'data-end-capped': boolean
  $spacing: SV | string
}

export const Stack = withTheme(styled.div<StackProps>`
  display: flex;

  &[data-row] {
    flex-direction: row;
    & > *:not(hr, :first-child) {
      padding-left: ${retheme('$spacing')};
    }
    &[data-divided] > *:not(hr, :last-child),
    &[data-end-capped] > *:nth-last-child(2):not(hr) {
      padding-right: ${retheme('$spacing')};
    }
  }

  &:not([data-row]) {
    flex-direction: column;
    & > *:not(hr, :first-child) {
      padding-top: ${retheme('$spacing')};
    }
    &[data-divided] > *:not(hr, :last-child),
    &[data-end-capped] > *:nth-last-child(2):not(hr) {
      padding-bottom: ${retheme('$spacing')};
    }
  }
`)

export const Item = styled.div``
