import {deepMerge, logger} from 'tizra'
import * as A from '../admin'

const log = logger('HeaderBlock/v0')

export interface Action {
  button: string
  option: 'Reader' | 'Download' | 'Sign in' | 'Contact'
}

export const actions = {
  READ: {button: 'Read online', option: 'Reader'} as Action,
  DOWNLOAD: {button: 'Download', option: 'Download'} as Action,
  LOGIN: {button: 'Sign in', option: 'Sign in'} as Action,
  CONTACT: {button: 'Contact us', option: 'Contact'} as Action,
} as const

export interface Config {
  VERSION?: never
  alignment: 'left' | 'center' | 'right'
  headingSize: 'small' | 'normal' | 'large'
  layout: 'above' | 'below' | 'left' | 'right' | 'none'
  subHeadingProp: string
  headingProp: string
  otherProps: string[]
  callsToAction: any
  customCallsToAction: {
    canAccess: any[]
    noAccessSignedOut: any[]
    noAccessSignedIn: any[]
  }
}

export const defaultConfig: Config = {
  alignment: 'center', // only applies to single column
  headingSize: 'normal',
  layout: 'right',
  subHeadingProp: 'Authors',
  headingProp: '_name',
  otherProps: [],
  callsToAction: {
    readOnline: {
      label: 'Read online',
    },
    download: {
      label: 'Download',
    },
    signIn: {
      label: 'Sign in',
      description: 'Already have an account? Please sign in.',
    },
    hmm: {
      label: 'Contact us',
      description:
        'This content is for paid subscribers only. Get in touch to learn more.',
    },
  },
  customCallsToAction: {
    canAccess: [],
    noAccessSignedOut: [],
    noAccessSignedIn: [],
  },
}

export const migrate: A.Migrate<Config> = props => {
  const unknown = props.config as any

  if (unknown?.VERSION) {
    log.error(`config.VERSION=${unknown.VERSION}`)
    return unknown as Config
  }

  return deepMerge(defaultConfig)(unknown)
}
