import './globalStyles'

import {Block} from 'quickstart/types'
import {IS_AUTOTEST, IS_DEV, IS_TEST} from 'tizra'
import {AbstractBlock} from './blocks/AbstractBlock'
import {AttachmentsBlock} from './blocks/AttachmentsBlock'
import {BookshelfBlock} from './blocks/BookshelfBlock'
import {BrowseBlock} from './blocks/BrowseBlock'
import {CartBlock} from './blocks/CartBlock'
import {DetailsBlock} from './blocks/DetailsBlock'
import {FooterBlock} from './blocks/FooterBlock'
import {GlobalBlock} from './blocks/GlobalBlock'
import {HeaderBlock} from './blocks/HeaderBlock'
import {HeadlineBlock} from './blocks/HeadlineBlock'
import {ImageBlock} from './blocks/ImageBlock'
import {MarkdownBlock} from './blocks/MarkdownBlock'
import {NavBlock} from './blocks/NavBlock'
import {PostBlock} from './blocks/PostBlock'
import {RedemptionBlock} from './blocks/RedemptionBlock'
import {RegisterUserBlock} from './blocks/RegisterUserBlock'
import {ResetPasswordBlock} from './blocks/ResetPasswordBlock'
import {SearchBlock} from './blocks/SearchBlock'
import {SearchWithinBlock} from './blocks/SearchWithinBlock'
import {SignInBlock} from './blocks/SignInBlock'
import {StaticPageBlock} from './blocks/StaticPageBlock'
import {SubnavBlock} from './blocks/SubnavBlock'
import {ThemeBlock} from './blocks/ThemeBlock'
import {TocBlock} from './blocks/TocBlock'

const blocks = {
  [AbstractBlock.info.name]: AbstractBlock,
  [AttachmentsBlock.info.name]: AttachmentsBlock,
  [BookshelfBlock.info.name]: BookshelfBlock,
  [BrowseBlock.info.name]: BrowseBlock,
  [CartBlock.info.name]: CartBlock,
  [DetailsBlock.info.name]: DetailsBlock,
  [FooterBlock.info.name]: FooterBlock,
  [GlobalBlock.info.name]: GlobalBlock,
  [HeaderBlock.info.name]: HeaderBlock,
  [HeadlineBlock.info.name]: HeadlineBlock,
  [ImageBlock.info.name]: ImageBlock,
  [MarkdownBlock.info.name]: MarkdownBlock,
  [NavBlock.info.name]: NavBlock,
  [PostBlock.info.name]: PostBlock,
  [RedemptionBlock.info.name]: RedemptionBlock,
  [RegisterUserBlock.info.name]: RegisterUserBlock,
  [ResetPasswordBlock.info.name]: ResetPasswordBlock,
  [SearchBlock.info.name]: SearchBlock,
  [SearchWithinBlock.info.name]: SearchWithinBlock,
  [SignInBlock.info.name]: SignInBlock,
  [StaticPageBlock.info.name]: StaticPageBlock,
  [SubnavBlock.info.name]: SubnavBlock,
  [ThemeBlock.info.name]: ThemeBlock,
  [TocBlock.info.name]: TocBlock,
} as const

export type BlockName = keyof typeof blocks

const paletteStyle = (borderColor: string) => ({
  border: `1px solid ${borderColor}`,
  background: '#e6e7e7',
})

const palettes = {
  // original color #c7b4d8
  general: {
    title: 'General Blocks',
    style: paletteStyle('#9f90ad'),
    blocks: [MarkdownBlock, ImageBlock],
  },

  content: {
    title: 'Content Blocks',
    style: paletteStyle('#1f77b4'),
    blocks: [
      HeaderBlock,
      AbstractBlock,
      DetailsBlock,
      AttachmentsBlock,
      TocBlock,
      PostBlock,
    ],
  },

  index: {
    title: 'Index Blocks',
    style: paletteStyle('#b1e18b'),
    blocks: [BrowseBlock, HeadlineBlock],
  },

  site: {
    title: 'Site Blocks',
    style: paletteStyle('#b15928'),
    blocks: [
      NavBlock,
      SubnavBlock,
      FooterBlock,
      BookshelfBlock,
      SearchBlock,
      SignInBlock,
      RegisterUserBlock,
      ResetPasswordBlock,
      RedemptionBlock,
      StaticPageBlock,
    ],
  },

  forms: {
    title: 'Form Blocks',
    style: paletteStyle('#febf6e'),
    blocks: [SearchWithinBlock],
  },

  commerce: {
    title: 'Commerce Blocks',
    style: paletteStyle('#e6191e'),
    blocks: [CartBlock],
  },

  // Unreleased blocks only appear in palettes on autotest or in Storybook on
  // development. They don't appear in palettes on production, but are still
  // available via getBlock so that any layouts with previously-released blocks
  // continue to work.
  unreleased: {
    title: 'UNRELEASED',
    style: paletteStyle('#e6191e'),
    blocks: IS_AUTOTEST || (IS_DEV && !IS_TEST) ? [] : [],
  },
} as const

const infosWithStyle = Object.fromEntries(
  Object.entries(blocks).map(([name, block]) => {
    const palette = Object.values(palettes).find(({blocks}) =>
      (blocks as Block<any, any, any>[]).includes(block),
    )
    const style = palette?.style || {}
    return [name, {...block.info, style}]
  }),
) as {
  [N in BlockName]: (typeof blocks)[N]['info'] & {style: object}
}

export const getBlock = <N extends BlockName>(name: N) => infosWithStyle[name]

export const getPalettes = () =>
  Object.entries(palettes)
    .map(([name, p]) => ({
      name,
      ...p,
      blocks: p.blocks.map(b => getBlock(b.info.name)),
    }))
    .filter(({blocks}) => blocks.length)
