import styled, {
  SystemProps,
  css,
  keyframes,
  system,
  th,
} from 'quickstart/styled-components/system'
import {IS_CHROMATIC} from 'tizra'
import {Button} from '../Button'

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  `

export const IconButton = styled(Button)<SystemProps>`
  pointer-events: auto;
  ${!IS_CHROMATIC &&
  css`
    animation: ${th('transitions.medium')} ${fade};
  `}
  ${system};
`
