import {Store} from '@reduxjs/toolkit'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {Divider, Scene} from 'quickstart/components'
import {
  BlockContextProvider,
  HydrationProvider,
  fromServer,
  useBlockContext,
  useIsomorphicLayoutEffect,
} from 'quickstart/hooks'
import {actions, selectors} from 'quickstart/reducer'
import {createStore} from 'quickstart/store'
import * as SC from 'quickstart/styled-components/system'
import type {
  Admin,
  Block,
  BlockContext,
  BlockContextFromServer,
  BlockInfo,
  BlockProps,
  BlockRender,
  BlockWithoutInfo,
  DefaultGlobalMigrateProps,
  DefaultMigrateProps,
  FCx,
  GlobalMigrate,
  GlobalMigrateWrapper,
  Migrate,
  MigrateWrapper,
  Storybook,
} from 'quickstart/types'
import {tid} from 'quickstart/utils'
import * as React from 'react'
import {createRoot, hydrateRoot} from 'react-dom/client'
import ReactDOMServer from 'react-dom/server'
import {Provider as ReduxProvider, useDispatch, useSelector} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {StaticRouter} from 'react-router-dom/server'
import {
  IS_BROWSER,
  IS_STORYBOOK,
  IS_TEST,
  clone,
  debugging,
  deepMerge,
  getQueryClient,
  logger,
  setDebug,
  stableJson,
  tryJson,
} from 'tizra'
import {SetRequired} from 'type-fest'
import {ThemeProvider} from './ThemeBlock/branding'
import {adminRenderer} from './admin/renderer'

const log = logger('blocks/block')

export const useSuccessUrl = (): string => {
  const {successUrl} = useBlockContext()
  if (typeof successUrl !== 'string') {
    return ''
  }
  if (successUrl && !successUrl.startsWith('/')) {
    log.error(`Ignoring invalid successUrl: ${successUrl}`)
    return ''
  }
  return successUrl
}

export const useGlobalConfig = <B extends Block<any, any, any>>(
  block: B,
): B extends Block<any, infer G, any> ? G : never => {
  const context = useBlockContext()
  return React.useMemo(
    () => block.info.globalMigrate({context}),
    [block, context],
  )
}

/**
 * Component wrapper that is the inner portion of BlockWrapper. This is factored
 * out of BlockWrapper so it can be used by our implementation of custom
 * elements in markdown.
 */
export interface ComponentWrapperProps {
  context: BlockContext
  hydrate?: boolean
  queryClient: QueryClient
  children?: React.ReactNode
}
export const ComponentWrapper = ({
  context,
  hydrate = false,
  queryClient,
  children,
}: ComponentWrapperProps) => (
  <QueryClientProvider client={queryClient}>
    <HydrationProvider hydrate={hydrate}>
      <ThemeProvider context={context}>
        <BlockContextProvider context={context}>
          {children}
        </BlockContextProvider>
      </ThemeProvider>
    </HydrationProvider>
  </QueryClientProvider>
)

/**
 * Block wrapper for both server-side and client-side rendering, however this is
 * NOT used for Storybook. For the Storybook wrapper, see blockDecorator.
 */
interface BlockWrapperProps
  extends SetRequired<ComponentWrapperProps, 'hydrate'> {
  Router: React.FC<{children: React.ReactNode}>
  store: Store
  routerProps?: any // for tests
}
const BlockWrapper = ({
  Router,
  routerProps,
  store,
  ...props
}: BlockWrapperProps) => (
  <ReduxProvider store={store}>
    <Router {...routerProps}>
      <ComponentWrapper {...props} />
    </Router>
  </ReduxProvider>
)

const _BlockWrapper = BlockWrapper

/**
 * Client-side block renderer. This is provided in the block's info structure as
 * `render`, so that renderBlocks in src/entry-client.ts can loop through the
 * blocks and render each one into its root.
 */
export const renderer = <C, G, N extends string>({
  Block,
  displayName,
}: BlockInfo<C, G, N>) => {
  const renderBlock: BlockRender = ({
    blockId,
    config,
    context,
    hydrate,
    queryClient,
    sortKey,
    store,
    // BlockRenderProps
    root: container,
    // BlockRenderPropsTest
    render,
    Router = BrowserRouter,
    routerProps,
  }) => {
    const blog = log.logger(displayName)

    // Make sure we have a block id, since we use this for unique ids in
    // multi-root React.
    blockId = blog.assert(blockId, 'missing blockId') || 'ZZZ'

    const wrappedBlock = (
      <BlockWrapper
        context={context}
        hydrate={hydrate}
        queryClient={queryClient}
        Router={Router}
        routerProps={routerProps}
        store={store}
      >
        <Block blockId={blockId} config={config} sortKey={sortKey} />
      </BlockWrapper>
    )

    // Under test, pass back whatever the render function returns.
    if (render) {
      return render(wrappedBlock)
    }

    // In normal operation, render and return the cleanup function.
    const identifierPrefix = makeIdentifierPrefix(blockId)
    let root: ReturnType<typeof createRoot>
    if (hydrate) {
      root = hydrateRoot(container!, wrappedBlock, {identifierPrefix})
    } else {
      root = createRoot(container!, {identifierPrefix})
      root.render(wrappedBlock)
    }
    return () => {
      blog.log('unmounting')
      root.unmount()
    }
  }
  return renderBlock
}

function makeIdentifierPrefix(blockId: string) {
  return `evergreen:${blockId}:`
}

/**
 * Server-side block renderer. This is provided in the block's info structure as
 * `ssr`, so that it can be called from QuickstartSSR.java to render the block
 * to HTML.
 */
interface ServerSideRenderProps {
  blockConfigJson: string
  blockContextJson: string
  blockId: string
}
export const serverSideRenderer =
  <C, G, N extends string>({
    Block,
    displayName,
    // Injectable so that tests can provide alternative implementations.
    BlockWrapper = _BlockWrapper,
  }: BlockInfo<C, G, N> & {
    BlockWrapper?: typeof _BlockWrapper
  }) =>
  ({
    blockConfigJson,
    blockContextJson,
    blockId,
  }: ServerSideRenderProps): {
    innerHtml: string
    styleTags: string
    usedContextProps: string[]
  } => {
    const blog = log.logger(displayName)
    const config = tryJson(blockConfigJson, e => blog.error(e))

    // Prepare to track references to individual props within blockContextJson,
    // so we can pass the list back to cubchicken for computing a cache key.
    const serverContext =
      tryJson<BlockContextFromServer>(blockContextJson, e => blog.error(e)) ||
      ({} as BlockContextFromServer)
    const usedContextProps = new Set<string>()
    const context = fromServer(serverContext, {usedProps: usedContextProps})

    // Debugging an individual site can be controlled by setting the design
    // property QuickstartDebug. Despite the name, this is no longer a set of
    // flags, just a big hammer boolean. Be careful... Setting this on the
    // server with SSR enabled generates a lot of debug in catalina.out
    setDebug(!!context.debugFlags)

    try {
      // BlockWrapper requires a redux store. The blocks never register with the
      // store in SSR, so just make a new one here to avoid globals.
      const store = createStore()

      // New react-query client for each block, since we are no longer doing
      // virtual API calls from SSR anyway, and this ensures that there are no
      // inter-block dependencies.
      const queryClient = getQueryClient({context})

      // Make sure we have a block id, since we use this for unique ids in
      // multi-root React.
      blockId = blog.assert(blockId, 'missing blockId in ssr') || 'ZZZ'
      const identifierPrefix = makeIdentifierPrefix(blockId)

      blog.debug?.('blockId:', blockId)
      blog.debug?.('blockConfigJson:', blockConfigJson)
      blog.debug?.('config:', config)
      blog.debug?.('blockContextJson:', blockContextJson)
      blog.debug?.('context:', serverContext)

      const sheet = new SC.ServerStyleSheet()

      try {
        const innerHtml = ReactDOMServer.renderToString(
          sheet.collectStyles(
            <BlockWrapper
              hydrate={true}
              Router={props => (
                <StaticRouter
                  location={'https://example.com/static-router-in-ssr/'}
                  {...props}
                />
              )}
              context={context}
              queryClient={queryClient}
              store={store}
            >
              <Block
                blockId={blockId}
                config={config}
                // 0 while on server, becomes n>=1 in browser
                sortKey={0}
              />
            </BlockWrapper>,
            // renderToString doesn't take options according to the typing, but it
            // actually works.
            // @ts-expect-error
            {identifierPrefix},
          ),
        )
        const styleTags = sheet.getStyleTags()
        const usedContextPropsArr = [...usedContextProps].sort()

        blog.debug?.('innerHtml:', innerHtml)
        blog.debug?.('styleTags:', styleTags)
        blog.debug?.('usedContextProps:', usedContextPropsArr)

        return {innerHtml, styleTags, usedContextProps: usedContextPropsArr}
      } finally {
        sheet.seal()
      }
    } finally {
      // Disable debug before this warm interpreter is reused.
      setDebug(false)
    }
  }

type InnerBlock<C> = FCx<{config: C}>

interface InfoProps<C, G, N extends string> {
  displayName: string
  name: N
  title: string
  Admin: Admin<C>
  GlobalAdmin?: Admin<G>
  defaultConfig?: C
  defaultGlobalConfig?: G
  dividerAbove?: boolean
  dividerBelow?: boolean
  dividers?: boolean
  globalMigrate?: GlobalMigrate<G, any>
  migrate?: Migrate<C, any>
  ssrEnabled?: boolean
  storybook?: any
  wrapperDiv?: boolean
}

const BlockDevtools = React.lazy(() =>
  import('./devtools.tsx').then(d => ({
    default: d.BlockDevtools,
  })),
)

export const blockWithInfo = <
  C extends object | undefined,
  G extends object | undefined,
  N extends string,
>(
  sparse: InfoProps<C, G, N>,
  BlockComponent: InnerBlock<C>,
): Block<C, G, N> => {
  const {
    Admin,
    GlobalAdmin,
    defaultConfig,
    defaultGlobalConfig,
    displayName,
    dividers = true,
    dividerAbove = dividers,
    dividerBelow = dividers,
    name,
    ssrEnabled = true,
    title,
    wrapperDiv = true,
  } = sparse

  const blog = log.logger(displayName)

  // Default migrate functions, if not provided by info.
  const defaultGlobalMigrate = ({globalConfig}: DefaultGlobalMigrateProps): G =>
    (defaultGlobalConfig &&
      deepMerge(defaultGlobalConfig)(globalConfig as any)) as G
  const defaultMigrate = ({config}: DefaultMigrateProps): C =>
    (defaultConfig && deepMerge(defaultConfig)(config as any)) as C
  const {
    migrate: blockMigrate = defaultMigrate,
    globalMigrate: blockGlobalMigrate = defaultGlobalMigrate,
  } = sparse

  // Upgraded globalMigrate to clone and pass selected globalConfig.
  const globalMigrate: GlobalMigrateWrapper<G> = props => {
    // AdminWrapper passes globalConfig since it reads/writes textarea,
    // contrast normal operation which reads globalConfigs from context.
    const globalConfig: G =
      'globalConfig' in props ? props.globalConfig
      : props.context?.globalConfig ?
        props.context.globalConfig[name] || defaultGlobalConfig
      : IS_STORYBOOK || IS_TEST ?
        defaultGlobalConfig // XXX: really?
      : (log.error(`missing globalConfig[${name}] in context, where are we?`),
        defaultGlobalConfig)
    const hacks =
      'adminContext' in props ?
        props.adminContext?.hacks ||
        (log.error('missing hacks in adminContext, where are we?'), {})
      : props.context?.hacks ||
        (log.error('missing hacks in context, where are we?'), {})
    let migratedGlobalConfig = blockGlobalMigrate({
      ...props,
      globalConfig: clone(globalConfig),
      hasHack: s => !!hacks[s],
    })
    if (migratedGlobalConfig) {
      if (defaultGlobalConfig && 'FREEMARKER' in defaultGlobalConfig) {
        migratedGlobalConfig = {
          ...migratedGlobalConfig,
          FREEMARKER: defaultGlobalConfig.FREEMARKER,
        }
      } else if ('FREEMARKER' in migratedGlobalConfig) {
        delete migratedGlobalConfig.FREEMARKER
      }
    }
    // This gets called from blockStoryMeta prior to capture of console logging.
    IS_TEST || blog.debug?.({...props, name, migratedGlobalConfig})
    return migratedGlobalConfig
  }

  // Upgraded migrate to pass hacks. If the block needs to access
  // unmigrated global config, it should look in context.
  const migrate: MigrateWrapper<C> = props => {
    const hacks =
      'adminContext' in props ?
        props.adminContext?.hacks ||
        (log.error('missing hacks in adminContext, where are we?'), {})
      : props.context?.hacks ||
        (log.error('missing hacks in context, where are we?'), {})
    let migratedConfig = blockMigrate({
      ...props,
      config: clone(props.config),
      hasHack: s => !!hacks[s],
    })
    if (migratedConfig) {
      if (defaultConfig && 'FREEMARKER' in defaultConfig) {
        migratedConfig = {
          ...migratedConfig,
          FREEMARKER: defaultConfig.FREEMARKER,
        }
      } else if ('FREEMARKER' in migratedConfig) {
        delete migratedConfig.FREEMARKER
      }
    }
    // This gets called from blockStoryMeta prior to capture of console logging.
    IS_TEST || blog.debug?.({...props, name, migratedConfig})
    return migratedConfig
  }

  const admin = adminRenderer({name, Admin, migrate})
  const globalAdmin =
    GlobalAdmin &&
    adminRenderer({
      name,
      Admin: GlobalAdmin,
      migrate: globalMigrate,
      global: true,
    })

  const BlockWithoutInfo: BlockWithoutInfo = ({
    blockId = (blog.warn('missing blockId'), 'mustBeStorybook'),
    config = (IS_TEST || blog.browser.warn('missing config'), undefined),
    sortKey = (blog.warn('missing sortKey'), undefined),
  }: BlockProps) => {
    const blog = log.logger(displayName)
    const context = useBlockContext()

    // Draw a divider above this block if:
    // - this block wants a divider
    // - there's a previous block
    // - the previous block wants a divider
    const prevInfos = useSelector(selectors.getPrevInfos) as {
      [k: string]: BlockInfo<unknown, unknown, any>
    }
    const prevInfo = prevInfos[blockId]
    const divider = !!(dividerAbove && prevInfo?.dividerBelow)

    blog.debug?.('DIVIDER', displayName, divider, {
      blockId,
      sortKey,
      prevInfo: prevInfo && {
        displayName: prevInfo.displayName,
        dividerBelow: prevInfo.dividerBelow,
      },
      dividerAbove,
    })

    // We can't dispatch actions during render, so defer registration with
    // the Redux store to an effect. This means that blocks are never
    // registered with the store in SSR, but that's fine--we don't currently
    // save and inject the preloaded state between SSR and CSR anyway.
    const dispatch = useDispatch()
    const enter = React.useCallback(() => {
      // GlobalBlock and ThemeBlock have sortkey=0, others have sortkey>=1.
      if (sortKey) {
        dispatch(actions.enter(blockId, {...Block.info, sortKey}))
      }
    }, [blockId, dispatch, sortKey])
    useIsomorphicLayoutEffect(enter, [enter])

    // If the block uses Scene to disappear entirely for lack of content,
    // then remove the block from prevBlocks so that it won't confuse blocks
    // above and below for their divider calculation. (A block that wants to
    // use Scene internally without affecting prevBlocks can use <Scene
    // root>)
    const exit = React.useCallback(() => actions.exit(blockId), [blockId])
    const handleChange = React.useCallback(
      (v: any) => (v ? enter() : exit()),
      [enter, exit],
    )

    const migratedConfig = migrate({config, context})
    const blockDevtoolsEnabled = IS_BROWSER && debugging()

    let result = (
      <Scene onChange={handleChange}>
        {({show}: {show: boolean}) => (
          <>
            {show && divider && <Divider />}
            <BlockComponent config={migrate({config, context})} />
          </>
        )}
      </Scene>
    )
    if (wrapperDiv) {
      result = (
        // This is where data-testid="block" is applied for tests.
        <div
          className={`quickstart-${name}-block`}
          style={{
            // Ensure space for BlockDevtools button so they don't overlap
            // vertically if block renders empty.
            ...(blockDevtoolsEnabled && {minHeight: '24px'}),
          }}
          {...tid('block')}
        >
          {result}
        </div>
      )
    }
    return (
      <>
        {blockDevtoolsEnabled && (
          <React.Suspense fallback={null}>
            <BlockDevtools
              blockId={blockId}
              displayName={displayName}
              name={name}
              rawConfig={config}
              migratedConfig={migratedConfig}
              globalConfig={
                name in context.globalConfig ?
                  globalMigrate({context})
                : undefined
              }
              blockContext={context}
              dividers={{
                above: dividerAbove,
                below: dividerBelow,
                prev: prevInfo && {
                  displayName: prevInfo.displayName,
                  below: prevInfo.dividerBelow,
                },
                sortKey,
              }}
            />
          </React.Suspense>
        )}
        {result}
      </>
    )
  }

  const info: BlockInfo<C, G, N> = {
    admin,
    displayName,
    dividerAbove,
    dividerBelow,
    globalAdmin,
    globalMigrate,
    migrate,
    name,
    title,

    // Production code shouldn't need these, but they're useful for stories and
    // tests.
    defaultConfig: defaultConfig as C,
    defaultGlobalConfig: defaultGlobalConfig as G,

    // defaultConfigJson is used by layout_palettes_quickstartblocks.ftl to save
    // the default config for a block into the DB on first insert from palette
    // into a slot. That way an old block doesn't sprout new behavior
    // unexpectedly--it should always go through the block's migrate() function
    // to make a proper judgment.
    defaultConfigJson:
      defaultConfig ? stableJson(defaultConfig, {indent: 2}) : '',

    // Fib about these properties for a moment, since they're circular and we
    // don't want to loosen the definition.
    Block: undefined as unknown as Block<C, G, N>,
    render: undefined as any,
    ssr: undefined as any,
    storybook: undefined as unknown as Storybook<C, G>,
    wrapperDiv,
  }

  const Block = Object.assign(BlockWithoutInfo, {displayName, info})

  // Fix the fibs
  info.Block = Block
  info.render = renderer(info)
  if (ssrEnabled) {
    info.ssr = serverSideRenderer(info)
  }
  info.storybook = {
    ...info,
    Admin,
    GlobalAdmin,
    ...sparse.storybook,
  }

  return Block
}

export * from '../components'
export * from '../hooks'
export * from '../styled-components/system'
export * from '../utils'
export * from './components/Background'
export * from './components/Browse'
