import {Icon} from 'quickstart/components/content/Icon'
import {Stack} from 'quickstart/components/layout/Stack'
import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {wrapChildren} from 'quickstart/utils'
import {
  Children,
  cloneElement,
  ComponentProps,
  isValidElement,
  ReactNode,
} from 'react'
import {partitionProps} from 'tizra'
import * as S from './styles'

interface ButtonProps
  extends UnprefixTransientProps<
    Omit<ComponentProps<typeof S.Button>, 'children'>
  > {
  children?: ReactNode
  icon?: ComponentProps<typeof Icon>['icon']
  rightIcon?: ComponentProps<typeof Icon>['icon']
}

const _Button = ({
  children,
  disabled,
  fluid = false,
  hoverable = true,
  icon,
  rightIcon,
  size = 'lg',
  variant = 'primary',
  ...rest
}: ButtonProps) => (
  <S.Button
    disabled={disabled}
    {...transient({
      fluid,
      hoverable,
      size,
      variant,
    })}
    {...rest}
  >
    {icon && <Icon icon={icon} />}
    {wrapChildren(children)}
    {rightIcon && <Icon icon={rightIcon} />}
  </S.Button>
)

export interface ButtonGroupProps extends ComponentProps<typeof Stack> {
  disabled?: boolean
  hoverable?: boolean
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
}

const Group = ({children, ...props}: ButtonGroupProps) => {
  const [groupProps, rest] = partitionProps(
    ['disabled', 'hoverable', 'size', 'variant'],
    props,
  )

  const childArray = Children.toArray(children)
  const primaryIndex = childArray.findIndex(x => isValidElement(x))

  return (
    <Stack row spacing="1rem" {...rest}>
      {childArray.map((child, i) =>
        isValidElement<object>(child) ?
          cloneElement(child, {
            variant: i === primaryIndex ? 'primary' : 'secondary',
            ...groupProps,
            ...child.props,
          })
        : child,
      )}
    </Stack>
  )
}

export const Button = Object.assign(_Button, {Group})
