import {useIsomorphicLayoutEffect} from './useIsomorphicLayoutEffect'
import {useRef, useCallback} from 'react'

// https://twitter.com/diegohaz/status/1324168516061245441
export const useEffectCallback = <T extends (...args: any[]) => any>(
  callback?: T,
) => {
  const ref = useRef<any>(() => {
    throw new Error('cannot call an event handler while rendering.')
  })
  useIsomorphicLayoutEffect(() => {
    ref.current = callback
  })
  return useCallback((...args: Parameters<T>) => ref.current?.(...args), [])
}
