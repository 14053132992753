import {
  Field,
  Hint,
  Label,
  Textarea,
  useHack,
} from 'quickstart/blocks/admin/base'
import {ReactNode, Suspense, lazy, useId} from 'react'

export interface MarkdownEditorProps {
  name: string
  label?: ReactNode
  hint?: ReactNode
  reset?: boolean
}

const Lexical = lazy(() =>
  import('./lexical.tsx').then(d => ({default: d.Lexical})),
)

export const MarkdownEditor = ({reset, ...props}: MarkdownEditorProps) => {
  const id = useId()

  if (!useHack('lexical')) {
    return <Textarea {...props} />
  }

  const {hint, label, ...rest} = props

  return (
    <div>
      {!!label && (
        <div>
          <Label htmlFor={id}>{label}</Label>
        </div>
      )}
      <div>
        <Field id={id} {...rest}>
          {({input}) => (
            <Suspense fallback={null}>
              <Lexical
                initialValue={input.value}
                onChange={input.onChange}
                reset={reset}
              />
            </Suspense>
          )}
        </Field>
      </div>
      {!!hint && <Hint>{hint}</Hint>}
    </div>
  )
}
