import {themeGetter} from 'quickstart/styled-components/system'
import {cssVars} from 'quickstart/styled-components/theme/base/fonts'
import {deepMerge} from 'tizra'

const TEXT_DECORATION = false

const getLinks = (theme: any) => {
  const th = themeGetter(theme)

  const reset: Record<string, any> = {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    opacity: 'inherit',
    lineHeight: 'inherit',
    '&:where(:active, :focus, :hover)': {
      opacity: 'inherit',
      outline: 'none !important', // firefox needs !important
    },
    '&:where(:disabled)': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  }

  const base = deepMerge(reset)({
    opacity: 1,
    '&:where(:disabled)': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  })

  const colored = {
    color: `var(${cssVars.colors.link})`,
  }

  const underlined = {
    ...th('underline'),
    textDecorationColor: `var(${cssVars.colors.link})`,
  }

  const bolded = {
    fontWeight: th('fontWeights.bold'),
  }

  const hover = {
    transition: `opacity ${th('transitions.medium')}`,
    '&:where(:hover)': {
      opacity: 0.6,
    },
  }

  const hoverLine =
    TEXT_DECORATION ?
      {
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        textDecorationThickness: th('borderWidths.md'),
        textUnderlineOffset: '.3em', // 6px for 20px text
        transition: `text-decoration-color ${th('transitions.medium')}`,
        '&:where(:hover)': {
          textDecorationColor: 'currentcolor',
        },
      }
    : {
        borderBottom: th('borders.std'),
        borderColor: 'transparent',
        transition: `border-color ${th('transitions.medium')}`,
        '&:where(:hover)': {
          borderColor: 'currentcolor',
        },
      }

  return {
    unstyled: reset,
    default: deepMerge(base)(colored, underlined, hover),
    ui: deepMerge(base)(colored, bolded, hover),
    breadcrumb: deepMerge(base)(colored, hover),
    nav: deepMerge(base)(bolded, hoverLine),
    stealth: deepMerge(base)(bolded, hover),
    sidebar: deepMerge(base)(hover),
    'sidebar-current': deepMerge(base)(colored, bolded),
  }
}

export default getLinks
