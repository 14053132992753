import {useMemo} from 'react'
import {
  GenericMetaObject,
  MetaObject,
  IS_SSR,
  TocEntry,
  isTocEntry,
  logger,
  meta,
  tizraCamelize,
} from 'tizra'
import {UseApiOptions, useApi} from './useApi'
import {useBlockContext} from './useBlockContext'
import * as R from 'rambdax'

const log = logger('useMetaObj')

// useMetaObj works for TocEntry if metaObj supplied, but generally it's called
// with a proper MetaObject or tizraId.
export type UseMetaObjProps<T extends MetaObject | TocEntry = MetaObject> = {
  metaObj?: T
  metaType?: string
  tizraId?: string
  attachParent?: boolean
  enabled?: boolean
  name?: never // protect against passing metaObj directly as props
  hacks?: never // protect against passing context directly as props
  options?: UseApiOptions<'query'>
}

/**
 * Fetch a single metaObj from the API, without attaching parent.
 */
const useMetaObjInternal = <T extends MetaObject | TocEntry>({
  enabled = true,
  metaObj,
  metaType,
  tizraId,
  options,
}: UseMetaObjProps<T>): T | undefined => {
  // We don't expect to receive a metaObj that doesn't match the other
  // parameters.
  if (metaObj && metaType && metaObj.metaType !== metaType) {
    log.error('metaObj/metaType mismatch', metaObj.metaType, metaType)
  }
  if (
    metaObj &&
    !isTocEntry(metaObj) &&
    tizraId &&
    metaObj.tizraId !== tizraId
  ) {
    log.error('metaObj/tizraId mismatch', metaObj.tizraId, tizraId)
  }

  // Only call API if not passed metaObj.
  enabled &&= !metaObj && !!tizraId && metaType !== 'SiteDisplay'

  // Check block context for the meta-object. This allows the Header Block to
  // render in SSR, and also allows the client to render more quickly without
  // waiting for an API response.
  const context = useBlockContext()
  const queryConfig = useMemo(() => {
    if (!enabled) return
    // This is complex because we're avoiding touching parts of context based on
    // the meta-type, since accesses to context are tracked.
    const raw =
      (
        (!metaType || metaType === context.metaType) &&
        tizraId === context.tizraId
      ) ?
        context.metaObject
      : (!metaType || metaType === 'PdfPage') && tizraId === context.pageId ?
        context.pageObject
      : null
    if (raw) {
      const initialData = [tizraCamelize(raw) as MetaObject]
      const initialDataUpdatedAt =
        (!IS_SSR &&
          context.renderedAt &&
          new Date(context.renderedAt).getTime()) ||
        undefined
      const queryConfig: UseApiOptions<'query'> = {
        initialData,
        initialDataUpdatedAt,
        // Override useApi's check for hydration.
        enableDuringHydration: true,
      }
      return queryConfig
    }
  }, [context, enabled, metaType, tizraId])

  // Rules of hooks: always call useApi, even if we don't need the data.
  // Falsy arg will avoid making an actual call.
  const {data} = useApi.query(enabled && tizraId && {tizraId}, {
    ...queryConfig,
    ...options,
  })
  if (data && data.length !== 1) {
    log.error(`received ${data.length} metaObjs for tizraId=${tizraId}`)
  }

  return metaObj || (data?.[0] as T)
}

/**
 * Fetch a metaObj from the API, optionally attaching its parent.
 */
export const useMetaObj = <T extends MetaObject | TocEntry = MetaObject>(
  _props: UseMetaObjProps<T> | boolean,
): T | undefined => {
  const context = useBlockContext()
  const props: UseMetaObjProps<T> =
    !_props ? {enabled: false}
    : _props === true ? R.pick(['metaType', 'tizraId'], context)
    : _props
  const metaObj = useMetaObjInternal(props)

  // Attach the parent if requested.
  const {attachParent = false, enabled = true, options} = props
  const parentId = meta.parentId(metaObj)
  const parent = useMetaObjInternal<GenericMetaObject>({
    metaObj: metaObj?.parent,
    tizraId: parentId,
    enabled: enabled && attachParent,
    options,
  })
  const metaObjWithParent = useMemo<T | undefined>(
    () =>
      metaObj?.parent ? metaObj
      : metaObj && parent ? {...metaObj, parent}
      : metaObj,
    [metaObj, parent],
  )

  return metaObjWithParent
}
