import {Shape} from 'quickstart/components/layout/Shape'
import {withProps, withTheme} from 'quickstart/theme'
import {styled, theme} from 'quickstart/theme/linaria'
import {Text} from '../Text'

export const Card = withTheme(styled.div`
  background-size: cover;
  background-position: center;
  background-color: ${theme.colors.backgroundColorWhite};
  box-shadow: ${theme.shadows.v2};

  ${theme.hovers.raise};

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &[data-stretch] {
    flex-grow: 1;
    justify-content: stretch;
  }

  &[data-clickable] {
    cursor: pointer;
  }
`)

export const Cover = styled(Shape)`
  // Must increase specificity when wrapping styled components.
  && {
    width: 100%;
  }
`

export const Body = withTheme(styled.div`
  word-break: break-word;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-top: ${theme.borderWidths.sm} solid ${theme.colors.borderColor};
  padding: ${theme.space.xl};
`)

export const Footer = withTheme(styled.div`
  word-break: break-word;
  padding: 0 ${theme.space.xl} ${theme.space.xl} ${theme.space.xl};
`)

export const Meta = withTheme(styled(
  withProps({variant: 'cardMeta', prose: true})(Text),
)`
  color: ${theme.colors.textColorMuted};
`)

export const Heading = styled(
  withProps({variant: 'cardHead', prose: true})(Text),
)`
  text-wrap: balance;
`

export const CallToAction = withTheme(styled(
  withProps({variant: 'cardCta'})(Text),
)`
  color: ${theme.colors.linkColor};
`)
