import {useNumberFormatter} from '@react-aria/i18n'
import * as S from './styles'

type CurrencyProps = Parameters<typeof useNumberFormatter>[0] & {
  amount: number | null | undefined
  currency: string
}

const commonMinimumFractionDigits: {[k: string]: number} = {
  AUD: 2,
  CAD: 2,
  EUR: 2,
  USD: 2,
}

export const Currency = ({
  amount,
  currency,
  minimumFractionDigits = commonMinimumFractionDigits[currency],
  ...props
}: CurrencyProps) => {
  const formatter = useNumberFormatter({
    style: 'currency',
    currency,
    minimumFractionDigits,
    ...props,
  })
  return typeof amount === 'number' ?
      <S.Numeric>{formatter.format(amount)}</S.Numeric>
    : null
}
