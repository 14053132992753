import {Digest} from 'quickstart/components/content/Digest'
import {UniversalLink} from 'quickstart/components/content/UniversalLink'
import * as R from 'rambdax'
import {ComponentProps} from 'react'
import {MetaObject, deepMerge, logger} from 'tizra'
import {
  SlotConfig,
  SlotName,
  SlotProps,
  defaultSlots,
  useMetadata,
} from '../MetaTile/common'

const log = logger('MetaDigest')

export const metaDigestSlots: {[k in SlotName]?: SlotProps} = {
  title: {force: true},
  snippet: {},
  link: {},
}

type MetaDigestProps = ComponentProps<typeof Digest> & {
  metaObj?: MetaObject
  slots?: {[k in SlotName]?: SlotConfig}
}

const orNull = (v: any) => v || null

const _MetaDigest = ({metaObj, slots: _slots, ...props}: MetaDigestProps) => {
  const slots = R.pick(
    Object.keys(metaDigestSlots),
    deepMerge(defaultSlots)(_slots),
  ) as Exclude<typeof _slots, undefined>

  const {href, title, snippet} = useMetadata({metaObj, slots})

  const asProps = orNull(href) && {as: UniversalLink, href}

  return (
    <Digest {...asProps} {...props}>
      {title && <Digest.Heading>{title}</Digest.Heading>}
      {snippet && <Digest.Snippet>{snippet}</Digest.Snippet>}
    </Digest>
  )
}

export const MetaDigest = Object.assign(_MetaDigest, {Group: Digest.Group})
