import {logger} from 'tizra'
import {SimpleFormStep} from './steps/SimpleFormStep'
import {UmphBillingStep} from './steps/UmphBillingStep'
import {StepComponent, StepComponentProps} from './types'

const log = logger('CartBlock/CheckoutStep')

export const decideStepComponent = (
  props: StepComponentProps,
): StepComponent => {
  const {checkoutMethod, step} = props
  log.log({checkoutMethod, step})
  if (checkoutMethod?.name === 'umph' && step?.step === 'billing-address') {
    return UmphBillingStep
  }
  return SimpleFormStep
}

export const CheckoutStep: StepComponent = props => {
  const Component = decideStepComponent(props)
  return <Component {...props} />
}
