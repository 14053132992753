import {FORM_ERROR} from 'final-form'
import {Text} from 'quickstart/components/content/Text'
import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {Input} from 'quickstart/components/controls/Input'
import {FormShell, FormShellProps} from 'quickstart/components/layout/FormShell'
import {useUserData} from 'quickstart/hooks'
import {FCx} from 'quickstart/types'
import {focusError, formErrors} from 'quickstart/utils/final-form-errors'
import {ReactNode} from 'react'
import * as Final from 'react-final-form'
import {api, logger} from 'tizra'

const log = logger('Auth')

interface AuthChangePasswordProps {
  autoFocus?: boolean
  message?: ReactNode
  modal?: boolean
  successUrl?: string
  variant?: FormShellProps['variant'] | 'checkout' // satisfy TS
}

export const AuthChangePassword: FCx<AuthChangePasswordProps> = ({
  autoFocus,
  modal = false,
  successUrl,
  variant = FormShell.defaultVariant(modal),
}) => {
  log.assert(
    variant !== 'checkout',
    'How are we in AuthChangePassword with variant=checkout ?!?',
  )
  const formShellVariant = variant === 'checkout' ? 'fluid' : variant

  const {name: username} = useUserData() || {}

  const submit = async ({password, password2}: any) => {
    // In theory, we should never hit this condition because RegisterUserBlock
    // sets the Auth view depending on whether or not the user is signed in.
    if (!username) {
      return {[FORM_ERROR]: 'Must be signed in to change password.'}
    }

    // This probably wouldn't work anyway, but let's not take chances.
    if (username === 'su@tizra.com') {
      return {[FORM_ERROR]: "Can't change super-user password here."}
    }

    // Server API takes only one password, so check for match before calling.
    if (password && password2 !== password) {
      return {password2: 'Passwords do not match.'}
    }

    let {status, errors, message, reason} = await api.changePassword({
      username,
      password,
    })
    if (status === 200) {
      return // React Final Form will set submitSucceeded
    }

    // Promote username error since there's no field in the form.
    if (errors?.username) {
      if (!message) {
        message = `Unknown error (username: ${errors.username})`
      }
      delete errors.username
    }

    return formErrors({errors, message, reason})
  }

  return (
    <Final.Form
      onSubmit={submit}
      decorators={[focusError]}
      render={({handleSubmit, submitSucceeded, submitError}) =>
        submitSucceeded ?
          <FormShell
            header="Change Successful"
            modal={modal}
            variant={formShellVariant}
            primaryButton={{
              children: 'Continue',
              onClick: () => {
                // Don't default successUrl here, because we might be in the
                // modal, or otherwise on the intended destination already. If
                // the caller (RegisterUserBlock) wants to redirect elsewhere,
                // they can pass it in.
                if (successUrl) {
                  window.location.href = successUrl
                } else {
                  window.location.reload()
                }
              },
            }}
          >
            <Text>Your password has been changed.</Text>
          </FormShell>
        : <FormShell
            header="Change Password"
            modal={modal}
            variant={formShellVariant}
            onSubmit={handleSubmit}
            errorMessage={submitError}
            preface="Enter a new password for your account."
            primaryButton={{
              type: 'submit',
              children: 'Change Password',
            }}
          >
            <ConnectedField
              autoComplete="new-password"
              autoFocus={autoFocus}
              component={Input}
              label="Password"
              name="password"
              type="password"
            />
            <ConnectedField
              autoComplete="new-password"
              component={Input}
              label="Confirm Password"
              name="password2"
              type="password"
            />
          </FormShell>
      }
    />
  )
}
