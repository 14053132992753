import {getContrast} from 'polished'
import * as R from 'rambdax'
import {logger} from 'tizra'

const log = logger('utils/color')

/**
 * Given a list of foregrounds, return the one with the best contrast against
 * the background.
 */
export const bestContrast = (
  foregrounds: string[],
  background: string,
): string => {
  const sortFn = (a: string) => -getContrast(a, background)
  return R.sortBy(sortFn, foregrounds)[0]
}
