import {Card} from 'quickstart/components/content/Card'
import {UniversalLink} from 'quickstart/components/content/UniversalLink'
import * as R from 'rambdax'
import {ComponentProps} from 'react'
import {MetaObject, deepMerge, logger} from 'tizra'
import {
  SlotConfig,
  SlotName,
  SlotProps,
  defaultSlots,
  useMetadata,
} from '../MetaTile/common'

const log = logger('MetaCard')

export const metaCardSlots: {[k in SlotName]?: SlotProps} = {
  cover: {force: true},
  label: {},
  title: {force: true},
  snippet: {},
  cta: {},
  link: {},
}

type MetaCardProps = ComponentProps<typeof Card> & {
  metaObj?: MetaObject
  slots?: {[k in SlotName]?: SlotConfig}
}

const orNull = (v: any) => v || null

export const MetaCard = ({metaObj, slots: _slots, ...props}: MetaCardProps) => {
  const slots = R.pick(
    Object.keys(metaCardSlots),
    deepMerge(defaultSlots)(_slots),
  ) as Exclude<typeof _slots, undefined>

  const {href, cover, label, title, snippet, cta} = useMetadata({
    metaObj,
    slots,
    thumbProps: {cover: true, ratio: 4 / 3},
  })

  const asProps = orNull(href) && {as: UniversalLink, href}

  return (
    <Card {...asProps} {...props}>
      <Card.Cover>{cover}</Card.Cover>
      <Card.Body>
        <Card.Meta>{label}</Card.Meta>
        <Card.Heading>{title}</Card.Heading>
        {snippet && <Card.Description>{snippet}</Card.Description>}
        {props.stretch && <div style={{flexGrow: 1}} />}
      </Card.Body>
      <Card.Footer>
        <Card.CallToAction>{cta}</Card.CallToAction>
      </Card.Footer>
    </Card>
  )
}
