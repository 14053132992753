import {themeGetter} from 'quickstart/styled-components/system'

// To allow for line-height of text in label
// const LINE_HEIGHT_ADJUSTMENTS = '0.15rem'

const getFields = (theme: any) => {
  const th = themeGetter(theme)

  const defaults = {
    ...th('ui.form'),
    color: th('colors.textColor'),
    lineHeight: theme.toRem(16), // XXX presumably to match button, need to revisit
    backgroundColor: th('colors.backgroundColorWhite'),
    borderColor: th('colors.borderColor'),
    borderWidth: th('borderWidths.md'),
    borderStyle: 'solid',
    borderRadius: th('radii.none'),
    outline: 'none',
  }

  const checked = {
    borderColor: th('colors.borderSelectedColor'),
    color: th('colors.buttonSolidFocus'),
    '&::after': {
      opacity: 1,
    },
  }

  return {
    default: {
      ...defaults,
    },
    disabled: {
      backgroundColor: th('colors.backgroundColorLight'),
      color: th('colors.textColorMuted'),
      cursor: 'not-allowed',
    },
    hover: {
      // TODO - this hover doesn't work
      borderColor: th('colors.borderHoverColor'),
    },
    focused: {
      borderColor: th('colors.borderFocusColor'),
    },
    sizes: {
      sm: {
        height: '2rem',
        padding: `0 ${th('space.formIndent')}`,
      },
      md: {
        height: '3rem',
        padding: `0 ${th('space.formIndent')}`,
      },
      lg: {
        height: '3.5rem',
        padding: `0 ${th('space.formIndent')}`,
      },
    },
    placeholder: {
      color: th('colors.textColorMuted'),
    },
    // fields.hint is also used by the separate Hint component in
    // Search/styles.js
    hint: {
      base: {
        ...th('ui.formMeta'),
        marginTop: th('prose.formMeta.marginBottom'),
      },
      default: {
        fontStyle: 'italic',
      },
      // There used to be an error variant here, but since we switched to using
      // ErrorBox, we could eventually collapse the above variants into a single
      // theme object.
    },
    label: {
      ...th('ui.form'),
      color: th('colors.textColor'),
      lineHeight: theme.toRem(16),
    },
    checkablelabel: {
      default: {
        fontWeight: th('fontWeights.regular'),
      },
      checked: {
        fontWeight: th('fontWeights.bold'),
      },
    },
    checkbox: {
      default: {
        width: theme.toRem(16),
        height: theme.toRem(16),
        flexShrink: 0,
      },
      checked: {
        ...checked,
      },
    },
    radio: {
      default: {
        width: theme.toRem(18),
        height: theme.toRem(18),
      },
      checked: {
        ...checked,
      },
    },
    radiotabs: {
      default: {
        ...defaults,
      },
      checked: {
        /* TODO
        color: colors.dark[200],
        'backgroundColor': colors.light[700],
        'borderColor': colors.primary[500],
        */
        '&:hover': {
          boxShadow: 'none',
        },
      },
      hover: {
        /* TODO
        'backgroundColor': colors.light[700],
        */
        boxShadow: th('shadows.sm'),
      },
    },
    select: {
      default: {
        // TODO - calculate this value
        // 1 = border
        // 27 + 27 = padding
        // 15 = line-height
        // * 6 max items
        maxHeight: '420px',
      },
      disabled: {
        color: th('colors.textColorMuted'),
        cursor: 'not-allowed',
      },
      existing: {
        color: th('colors.textColor'),
        cursor: 'not-allowed',
      },
      highlighted: {
        backgroundColor: th('colors.backgroundColorLight'),
        cursor: 'default',
      },
      selectedAndHighlighted: {
        backgroundImage:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08) 100%)',
      },
      selected: {
        fontWeight: th('fontWeights.bold'),
      },
    },
    fieldset: {
      borderWidth: 0,
    },
  }
}

export default getFields
