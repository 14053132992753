import {classes, dataProps} from 'quickstart/theme'
import {cx, theme} from 'quickstart/theme/linaria'
import {truthy} from 'quickstart/types'
import {Children, ComponentProps} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('Stack')

export const Item = (props: ComponentProps<typeof S.Item>) => (
  <S.Item {...props} className={cx(classes.stackItem, props.className)} />
)

type BK = keyof typeof theme.borders

interface DividerProps extends ComponentProps<'hr'> {
  border: BK | string | boolean
  row: boolean
}

const Divider = ({border: bk, row, ...props}: DividerProps) => {
  const XD = row ? S.RowDivider : S.ColDivider
  const border =
    !bk ? ''
    : bk === true ? theme.borders.fine
    : bk in theme.borders ? theme.borders[bk as BK]
    : bk
  return <XD aria-hidden={true} $border={border} {...props} />
}

type SK = keyof typeof theme.space

type StackProps = ComponentProps<'div'> & {
  row?: boolean
  spacing?: SK | string
  divided?: DividerProps['border']
  capped?: DividerProps['border']
  startCapped?: DividerProps['border']
  endCapped?: DividerProps['border']
  wrapChildren?: boolean
  as?: ComponentProps<typeof S.Stack>['as']
}

export const Stack = ({
  children,
  capped = false,
  startCapped = capped,
  endCapped = capped,
  divided = false,
  row = false,
  spacing: sk = 'md',
  wrapChildren = true,
  ...props
}: StackProps) => {
  const wrapper = props.as === 'ol' || props.as === 'ul' ? 'li' : 'div'

  const spacing = sk in theme.space ? theme.space[sk as SK] : sk

  const childArray = Children.toArray(children)
    .filter(truthy)
    .flatMap((child, i, arr) => [
      ((i === 0 && startCapped) || (i > 0 && divided)) && (
        <Divider
          key={`divider.${i}`}
          border={i === 0 ? startCapped : divided}
          row={row}
        />
      ),
      wrapChildren ?
        <Item as={wrapper} key={`item.${i}`}>
          {child}
        </Item>
      : child,
      i === arr.length - 1 && endCapped && (
        <Divider key={`divider.${i + 1}`} border={endCapped} row={row} />
      ),
    ])
    .filter(truthy)

  return (
    <S.Stack
      $spacing={spacing}
      {...dataProps({
        row,
        divided: !!divided,
        startCapped: !!startCapped,
        endCapped: !!endCapped,
      })}
      {...props}
      className={cx(classes.stack, props.className)}
    >
      {childArray}
    </S.Stack>
  )
}
