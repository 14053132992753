import {WindowTizraApi} from 'quickstart/hooks'
import * as React from 'react'
import {logger} from 'tizra'
import {useAuthConfig} from '../SignInBlock'
import * as B from '../block'
import {Admin} from './admin'
import {ReactQueryDevtools} from './safe-react-query-devtools'
import {useQueryClient} from '@tanstack/react-query'

const log = logger('GlobalBlock')

export const GlobalBlock = B.blockWithInfo(
  {
    name: 'global',
    displayName: 'GlobalBlock',
    title: 'Global', // just for storybook
    wrapperDiv: false,
    Admin,
  },
  () => {
    return (
      <>
        <ReactQueryDevtools />
        {useLoginLogout(useAuthConfig())}
        {useSessionErrors()}
      </>
    )
  },
)

const authConfigKeys = {
  register: 'registration',
  resetPassword: 'passwordReset',
  signin: 'signin',
} as const

function useLoginLogout(authConfig: ReturnType<typeof useAuthConfig>) {
  const props = B.SignInModal.useProps()
  const {show} = props.store
  const queryClient = useQueryClient()

  React.useEffect(() => {
    log.debug?.('in useLoginLogout effect')

    Object.assign<Window['tizra'], Partial<Window['tizra']>>(window.tizra, {
      api: B.makeWindowApi({queryClient}),
      login: (view = 'signin') => {
        if (view === 'signin' && window.tizra.customLoginUrl) {
          window.location.assign(window.tizra.customLoginUrl)
          return
        }
        const k = authConfigKeys[view]
        if (!k) {
          log.error('unknown view', view)
          return
        }
        if (authConfig.modal.enabled) {
          show(view)
        } else {
          const [url, params = ''] = authConfig[k].url.split('?', 2)
          const usp = new URLSearchParams(params)
          usp.set(
            'successUrl',
            window.location.pathname +
              window.location.search +
              window.location.hash,
          )
          window.location.assign(url + '?' + usp)
        }
      },
      logout: () => {
        window.location.assign(
          window.tizra.customLogoutUrl ||
            '/authcallback/_/internal?operation=logout',
        )
      },
      registerUser: () => {
        window.tizra.login('register')
      },
      resetPassword: () => {
        window.tizra.login('resetPassword')
      },
    })

    const apiQueue = window.tizra._apiQueue
    window.tizra._apiQueue = []
    if (apiQueue) {
      for (const {name, args, resolve, reject} of apiQueue) {
        if (!(name in window.tizra.api)) {
          reject(`invalid api: ${name}`)
          continue
        }
        try {
          window.tizra.api[name as keyof WindowTizraApi](...args).then(
            resolve,
            reject,
          )
        } catch (e) {
          reject(`${e}`)
        }
      }
    }
  }, [authConfig, queryClient, show])

  return <B.SignInModal authConfig={authConfig} {...props} />
}

function useSessionErrors() {
  const {sessionErrors} = B.useBlockContext()
  const store = B.SessionErrorsModal.useStore({
    defaultOpen: !!sessionErrors.length,
  })
  return <B.SessionErrorsModal store={store} sessionErrors={sessionErrors} />
}

// This module sets up the queueing window.tizra.api every time it loads.
if (typeof window !== 'undefined') {
  window.tizra ||= {} as Window['tizra']
  window.tizra._apiQueue = []
  window.tizra.api = new Proxy({} as WindowTizraApi, {
    get(_, name) {
      if (typeof name !== 'string') return undefined
      return (...args: any[]) => {
        return new Promise<unknown>((resolve, reject) =>
          window.tizra._apiQueue!.push({name, args, resolve, reject}),
        )
      }
    },
  })
}
