import styled, {
  css,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'

type SummaryProps = SystemProps & {$clickable?: boolean}

export const Summary = styled.div<SummaryProps>(
  ({$clickable}) => css`
    display: flex;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    ${th('summaries.default.summary')};
    ${$clickable && {
      cursor: 'pointer',
    }};
    ${system};
  `,
)

export const Cover = styled.div<SystemProps>`
  display: block; // for <Cover as={UniversalLink} />
  flex: 0 0 auto;
  align-items: flex-start;
  ${th('summaries.default.cover')};
  width: 175px;
  overflow: hidden;
  ${system};
`

export const Body = styled.div<SystemProps>`
  flex: 1 1 auto;
  ${th('summaries.default.body')};
  ${system};
`

export const Footer = styled.div<SystemProps>`
  word-break: break-word;
  ${th('summaries.default.footer')};
  ${system};
`

export const Heading = styled.div<SystemProps>`
  ${th('summaries.default.heading')};
  ${system};
`

export const Description = styled.div<SystemProps>`
  word-break: break-word;
  ${th('summaries.default.description')};
  ${system};
`

export const PageInfo = styled.div<SystemProps>`
  ${th('summaries.default.pageInfo')};
  ${system};
`
