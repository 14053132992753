import {breakpoints} from 'quickstart/theme'
import {styled} from 'quickstart/theme/linaria'
import * as B from '../block'

export const OffersStack = styled(B.Stack)<{asModal: boolean}>`
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    width: ${props => (props.asModal ? '75%' : '50%')};
  }
`
