import {textComponent} from 'quickstart/components/content/Text'
import {toRem, withTheme} from 'quickstart/theme'
import {styled, theme} from 'quickstart/theme/linaria'

const indent = theme.space.lg

const _Form = styled.form`
  display: grid;
  column-gap: ${theme.space.xl};
  row-gap: ${theme.space.xxl};
`
export const Form = withTheme(_Form)

// Apply form font, since it's harder to apply in Input, Select, etc. This could
// be reverted to body font by an internal component as needed. We don't apply
// in Form above because it's hard to combine Text and AK.Form.
const _Field = styled(textComponent({font: 'ui', variant: 'form'}))`
  display: flex;
  flex-direction: column;

  ${_Form}[data-size=sm] &:not([data-size]),
  &[data-size=sm] {
    --field-input-height: 2rem;
    --field-row-gap: ${theme.space.xxs};
  }
  ${_Form}[data-size=md] &:not([data-size]),
  &[data-size=md] {
    --field-input-height: 3rem;
    --field-row-gap: ${theme.space.xs};
  }
  ${_Form}[data-size=lg] &:not([data-size]),
  &[data-size=lg] {
    --field-input-height: 3.5rem;
    --field-row-gap: ${theme.space.sm};
  }
`
export const Field = withTheme(_Field)

// TODO: old fieldStyles had variant colors, transition

const _Input = styled(
  textComponent({font: 'ui', variant: 'form', as: 'input'}),
)`
  width: 100%;
  min-height: var(--field-input-height);
  padding: ${theme.space.xs} ${indent};

  // Ariakit examples use box-shadow instead of border
  border: ${theme.borders.std};
  border-radius: 0;

  &::placeholder {
    color: ${theme.colors.textColorMuted};
  }

  &:focus-visible,
  &[data-focus-visible] {
    border-color: ${theme.colors.borderFocusColor};
  }
`
export const Input = withTheme(_Input)

export const Error = withTheme(styled.div`
  padding: ${theme.space.md};
  border: ${theme.borders.std};
  border-color: ${theme.colors.borderError};
  background-color: ${theme.colors.backgroundError};
  color: ${theme.colors.textError};

  :not(${_Input}) + & {
    margin-top: var(--field-row-gap);
  }

  &:empty {
    display: none;
  }
`)

export const Buttons = withTheme(styled.div`
  display: flex;
  gap: ${theme.space.xl};
`)

export const LabelWrapper = withTheme(styled.div`
  line-height: 1rem; // XXX compat with old
  margin: 0 ${indent} var(--field-row-gap);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${theme.space.xxl};
`)

// This is rendered as either label or span. See Label.tsx
export const Label = styled.label``

export const RightLabel = styled.div`
  &:empty {
    display: none;
  }
`

export const Hint = withTheme(styled(
  textComponent({font: 'ui', variant: 'formMeta', italic: true}),
)`
  margin: var(--field-row-gap) ${indent} 0;
  &:empty {
    display: none;
  }
`)

export const Select = withTheme(styled(
  textComponent({font: 'ui', variant: 'form', as: 'button'}),
)`
  display: flex;
  width: 100%;
  height: var(--field-input-height);
  user-select: none;
  align-items: center;
  gap: 0.25rem; // XXX
  white-space: nowrap;
  // Ariakit examples use box-shadow instead of border
  border: ${theme.borders.std};
  border-radius: 0;
  padding: 0 ${indent};
  background-color: inherit;
  color: inherit;
  text-decoration-line: none;
  justify-content: space-between;

  &[aria-disabled='true'] {
    color: ${theme.colors.textColorMuted};
  }

  &[data-focus-visible],
  &[aria-expanded='true'] {
    border-color: ${theme.colors.borderFocusColor};
  }

  @media (min-width: 640px) {
    & {
      gap: 0.5rem;
    }
  }
`)

// This builds on Field for the sake of size control.
export const SelectPopover = withTheme(styled(_Field)`
  z-index: 50;
  display: flex;
  max-height: min(var(--popover-available-height, 300px), 300px);
  flex-direction: column;
  overflow: auto;
  overscroll-behavior: contain;
  border: ${theme.borders.std};
  border-top: none;
  border-radius: 0;
  background-color: ${theme.colors.backgroundColorWhite};
  color: ${theme.colors.textColor};
`)

export const SelectItem = withTheme(styled.div`
  display: flex;
  cursor: default;
  align-items: center;
  // This complicated padding ensures we use the same padding regardless of the
  // number of lines. The normal case a single line will have a final height of
  // --field-input-height.
  padding: calc((var(--field-input-height) - 1lh) / 2) ${indent};
  outline: none !important;

  border-top: ${theme.borderWidths.sm} solid ${theme.colors.borderColorLight};
  &:first-child {
    border-top: none;
  }

  &[aria-disabled='true'] {
    color: ${theme.colors.textColorMuted};
    cursor: not-allowed;
  }

  &[aria-selected='true'] {
    font-weight: ${theme.fontWeights.bold};
  }

  &[data-active-item] {
    background-color: ${theme.colors.backgroundColorLight};
  }
`)

export const _Checklist = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xs};
`
export const Checklist = withTheme(_Checklist)

export const CheckLabel = withTheme(styled.label`
  display: flex;
  user-select: none;
  align-items: baseline;
  gap: ${theme.space.xs};
  color: ${theme.colors.textColor};
  cursor: pointer;

  ${_Checklist} &[data-checked='true'] {
    font-weight: ${theme.fontWeights.bold};
  }

  &[data-disabled='true'] {
    color: ${theme.colors.textColorMuted};
  }

  --border: ${theme.colors.borderColor};

  &:hover,
  &[data-focus-visible='true'] {
    --border: ${theme.colors.borderSelectedColor};
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
  }
`)

const checkboxSvg = `<svg stroke-width="0" viewBox="0 0 512 512" height="0.875em" width="0.875em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`

export const Check = withTheme(styled.div`
  position: relative;
  top: 2px;
  display: block;
  width: ${toRem(18)};
  height: ${toRem(18)};
  flex-shrink: 0;
  border: ${theme.borders.std};
  border-color: var(--border);
  border-radius: 0;
  background-color: ${theme.colors.backgroundColorWhite};
  line-height: ${toRem(16)};

  &:after {
    content: '';
    mask: url('data:image/svg+xml; utf8, ${checkboxSvg}') no-repeat 50% 50%;
    mask-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: background-color ${theme.transitions.fast};
  }

  &[data-checked='true']:after {
    background-color: ${theme.colors.buttonSolidFocus};
  }
`)
