import isPropValid from '@emotion/is-prop-valid'
import classNames from 'classnames'
import {Variant} from 'quickstart/styled-components/theme/base'
import {FontKey, Rhythm, typography} from 'quickstart/theme'
import * as R from 'rambdax'
import {ComponentProps, JSX, createContext, useContext} from 'react'
import S from './styles.module.css'

export interface TextProps extends ComponentProps<'div'> {
  as?: any
  bold?: boolean
  // clamp?: boolean
  font?: FontKey
  italic?: boolean
  lines?: number
  prose?: boolean // shortcut sets rhythm and font
  proseFont?: FontKey
  rhythm?: Rhythm
  ui?: never // disused since Prose component
  underline?: boolean
  variant?: Variant
  theme?: any // drop for Linaria/SC compat
}

const HEADING_TAGS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  sectionHead: 'h2',
  sectionHeadTight: 'h2',
  cardHead: 'div', // TODO: h3?
  headlineHead: 'div',
} as {
  [k in Variant]: keyof JSX.IntrinsicElements
}

const TAGS = HEADING_TAGS

const TextContext = createContext<null | Variant>(null)

export const Text = ({
  ref,
  prose = false,
  rhythm = prose ? 'prose' : undefined,
  variant,
  as: TextAs,
  proseFont,
  font,
  bold,
  italic,
  underline,
  lines,
  theme,
  ...props
}: TextProps) => {
  const parentVariant = useContext(TextContext)

  TextAs ??= (variant && TAGS[variant]) || 'div'
  variant ??= parentVariant ?? 'textMd'
  proseFont ??= variant in HEADING_TAGS ? 'heading' : 'body'
  font ??= prose || variant in HEADING_TAGS ? proseFont : undefined

  const text = (
    <TextAs
      data-bold={bold || undefined}
      data-clamp={lines || undefined}
      data-font={font}
      data-italic={italic || undefined}
      data-prose-font={proseFont}
      data-reset={variant !== parentVariant || undefined}
      data-rhythm={rhythm}
      data-underline={underline || undefined}
      {...(typeof TextAs === 'string' ?
        R.filter((_v, k) => isPropValid(k), props)
      : props)}
      className={classNames(
        S.text,
        variant === parentVariant && S.reset,
        props.className,
      )}
      style={{
        ...(variant === parentVariant ? null : typography.modVars[variant]),
        '--eg-lines': lines,
        ...props.style,
      }}
      ref={ref}
    />
  )

  return variant === parentVariant ? text : (
      <TextContext.Provider value={variant}>{text}</TextContext.Provider>
    )
}

export const H1 = (props: TextProps) => <Text variant="h1" {...props} />

export const H2 = (props: TextProps) => <Text variant="h2" {...props} />

export const H3 = (props: TextProps) => <Text variant="h3" {...props} />

export const H4 = (props: TextProps) => <Text variant="h4" {...props} />

export const textComponent =
  (config: Omit<TextProps, 'children'> | TextProps['variant']) =>
  (props: TextProps) => (
    <Text
      {...(typeof config === 'string' ? {variant: config} : config)}
      {...props}
    />
  )

interface ProseProps extends ComponentProps<'div'> {
  as?: any
  fonts?: 'prose' | 'ui'
}

export const Prose = ({
  as: asProp = 'div',
  fonts = 'prose',
  ...props
}: ProseProps) => {
  const ProseAs = asProp as 'div'
  return (
    <ProseAs
      data-fonts={fonts}
      {...props}
      className={classNames(S.prose, props.className)}
    />
  )
}
