import {Text} from 'quickstart/components/content/Text'
import * as R from 'rambdax'
import {ReactElement} from 'react'
import * as S from './styles'

interface ResultsProps {
  divided?: boolean
  items?: ReactElement[]
  count?: number | null
  control?: ReactElement
  noResultsMessage?: ReactElement
}

export const Results = ({
  divided = true,
  items,
  count = items?.length,
  control,
  noResultsMessage,
}: ResultsProps) => (
  <S.Results>
    <S.Header>
      <Text variant="textMd">
        {R.isNil(count) ?
          'Searching...'
        : `${count || 'No'} result${count === 1 ? '' : 's'}`}
      </Text>
      {control}
    </S.Header>
    <S.Body>
      {count === 0 ?
        noResultsMessage && (
          <S.Item divided={divided} lastItem={true}>
            {noResultsMessage}
          </S.Item>
        )
      : items?.map((item, i, arr) => (
          <S.Item key={i} divided={divided} lastItem={i === arr.length - 1}>
            {item}
          </S.Item>
        ))
      }
    </S.Body>
  </S.Results>
)
