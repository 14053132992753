import {logger} from 'tizra'
import * as B from '../block'
import {useThemeConfig} from '../ThemeBlock'
import {
  defaultConfig,
  defaultGlobalConfig,
  globalMigrate,
  SignInAdmin,
  SignInBlockGlobalConfig,
  SignInGlobalAdmin,
} from './admin'

const log = logger('SignInBlock')

export const SignInBlock = B.blockWithInfo(
  {
    name: 'signin',
    displayName: 'SignInBlock',
    title: 'Sign In',
    Admin: SignInAdmin,
    GlobalAdmin: SignInGlobalAdmin,
    defaultConfig,
    defaultGlobalConfig,
    globalMigrate,
  },
  ({config: {authAgent, ...config}}) => {
    const authConfig = useAuthConfig({authAgent})

    // Default successUrl here because we're definitely in block instead of
    // modal. If there is a successUrl in the query string, then the server will
    // pass it in block context, otherwise assume we should return to the home
    // page after a successful operation.
    const successUrl = B.useSuccessUrl() || '/'

    return (
      <B.Section>
        <B.Auth
          {...authConfig}
          {...config}
          modal={false}
          successUrl={successUrl}
          view="signin"
        />
      </B.Section>
    )
  },
)

interface UseAuthConfigProps {
  authAgent?: SignInBlockGlobalConfig['authAgent'] | ''
}

export function useAuthConfig(props: UseAuthConfigProps = {}) {
  const {logoLink, logoUrl} = useThemeConfig()
  const authConfig = {
    ...B.useGlobalConfig(SignInBlock),
    logoLink,
    logoUrl,
  }
  log.debug?.('global authConfig is:', authConfig)
  if (props.authAgent) {
    log.debug?.('received authAgent in props:', props.authAgent)
    authConfig.authAgent = props.authAgent
  }
  if (authConfig.authAgent !== 'tizra') {
    log.debug?.(
      `authAgent is ${authConfig.authAgent}, disabling passwordReset and registration`,
    )
    authConfig.passwordReset.enabled = false
    authConfig.registration.enabled = false
  }
  log.debug?.('authConfig is', authConfig)
  return authConfig
}
