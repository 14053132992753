import {PropDef} from 'tizra'
import * as A from '../admin'

interface Config {
  heading: string
  excludes: string[]
}

export const defaultConfig: Config = {
  heading: 'Details',
  excludes: [
    'Abstract',
    'Authors',
    'CoverImage',
    'Description',
    'Name',
    'Title',
  ],
}

// This is broken out of the usual A.usePropOptions so that the block can re-use
// it.
export const propertyFilter = (def: PropDef) =>
  def.isUserVisible && A.DETAIL_TYPES.includes(def.type)

export const DetailsAdmin: A.Admin<Config> = () => {
  const propOptions = A.usePropOptions({
    spec: 'sub-prop-defs',
    filter: propertyFilter,
  })

  return (
    <>
      <A.Group title="Content">
        <A.Unchecklist
          label="Included properties"
          name="excludes"
          options={propOptions}
        />
      </A.Group>
      <A.Group title="Presentation">
        <A.Input label="Heading" name="heading" />
      </A.Group>
    </>
  )
}
