import {useAdminApi} from 'quickstart/hooks/useApi'
import {useMemo} from 'react'
import {AdminType} from 'tizra'
import {easyOptions} from '../utils'

type Filter = (sourceName: string, type: AdminType) => boolean

const defaultFilter = (sourceName: string) =>
  sourceName !== 'ImageSource' &&
  sourceName !== 'PdfPage' &&
  sourceName !== 'PdfSource'

export const useSourceOptions = ({
  filter = defaultFilter,
}: {filter?: Filter} = {}) => {
  const metaTypes = useAdminApi.types().data
  return useMemo(() => {
    const sourceNames = []
    for (const type of Object.values(metaTypes || {})) {
      for (const sourceName of type.metaSourceNames || []) {
        if (!filter || filter(sourceName, type)) {
          sourceNames.push(sourceName)
        }
      }
    }
    return easyOptions(sourceNames)
  }, [filter, metaTypes])
}
