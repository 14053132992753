import * as AK from '@ariakit/react'
import {useNextFrame} from 'quickstart/hooks'
import {useTheme} from 'quickstart/styled-components/system'
import {Opt} from 'quickstart/types'
import {ComponentProps, createContext, ReactNode, Ref, useContext} from 'react'
import * as S from './styles'

const MenuContext = createContext<{nested: boolean}>({nested: false})

const useStore = (props?: AK.MenuStoreProps) => {
  const nested = useContext(MenuContext).nested
  return AK.useMenuStore({
    ...props,
    placement: props?.placement ?? (nested ? 'right-start' : 'bottom-start'),
  })
}

export type DropdownMenuProps = Opt<
  Omit<ComponentProps<typeof S.Menu>, 'children' | 'ref'>,
  'store'
> &
  Partial<Pick<AK.MenuStoreProps, 'placement'>> & {
    children?: ReactNode | ((props: {store: AK.MenuStore}) => ReactNode)
    label?: ReactNode
    ref?: Ref<any>
  }

const _DropdownMenu = ({
  ref,
  children,
  gutter,
  label,
  placement,
  shift,
  store: passedStore,
  variant = 'simple',
  ...props
}: DropdownMenuProps) => {
  const nested = useContext(MenuContext).nested
  const createdStore = useStore({placement})
  const store = passedStore || createdStore
  const open = store.useState('open')
  const delayedVisible = useNextFrame(open) ? {} : {opacity: 0}
  const {toPx, borderWidths, space} = useTheme() as any

  return (
    <>
      {nested ?
        // If it's a submenu, we have to combine the MenuButton and the
        // MenuItem components into a single component, so it works as a
        // submenu button.
        <S.Item
          variant={variant}
          ref={ref}
          render={
            <S.Button
              display="flex"
              flex="1 1 0%"
              justifyContent="space-between"
              store={store}
              variant={variant}
            />
          }
        >
          <div>{label}</div>
          <AK.MenuButtonArrow />
        </S.Item>
      : label ?
        // Otherwise, we just render the menu button if requested.
        <S.Button store={store} variant={variant} ref={ref}>
          <div>{label}</div>
          <AK.MenuButtonArrow />
        </S.Button>
      : null}
      <MenuContext.Provider value={{nested: true}}>
        <S.Menu
          tabIndex={0}
          {...props}
          gutter={gutter ?? (nested ? undefined : toPx(space.lg))}
          shift={shift ?? (nested ? -toPx(borderWidths.md) : undefined)}
          store={store}
          variant={variant}
          style={{
            ...props.style,
            ...delayedVisible,
          }}
          ref={ref}
        >
          {typeof children === 'function' ? children({store}) : children}
        </S.Menu>
      </MenuContext.Provider>
    </>
  )
}

export const DropdownMenu = Object.assign(_DropdownMenu, {
  Button: S.Button,
  Item: S.Item,
  Separator: S.Separator,
  useStore,
})
