import {css, th} from 'quickstart/styled-components/system'
import {Size, Variant} from 'quickstart/types'
import {getVariantColor} from './variants'

export const fieldStyles = (
  {
    size,
    type,
    variant,
  }: {
    size?: Size
    type?: string
    variant?: Variant
  } & object, // helps TS match override
) => css`
  ${th('fields.default')};
  width: 100%;
  ${variant &&
  css`
    border-color: ${getVariantColor(variant)};
  `};
  transition: medium;
  appearance: none;
  ${size && th(`fields.sizes.${size}`)};

  &::placeholder {
    ${th('fields.placeholder')};
  }

  &:focus {
    ${th('fields.focused')};
  }

  &[disabled] {
    ${th('fields.disabled')};
  }

  &[aria-checked='true'] {
    ${type &&
    ['radio', 'checkbox'].includes(type) &&
    th(`fields.${type}.checked`)};
  }

  &:invalid,
  &:-moz-submit-invalid,
  &:-moz-ui-invalid {
    box-shadow: none;
  }
`
