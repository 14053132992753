import {Text} from 'quickstart/components/content/Text'
import {Shape} from 'quickstart/components/layout/Shape'
import {cssVars, withProps, withTheme} from 'quickstart/theme'
import {styled, theme} from 'quickstart/theme/linaria'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Cover = styled(Shape)`
  width: 100%;
`

export const Body = withTheme(styled.div`
  word-break: break-word;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-top: ${theme.borderWidths.sm} solid ${theme.colors.borderColor};
`)

export const Heading = withTheme(styled(
  withProps({variant: 'headlineHead', prose: true})(Text),
)`
  margin-top: var(${cssVars.lineHeight});
`)
