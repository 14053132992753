import {darken} from 'polished'
import {bestContrast} from 'quickstart/utils'
import {useMemo} from 'react'
import {logger} from 'tizra'
import {SvgIcon} from './SvgIcon'

const log = logger('AttachmentIcon')

/**
 * Base palettes.
 *
 * Keys are tried in order:
 * - file extension
 * - full mime-type
 * - type/ portion of mime-type
 * - /subtype portion of mime-type
 * - mime-type prefix (so "application/vnd.ms-" matches
 *   "application/vnd.ms-excel")
 *
 * Palettes can be a single color (mainColor) or an object expressing a full or
 * partial palette: {mainColor, accentColor, textColor}.
 */

interface Palette {
  mainColor?: string
  accentColor?: string
  textColors?: string[]
  textColor?: string
}

const colors = {
  red: '#fc4237',
  gold: '#f0923c',
  green: '#76ab59',
  blue: '#84e0ff',
  violet: '#634c9f',
}

const basePalettes: {[k: string]: Palette | string} = {
  pdf: colors.red,
  png: colors.red,
  gif: colors.red,
  doc: colors.blue,
  docx: colors.blue,
  xml: colors.blue,
  xsd: colors.blue,
  html: colors.blue,
  mov: colors.violet,
  mp3: colors.violet,
  mp4: colors.violet,
  jpeg: colors.violet,
  jpg: colors.violet,
  xls: colors.green,
  xlsx: colors.green,
  txt: colors.green,
  ppt: colors.gold,
  pptx: colors.gold,
  zip: colors.gold,
  epub: colors.gold,
  'image/': colors.violet,
}

const expandPalette = (p?: string | Palette) => {
  const o = typeof p === 'string' ? {mainColor: p} : p || {}
  const {mainColor = '#ddd'} = o
  const {
    accentColor = darken(0.17, mainColor),
    textColors = ['#fff', '#000'],
    textColor = bestContrast(textColors, accentColor),
  } = o
  return {
    mainColor,
    accentColor,
    textColor,
  }
}

const getPalette = (mimeType: string, exts: string[]) => {
  for (const ext of exts) {
    const basePalette = basePalettes[ext]
    if (basePalette) {
      return expandPalette(basePalette)
    }
  }
  if (mimeType) {
    const basePalette = basePalettes[mimeType]
    if (basePalette) {
      return expandPalette(basePalette)
    }
    if (mimeType.includes('/')) {
      const [type, subtype] = mimeType.split('/')
      const basePalette =
        (type && basePalettes[`${type}/`]) ||
        (subtype && basePalettes[`/${subtype}`]) ||
        Object.entries(basePalettes).find(
          ([k]) => k.includes('/') && mimeType.startsWith(k),
        )?.[1]
      if (basePalette) {
        return expandPalette(basePalette)
      }
    }
  }
  return expandPalette() // fallback default
}

interface AttachmentIconProps {
  attachment?: {
    contentType?: string
    name?: string
  }
  size?: string
}

export const AttachmentIcon = ({
  attachment,
  size = '1em',
}: AttachmentIconProps) => {
  const {label, palette} = useMemo(() => {
    // Get all this defaulting out of the way
    const contentType = attachment?.contentType || ''
    const name = attachment?.name || ''

    const mimeType = contentType
      .trim()
      .replace(/[\s;].*/s, '')
      .toLowerCase()

    const baseName = name.replace(/.*\//s, '')
    const baseExt =
      baseName.includes('.') ?
        baseName.replace(/.*\./s, '').trim().toLowerCase()
      : ''

    return {
      label: baseExt.substring(0, 4).toUpperCase(),
      palette: getPalette(mimeType, [baseExt].filter(Boolean)),
    }
  }, [attachment])

  return <SvgIcon label={label} size={size} {...palette} />
}
