import {Text} from 'quickstart/components/content/Text'
import {UniversalLink} from 'quickstart/components/content/UniversalLink'
import styled, {css, th} from 'quickstart/styled-components/system'
import {cssVars} from 'quickstart/styled-components/theme/base/fonts'
import {ComponentProps} from 'react'

export type Variant = 'bars' | 'lined' | 'plain'

type TocEntryRowProps = ComponentProps<typeof UniversalLink> & {
  $variant: Variant
  $level: number
}

export const TocEntryRow = styled(UniversalLink)<TocEntryRowProps>(
  ({$variant, $level}) => css`
    display: flex;
    align-items: baseline;
    position: relative;
    ${$variant === 'bars' ?
      css`
        column-gap: 1rem;
        padding: 0.75rem 1rem;
        padding-left: ${$level + 1}rem;
        &:nth-of-type(odd) {
          background-color: ${th('colors.backgroundColorLight')};
        }
      `
    : $variant === 'lined' ?
      css`
        border-top: ${th('borders.std')};
        column-gap: 1rem;
        padding: 1rem;
        padding-left: ${$level + 1}rem;
      `
    : css`
        column-gap: 0;
        padding: 0.25rem 0;
        padding-left: ${$level}rem;
      `}
  `,
)

export const TocEntryText = (props: ComponentProps<typeof Text>) => (
  <Text font="body" {...props} />
)

type TocEntryTitleProps = ComponentProps<typeof TocEntryText> & {
  $level: number
  $variant: Variant
  $positioned?: true
}

export const TocEntryTitle = styled(TocEntryText).attrs<TocEntryTitleProps>(
  props => ({...props, bold: props.$level === 0}),
)(
  ({$variant, $positioned}) => css`
    ${$variant === 'plain' && $positioned ?
      css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      `
    : $variant === 'plain' ?
      css`
        flex: 0 1 100%;
        position: relative;
      `
    : css`
        flex: 0 1 100%;
      `}
  `,
)

export const TocEntryTitleSpan = styled.span`
  background-color: ${th('colors.backgroundColorWhite')};
`

export const TocEntryDots = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  border-bottom: dotted;
`

export const TocEntryPageNum = styled.div<{$variant: Variant; $level: number}>(
  ({$level, $variant}) => css`
    flex: 0 0 auto;
    ${$variant === 'plain' &&
    css`
      align-self: flex-end;
    `}
    ${$level === 0 &&
    css`
      font-weight: var(${cssVars.relativeWeights.bolder});
    `}
  `,
)
