import {ErrorBox} from 'quickstart/components/content/ErrorBox'
import {Html} from 'quickstart/components/content/Html'
import {Text} from 'quickstart/components/content/Text'
import {Button} from 'quickstart/components/controls/Button'
import {Box} from 'quickstart/components/layout/Box'
import {Grid} from 'quickstart/components/layout/Grid'
import {Modal} from 'quickstart/components/layout/Modal'
import {useMarkdown} from 'quickstart/hooks/useMarkdown'
import {ComponentProps, ReactNode} from 'react'

const FormGrid = (props: ComponentProps<typeof Grid>) => (
  <Grid colGap="xl" rowGap="xxl" {...props} />
)

const Buttons = (props: ComponentProps<typeof FormGrid>) => (
  <FormGrid rowGap="xl" {...props} />
)

const useMarkdownMaybe = (x: any) => {
  const isMarkdown = typeof x === 'string'
  const html = useMarkdown(isMarkdown && x)
  return (
    !isMarkdown ? x && <Text>{x}</Text>
    : html?.trim() ? <Html html={html} variant="raw" />
    : null
  )
}

export type FormShellProps = ComponentProps<typeof FormGrid> & {
  errorMessage?: ReactNode
  footer?: ReactNode
  header?: ReactNode
  helpMessage?: ReactNode
  modal?: boolean
  onSubmit?: any
  preface?: ReactNode
  primaryButton?: ComponentProps<typeof Button>
  secondaryButtons?: ComponentProps<typeof Button>[]
  variant?: 'modal' | 'modalish' | 'inline' | 'fluid'
}

const defaultVariant = (
  modal: FormShellProps['modal'],
): FormShellProps['variant'] => (modal ? 'modal' : 'inline')

const _FormShell = ({
  children,
  errorMessage,
  footer,
  header,
  helpMessage: rawMessage,
  preface,
  onSubmit,
  primaryButton,
  secondaryButtons,
  modal = false,
  variant = defaultVariant(modal),
  ...props
}: FormShellProps) => {
  const helpMessage = useMarkdownMaybe(rawMessage)

  const buttons: ReactNode[] =
    secondaryButtons
      ?.map((buttonProps, i) => (
        <Button key={`secondary-${i}`} variant="secondary" {...buttonProps} />
      ))
      .filter(Boolean) || []

  if (primaryButton) {
    const pb = <Button key="primary" variant="primary" {...primaryButton} />
    if (variant === 'fluid') {
      buttons.unshift(pb)
    } else {
      buttons.push(pb)
    }
  }

  let result = (
    <FormGrid
      // @ts-expect-error
      as={onSubmit ? 'form' : 'div'}
      onSubmit={onSubmit}
      {...(variant === 'inline' && {gridTemplateColumns: {md: '2fr 1fr'}})}
      {...props}
    >
      <FormGrid>
        {variant === 'modal' ?
          null
        : typeof header === 'string' && header ?
          <Text as="h2" variant="sectionHead">
            {header}
          </Text>
        : header}

        {errorMessage ?
          <ErrorBox>{errorMessage}</ErrorBox>
        : (variant === 'inline' || variant === 'fluid') && helpMessage ?
          helpMessage
        : preface && <Text font="body">{preface}</Text>}

        {children}

        {!!buttons.length && (
          <Buttons
            gridTemplateColumns={
              variant === 'inline' || variant === 'fluid' ?
                {_: '1fr', sm: '1fr 1fr'}
              : `repeat(${buttons.length > 1 ? 2 : 1}, 1fr)`
            }
          >
            {buttons}
          </Buttons>
        )}

        {footer}
      </FormGrid>
    </FormGrid>
  )

  if (variant === 'modalish') {
    // For the modalish variant, wrap the result in a bordered box and put the
    // help message on the left.
    result = (
      <Box
        border={{sm: 'fine'}}
        p={{_: 'borderedBoxPaddingSm', sm: 'borderedBoxPaddingLg'}}
      >
        {result}
      </Box>
    )
    result =
      helpMessage ?
        <FormGrid cols={{_: 1, md: 2}}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {helpMessage}
          </Box>
          <Box maxWidth={{sm: 480}}>{result}</Box>
        </FormGrid>
      : <Box mx="auto" maxWidth={{sm: 480}}>
          {result}
        </Box>
  }

  return modal ?
      <>
        <Modal.Title>{header}</Modal.Title>
        <Modal.Content>{result}</Modal.Content>
      </>
    : result
}

const _FormShellX = {
  displayName: 'FormShell',
  defaultVariant,
  Grid: FormGrid,
} as const

export const FormShell = _FormShell as typeof _FormShell & typeof _FormShellX
Object.assign(FormShell, _FormShellX)
