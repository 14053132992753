import {Text} from 'quickstart/components/content/Text'
import {Checkbox} from 'quickstart/components/controls/Checkbox'
import {Radios} from 'quickstart/components/controls/Radio'
import {Accordion} from 'quickstart/components/layout/Accordion'
import {Box} from 'quickstart/components/layout/Box'
import {SearchForm} from 'quickstart/components/tizra/SearchForm'
import {
  UseSearchReturn,
  useEqualBy,
  useHitCounts,
  useSticky,
} from 'quickstart/hooks'
import {
  SearchField,
  formFieldKey,
  formFields,
} from 'quickstart/lib/search/config'
import {cleanOptions, relabelWithHits} from 'quickstart/lib/search/options'
import * as R from 'rambdax'
import * as React from 'react'
import * as Final from 'react-final-form'
import {ensureArray, logger, semiSplit} from 'tizra'

const log = logger('SearchFacets')

const MAX_RENDER_CHECKBOXES_DEFAULT = 400

const NoSelectionsMessage = () => (
  <Box color="textColorMuted">
    No selections available for your search criteria
  </Box>
)

interface SearchFacetsConfigFieldProps {
  field: SearchField
  search: UseSearchReturn
  visible: boolean
}

const SearchFacetsConfigField = ({
  field,
  search,
  visible,
}: SearchFacetsConfigFieldProps) => {
  const hitCounts = useSticky(useHitCounts({field, search, visible}))

  const valueSpec = React.useMemo<Record<string, string>>(
    () =>
      field.serverDef?.valueSpecification && !field.serverDef.isCalculated ?
        R.piped(
          field.serverDef.valueSpecification,
          semiSplit,
          R.map(s => s.split('|', 2).map(ss => ss.trim())),
          R.map(vk => [vk[1] || vk[0], vk[0] || vk[1]] as [string, string]),
          kvs => kvs.filter(([k]) => k),
          kvs => R.fromPairs(kvs),
        )
      : {},
    [field.serverDef],
  )

  const options = React.useMemo(
    () =>
      cleanOptions(field.options).map(o => ({
        ...o,
        label: valueSpec[o.value] || o.label,
      })),
    [field.options, valueSpec],
  )

  const valueSet = useEqualBy(
    R.equals,
    new Set(ensureArray(search.params[field.name])),
  )

  const facet: SearchField = React.useMemo(() => {
    const widget =
      field.widget ||
      (Array.isArray(field.defaultValue) ? 'checklist' : 'radio')
    const maxRender =
      field.maxRender ||
      (['checklist', 'radio'].includes(widget) &&
        MAX_RENDER_CHECKBOXES_DEFAULT) ||
      Infinity
    const relabeledOptions = relabelWithHits(options, hitCounts, valueSet)
    const limitedOptions =
      relabeledOptions.length > maxRender ?
        relabeledOptions.slice(0, maxRender)
      : relabeledOptions
    return {
      ...field,
      widget,
      options: limitedOptions,
    }
  }, [field, hitCounts, options, valueSet])

  if (facet.widget === 'radio') {
    return !facet.options?.length ?
        <NoSelectionsMessage />
      : <Final.Field
          name={facet.name}
          render={({input}) => (
            <Text variant="form">
              <Radios options={options} {...input} />
            </Text>
          )}
        />
  }

  if (facet.widget === 'checklist') {
    return !facet.options?.length ?
        <NoSelectionsMessage />
      : <Final.Field
          name={facet.name}
          render={({input}) =>
            !!visible && (
              <Text variant="form">
                <Checkbox.Group options={facet.options as any} {...input} />
              </Text>
            )
          }
        />
  }

  return (
    <div>
      {facet.name} {facet.widget}
    </div>
  )
}

type SearchFacetsProps = Omit<
  React.ComponentProps<typeof Accordion.Group>,
  'children'
> & {
  search: UseSearchReturn
}

export const SearchFacets = ({
  search,
  search: {config},
  ...rest
}: SearchFacetsProps) => {
  // Memoize so that fs/ffks don't change on every render, causing looping
  // renders via effects.
  const [fs, ffks, defaultOpen] = React.useMemo(() => {
    const fs = formFields(config, 'desktopFilters').filter(
      // We need to filter out the terms field explicitly, since it appears
      // at the top of the results instead of next to them.
      f => f.name !== 'terms',
    )
    // Yikes! Top-level defaultOpen can be boolean or array. Field-level can be
    // boolean or undefined. Convert to the simplest representation to pass to
    // Accordion.Group.
    const defaultOpen =
      (
        fs.every(f => f.defaultOpen === undefined) &&
        typeof config.defaultOpen === 'boolean'
      ) ?
        config.defaultOpen
      : fs.flatMap((f, i) =>
          f.defaultOpen === true ? [i]
          : f.defaultOpen === false ? []
          : Array.isArray(config.defaultOpen) ?
            (
              config.defaultOpen.includes(i) ||
              config.defaultOpen.includes(f.name)
            ) ?
              [i]
            : []
          : config.defaultOpen ? [i]
          : [],
        )
    return [fs, fs.map(formFieldKey), defaultOpen]
  }, [config])

  return (
    <SearchForm instant search={search}>
      <Accordion.Group defaultOpen={defaultOpen} {...rest}>
        {fs.map(({label, ...field}, i) => (
          <Accordion key={ffks[i]} title={label}>
            {({visible}) => {
              return (
                <SearchFacetsConfigField
                  field={field}
                  search={search}
                  visible={visible}
                />
              )
            }}
          </Accordion>
        ))}
      </Accordion.Group>
    </SearchForm>
  )
}
