import {styled} from 'quickstart/theme/linaria'

type ShapeProps = {
  $shape: 'circle' | 'square' | 'rectangle' | 'pill'
}

export const Shape = styled.div<ShapeProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  // Square and circle have a 1:1 aspect-ratio. Pill and rectangle can be any
  // aspect ratio (including taller than wide).
  aspect-ratio: ${({$shape}) =>
    $shape === 'circle' || $shape === 'square' ? '1' : ''};

  border-radius: ${({$shape}) =>
    $shape === 'circle' ? '50%'
    : $shape === 'pill' ?
      '10000px' // https://stackoverflow.com/a/18795153
    : ''};

  img {
    object-fit: cover;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
  }
`
