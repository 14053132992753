import * as A from '../admin'

export interface Config {
  staticText: string
  staticType: 'markdown' | 'html' | 'text' | string
}

export const defaultConfig: Config = {
  staticText: '',
  staticType: '',
}

export const Admin: A.Admin<Config> = () => null
