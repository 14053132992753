import {styled} from 'quickstart/theme/linaria'

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`

// This is never directly svg, it's always overridden with as={InnerIcon}...
// which is always an svg.
export const Icon = styled.svg`
  display: block;
  height: 100%;
  width: auto;
`
