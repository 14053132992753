import * as B from '../block'
import {AttachmentsAdmin, defaultConfig} from './admin'

export const AttachmentsBlock = B.blockWithInfo(
  {
    name: 'attachments',
    displayName: 'AttachmentsBlock',
    title: 'Attachments',
    Admin: AttachmentsAdmin,
    defaultConfig,
  },
  ({
    config: {
      excludedSources,
      filterByUserLicenses,
      heading,
      iconSize,
      iconsEnabled,
    },
  }) => {
    const {tizraId} = B.useBlockContext()
    const content = B.useAttachmentsList({
      excludedSources,
      filterByUserLicenses,
      iconSize,
      iconsEnabled,
      tizraId,
    })
    return (
      <B.Scene>
        {content && <B.Section heading={heading}>{content}</B.Section>}
      </B.Scene>
    )
  },
)
