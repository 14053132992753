import * as A from 'quickstart/blocks/admin/base'

export const oneD = ['', 'w', 'e'] as const

export type OneD = (typeof oneD)[number]

export const twoD = ['', 'n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'] as const

export type TwoD = (typeof twoD)[number]

export type Just = 'left' | 'right' | 'center'

export interface BackgroundSpec {
  image: {
    source: '' | 'cover' | 'url'
    url: string
    crop: boolean
    focus: TwoD
  }
  color: {
    source: '' | 'config' | 'custom'
    color: string
    custom: string
  }
  bleed: boolean
  foreground: {
    color: string
    position: TwoD | Just // compat
  }
}

export const defaultBackgroundSpec: BackgroundSpec = {
  image: {
    source: '',
    url: '',
    crop: true,
    focus: 'n',
  },
  color: {
    source: '',
    color: '',
    custom: '',
  },
  foreground: {
    color: '',
    position: 'w',
  },
  bleed: true,
}

export const backgroundSpecFREEMARKER = (prefix: string) => {
  const _ = A.namely(prefix)
  return {
    [_('image.url')]: 'text',
    [_('color.color')]: 'text',
    [_('color.custom')]: 'text',
    [_('foreground.color')]: 'text',
  }
}

interface BackgroundConfigTabProps {
  name: string
  named: (s: string) => string
}

const BackgroundConfigImage = ({name, named: _}: BackgroundConfigTabProps) => {
  const v = A.useValueGetter(name)
  return (
    <>
      <A.Radio
        name={_('image.source')}
        options={A.options({
          '': 'none',
          cover: 'Cover Image',
          url: 'Custom URL',
        })}
      />
      {v('image.source') !== '' && (
        <>
          {v('image.source') === 'url' && (
            <A.Input label="Custom image URL" name={_('image.url')} />
          )}
          <A.YesNo
            label="Crop vertically to fit content?"
            name={_('image.crop')}
          />
          <A.Gravity
            label="Image focus"
            name={_('image.focus')}
            hint="When the image is cropped to fit, what part is kept?"
          />
        </>
      )}
    </>
  )
}

const BackgroundConfigColor = ({name, named: _}: BackgroundConfigTabProps) => {
  const v = A.useValueGetter(name)
  return (
    <>
      <A.Radio
        name={_('color.source')}
        options={A.options({
          '': 'none',
          config: 'CSS color',
          custom: 'CSS background property value (advanced)',
        })}
      />
      {v('color.source') !== '' && (
        <>
          {v('color.source') === 'config' && (
            <A.Input
              label="Color"
              name={_('color.color')}
              hint="Use alpha channel to layer on image, i.e. #RRGGBBAA"
            />
          )}
          {v('color.source') === 'custom' && (
            <A.Input
              label="Background property value"
              name={_('color.custom')}
              hint="Use alpha channel to layer on image, i.e. linear-gradient(#RRGGBBAA, #RRGGBBAA)"
            />
          )}
        </>
      )}
    </>
  )
}

const BackgroundConfigBgLayout = ({named: _}: BackgroundConfigTabProps) => {
  return <A.YesNo label="Full bleed?" name={_('bleed')} />
}

const BackgroundConfigForeground = ({named: _}: BackgroundConfigTabProps) => {
  return (
    <A.Input
      label="Text color"
      name={_('foreground.color')}
      hint="When a background is configured, this foreground color will be used for contrast."
    />
  )
}

const BackgroundConfigFgLayout = ({named: _}: BackgroundConfigTabProps) => {
  return (
    <A.Gravity
      label="Text position in block"
      name={_('foreground.position')}
      hint="Vertical positioning only applies when a background image is configured and vertical cropping is disabled."
    />
  )
}

export const useBackgroundConfigTabs = ({name}: {name: string}) => {
  const named = A.namely(name)
  const tabProps = {name, named}
  return {
    background: {
      image: {
        title: 'Image',
        children: <BackgroundConfigImage {...tabProps} />,
      },
      color: {
        title: 'Color',
        children: <BackgroundConfigColor {...tabProps} />,
      },
      layout: {
        title: 'Layout',
        children: <BackgroundConfigBgLayout {...tabProps} />,
      },
    },
    foreground: {
      color: {
        title: 'Color',
        children: <BackgroundConfigForeground {...tabProps} />,
      },
      layout: {
        title: 'Layout',
        children: <BackgroundConfigFgLayout {...tabProps} />,
      },
    },
  } as const
}
