import * as A from '../admin'

export interface ResetPasswordBlockConfig extends A.BlockConfig {
  heading: string
  message: string
}

export const defaultConfig: ResetPasswordBlockConfig = {
  heading: 'Reset Password',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export const ResetPasswordAdmin = () => (
  <>
    <A.Group title="Content">
      <A.Input label="Heading" name="heading" />
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <p>
      Global sign-in configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)
