import * as R from 'rambdax'
import {flatten} from 'tizra'
import * as constants from './constants'
import {defaultTheme} from './theme'

/**
 * Convert full theme to CSS vars.
 */
export const themeVars = (theme: object) =>
  R.piped(
    theme,
    R.filter(
      (_v: any, k: string) =>
        ![
          'baseFonts',
          'fakeCovers',
          'fonts',
          'fontMap',
          'fontWeights',
          'scales',
        ].includes(k),
    ),
    theme =>
      flatten(theme, {
        prefix: '--eg-theme-',
        joiner: '-',
        transformKey: k =>
          typeof k !== 'string' || !/[\W_]/.test(k) ? k : undefined,
        transformValue: v => `${v}`,
      }) as Record<string, string>,
  )

export const defaultThemeVars = themeVars({...constants, ...defaultTheme})
