// This module is replaced in the SSR build, see vite.config, to avoid GraalVM
// choking on SolidJS references to window.document.

import {IS_BROWSER, IS_DEV, debugging} from 'tizra'
import {ComponentProps, Suspense, lazy} from 'react'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    d => ({default: d.ReactQueryDevtools}),
  ),
)

export const ReactQueryDevtools = (
  props: ComponentProps<typeof ReactQueryDevtoolsProduction>,
) => {
  if (!IS_BROWSER || (!IS_DEV && !debugging())) {
    return null
  }
  return (
    <Suspense fallback={null}>
      <ReactQueryDevtoolsProduction buttonPosition="bottom-left" {...props} />
    </Suspense>
  )
}
