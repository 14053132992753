import {BlockName} from 'quickstart'

export interface TabStructureContent {
  contents?: TabStructureContent[]
  globalAdmin?: BlockName
  params?: string[]
  toggle?: string
}

export interface TabStructure {
  features: {contents: TabStructureContent[]}
  nav: {contents: TabStructureContent[]}
  theme: {contents: TabStructureContent[]}
}

// First level is tabs. These are hard-coded in cubchicken to match.
// Second level is toggles with nested contents.
// Third level is references to block-supplied global admins.
export const tabStructure: TabStructure = {
  features: {
    contents: [
      {toggle: 'Authentication', contents: [{globalAdmin: 'signin'}]},
      {toggle: 'Bookshelf', contents: [{globalAdmin: 'bookshelf'}]},
      {
        toggle: 'Reader',
        contents: [
          {
            params: [
              'readerCSS',
              'readerMarkup',
              'readerLogo',
              'ReaderAppEnabled',
              'ReaderAppHomeLink',
            ],
          },
        ],
      },
      {toggle: 'Redemption codes', contents: [{globalAdmin: 'redemption'}]},
      {toggle: 'Search', contents: [{globalAdmin: 'search'}]},
    ],
  },
  nav: {
    contents: [
      {toggle: 'Main Nav', contents: [{globalAdmin: 'nav'}]},
      {toggle: 'Footer', contents: [{globalAdmin: 'footer'}]},
    ],
  },
  theme: {
    contents: [{globalAdmin: 'theme'}],
  },
}
