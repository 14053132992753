import {isPlainObject} from 'tizra'

interface Visitor {
  (
    value: any,
    key: string | number | undefined,
    o: object | any[] | undefined,
  ): 'prune' | 'done' | void
}

interface Options {
  log?: (...args: any[]) => void
}

const dummy = () => {}

export function walk(
  visitor: Visitor,
  value: any,
  {log = dummy}: Options = {},
) {
  function _walk(visitor: Visitor, value: any, key?: any, parent?: any) {
    log('walk', {value, key, parent})

    const result = visitor(value, key, parent)
    if (typeof result === 'string') {
      return result // prune, done
    }

    if (Array.isArray(result)) {
      for (const k of result) {
        if (_walk(visitor, value[k], k, value) === 'done') {
          return 'done'
        }
      }
    } else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (_walk(visitor, value[i], i, value) === 'done') {
          return 'done'
        }
      }
    } else if (isPlainObject(value)) {
      for (const [vk, vv] of Object.entries(value)) {
        if (_walk(visitor, vv, vk, value) === 'done') {
          return 'done'
        }
      }
    }
  }

  return _walk(visitor, value)
}
