import {useHydrating, useMergedRefs, useVisible} from 'quickstart/hooks'
import {
  ComponentPropsWithRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as regexp from 'tizra'
import {IS_TEST, logger} from 'tizra'
import * as S from './styles'

const log = logger('Image/useLazyImgProps')

// http://png-pixel.com/
const SINGLE_PIXEL_TRANSPARENT =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

const MIN_ANDROID_CHROME_VERSION = 91
const MIN_CHROME_VERSION = 77
const MIN_EDGE_VERSION = 79
const MIN_FIREFOX_VERSION = 75

// Couldn't make FirefoxVersion work in testing, even though it supposedly
// supports loading="lazy" natively since version 75.
const FIREFOX_IS_BROKEN = true

const USERAGENT_REGEXP = regexp.tag('x')`
    \bAndroid\b.*\bChrome\/(\d+)
  | \bChrome\/(\d+)
  | \bEdg\/(\d+) # not a typo
  | \bFirefox\/(\d+)
`

const userAgentSupportsLazyLoading = (userAgent: string) => {
  const m = userAgent.match(USERAGENT_REGEXP)
  if (m) {
    const [androidChromeVersion, chromeVersion, edgeVersion, firefoxVersion] =
      m.slice(1)
    return (
      parseInt(androidChromeVersion) >= MIN_ANDROID_CHROME_VERSION ||
      parseInt(chromeVersion) >= MIN_CHROME_VERSION ||
      parseInt(edgeVersion) >= MIN_EDGE_VERSION ||
      (parseInt(firefoxVersion) >= MIN_FIREFOX_VERSION && !FIREFOX_IS_BROKEN)
    )
  }
  return false
}

const browserSupportsLazyLoading = () => {
  if (typeof window !== 'undefined') {
    if (IS_TEST && /\bjsdom\b/.test(window.navigator.userAgent)) {
      // Otherwise the image will always use the placeholder src, and there are
      // no load/error handlers attached.
      return true
    }
    if (/\bFirefox\b/.test(window.navigator.userAgent) && FIREFOX_IS_BROKEN) {
      return false
    }
    return typeof HTMLImageElement === 'undefined' ? null : (
        'loading' in HTMLImageElement.prototype
      )
  }
}

let warned = false

export interface UseLazyImgProps extends ComponentPropsWithRef<typeof S.Image> {
  userAgent?: string
}

export const useLazyImgProps = ({
  loading = 'lazy',
  onError,
  onLoad,
  src: srcProp,
  ref: forwardedRef,
  userAgent = '',
  ...props
}: UseLazyImgProps) => {
  const hydrating = useHydrating()
  const serverRendered = useRef(hydrating).current
  const ssrLazyLoading = userAgentSupportsLazyLoading(userAgent)
  const browserLazyLoading = browserSupportsLazyLoading()
  const nativeLazyLoading = hydrating ? ssrLazyLoading : browserLazyLoading
  const [errored, setErrored] = useState(false)

  log.debug?.({
    hydrating,
    serverRendered,
    ssrLazyLoading,
    browserLazyLoading,
    nativeLazyLoading,
    errored,
  })

  // The src prop should never change, because this hook is called by
  // a component that keys on the src prop.
  const src = useRef(srcProp).current
  log.assert(src === srcProp, 'useLazyImgProps received an update to src')

  if (ssrLazyLoading && browserLazyLoading === false) {
    // This is a problem because the server will render img tags with
    // loading="lazy" but the browser doesn't actually support it. The result
    // will be loading a lot of below-the-fold images eagerly.
    log.error("yikes, ssrLazyLoading is true but browser doesn't support it")
  }

  if (userAgent && !ssrLazyLoading && browserLazyLoading && !warned) {
    // Not a super big deal, just means we need to update our user-agent
    // detection of support for loading="lazy".
    log.warn(`ssrLazyLoading is false but has support (${userAgent})`)
    warned = true
  }

  const nativeRef = useRef<HTMLImageElement>(null)

  const [visible, visibilityRef] = useVisible({sticky: true})

  const handleError = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      setErrored(true)
      return onError?.(e)
    },
    [onError],
  )

  const handleLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => onLoad?.(e),
    [onLoad],
  )

  useEffect(() => {
    if (!src) {
      // Let the caller know there's nothing to render.
      // We don't have to wait for a network request to fail.
      handleError({currentTarget: {src}, target: {src}} as any)
    } else if (serverRendered && (ssrLazyLoading || loading !== 'lazy')) {
      // Check for missed events for SSR image.
      // https://github.com/facebook/react/issues/15446
      const img = nativeRef.current
      if (!img?.complete) {
        return // Normal events will fire when it finishes
      } else if (img.naturalWidth || img.naturalHeight) {
        // This means the image loaded, but it doesn't work for SVG on Firefox,
        // where naturalWidth and naturalHeight are always 0.
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1607081
        img.dispatchEvent(new Event('load'))
      } else {
        // Try to decode the image and use the promise result to decide if image
        // loading succeeded or failed. This seems to work reliably on Chrome
        // and Firefox for raster and vector images, and also fails reliably for
        // broken images. https://codepen.io/agriffis/pen/VwQVEKQ
        img
          .decode()
          .then(() => img.dispatchEvent(new Event('load')))
          .catch(() => img.dispatchEvent(new Event('error')))
      }
    }
  }, [
    handleError,
    handleLoad,
    loading,
    nativeRef,
    serverRendered,
    ssrLazyLoading,
    src,
  ])

  const ref = useMergedRefs([
    forwardedRef,
    nativeRef,
    !browserLazyLoading && visibilityRef,
  ])

  if (errored || !src) {
    return null
  }

  if (nativeLazyLoading) {
    return {
      ...props,
      loading,
      onError: handleError,
      onLoad: handleLoad,
      ref,
      src,
    }
  }

  return {
    ...props,
    ...(loading === 'lazy' && !visible ?
      {src: SINGLE_PIXEL_TRANSPARENT}
    : {
        onError: handleError,
        onLoad: handleLoad,
        src,
      }),
    ...(loading === 'eager' && {loading}),
    ref,
  }
}
