import {ComponentProps, ReactNode} from 'react'
import * as S from './styles'
import {UseLazyImgProps, useLazyImgProps} from './useLazyImgProps'

interface ImageProps
  extends Omit<ComponentProps<typeof S.Image>, 'src'>,
    Omit<UseLazyImgProps, 'src'> {
  fallback?: ReactNode
  src?: string
}

const KeyedImage = ({fallback = null, ...props}: ImageProps) => {
  const imgProps = useLazyImgProps(props)
  return imgProps ? <S.Image {...imgProps} /> : fallback
}

const _Image = (props: ComponentProps<typeof KeyedImage>) => (
  <KeyedImage key={props.src} {...props} />
)

export const Image = Object.assign(_Image, {
  Caption: S.Caption,
})

export const StyledImage = S.Image // for nested styles
