import {Icon} from 'quickstart/components/content/Icon'
import {NavLink, NavLinkProps} from 'quickstart/components/layout/NavLink'

interface ProfileMenuProps {
  profileName: string
  items: NavLinkProps[]
}

export const ProfileMenu = ({profileName, items}: ProfileMenuProps) => {
  if (!items.length) {
    return null
  }

  if (items.length === 1) {
    // @ts-expect-error _signin is a hack
    const {_signin, ...item} = items[0]
    if (_signin) {
      return <NavLink {...item} />
    }
  }

  const label = (
    <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
      <Icon icon="user" />
      <div style={{alignSelf: 'baseline'}}>{profileName}</div>
    </div>
  )

  return (
    <NavLink
      type="menu"
      label={label as any} // wants to be string
      items={items}
      menuProps={{placement: 'bottom-end'}}
      itemProps={{justifyContent: 'flex-end'}}
    />
  )
}
