import {useUp} from 'quickstart/styled-components/system'
import {logger} from 'tizra'
import {useBlockContext} from './useBlockContext'

const log = logger('useMobile')

export const useMobile = () => {
  const isMobileUserAgent = useBlockContext('isMobileUserAgent')
  const isDesktop = useUp('lg', !isMobileUserAgent)
  return !isDesktop
}
