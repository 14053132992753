import styled, {system, SystemProps} from 'quickstart/styled-components/system'
import {ComponentProps} from 'react'

const StyledButton = styled.button<SystemProps>`
  ${system}
`

export const Button = ({
  children,
  ...props
}: ComponentProps<typeof StyledButton>) =>
  // This uses <input type=button> by default because admin css uses that, and
  // doesn't provide any styling for <button>. We have to fall back to <button>
  // if children is something other than a string, though.
  typeof children === 'string' ?
    <StyledButton
      as="input"
      type="button"
      className="button"
      value={children}
      {...props}
    />
  : <StyledButton type="button" {...props}>
      {children}
    </StyledButton>
