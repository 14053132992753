import {useCallback, useEffect, useState} from 'react'
import {IS_BOT} from 'tizra'
import {useIntersectionObserver} from './useIntersectionObserver'

/**
 * Wrap useIntersectionObserver to provide a more useful API. The most useful is
 * when sticky is true, but we default to false so that the calling code is
 * clearer.
 */
export const useVisible = ({
  bots = true,
  enabled = true,
  options,
  sticky = false,
}: {
  bots?: boolean
  enabled?: boolean
  options?: IntersectionObserverInit
  sticky?: boolean
} = {}) => {
  const [visible, setVisible] = useState<boolean | undefined>(undefined)
  const [entry, observerCallbackRef] = useIntersectionObserver({
    enabled: enabled && (bots || !IS_BOT) && (!visible || !sticky),
    ...options,
  })

  // Whenever the node changes, reset isVisible to false (regardless of sticky)
  // and update the observer. The observer will call back by updating entry.
  const callbackRef = useCallback(
    (node: any) => {
      if (node) {
        setVisible(false)
      }
      observerCallbackRef(node)
    },
    [observerCallbackRef],
  )

  // Whenever we get a new IntersectionObserverEntry, update isVisible
  // accordingly.
  useEffect(() => {
    if (entry) {
      setVisible(isVisible => (isVisible && sticky) || entry.isIntersecting)
    }
  }, [entry, sticky])

  return [visible, callbackRef] as const
}
