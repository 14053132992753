import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {ComponentProps} from 'react'
import * as S from './styles'

type FlexProps = UnprefixTransientProps<ComponentProps<typeof S.Flex>>

export const Flex = ({columnGap, gap, rowGap, ...props}: FlexProps) => (
  <S.Flex {...transient({columnGap, gap, rowGap})} {...props} />
)
