import {useNavigate} from 'react-router-dom'
import {api, logger} from 'tizra'
import * as B from '../block'
import {CartItem} from './CartItem'
import {Config} from './admin'
import * as S from './styles'

const log = logger('CartBlock/Cart')

const YOUR_CART = 'Your shopping bag'

const EMPTY_MESSAGE =
  'You have nothing in your bag yet. [Continue browsing here](/)'

interface CartProps {
  config: Config
}

export const Cart = ({config}: CartProps) => {
  const navigate = useNavigate()
  const {data: cart} = B.useApi.cart()

  if (!cart) {
    return null
  }

  if (cart.length === 0) {
    return (
      <B.EmptyBlock
        heading={YOUR_CART}
        message={config.emptyMessage || EMPTY_MESSAGE}
      />
    )
  }

  const subtotal = cart.reduce(
    (sum: number, {offer: {price}}: any) => sum + price,
    0,
  )

  const currency =
    log.assert(
      cart[0].offer.currencyInfo,
      'missing currency-info in cart API response',
    ) || 'USD'

  return (
    <B.Section>
      <B.Grid cols={{_: 1, lg: 2}} colGap="48px">
        <B.Stack divided endCapped>
          <B.Text variant="h3">{YOUR_CART}</B.Text>
          {cart.map((x: any, i: number) => (
            <CartItem
              {...x}
              variant="cart"
              key={i}
              onRemove={() =>
                api
                  .cartRemove({itemId: x.item.tizraId})
                  .catch(() => {})
                  .then(() => {
                    // We must reload the page so that the server-provided
                    // PayPal button gets the update.
                    document.location.reload()
                  })
              }
            />
          ))}
        </B.Stack>
        <B.Stack spacing="xxl">
          <S.BorderedStack spacing="xxl">
            <B.Text variant="h5">Your order</B.Text>
            <B.Stack spacing="xs" divided>
              <B.Text>{cart.length} items</B.Text>
              <B.LeftRight>
                Subtotal
                <B.Currency amount={subtotal} currency={currency} />
              </B.LeftRight>
            </B.Stack>
          </S.BorderedStack>
          {config.summaryMessage ?
            <B.Text>{config.summaryMessage}</B.Text>
          : null}
          <S.ButtonStack>
            <B.Button
              variant="primary"
              size="md"
              fluid
              onClick={() => navigate('#checkout')}
              {...B.tid('checkout-button')}
            >
              Check out
            </B.Button>
            <B.Button
              variant="secondary"
              size="md"
              fluid
              as="a"
              // @ts-expect-error Button doesn't accept href even with as="a"
              href="/"
            >
              Keep shopping
            </B.Button>
          </S.ButtonStack>
        </B.Stack>
      </B.Grid>
    </B.Section>
  )
}
