import {Link} from 'quickstart/components/content/Link'
import {Text} from 'quickstart/components/content/Text'
import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {Input} from 'quickstart/components/controls/Input'
import {FormShell} from 'quickstart/components/layout/FormShell'
import {MetaLink} from 'quickstart/components/tizra/MetaLink'
import {MetaValue} from 'quickstart/components/tizra/MetaValue'
import {useApi, useMetaObj, useUserData} from 'quickstart/hooks'
import {focusError, formErrors} from 'quickstart/utils/final-form-errors'
import {walk} from 'quickstart/utils/walk'
import {ComponentProps, ReactNode, useEffect, useMemo, useState} from 'react'
import * as Final from 'react-final-form'
import {ApiError, FormErrors, api, logger} from 'tizra'

const log = logger('RedemptionForm')

interface RedemptionFormProps {
  autoFocus?: boolean
  bookshelfConfig: {url: string}
  'data-testid'?: string
  heading?: string
  message?: ReactNode
  redemptionCode?: string
  successUrl?: string
}

const licensedIds = (licensesResponse: any) => {
  const ids = new Set<string>()
  walk(
    (x: any, k: any) => void (x && k === 'controlled' && ids.add(x)),
    licensesResponse,
  )
  return Array.from(ids).sort() as string[]
}

const diff = (a: Iterable<any>, b: Iterable<any>) => {
  const c = new Set(a)
  for (const x of b) {
    c.delete(x)
  }
  return Array.from(c).sort()
}

const redemptionCodeFieldName = 'redemption-code'

export const RedemptionForm = ({
  autoFocus,
  bookshelfConfig,
  'data-testid': dataTestId,
  heading,
  message,
  redemptionCode,
  successUrl,
}: RedemptionFormProps) => {
  const tid = (s: string) =>
    dataTestId ? {'data-testid': `${dataTestId}-${s}`} : null

  const userData = useUserData()

  const [response, setResponse] = useState<any>()

  const {data: licensesBefore} = useApi.licenses()

  const {data: licensesAfter} = useApi.licenses(
    response?.tagsAdded && {_: 'react-query-cache-buster'},
  )

  const newIds = useMemo<string[] | undefined>(
    () =>
      licensesAfter ?
        diff(licensedIds(licensesAfter), licensedIds(licensesBefore))
      : response &&
        licensedIds(response.sessionLicensesAdded || response.licensesAdded),
    [licensesBefore, licensesAfter, response],
  )

  const newItem = useMetaObj({
    enabled: newIds?.length === 1,
    tizraId: newIds?.[0],
  })

  log.debug?.({response, licensesBefore, licensesAfter, newIds, newItem})

  const plural =
    newIds?.length === 1 ?
      {item: 'item', has: 'has'}
    : {item: 'items', has: 'have'}

  const successMessage =
    newIds?.length ?
      <MetaValue
        metaObj={newItem}
        prop={['_name', 'Title']}
        render={content => (
          // TODO customer-html stupid hack to get <strong> to work, until we
          // have that universally in Text
          <span className="customer-html">
            <strong>{content || `${newIds.length} ${plural.item}`}</strong>{' '}
            {plural.has} been added to your account.
          </span>
        )}
      />
    : response?.reason === 'already' ?
      "Welcome back! You've used this code previously, so you already have access to the content."
    : null

  const successButtons: Partial<ComponentProps<typeof FormShell>> =
    successUrl ?
      {
        primaryButton: {
          ...tid('successUrl'),
          as: Link,
          children: 'View my content',
          // @ts-expect-error Button as={Link}
          href: successUrl,
        },
      }
    : newIds?.length !== 1 ?
      {
        primaryButton: {
          ...tid('bookshelf'),
          as: Link,
          children: 'View my content',
          href: bookshelfConfig.url,
        },
      }
    : {
        primaryButton: {
          ...tid('detail'),
          as: MetaLink,
          tizraId: newIds[0],
          children: 'View my content',
        },
      }

  const submit = async (values: {[redemptionCodeFieldName]: string}) => {
    const {status, ...data} = await api.redeem(values).catch(e => {
      // Only here for 500, not 40x per entry in info.ts
      log.error(e)
      return {
        status: (e instanceof ApiError && e.status) || 0,
        reason: 'exception',
        message: `${e}`,
        errors: undefined,
      }
    })

    // Redemption API returns 201 for proper success, and 200 for a code that
    // was already redeemed by this user.
    if (status >= 200 && status < 300) {
      setResponse(data)
      return // React Final Form will set submitSucceeded
    }

    let {errors, message, reason} = data as FormErrors

    // If the API returns not-logged-in, this means the session expired between
    // the time we rendered the form and now, so we need to reload the page.
    if (reason === 'not-logged-in' && userData) {
      window.location.reload()
    }

    // The redemption API returns all errors at the top level. Move some of them
    // to field error for better UX. (Note that we handle 'already' here but
    // since it's a 200 response, it will already have been handled above.)
    if (reason === 'invalid' || reason === 'already') {
      errors = {
        [redemptionCodeFieldName]:
          reason === 'invalid' && !values[redemptionCodeFieldName]?.trim() ?
            {reason: 'required', message: 'This field is required.'}
          : {reason, message: message || ''},
        ...errors,
      }
      message = reason = undefined
    }

    return formErrors({errors, message, reason, status})
  }

  // The render function below sets form. It's hokey, but..
  // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#via-closure
  // TODO make this into a Final Form decorator based on form validity.
  let form: any
  useEffect(() => {
    if (redemptionCode && userData) {
      log.log('received redemptionCode in props, attempting submit')
      form.submit()
    }
  }, [redemptionCode, userData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Final.Form
      onSubmit={submit}
      decorators={[focusError]}
      initialValues={{[redemptionCodeFieldName]: redemptionCode || ''}}
      render={renderProps => {
        form = renderProps.form // See useEffect above
        const {handleSubmit, submitSucceeded, submitError} = renderProps
        if (submitSucceeded) {
          return (
            <FormShell header="Success!" {...successButtons}>
              <Text {...tid('success')}>{successMessage}</Text>
            </FormShell>
          )
        }

        return (
          <FormShell
            onSubmit={handleSubmit}
            header={heading}
            helpMessage={message}
            errorMessage={
              submitError && <div {...tid('error')}>{submitError}</div>
            }
            primaryButton={{
              ...tid('submit'),
              type: 'submit',
              children: 'Submit',
            }}
          >
            <ConnectedField
              autoFocus={autoFocus}
              component={Input}
              label="Redemption Code"
              name={redemptionCodeFieldName}
              {...tid('input')}
            />
          </FormShell>
        )
      }}
    />
  )
}
