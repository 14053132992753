import {Text} from 'quickstart/components/content/Text'
import {classes, withProps} from 'quickstart/theme'
import {atBreakpointsWith, styled} from 'quickstart/theme/linaria'

const paddings = {
  default: {
    xs: '1.25rem',
    sm: '1.5rem',
    md: '2rem',
    lg: '3rem',
  },
  skinny: {
    xs: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '2rem',
  },
  superskinny: {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
  },
  abutting: {
    xs: '0',
  },
}

export const Section = styled.section<{
  'data-variant': keyof typeof paddings
}>`
  ${Object.fromEntries(
    Object.entries(paddings).map(([variant, pads]) => [
      `&[data-variant=${variant}]`,
      atBreakpointsWith((v: string) => ({paddingTop: v, paddingBottom: v}))(
        pads,
      ),
    ]),
  )}
`

export const SectionHeading = styled(
  withProps({variant: 'sectionHead', prose: true})(Text),
)`
  &:is(${classes.stack}, ${classes.stackItem}) > & {
    margin-bottom: 0;
  }
`
