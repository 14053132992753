import {Children, ReactNode} from 'react'

/**
 * Wrap text nodes in <span> so that styling can apply, for example, an asterisk
 * next to indicate required next to a label. This comes from welcome-ui.
 */
export const wrapChildren = (children: ReactNode) =>
  Children.map(children, child =>
    typeof child === 'number' || typeof child === 'string' ?
      <span key={child}>{child}</span>
    : child,
  )
