import {StyledImage} from 'quickstart/components/content/Image'
import {IconButton} from 'quickstart/components/controls/IconButton'
import {Container} from 'quickstart/components/layout/Container'
import styled, {
  css,
  shouldForwardProp,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {Divider} from '../Divider'

export const DESKTOP = 'md'

/*
 * MOBILE          DESKTOP
 * Logo Button     Logo Search
 * (Menu)          Links Actions
 * Search
 *
 * The mobile menu appears in parentheses because it only shows when the
 * hamburger is tapped, and then it covers the screen from that point downward.
 */

export const Nav = styled.div``

/**
 * LogoRow appears on both mobile and desktop
 */
export const LogoRow = styled(Container)`
  height: ${th('nav.logoRow.height')};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: auto;
  }
`

/**
 * SearchRow only appears on mobile
 */
export const SearchRow = styled.div`
  background-color: ${th('nav.searchRow.backgroundColor')};
  color: ${th('nav.searchRow.color')};
  @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
    display: none;
  }
`

export const SearchRowItems = styled(Container)`
  height: ${th('nav.searchRow.height')};
`

/**
 * NavRow only appears on desktop
 */
export const NavRow = styled(Container)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  height: auto;

  display: none; // mobile
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
    display: flex;
  }
`

/**
 * Logo graphic
 * Height should be a round pixel number to avoid fuzziness.
 */
export const Logo = styled.div`
  padding-top: ${th('nav.logoRow.logoPadding')};
  padding-bottom: ${th('nav.logoRow.logoPadding')};

  ${StyledImage} {
    height: calc(
      ${th('nav.logoRow.height')} - (2 * ${th('nav.logoRow.logoPadding')})
    );
    width: auto;
    object-fit: contain;
    object-position: left center;
  }

  @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
    height: auto;
    padding: 0;
    ${StyledImage} {
      height: 3.25rem;
    }
  }
`

type NavButtonProps = Omit<SystemProps, 'right' | 'left'> & {
  left?: boolean
  right?: boolean
}

const StealthIconButton = styled(IconButton).attrs(({variant = 'stealth'}) => ({
  variant,
}))``

/**
 * NavButton is an IconButton that can be bled to the right or left, so that the
 * icon itself is aligned with the container edge, and the padding is the same
 * on each side.
 */
export const NavButton = styled(StealthIconButton).withConfig({
  shouldForwardProp,
})<NavButtonProps>(
  ({left, right}) => css`
    flex: 0 0 auto;
    height: ${th('nav.logoRow.height')};
    padding: 20px ${th('containers.default.paddings.xs')};

    ${left &&
    css`
      margin-left: -${th('containers.default.paddings.xs')};
    `};
    ${right &&
    css`
      margin-right: -${th('containers.default.paddings.xs')};
    `};

    @media (min-width: ${th(`breakpoints.sm`)}) {
      ${(left || right) &&
      css`
        padding-left: ${th('containers.default.paddings.sm')};
        padding-right: ${th('containers.default.paddings.sm')};
      `};
      ${left &&
      css`
        margin-left: -${th('containers.default.paddings.sm')};
      `};
      ${right &&
      css`
        margin-right: -${th('containers.default.paddings.sm')};
      `};
    }

    @media (min-width: ${th(`breakpoints.md`)}) {
      ${(left || right) &&
      css`
        padding-left: ${th('containers.default.paddings.md')};
        padding-right: ${th('containers.default.paddings.md')};
      `};
      ${left &&
      css`
        margin-left: -${th('containers.default.paddings.md')};
      `};
      ${right &&
      css`
        margin-right: -${th('containers.default.paddings.md')};
      `};
    }

    display: flex;
    justify-content: center;

    ${system};
  `,
)

/**
 * MenuHolder is a zero-height div between LogoRow and SearchRow on mobile. It
 * gives the Menu something to attach to, so that when the mobile menu is
 * showing, it starts in the right place on the screen.
 */
export const MenuHolder = styled.div<SystemProps>`
  display: block;
  height: 0;
  overflow: visible;
  ${system};

  @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
    display: none;
  }
`

export const Menu = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - ${th('nav.logoRow.height')});
  overflow-y: auto;
  z-index: 1;
  background-color: ${th('nav.menu.backgroundColor')};
`

export const NavDivider = styled(Divider)`
  display: none;
  @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
    display: block;
  }
`

export const Mobile = styled.div<{$display?: string}>(
  ({$display = 'block'}) => css`
    display: ${$display};
    @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
      display: none;
    }
  `,
)

export const Desktop = styled.div<{$display?: string}>(
  ({$display = 'block'}) => css`
    display: none;
    @media (min-width: ${th(`breakpoints.${DESKTOP}`)}) {
      display: ${$display};
    }
  `,
)
