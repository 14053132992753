import * as B from '../block'
import {useAuthConfig} from '../SignInBlock'
import {
  defaultConfig,
  RegisterUserAdmin,
  RegisterUserBlockConfig,
} from './admin'

export const RegisterUserBlock = B.blockWithInfo(
  {
    name: 'register-user',
    displayName: 'RegisterUserBlock',
    title: 'Register User',
    Admin: RegisterUserAdmin,
    defaultConfig,
  },
  ({config}: {config: RegisterUserBlockConfig}) => {
    const authConfig = useAuthConfig({authAgent: 'tizra'})
    const users = B.useUserDatas() // null = don't know yet (SSR)

    // Default successUrl here because we're definitely in block instead of
    // modal. If there is a successUrl in the query string, then the server will
    // pass it in block context, otherwise assume we should return to the home
    // page after a successful operation.
    const successUrl = B.useSuccessUrl() || '/'

    return (
      <B.Section>
        {users &&
          (users.length > 1 ?
            <B.FormShell
              header="Whoops"
              helpMessage={config.message}
              errorMessage="Can't change password when signed in to more than one account."
            />
          : <B.Auth
              {...authConfig}
              {...config}
              modal={false}
              successUrl={successUrl}
              view={users.length ? 'changePassword' : 'register'}
            />)}
      </B.Section>
    )
  },
)
