import * as H from 'quickstart/hacks'
import {AdminContext, AdminContextFromServer, Hack} from 'quickstart/types'
import * as React from 'react'
import {logger} from 'tizra/log'

const log = logger('useAdminContext')

export const defaultAdminContextFromServer: AdminContextFromServer = {
  debugFlags: '',
  globalConfig: {},
  hacks: [],
  metaType: 'SiteDisplay',
  metaTypes: [],
  siteDisplayId: 0,
}

export const fromServer = (
  context: AdminContextFromServer,
  global: boolean,
): AdminContext => ({
  ...defaultAdminContextFromServer,
  ...context,
  hacks: H.fromServer(context.hacks),
  global,
})

export const defaultAdminContext: AdminContext = fromServer(
  defaultAdminContextFromServer,
  false,
)

const AC = React.createContext<AdminContext>(defaultAdminContext)

export const AdminContextProvider = ({
  context,
  global,
  children,
}: {
  context: AdminContextFromServer
  global: boolean
  children: React.ReactNode
}) => {
  const value = React.useMemo(
    () => fromServer(context, global),
    [context, global],
  )
  return <AC.Provider value={value}>{children}</AC.Provider>
}

export const useAdminContext = () => React.useContext(AC)

export const useHack = (s: Hack) => !!useAdminContext().hacks[s]
