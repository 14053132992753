import {Text} from 'quickstart/components/content/Text'
import {space, useTheme} from 'quickstart/theme'
import {ComponentProps} from 'react'

export const ErrorBox = (props: ComponentProps<typeof Text>) => {
  const theme = useTheme()
  return (
    <Text
      {...props}
      style={{
        padding: space.md,
        border: theme.borders.std,
        borderColor: theme.colors.borderError,
        backgroundColor: theme.colors.backgroundError,
        color: theme.colors.textError,
        ...props.style,
      }}
    />
  )
}
