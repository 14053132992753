import {createGlobalStyle} from 'quickstart/styled-components/system'
import {customerStyles} from './html'

/*
 * Currently this works by rendering <GlobalStyle /> in ThemeBlock. Therefore it
 * only applies to site render via renderBlocks, and does not apply to the Tizra
 * admin. In Storybook, ThemeBlock is rendered by blockDecorator or
 * componentDecorator.
 *
 * In the future we want to switch to Linaria which generates CSS at build-time,
 * rather than styled-components which generates dynamically at run-time. In
 * preparation for that, we need to protect the Tizra admin from any resets we
 * apply here.
 *
 * PRODUCTION:
 * - site render:
 *   - ThemeBlock renders
 *   - scoped to <body id="t-my">
 * - admin dialogs:
 *   - ThemeBlock doesn't render
 *   - tizra.css applies globally
 *
 * STORYBOOK:
 * - admin stories:
 *   - ThemeBlock doesn't render
 *   - <AdminStory /> applies raw tizra.css globally
 *   - consider moving to adminDecorator
 *   - additionally protected because none of the selectors match
 * - component stories:
 *   - ThemeBlock renders
 *   - scoped to .component-story-body (and .dialog-backdrop)
 *   - admin dialogs scope tizra.css to .tizra-admin-portal
 * - block stories:
 *   - ThemeBlock renders
 *   - scoped to .block-story-body (and .dialog-backdrop)
 *   - admin dialogs scope tizra.css to .tizra-admin-portal
 */

export const GlobalStyle = createGlobalStyle`
  :where(#t-my, #eg-portal-root), .eg-admin.eg-admin {
    .customer-html {
      ${customerStyles};
    }
  }
`
