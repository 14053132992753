import {
  NavLinkLinkPropsWithType,
  NavLinkProps,
} from 'quickstart/components/layout/NavLink'
import {falsish} from 'quickstart/types'
import {ReactNode} from 'react'

const isEmptyReactNode = (x: ReactNode) =>
  falsish(x) || (typeof x === 'string' && !x.trim())

/**
 * Test if a link is empty for the purposes of NavTree in the admin, in other
 * words neither the label nor the href is set. This is different from testing
 * for display, where an empty label or missing href/onClick should cause the
 * item to be omittied.
 */
export const isEmptyLink = (
  item: NavLinkProps,
): item is NavLinkLinkPropsWithType =>
  item.type === 'link' && isEmptyReactNode(item.label) && !item.href
