import {createAction, createReducer, createSelector} from '@reduxjs/toolkit'
import {BlockInfo} from './types'

interface Info extends BlockInfo<any, any, any> {
  sortKey: number
}

export const actions = {
  enter: createAction('enter', (id: string, info: Info) => ({
    payload: {id, info},
  })),
  exit: createAction('exit', (id: string) => ({payload: {id}})),
}

const initialState: {
  infos: Record<string, Info>
} = {
  infos: {},
}

const createRootReducer = () =>
  createReducer(initialState, builder =>
    builder
      .addCase(actions.enter, (state, {payload: {id, info}}) => ({
        ...state,
        infos: {
          ...state.infos,
          [id]: info,
        },
      }))
      .addCase(actions.exit, (state, {payload: {id}}) => {
        const {
          infos: {[id]: _drop, ...infos},
        } = state
        return {
          ...state,
          infos,
        }
      }),
  )

type State = ReturnType<ReturnType<typeof createRootReducer>>

const getInfos = (state: State) => state.infos

const getPrevInfos = createSelector([getInfos], infos => {
  let prev: State['infos'][string] | undefined
  return Object.entries(infos)
    .sort(([, {sortKey: a}], [, {sortKey: b}]) =>
      a === b ? 0
      : a < b ? -1
      : 1,
    )
    .reduce(
      (prevInfos, [k, info]) => {
        if (prev) {
          prevInfos[k] = prev
        }
        prev = info
        return prevInfos
      },
      {} as State['infos'],
    )
})

export const selectors = {
  getInfos,
  getPrevInfos,
}

export default createRootReducer
