import {Box} from 'quickstart/components/layout/Box'
import styled, {css} from 'quickstart/styled-components/system'
import {CSSProperties} from 'react'

export const RowContainer = styled(Box)<{
  align?: CSSProperties['alignItems']
  justify?: CSSProperties['justifyContent']
}>(
  ({align, justify}) => css`
    display: flex;
    align-items: ${align || 'center'};
    justify-content: ${justify || 'flex-start'};
  `,
)
