import * as A from '../admin'

export interface Config {
  heading: string
  showPageNums: boolean
  style: 'lined' | 'bars' | 'plain'
  labelFreeContent: boolean
}

export const defaultConfig: Config = {
  heading: 'Table of Contents',
  showPageNums: true,
  style: 'lined',
  labelFreeContent: false,
}

export const TocAdmin: A.Admin<Config> = () => {
  return (
    <>
      <A.Group title="Presentation">
        <A.Input label="Heading" name="heading" />
        <A.Radio
          label="Style"
          name="style"
          options={A.options({
            lined: 'Lined',
            bars: 'Bars',
            plain: 'Plain',
          })}
        />
        <A.YesNo label="Show page numbers?" name="showPageNums" />
        <A.YesNo label="Label free content?" name="labelFreeContent" />
      </A.Group>
    </>
  )
}
