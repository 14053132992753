import {deepMerge} from 'tizra'

export interface ThemeBlockGlobalConfig {
  buttonColor: string
  linkColor: string
  logoUrl: string
  logoLink: string
  bodyFont: 'sans' | 'serif'
  headingFont: 'sans' | 'serif'
}

export const defaultGlobalConfig: ThemeBlockGlobalConfig = {
  buttonColor: '',
  linkColor: '',
  logoUrl: '/aa_shared/quickstart/assets/logo.png',
  logoLink: '/',
  bodyFont: 'sans',
  headingFont: 'sans',
}

// This is special! Instead of A.GlobalMigrate<ThemeBlockGlobalConfig>
// we require only a subset of props. The result is type-compatible but makes it
// easier to implement createBrandedTheme() which needs to run sometimes where
// it's hard to access ThemeBlock.info.globalMigrate (the enhanced wrapper)
// because of circular imports.
export const globalMigrate = ({
  globalConfig: unknownGlobalConfig,
}: {
  globalConfig: unknown
}): ThemeBlockGlobalConfig => {
  const globalConfig = {
    ...(unknownGlobalConfig as any),
  } as Partial<ThemeBlockGlobalConfig>
  return deepMerge(defaultGlobalConfig)(globalConfig)
}
