import {FilesList} from 'quickstart/components/content/FilesList'
import {useApi} from 'quickstart/hooks'
import {MetaProps, partitionMetaProps} from 'quickstart/utils'
import * as R from 'rambdax'
import {ReactElement, ReactNode} from 'react'
import {Attachment} from 'tizra'

interface UseAttachmentsListProps extends MetaProps {
  excludedSources?: string[]
  filterByUserLicenses?: boolean
  heading?: ReactNode
  iconSize?: 'auto' | 'small' | 'medium' | 'large'
  iconsEnabled?: boolean
}

const isVisible = (x: Attachment) => x.props.isVisible !== false

const includeDirectly = (x: Attachment) => !!x.props.includeDirectly

export const useAttachmentsList = (props: UseAttachmentsListProps) => {
  const [
    metaProps,
    {excludedSources = [], filterByUserLicenses = false, ...rest},
  ] = partitionMetaProps(props)
  const {metaObj, tizraId = metaObj?.tizraId} = metaProps

  const notExcluded = (x: Attachment) => !excludedSources.includes(x.sourceName)

  const pred = R.allPass([
    isVisible,
    R.complement(includeDirectly),
    notExcluded,
  ])

  const attachments = useApi
    .attachments(
      tizraId && {
        tizraId,
        filterLicenses: filterByUserLicenses,
      },
    )
    .data?.result?.filter(pred)

  if (!attachments?.length) {
    return null
  }

  return <FilesList items={attachments} {...rest} />
}

type AttachmentsListRender = (
  content: ReturnType<typeof useAttachmentsList>,
) => ReactElement

interface AttachmentsListProps extends UseAttachmentsListProps {
  children?: AttachmentsListRender
  render?: AttachmentsListRender
}

export const AttachmentsList = ({
  children,
  render = children,
  ...rest
}: AttachmentsListProps) => {
  const content = useAttachmentsList(rest)
  return render ? render(content) : content
}
