/**
 * Customer HTML can appear in two places in the HTML hierarchy:
 *
 *  1. Outside Quickstart blocks, where it should receive reasonable styling.
 *     (This is currently not happening.)
 *
 *  2. Inside Quickstart blocks where customers supply HTML or Markdown, using
 *     the customer-html class. For example, see MarkdownBlock
 */
import {css, th} from 'quickstart/styled-components/system'

// Just the stuff that hasn't migrated yet to Linaria. Rather than continue to
// proliferate in linaria/global .customer-html, this should go into the
// HtmlProse component.
export const customerStyles = css`
  /* :where() to avoid pseudo-classes increasing specificity */
  button {
    ${th('buttons.base')};
    ${th('buttons.sizes.lg')};
    &:where(:first-of-type) {
      ${th('buttons.primary')};
      &:where(:focus) {
        ${th('buttons.focus.primary')};
      }
      &:where(:hover) {
        ${th('buttons.hover.primary')};
      }
    }
    &:where(:not(:first-of-type)) {
      ${th('buttons.secondary')};
      &:where(:focus) {
        ${th('buttons.focus.secondary')};
      }
      &:where(:hover) {
        ${th('buttons.hover.secondary')};
      }
    }
    &:where(:disabled) {
      ${th('buttons.disabled')};
      cursor: not-allowed;
    }
  }

  a {
    ${th('links.default')};
  }
`
