import {SlotName} from 'quickstart/components/tizra/MetaTile/common'
import * as R from 'rambdax'
import {deepMerge, logger, semiJoin, semiSplit} from 'tizra'
import * as A from '../admin'

const log = logger('BrowseBlock/admin')

interface Config3 extends A.BrowseSpec {
  VERSION: 3
}

export type Config = Config3

export const defaultConfig3: Config3 = {
  ...A.defaultBrowseSpec,
  filterMode: 'auto',
  VERSION: 3,
}

export const defaultConfig: Config = defaultConfig3

type Slots2 = 'cover' | 'label' | 'title' | 'meta' | 'snippet' | 'cta' | 'link'

// This is the configuration from the unstable releases deployed to ASNT.
interface Config2 {
  heading: string // for inclusion on the Content tab
  metaTypes: string[]
  filterCollectionId: string
  requiredAdminTags: string
  excludedAdminTags: string
  maxItems: string // so it can be blank
  sortDirection: 'ascending' | 'descending'
  sortProp: string
  display:
    | 'cards'
    | 'covers'
    | 'digests'
    | 'images'
    | 'headline'
    | 'titles'
    | 'tiles'
  size: 'small' | 'medium' | 'large'
  slots: {[k in Slots2]: {prop: string; fallback: boolean}}
  background: string
  foreground: string
  filterMode: 'auto' | 'custom' | 'disabled'
  filterPropPrefix: string
  filters: Array<{allOptionLabel: string; prop: string; reverse: boolean}>
  customBrowseConfigJson: string
}

export const defaultConfig2: Config2 = {
  heading: '',
  metaTypes: ['Book', 'Video'],
  sortProp: 'Title',
  sortDirection: 'ascending',
  filterMode: 'disabled',
  filterPropPrefix: 'Browse',
  filterCollectionId: '',
  requiredAdminTags: '',
  excludedAdminTags: '',
  filters: [
    {prop: '', allOptionLabel: '', reverse: false},
    {prop: '', allOptionLabel: '', reverse: false},
  ],
  display: 'cards',
  slots: {
    cover: {prop: 'CardCoverImage', fallback: true},
    label: {prop: 'CardMediaType', fallback: true},
    title: {prop: 'CardTitle', fallback: true},
    meta: {prop: 'CardMeta', fallback: true},
    snippet: {prop: 'CardSnippet', fallback: true},
    cta: {prop: 'CardCTA', fallback: true},
    link: {prop: 'CardLink', fallback: true},
  },
  size: 'large',
  background: '',
  foreground: '',
  maxItems: '',
  customBrowseConfigJson: '',
}

const isConfig2 = (x: unknown): x is Config2 =>
  typeof x === 'object' && x !== null && !('VERSION' in x) && 'filters' in x

interface Config1 {
  heading: string
  metaTypes: string[]
  sortProp: string
  sortDirection: 'ascending' | 'descending'
  filterMode: 'auto' | 'custom'
  filterCollectionId: string
  filterPropName1: string
  allOptionLabel1: string
  filterPropSort1: 'ascending' | 'descending'
  filterPropName2: string
  allOptionLabel2: string
  filterPropSort2: 'ascending' | 'descending'
  display: 'cards' | 'covers' | 'images'
  maxItems: string
  customBrowseConfigJson: string
}

const isConfig1 = (x: unknown): x is Config1 =>
  typeof x === 'object' && x !== null && !('VERSION' in x) && !('filters' in x)

export const defaultConfig1: Config1 = {
  heading: '',
  metaTypes: ['Book', 'Video'],
  sortProp: 'Title',
  sortDirection: 'ascending',
  filterMode: 'auto',
  filterCollectionId: '',
  filterPropName1: '',
  allOptionLabel1: '',
  filterPropSort1: 'ascending',
  filterPropName2: '',
  allOptionLabel2: '',
  filterPropSort2: 'ascending',
  display: 'cards',
  maxItems: '',
  customBrowseConfigJson: '',
}

const migrate1to2: A.Migrate<Config2, Config1> = ({
  adminContext,
  context,
  config,
}) => {
  if (!isConfig1(config)) {
    log.error('migrate1to2 received invalid config1')
  }
  let {
    metaTypes,
    filterPropName1,
    allOptionLabel1,
    filterPropSort1,
    filterPropName2,
    allOptionLabel2,
    filterPropSort2,
    ...config1
  } = deepMerge(defaultConfig1)(config)

  // BrowseSpec changed filters to an array.
  const filters = [
    {
      prop: filterPropName1,
      allOptionLabel: allOptionLabel1,
      reverse: filterPropSort1 === 'descending',
    },
    {
      prop: filterPropName2,
      allOptionLabel: allOptionLabel2,
      reverse: filterPropSort2 === 'descending',
    },
  ]

  // BrowseBlock originally ignored metaTypes on collection home. Preserve
  // that behavior until an overriding config is saved in the admin.
  if (
    config1.filterCollectionId === '' &&
    (context || adminContext).metaType.includes('Collection')
  ) {
    metaTypes = ['AdminTagged']
  }

  return deepMerge(defaultConfig2)(config1, {filters, metaTypes})
}

const migrate2to3: A.Migrate<Config3, Config2> = ({config}) => {
  if (!isConfig2(config)) {
    log.error('migrate2to3 received invalid config2')
  }
  const {
    display: display2,
    slots: slots2,
    ...config2
  } = deepMerge(defaultConfig2)(config)

  // Display options shrink from
  // - covers -> tiles with cover/title/link
  // - images -> tiles with cover/link
  //
  // Slots change from {prop, fallback} to {prop, enabled}.
  // For some fallbacks, collapse into semi-colon-joined prop.
  const display: Config3['display'] =
    display2 === 'covers' || display2 === 'images' ? 'tiles' : display2

  const slots = R.map((slotConfig, _slotName) => {
    const slotName = _slotName as SlotName
    const {prop: prop2, fallback} =
      slots2[slotName as keyof typeof slots2] || {}
    if (prop2 === '' && !fallback) {
      return {...slotConfig, enabled: false}
    }

    // Same order as defaultSlots, and complete.
    switch (slotName) {
      case 'cover':
        return {prop: prop2, enabled: true}
      case 'label':
        return {
          prop: fallback ? semiJoin([...semiSplit(prop2), 'MediaType']) : prop2,
          enabled: display2 !== 'covers' && display2 !== 'images',
        }
      case 'title':
        return {
          prop: prop2,
          enabled: display2 !== 'images',
        }
      case 'meta':
        return {
          prop: fallback ? semiJoin([...semiSplit(prop2), 'Authors']) : prop2,
          enabled: display2 !== 'covers' && display2 !== 'images',
        }
      case 'snippet':
        return {
          prop:
            display2 === 'cards' && fallback ?
              semiJoin([...semiSplit(prop2), 'Authors'])
            : prop2,
          enabled: true,
        }
      case 'description':
        // This is the only new slot from v2 to v3 is description. Check if
        // customer had previously configured tiles with snippet, before we
        // switched to description without fallback.
        if (display2 === 'tiles') {
          const {prop: prop2, fallback} = slots2.snippet
          return {
            prop: fallback ? semiJoin([...semiSplit(prop2), 'Authors']) : prop2,
            enabled: true,
          }
        } else if (display === 'tiles') {
          return {...slotConfig, enabled: false}
        } else {
          return slotConfig
        }
      case 'cta':
        return {
          prop: prop2,
          enabled: display2 !== 'covers' && display2 !== 'images',
        }
      case 'link':
        return {prop: prop2, enabled: true}
      default:
        break
    }
    const exhaustiveCheck: never = slotName
    log.error(`unhandled case: ${exhaustiveCheck}`)
    return slotConfig
  }, defaultConfig3.slots) as Config3['slots']

  return deepMerge(defaultConfig3)(config2, {
    display,
    slots,
    VERSION: 3,
  })
}

export const migrate: A.Migrate<Config> = ({
  config: unknownConfig,
  ...props
}) => {
  let config = {...(unknownConfig as any)}
  if (isConfig1(config)) {
    config = migrate1to2({...props, config})
  }
  if (isConfig2(config)) {
    config = migrate2to3({...props, config})
  }
  return deepMerge(defaultConfig)(config)
}

export const BrowseAdmin: A.Admin<Config> = () => {
  const tabs = A.useBrowseConfigTabs({
    include: {heading: true, filters: true},
  })
  return (
    <A.Tabs>
      {Object.entries(tabs).map(([key, {content, title}]) => (
        <A.Tab key={key} title={title}>
          {content}
        </A.Tab>
      ))}
    </A.Tabs>
  )
}
