import * as A from '../admin'

export const defaultConfig = {
  heading: '',
  match: {
    any: '',
    all: '',
    excluded: '',
  },
}

export const SubnavAdmin = ({values}) => {
  return (
    <>
      <A.Group title="Content">
        <A.Input label="Heading" name="heading" />
      </A.Group>
      <A.Group title="Collections to show">
        <A.Taggers name="match" />
      </A.Group>
    </>
  )
}
