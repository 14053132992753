import {themeGetter} from 'quickstart/styled-components/system'

const getModals = (theme: any) => {
  const th = themeGetter(theme)
  return {
    backdrop: {
      backgroundColor: th('colors.backgroundColorOverlay'),
      zIndex: 999,
    },
    default: {
      zIndex: 999,
      backgroundColor: th('colors.backgroundColorWhite'),
      borderRadius: th('radii.none'),
      border: 'none',
      // TODO should be single-direction shadow
      boxShadow: th('shadows.v2'),
    },
    footer: {
      borderTopColor: th('colors.borderColor'),
      borderTopStyle: 'solid',
      borderTopWidth: th('borderWidths.sm'),
      padding: `${th('space.lg')} ${th('space.xl')}`,
    },
    title: {
      backgroundColor: th('colors.backgroundColorMedium'),
      border: 'none',
      padding: `${th('space.lg')} ${th('space.xl')}`,
      textAlign: 'center',
    },
    content: {
      // TODO v2 is 48 by 64
      padding: theme.toRem(48),
      // matches padding-left on SessionErrorsModal Key
    },
    gutter: theme.toRem(32),
    sizes: {
      sm: {
        width: theme.toRem(480),
      },
      md: {
        width: theme.toRem(550),
      },
      lg: {
        width: theme.toRem(680),
      },
      xl: {
        width: theme.toRem(1200),
      },
      auto: {},
    },
    cover: {},
  }
}

export default getModals
