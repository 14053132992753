import * as A from '../admin'

export interface RedemptionBlockConfig extends A.BlockConfig {
  heading: string
  message: string
}

export const defaultConfig: RedemptionBlockConfig = {
  heading: 'Enter your access code',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export const RedemptionAdmin: A.Admin<RedemptionBlockConfig> = () => (
  <>
    <A.Group title="Content">
      <A.Input label="Heading" name="heading" />
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <p>
      Global redemption configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)

export interface RedemptionBlockGlobalConfig {
  url: string
}

export const defaultGlobalConfig: RedemptionBlockGlobalConfig = {
  url: '/add',
}

export const RedemptionGlobalAdmin: A.Admin<
  RedemptionBlockGlobalConfig
> = () => (
  <A.Group>
    <A.Input label="Path to static page for redemptions" name="url" />
  </A.Group>
)
