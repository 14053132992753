import {NavLinkProps} from 'quickstart/components/layout/NavLink'
import {ReactNode} from 'react'
import {semiJoin, semiSplit} from 'tizra'
import * as A from '../../base'

export interface InputProps {
  props: NavLinkProps
  update: (props: NavLinkProps) => void
}

export const MenuInput = ({props, update}: InputProps) => {
  if (props.type !== 'menu') {
    return <A.Box>ERROR</A.Box>
  }
  return (
    <input
      value={props.label || ''}
      placeholder="label"
      onChange={ev => update({...props, label: ev.target.value})}
    />
  )
}

export const LinkInput = ({props, update}: InputProps) => {
  if (props.type && props.type !== 'link') {
    return <A.Box>ERROR</A.Box>
  }
  return (
    <>
      <input
        value={`${props.label || ''}`}
        placeholder="label"
        onChange={ev => update({...props, label: ev.target.value})}
      />
      <input
        value={props.href || ''}
        placeholder="href"
        onChange={ev => update({...props, href: ev.target.value})}
      />
    </>
  )
}

export const SeparatorInput = () => (
  <>
    <hr style={{display: 'block', width: '80px', alignSelf: 'center'}} />
  </>
)

export const ExpandoInput = ({props, update}: InputProps) => {
  if (props.type !== 'expando') {
    return <A.Box>ERROR</A.Box>
  }
  return (
    <A.Column>
      <A.Row>
        <input
          value={props.adminTag || ''}
          placeholder="admin tag"
          onChange={ev => update({...props, adminTag: ev.target.value})}
        />
        <input
          defaultValue={semiJoin(props.metaTypes || [])}
          placeholder="meta-types"
          onChange={ev =>
            update({...props, metaTypes: semiSplit(ev.target.value)})
          }
        />
      </A.Row>
      <A.Row>
        <input
          value={props.sortProp || ''}
          placeholder="sort prop"
          onChange={ev => update({...props, sortProp: ev.target.value})}
        />
        <Select
          value={props.sortDirection || 'ascending'}
          options={[{value: 'ascending'}, {value: 'descending'}]}
          onChange={v => update({...props, sortDirection: v as any})}
        />
      </A.Row>
    </A.Column>
  )
}

export const BookshelfInput = ({props, update}: InputProps) => {
  if (props.type !== 'bookshelf') {
    return <A.Box>ERROR</A.Box>
  }
  return (
    <A.Column>
      <A.Row>
        <input
          value={`${props.label || ''}`}
          placeholder="label"
          onChange={ev => update({...props, label: ev.target.value})}
        />
      </A.Row>
      <A.Hint>
        Use AUTO for global bookshelf title configured in Features tab.
      </A.Hint>
    </A.Column>
  )
}

export const AccountInput = ({props, update}: InputProps) => {
  if (props.type !== 'account') {
    return <A.Box>ERROR</A.Box>
  }
  return (
    <A.Column>
      <A.Row>
        <input
          value={`${props.label || ''}`}
          placeholder="label"
          onChange={ev => update({...props, label: ev.target.value})}
        />
      </A.Row>
    </A.Column>
  )
}

// This styling matches an admin input/select.
const adminInputStyle = {
  border: '1px solid #c3c3c3',
  borderTop: '1px solid #7c7c7c',
  padding: '2px 2px 2px 2px',
  height: '13.5px', // for when there's no inner text
  fontFamily: 'verdana, sans-serif',
  fontSize: '100%',
}

const Select = ({
  options,
  value,
  onChange,
  placeholder,
}: {
  options: Array<{value: string; label?: ReactNode; shortLabel?: ReactNode}>
  value: string
  onChange: (value: string) => void
  placeholder?: string
}) => {
  const found = options.find(o => o.value === value)
  return (
    <A.Dropdown
      options={options.map(o => ({
        label: (
          <A.Row>
            <A.Box opacity={o.value === value ? 1 : 0}>
              <A.Icon.Check />
            </A.Box>
            <A.Box>{o.label || o.value}</A.Box>
          </A.Row>
        ),
        ...o,
      }))}
      onChoose={onChange}
    >
      <div style={adminInputStyle}>
        <A.Row alignItems="center">
          <A.Box>
            {(found && (found.shortLabel || found.label || found.value)) ||
              placeholder}
          </A.Box>
          <A.Icon.ChevronDown />
        </A.Row>
      </div>
    </A.Dropdown>
  )
}

const icons = {
  account: <A.Icon.User />,
  bookshelf: <A.Icon.Bookshelf />,
  expando: <A.Icon.Cpu />,
  link: <A.Icon.Link />,
  menu: <A.Icon.Menu />,
  separator: <A.Icon.Separator />,
}

export const Switcher = ({
  options: _options,
  value,
  onChange,
  onRemove,
}: {
  options: Exclude<NavLinkProps['type'], undefined>[]
  value: Exclude<NavLinkProps['type'], undefined>
  onChange: (value: NavLinkProps['type']) => void
  onRemove?: () => void
}) => {
  const options: Array<{value: string; label?: ReactNode}> = _options
    .filter(v => v !== value)
    .map(value => ({
      value,
      label: (
        <A.Row>
          {icons[value]}Switch to {value}
        </A.Row>
      ),
    }))
  if (onRemove) {
    if (options.length) {
      options.push({value: '---'})
    }
    options.push({
      value: 'remove',
      label: (
        <A.Row>
          <A.Icon.Trash />
          Remove this {value}
        </A.Row>
      ),
    })
  }
  const trigger = (
    <div style={adminInputStyle}>
      <A.Row alignItems="center">
        {icons[value || 'link']}
        <A.Icon.ChevronDown />
      </A.Row>
    </div>
  )
  return options.length ?
      <A.Dropdown
        options={options}
        onChoose={value =>
          value === 'remove' ? onRemove?.() : onChange(value as any)
        }
      >
        {trigger}
      </A.Dropdown>
    : <div style={{color: '#7c7c7c', cursor: 'not-allowed'}}>{trigger}</div>
}
