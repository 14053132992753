import {themeGetter} from 'quickstart/styled-components/system'

const getDropdownMenus = (theme: any) => {
  const th = themeGetter(theme)
  return {
    default: {
      menu: {
        backgroundColor: th('colors.backgroundColorWhite'),
        border: th('borders.std'),
      },
      item: {
        backgroundColor: 'transparent',
        color: th('colors.textColor'),
        ...th('ui.nav'),
        '&:hover, &:focus, &[data-active-item]': {
          backgroundColor: th('colors.backgroundColorLight'),
          opacity: 1, // override Link opacity dimming
        },
        '&[disabled]': {
          backgroundColor: 'transparent',
          color: th('colors.buttonDisabled'),
          cursor: 'not-allowed',
        },
      },
      separator: {
        backgroundColor: th('colors.backgroundColorLight'),
        border: 'none',
        borderTop: th('borders.fine'),
        padding: 0,
      },
    },
  }
}

export default getDropdownMenus
