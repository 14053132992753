import styled, {
  createParser,
  system,
  SystemProps,
} from 'quickstart/styled-components/system'

interface BoxProps {
  row?: boolean
  col?: true | 'auto' | number | string
}

export const Box = styled.div<SystemProps & BoxProps>(
  createParser({
    // @ts-expect-error
    row: v =>
      v && {
        boxSizing: 'border-box',
        flexGrow: 1,
        flexWrap: 'wrap',
        display: 'flex',
      },

    // @ts-expect-error
    col: v => {
      if (v === true) {
        return {
          flexBasis: 0,
          flexGrow: 1,
          maxWidth: '100%',
        }
      }

      if (v === 'auto') {
        return {
          flex: '0 0 auto',
          maxWidth: 'none',
          width: 'auto',
        }
      }

      const width =
        typeof v === 'number' && v > 0 && v <= 1 ? `${100 * v}%`
        : typeof v === 'number' ? `${v}px`
        : v

      return {
        flex: `0 0 ${width}`,
        maxWidth: width,
      }
    },
  }),
  system,
)
