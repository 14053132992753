import {Tag} from 'quickstart/components/controls/Tag'
import {UseSearchReturn} from 'quickstart/hooks'
import {formFields} from 'quickstart/lib/search/config'
import {summaryList} from 'quickstart/lib/search/summary'
import * as R from 'rambdax'
import {logger} from 'tizra'

const log = logger('SearchTags')

interface SearchTagsProps {
  search: UseSearchReturn
}

export const SearchTags = ({
  search: {config, params, update},
}: SearchTagsProps) => {
  const fs = formFields(config, 'filterTags')
  const tags = R.piped(
    fs,
    R.chain(f => summaryList(f, params[f.name])),
    R.map(({key, label, name, removed, summary}) => (
      <Tag key={key} onClick={() => update({[name]: removed})}>
        {label && `${label}: `}
        {summary}
      </Tag>
    )),
  )

  return tags.length === 0 ?
      null
    : <Tag.Group title="Currently filtering by:">{tags}</Tag.Group>
}
