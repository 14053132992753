import styled, {
  css,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {centerContent, fieldStyles, overflowEllipsis} from 'quickstart/utils'

export type Size = 'sm' | 'md' | 'lg'

export const Wrapper = styled.div<SystemProps>`
  position: relative;
  ${system};
`

export const InputWrapper = styled.div`
  position: relative;
`

/* TODO This doesn't work because fields.sizes.lg.height is undefined
    ${hasIcon &&
    css`
      padding-left: ${th(`fields.sizes.${size}.height`)};
    `};
    */
export const Input = styled.input<{hasIcon?: boolean; size?: Size}>(
  ({hasIcon, size}) => css`
    position: relative;
    ${fieldStyles};
    ${overflowEllipsis};

    br {
      display: none;
    }
    ::placeholder {
      color: ${th('colors.textColor')};
    }
  `,
)

export const Hint = styled.div<{size?: Size}>(
  ({size}) => css`
    ${th('fields.hint.base')};
    ${th('fields.hint.default')};
    padding: ${size && th(`fields.sizes.${size}.padding`)};
  `,
)

export const Menu = styled.ul`
  ${th('fields.select.default')};
  /* start welcome-ui cardStyles */
  background-color: ${th('colors.backgroundColorWhite')};
  color: ${th('colors.textColor')};
  border-radius: ${th('radii.none')};
  border-style: solid;
  border-width: ${th('borderWidths.md')};
  border-color: ${th('colors.borderColor')};
  /* end welcome-ui cardStyles */
  border-top: none;
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  transition: ${th('transitions.medium')};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const Item = styled.li<{
  isExisting?: boolean
  isHighlighted?: boolean
  isSelected?: boolean
}>(
  ({isExisting, isHighlighted, isSelected}) => css`
    ${isHighlighted && th('fields.select.highlighted')};
    ${isSelected && th('fields.select.selected')};
    ${isExisting && th('fields.select.existing')};
    ${isSelected &&
    isHighlighted &&
    th('fields.select.selectedAndHighlighted')};
    ${th('ui.form')};
    ${overflowEllipsis};
    padding: ${th('space.sm')};
    list-style: none;
    text-decoration: none;
    transition: background ${th('transitions.medium')};
  `,
)

/* TODO this is undefined
    width: ${th(`fields.sizes.${size}.height`)};
    */
export const Icon = styled.div<{size?: Size}>(
  ({size}) => css`
    position: absolute;
    padding: 0;
    top: 0;
    bottom: 0;
    left: 0;
    ${centerContent}
  `,
)

export const Indicators = styled.div`
  position: absolute;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
`

/* TODO undefined
    width: ${th(`fields.sizes.${size}.height`)};
    */
export const DropDownIndicator = styled.button.attrs<{
  isOpen?: boolean
  size?: Size
}>(({as = 'button'}) => ({...(as === 'button' && {type: 'button'})}))(
  ({isOpen, size}) => css`
    position: relative;
    height: 100%;
    padding: 0;
    outline: none !important; /* important for firefox */
    appearance: none;
    cursor: pointer;
    border: none;
    background: transparent;
    ${centerContent};

    ${Icon} > * {
      transform: ${isOpen ? 'rotate3d(0, 0, 1, 180deg)' : 'rotate3d(0)'};
      transition: ${th('transitions.medium')};
    }

    &:not(:last-child) {
      width: auto;
    }
  `,
)
