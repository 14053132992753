import * as R from 'rambdax'
import {useRef, useState} from 'react'
import * as B from '../block'
import {Actions} from './Actions'
import {Cover} from './Cover'
import {Message} from './Message'
import {Title} from './Title'
import {Config} from './admin'
import {logger} from 'tizra'

export const log = logger('PublicationHeader')

export const PublicationHeader = ({
  alignment,
  callsToAction,
  layout,
  headingProp,
  subHeadingProp,
  otherProps,
  message,
  v1,
}: Config) => {
  const {pageId} = B.useBlockContext()
  const metaObj = B.useMetaObj(true)
  const [imageErrored, setImageErrored] = useState(false)
  const isMobile = B.useMobile()
  const forceOneColumn = isMobile || imageErrored

  // The admin form tries to avoid double-selecting props between headingProp,
  // subHeadingProp and otherProps, but sometimes values can remain selected
  // even when they were removed from the list of options.
  otherProps = R.reject(
    R.either(R.equals(headingProp), R.equals(subHeadingProp)),
    otherProps,
  )

  if (forceOneColumn) {
    layout =
      layout === 'left' ? 'above'
      : layout === 'right' ? 'below'
      : layout
  }

  const singleColumn = ['above', 'below', 'none'].includes(layout)

  log.debug?.({
    forceOneColumn,
    isMobile,
    imageErrored,
    layout,
    metaObj,
    pageId,
    singleColumn,
  })

  const onError = useRef(() => setImageErrored(true)).current
  const onLoad = useRef(() => setImageErrored(false)).current

  return (
    <B.Section>
      <B.Grid
        inset={!singleColumn}
        cols={singleColumn ? 1 : 2}
        colGap="4rem"
        rowGap={{xs: '1.25rem', sm: '1.5rem', md: '2rem', lg: '2.75rem'}}
        reversed={layout === 'above' || layout === 'left'}
      >
        {layout !== 'none' && (
          <Cover
            metaObj={metaObj}
            alignment={alignment}
            singleColumn={singleColumn}
            onError={onError}
            onLoad={onLoad}
          />
        )}
        <B.Stack
          spacing="xxl"
          wrapChildren={false}
          style={{justifyContent: 'center'}}
        >
          <Title
            metaObj={metaObj}
            alignment={alignment}
            headingProp={headingProp}
            otherProps={otherProps}
            subHeadingProp={subHeadingProp}
          />
          <Actions
            alignment={alignment}
            metaObj={metaObj}
            pageId={pageId}
            callsToAction={callsToAction}
            v1={v1}
          />
          <Message
            metaObj={metaObj}
            pageId={pageId}
            alignment={alignment}
            callsToAction={callsToAction}
            message={message}
            v1={v1}
          />
        </B.Stack>
      </B.Grid>
    </B.Section>
  )
}
