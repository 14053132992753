import {FormErrors, logger} from 'tizra'

const log = logger('Auth/remoteLogin')

export const remoteLogin = (
  values: any,
): Promise<FormErrors & {status: number}> =>
  window
    .fetch('/login/remoteAuthenticationAgent', {
      method: 'POST',
      headers: {
        // accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(values).toString(),
    })
    .then(r => {
      if (r.ok && r.redirected && r.url) {
        const err = new URL(r.url).searchParams.get('error') || '0'
        switch (err) {
          case '0': // AuthenticationConstants/LOGIN_SUCCESS
            return {status: 200}
          case '7': // AuthenticationConstants/LOGIN_FAILED_ACCOUNT_PENDING
            return {status: 403, reason: 'pending'}
          case '8': // AuthenticationConstants/LOGIN_FAILED_ACCOUNT_CANCELED
            return {status: 403, reason: 'canceled'}
          default:
            return {status: 403, reason: 'invalid'}
        }
      }
      log.warn('unexpected response', r)
      return {
        status: r.status,
        reason: 'unknown',
        message: 'Unknown error',
      }
    })
    .catch(() => ({
      status: 0,
      reason: 'network',
      message: 'Network error',
    }))
