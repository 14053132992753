import {Text} from 'quickstart/components/content/Text'
import {ClearButton} from 'quickstart/components/controls/ClearButton'
import {Box} from 'quickstart/components/layout/Box'
import {Shape} from 'quickstart/components/layout/Shape'
import styled, {css, system, th} from 'quickstart/styled-components/system'

export const Backdrop = styled.div`
  ${th('modals.backdrop')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 150ms ease-in-out;

  /* on open dialog for animation */
  &[data-enter] {
    opacity: 1;
  }
`

export const Close = styled(ClearButton)`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;

  padding: ${th('space.lg')};
  height: calc(25px + (${th('space.lg')} * 2));
  width: calc(25px + (${th('space.lg')} * 2));

  &:focus {
    box-shadow: none;
  }
`

export const Dialog = styled.div<{$size?: 'sm' | 'md' | 'lg' | 'xl' | 'auto'}>(
  ({$size, theme}) => css`
    ${th('modals.default')};
    ${th(`modals.sizes.${$size || 'auto'}`)};
    position: relative;
    display: flex;
    flex-direction: column;
    opacity: 0;
    max-height: calc(100% - ${theme.toRem(20)});
    max-width: calc(100% - ${theme.toRem(20)});
    transition: opacity 250ms ease-in-out;

    &:focus {
      outline: none !important; /* important for firefox */
    }

    /* on open dialog for animation */
    &[data-enter] {
      opacity: 1;
    }
  `,
)

export const Content = styled(Box)`
  ${th('modals.content')};
  flex: 1;
  overflow-y: auto;
`

export const Cover = styled(Shape)`
  ${th('modals.cover')};
`

export const Title = styled(Text).attrs(props => ({
  variant: 'modalHead',
  ...props,
}))`
  flex: 0 0 auto; /* don't grow or shrink */
  ${th('modals.title')};
  ${system};
`

export const Footer = styled(Box)`
  ${th('modals.footer')};
  width: 100%;
`
