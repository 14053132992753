import * as B from '../block'
import {Admin, defaultConfig, defaultGlobalConfig, GlobalAdmin} from './admin'
import {Cart} from './Cart'
import {Checkout} from './Checkout'

export const CartBlock = B.blockWithInfo(
  {
    name: 'cart',
    displayName: 'CartBlock',
    title: 'Cart',
    Admin,
    GlobalAdmin,
    defaultConfig,
    defaultGlobalConfig,
  },
  ({config}) => {
    const {hash} = B.useLocation()
    return hash === '#checkout' ?
        <Checkout config={config} />
      : <Cart config={config} />
  },
)
