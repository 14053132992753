import {Toc} from 'quickstart/components/content/Toc'
import {useApi} from 'quickstart/hooks'
import * as R from 'rambdax'
import {ComponentProps, useMemo} from 'react'
import {Excerpt, Falsy, MetaObject, TocEntry, logger} from 'tizra'

const log = logger('MetaToc')

interface UseMetaTocProps extends Omit<ComponentProps<typeof Toc>, 'entries'> {
  metaObj?: MetaObject
}

/**
 * Add lastPageNumber to each entry.
 */
const addLastPages = (
  entries: TocEntry[],
): Array<TocEntry & {lastPageNumber: number}> => {
  if (!entries.length) return []
  const maxLevel = entries.reduce((a, {level: b}) => Math.max(a, b), 0)
  const seen = Array(maxLevel + 1).fill(entries[entries.length - 1].pageNumber)
  return [...entries] // so .reverse() doesn't mutate
    .reverse()
    .map(entry => {
      const lastPageNumber = seen[entry.level]
      seen.fill(entry.pageNumber, entry.level)
      return {...entry, lastPageNumber}
    })
    .reverse()
}

export const useTocWithLastPages = (tizraId: string | Falsy) => {
  const entries = useApi.toc(tizraId && {tizraId}).data
  return useMemo(
    () => entries && log.pipe('useTocWithLastPages')(addLastPages(entries)),
    [entries],
  )
}

export const useFreeExcerptsByStartingPage = (tizraId: string | Falsy) => {
  const excerpts = useApi.excerpts(
    tizraId && {tizraId, fields: ['is-free', 'pages']},
  ).data?.result
  return useMemo(
    () =>
      excerpts &&
      log.pipe('useFreeExcerptsByStartingPage')(
        excerpts
          .filter(x => x.isFree)
          .reduce(
            (xss, {name, tizraId, pages}) => {
              const xs = (xss[pages[0]] ||= [])
              xs.push({name, tizraId, pages})
              return xss
            },
            [] as Pick<Excerpt, 'name' | 'tizraId' | 'pages'>[][],
          ),
      ),
    [excerpts],
  )
}

export const useEnhancedToc = ({
  labelFreeContent,
  metaObj,
}: {
  labelFreeContent: boolean
  metaObj?: MetaObject
}) => {
  const entries = useTocWithLastPages(metaObj?.tizraId)
  const _freeExcerpts = useFreeExcerptsByStartingPage(
    labelFreeContent && metaObj?.tizraId,
  )
  return useMemo(() => {
    const freeExcerpts = _freeExcerpts?.slice() // clone sparse array
    return (
      entries &&
      R.piped(
        entries,
        R.filter(entry => entry.isDisplayed),
        R.map(entry => {
          const isFree = freeExcerpts?.[entry.pageNumber]?.some(
            x => x.pages[1] >= entry.lastPageNumber - 1,
          )
          if (isFree) {
            delete freeExcerpts![entry.pageNumber]
          }
          return {
            ...entry,
            isFree,
            // Attach parent for the sake of meta.href linking to url-id.
            // https://github.com/Tizra/frontend/issues/303
            parent: metaObj,
          }
        }),
        log.pipe('useEnhancedToc'),
      )
    )
  }, [entries, _freeExcerpts, metaObj])
}

export const useMetaToc = ({
  labelFreeContent,
  metaObj,
  ...props
}: UseMetaTocProps) => {
  const entries = useEnhancedToc({labelFreeContent, metaObj})

  // The ToC needs more than one bookmark or it's bogus. This can happen
  // especially for publications that end up with a single bookmark representing
  // the entire document, for example:
  //
  //   [{title: "foo.pdf", level: 0, pageNumber: 1}]
  //
  // https://github.com/Tizra/quickstart-v3/issues/245
  if (!entries || entries.length < 2) {
    return null
  }

  return (
    <Toc entries={entries} labelFreeContent={labelFreeContent} {...props} />
  )
}
