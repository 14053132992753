import {ReactNode, Fragment, ComponentProps} from 'react'
import * as B from '../block'
import {Config} from './admin'
import {logger, meta, truthyArray} from 'tizra'
import {SearchBlock} from '../SearchBlock'
import {simpleTerm} from 'quickstart/lib/search/search-params'
import {URLish} from 'tizra/urlish'

export const log = logger('PublicationHeader')

const and = (items: ReactNode[]) =>
  items.map((item, i) => (
    <Fragment key={i}>
      {i === 0 ?
        null
      : items.length === 2 ?
        ' and '
      : i < items.length - 1 ?
        ', '
      : ', and '}
      {item}
    </Fragment>
  ))

const urlWithParams = (url: string, params: Record<string, string>) => {
  const u = new URLish(url)
  Object.entries(params).forEach(([k, v]) => u.searchParams.set(k, v))
  return u.toString()
}

type StrategizedSearchLinkProps = ComponentProps<typeof B.Link> & {
  prop: string
  value: string
}

const StrategizedSearchLink = ({
  prop,
  value,
  ...props
}: StrategizedSearchLinkProps) => {
  const {config} = B.useGlobalSearchConfig(B.useGlobalConfig(SearchBlock), {
    doPromises: false,
  })
  const [_mapParamsIn, mapParamsOut] = B.useSearchParamsMap(config)
  const field = prop && Object.values(config.fields).find(f => f.prop === prop)
  const param = field && mapParamsOut[field.name]
  return param ?
      <B.Link
        href={urlWithParams('/~searchResults', {[param]: value})}
        {...props}
      />
    : props.children
}

type StrategizedBrowseLinkProps = ComponentProps<typeof B.Link> & {
  value: string
  browse: {url: string; param: string}
}

const StrategizedBrowseLink = ({
  browse: {url, param},
  value,
  ...props
}: StrategizedBrowseLinkProps) => {
  return url && param ?
      <B.Link href={urlWithParams(url, {[param]: value})} {...props} />
    : props.children
}

type StrategizedObjectLinkProps = ComponentProps<typeof B.Link> & {
  value: string
  object: {metaType: string; prop: string}
}

const StrategizedObjectLink = ({
  object: {metaType, prop},
  value,
  ...props
}: StrategizedObjectLinkProps) => {
  const found = B.useApi.search(
    metaType &&
      prop && {
        all: [simpleTerm([metaType], prop, value)],
        page: 1,
      },
  ).data?.result[0]
  return found ? <B.Link href={meta.href(found)} {...props} /> : props.children
}

type StrategizedLinkProps = ComponentProps<typeof B.Link> & {
  prop: string
  value: string
} & (
    | {strategy: 'none' | 'search'}
    | {strategy: 'browse'; browse: {url: string; param: string}}
    | {strategy: 'object'; object: {metaType: string; prop: string}}
  )

const StrategizedLink = (props: StrategizedLinkProps) => {
  switch (props.strategy) {
    case 'search':
      return <StrategizedSearchLink {...props} />
    case 'browse':
      return <StrategizedBrowseLink {...props} />
    case 'object':
      return <StrategizedObjectLink {...props} />
    default:
      if (props.strategy !== 'none') {
        const exhaustiveCheck: never = props.strategy
        log.error(`unhandled case: ${exhaustiveCheck}`)
      }
      return props.children
  }
}

const ByLine = ({
  values,
  config,
}: {
  values: string[]
  config: Config['post']
}) => {
  const linked = values.map((value, i) => (
    <StrategizedLink value={value} variant="ui" key={i} {...config.authors}>
      {value}
    </StrategizedLink>
  ))
  return linked.length ? <B.Text italic>by {and(linked)}</B.Text> : null
}

const TagsLine = ({
  values,
  config,
}: {
  values: string[]
  config: Config['post']
}) => {
  // TODO: change this to as={UniversalLink} somehow so that we don't get an
  // extra wrapping element that causes a vertical shift.
  const linked = values.map((value, i) => (
    <StrategizedLink value={value} variant="unstyled" key={i} {...config.tags}>
      <B.Tag variant="pill">{value}</B.Tag>
    </StrategizedLink>
  ))
  return linked.length ? <B.Tag.Group>{linked}</B.Tag.Group> : null
}

const strArray = (x: any) => truthyArray(x).map(x => `${x}`)

export const PostHeader = (config: Config['post']) => {
  const metaObj = B.useMetaObj(true)
  const authors = strArray(meta.prop(metaObj, [config.authors.prop]))
  const tags = strArray(meta.prop(metaObj, [config.tags.prop]))

  return (
    <B.Section>
      <B.TextContainer variant="wider">
        <B.Stack wrapChildren={false}>
          <TagsLine values={tags} config={config} />
          <B.MetaValue prop={config.heading.prop} metaObj={metaObj}>
            {content => content && <B.H1>{content}</B.H1>}
          </B.MetaValue>
          <B.MetaValue
            prop={config.date.prop}
            metaObj={metaObj}
            date={{dateStyle: 'long'}}
          />
          <ByLine values={authors} config={config} />
        </B.Stack>
      </B.TextContainer>
    </B.Section>
  )
}
