import {
  PersistedClient,
  Persister,
  persistQueryClient,
} from '@tanstack/query-persist-client-core'
import * as idb from 'idb-keyval'
import {logger} from '../log'
import {GetQueryClientProps, getQueryClient} from './client'
import {gcTime} from './private'

const log = logger('tizra/react-query')

interface GetPersistentQueryClientProps extends GetQueryClientProps {
  buster?: string
}

export const getPersistentQueryClient = async ({
  buster,
  ...props
}: GetPersistentQueryClientProps) => {
  const queryClient = getQueryClient(props)

  // Conditionalized mainly for jsdom
  if (typeof window !== 'undefined' && window.indexedDB) {
    try {
      const [_unsubscribe, restorePromise] = persistQueryClient({
        queryClient,
        persister: createIDBPersister(),
        maxAge: gcTime, // per docs, these should match
        buster,
      })
      await restorePromise
    } catch (e) {
      log.error('failed to persist query client', e)
    }
  }

  return queryClient
}

/**
 * Create an Indexed DB persister.
 * @see https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient#building-a-persister
 */
function createIDBPersister(
  idbValidKey: IDBValidKey = 'reactQuery',
): Persister {
  return {
    persistClient: async (client: PersistedClient) => {
      await idb.set(idbValidKey, client)
    },
    restoreClient: async () => {
      return await idb.get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await idb.del(idbValidKey)
    },
  }
}
