import {AdminInnerWrapperProps} from 'quickstart/types'
import {Suspense, lazy, useEffect, useRef} from 'react'
import {logger} from 'tizra'
import {SetOptional} from 'type-fest'
import {AdminInnerWrapper} from '../../renderer/AdminWrapper.tsx'
import type {LexicalProps} from './lexical'

const log = logger('StandaloneMarkdownEditor')

const Lexical = lazy(() =>
  import('./lexical.tsx').then(d => ({default: d.Lexical})),
)

interface SuspenseReporterProps {
  onRender: () => void
}

const SuspenseReporter = ({onRender}: SuspenseReporterProps) => {
  const ref = useRef(false)
  useEffect(() => {
    if (!ref.current) {
      ref.current = true
      onRender()
    }
  }, [onRender])
  return null
}

export type StandaloneMarkdownEditorProps = LexicalProps &
  Partial<SuspenseReporterProps> &
  SetOptional<Omit<AdminInnerWrapperProps, 'children'>, 'global'>

export const StandaloneMarkdownEditor = ({
  context,
  global = true,
  onRender,
  queryClient,
  ...props
}: StandaloneMarkdownEditorProps) => {
  return (
    <Suspense fallback={null}>
      <AdminInnerWrapper
        context={context}
        queryClient={queryClient}
        global={true}
      >
        <Lexical reset={true} {...props} />
      </AdminInnerWrapper>
      {onRender && <SuspenseReporter onRender={onRender} />}
    </Suspense>
  )
}
