import {SideNavContent} from 'quickstart/components/layout/SideNav/styles'
import {breakpoints, withTheme} from 'quickstart/theme'
import {styled, theme} from 'quickstart/theme/linaria'

export const Divider = withTheme(styled.hr`
  margin: 0;
  border-top: 1px solid ${theme.colors.borderColor};
  @media (min-width: ${breakpoints.lg}) {
    ${SideNavContent} & {
      display: none;
    }
  }
`)
