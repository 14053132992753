import {Middleware, configureStore} from '@reduxjs/toolkit'
import {logger} from 'tizra'
import createRootReducer from './reducer'

const log = logger('store')

const loggerMiddleware: Middleware = _store => next => action =>
  next(log.tap(action))

export const createStore = () =>
  configureStore({
    reducer: createRootReducer(),
    middleware: [loggerMiddleware] as const,
  })
