import {themeGetter} from 'quickstart/styled-components/system'

const getListDefinitions = (theme: any) => {
  const th = themeGetter(theme)
  return {
    term: {
      fontWeight: th('fontWeights.bold'),
    },
    details: {},
  }
}

export default getListDefinitions
