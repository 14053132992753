import * as R from 'rambdax'
import {deepMerge, isObjectType} from 'tizra'
import {PartialDeep, SetRequired} from 'type-fest'
import * as A from '../admin'

interface V0 extends A.BlockConfig {
  alignment: 'left' | 'center' | 'right'
  heading: string
  message: string
}

interface V1 extends A.BlockConfig {
  VERSION: 1
  heading: string
  message: string
  justification: 'left' | 'center' | 'right'
  position: 'left' | 'center' | 'right'
}

interface V2 extends A.BlockConfig {
  VERSION: 2
  heading: string
  message: string
  justification: A.Just
  container: 'default' | 'wider' | 'none'
  background: A.BackgroundSpec
}

export const defaultV2: V2 = {
  VERSION: 2,
  heading: '',
  message: 'Hello World',
  container: 'default',
  justification: 'left',
  background: A.defaultBackgroundSpec,
  FREEMARKER: {
    ...A.backgroundSpecFREEMARKER('background'),
    message: 'markdown',
  },
}

export type Config = V2

export const defaultConfig = defaultV2

const posToCardinal = (pos?: 'left' | 'center' | 'right'): A.OneD =>
  pos === 'center' ? ''
  : pos === 'right' ? 'e'
  : 'w'

type PV<T extends {VERSION: any}> = SetRequired<PartialDeep<T>, 'VERSION'>

const defaultV1: V1 = {
  VERSION: 1,
  heading: '',
  message: '',
  justification: 'left',
  position: 'left',
}

const zeroToOne = <C extends PartialDeep<V0>>(config: C): V1 =>
  deepMerge(defaultV1)({
    heading: config.heading,
    message: config.message,
    justification: config.alignment,
    position: config.alignment,
  })

const oneToTwo = <C extends PV<V1>>(config: C): V2 =>
  deepMerge(defaultV2)({
    heading: config.heading,
    message: config.message,
    justification: config.justification,
    background: {
      foreground: {
        position: posToCardinal(config.position),
      },
    },
  })

const isV0 = (config: unknown): config is PartialDeep<V0> =>
  isObjectType(config) && !('VERSION' in config)

const isV1 = (config: unknown): config is PV<V1> =>
  (config as any)?.VERSION === 1

export const migrate: A.Migrate<
  Config,
  undefined | PartialDeep<V0> | PV<V1> | PV<V2>
> = ({config}) =>
  R.piped(
    config,
    config => (isV0(config) ? zeroToOne(config) : config),
    config =>
      isV1(config) ? oneToTwo(config) : deepMerge(defaultConfig)(config),
  )

export const MarkdownAdmin: A.Admin<Config> = () => {
  const tabs = A.useBackgroundConfigTabs({name: 'background'})
  const containerOptions = A.useOptions<Config['container']>(A.containerOptions)
  const justificationOptions = A.useOptions<Config['justification']>(
    A.justificationOptions,
  )
  return (
    <A.Tabs>
      <A.Tab title="Content">
        <A.Input label="Heading" name="heading" />
        <A.MarkdownEditor label="Custom text" name="message" reset />
      </A.Tab>
      <A.Tab title="Presentation">
        <A.SubTabs>
          <A.SubTab title="Background">
            {Object.entries(tabs.background).map(([k, props]) => (
              <A.SubGroup key={k} {...props} />
            ))}
          </A.SubTab>
          <A.SubTab title="Foreground">
            {Object.entries(tabs.foreground).map(
              ([k, {children, ...props}]) => (
                <A.SubGroup key={k} {...props}>
                  {k === 'layout' && (
                    <A.Radio
                      label="Text container"
                      name="container"
                      options={containerOptions}
                    />
                  )}
                  {children}
                  {k === 'layout' && (
                    <A.Radio
                      label="Text justification"
                      name="justification"
                      options={justificationOptions}
                    />
                  )}
                </A.SubGroup>
              ),
            )}
          </A.SubTab>
        </A.SubTabs>
      </A.Tab>
    </A.Tabs>
  )
}
