import {themeGetter} from 'quickstart/styled-components/system'

const getCheckboxes = (theme: any) => {
  const th = themeGetter(theme)
  return {
    label: {
      default: {
        ...th('ui.form'),
        color: th('colors.textColor'),
        paddingLeft: th('space.xs'),
      },
      checked: {
        fontWeight: th('fontWeights.bold'),
      },
      disabled: {
        color: th('colors.textColorMuted'),
      },
    },
    checkbox: {
      default: {
        backgroundColor: th('colors.backgroundColorWhite'),
        borderColor: th('colors.borderColor'),
        borderWidth: th('borderWidths.md'),
        borderStyle: 'solid',
        borderRadius: th('radii.none'),
        width: theme.toRem(18), // TODO
        height: theme.toRem(18), // TODO
        flexShrink: 0,
      },
      checked: {
        borderColor: th('colors.borderSelectedColor'),
        color: th('colors.buttonSolidFocus'),
      },
    },
  }
}

export default getCheckboxes
