import {formErrors} from 'quickstart/utils/final-form-errors'
import {useEffect, useRef} from 'react'
import * as Final from 'react-final-form'
import {api, ApiError, FormErrors, isEmptyObj, logger} from 'tizra'
import * as B from '../../block'
import {StepComponent} from '../types'

const log = logger('CartBlock/CheckoutStep')

export const SimpleFormStep: StepComponent = ({
  active,
  checkoutMethod: _checkoutMethod,
  step: _step,
  nextSteps,
  proceed,
  setNextSteps,
  userData,
}) => {
  const checkoutMethod = _checkoutMethod!
  const step = _step!
  const infoRequired = nextSteps[step.step]?.infoRequired || {}
  const buttonPrompt = nextSteps[step.step]?.buttonPrompt || 'Continue'

  const submit = async (values: any) => {
    const {status, ...data} = await api
      .checkoutStep({
        methodName: checkoutMethod.name,
        stepName: step.step,
        values,
      })
      .catch(e => {
        // Only here for 500, not 40x per entry in info.ts
        log.error(e)
        return {
          status: (e instanceof ApiError && e.status) || 0,
          reason: 'exception',
          message: `${e}`,
          errors: undefined,
        }
      })

    log.log({data, values, status})

    if (status >= 200 && status < 300) {
      // TODO: final
      if ('nextSteps' in data && data.nextSteps) {
        setNextSteps(data.nextSteps)
        proceed()
      }
      return // React Final Form will set submitSucceeded
    }

    let {errors, message, reason} = data as FormErrors

    // If the API returns permission denied, this means the session expired
    // between the time we rendered the form and now, so we need to reload the
    // page.
    if (status === 403 && userData) {
      window.location.reload()
    }

    if (reason === 'validation' && errors && !isEmptyObj(errors)) {
      message = reason = undefined
    }

    return formErrors({errors, message, reason, status})
  }

  const firstInputRef = useRef<HTMLInputElement>(undefined)
  const firstInput = firstInputRef.current
  useEffect(() => void (active && firstInput?.focus()), [active, firstInput])

  return (
    <Final.Form
      onSubmit={submit}
      render={({handleSubmit}) => (
        <form
          onSubmit={handleSubmit}
          style={{display: active ? undefined : 'none'}}
        >
          <B.FormShell
            variant="fluid"
            primaryButton={{type: 'submit', children: buttonPrompt}}
          >
            {Object.entries(infoRequired).map(([name, field], i) => (
              <B.ConnectedField
                key={name}
                component={B.Input}
                name={name}
                label={field.prompt}
                required={field.required ?? !field.optional}
                ref={i ? undefined : firstInputRef}
              />
            ))}
          </B.FormShell>
        </form>
      )}
    />
  )
}
