import {NavLinkProps} from 'quickstart/components/layout/NavLink'
import * as A from '../admin'
import {deepMerge, truthy} from 'tizra'

export const FooterAdmin = () => (
  <p>
    Footer is configured globally in <A.NavOut tab="Design" subtab="nav" />.
  </p>
)

export interface FooterBlockGlobalConfig extends A.GlobalBlockConfig {
  alignment: 'center' | 'left' | 'right'
  items: NavLinkProps[]
  copyright: string
  // old
  links?: Array<{text: string; link: string}>
  showSiteAdminLink?: boolean
}

export const defaultGlobalConfig: FooterBlockGlobalConfig = {
  alignment: 'center',
  items: [{type: 'link', label: 'Site Admin', href: '/admin/main'}],
  copyright: '',
  FREEMARKER: {
    copyright: 'html',
    'items.*.label': 'text',
    'items.*.href': 'text',
  },
}

const oldDefaultGlobalConfig: Pick<
  Required<FooterBlockGlobalConfig>,
  'links' | 'showSiteAdminLink'
> = {
  showSiteAdminLink: true,
  links: [
    {text: 'Privacy', link: '/privacy'},
    {text: 'Terms of Use', link: '/terms'},
  ],
}

export const globalMigrate: A.GlobalMigrate<FooterBlockGlobalConfig> = ({
  globalConfig: unknownGlobalConfig,
}) => {
  let globalConfig = {
    ...(unknownGlobalConfig as any),
  } as Partial<FooterBlockGlobalConfig>

  if (!globalConfig.items) {
    // Migrate from (really old) static four links to dynamic.
    if (!globalConfig.links) {
      const {text0, link0, text1, link1, text2, link2, text3, link3, ...rest} =
        globalConfig as any
      globalConfig = {
        ...rest,
        links: [
          {text: text0, link: link0},
          {text: text1, link: link1},
          {text: text2, link: link2},
          {text: text3, link: link3},
        ],
      }
    }

    // Migrate to nested nav.
    const {links, showSiteAdminLink} = {
      ...oldDefaultGlobalConfig,
      ...globalConfig,
    }
    const migratedItems = links
      .map(
        ({text, link}) =>
          text && link && {type: 'link' as const, label: text, href: link},
      )
      .filter(truthy)
    if (showSiteAdminLink) {
      migratedItems.push({
        type: 'link' as const,
        label: 'Site Admin',
        href: '/admin/main',
      })
    }
    globalConfig.items = migratedItems
  }

  // Clear the old links-based config.
  delete globalConfig.links
  delete globalConfig.showSiteAdminLink

  return deepMerge(defaultGlobalConfig)(globalConfig)
}

export const FooterGlobalAdmin: A.Admin<FooterBlockGlobalConfig> = () => (
  <>
    <A.Group title="Links">
      <A.AdminField>
        <A.Hint>Freemarker supported in labels and hrefs.</A.Hint>
      </A.AdminField>
      <A.AdminField>
        <A.NavTree name="items" type="footer" />
      </A.AdminField>
    </A.Group>
    <A.Group title="Appearance">
      <A.Radio
        label="Alignment"
        name="alignment"
        options={A.options({
          left: 'Left aligned',
          center: 'Centered',
          right: 'Right aligned',
        })}
      />
    </A.Group>
    <A.Group>
      <A.Input label="Copyright" name="copyright" />
    </A.Group>
  </>
)
