import {textContainers} from 'quickstart/theme'
import {atBreakpointsWith, styled} from 'quickstart/theme/linaria'

const defaultWidths = atBreakpointsWith((v: string) => ({maxWidth: v}))(
  textContainers.default.maxWidths,
)

const widerWidths = atBreakpointsWith((v: string) => ({maxWidth: v}))(
  textContainers.wider.maxWidths,
)

export const TextContainer = styled.div`
  /* Consume up to max-width in flex container. This allows positioning within
   * the site container to work consistently, regardless of content width.
   */
  width: 100%;

  /* Both default and wider use the the wider max-widths, but default will
   * constrain inner paragraph-like elements below.
   */
  &:is([data-variant='default'], [data-variant='wider']) {
    ${widerWidths};
  }

  &[data-variant='default'] {
    /* In paragraph-like elements, constrain the width to the normal (not
     * wider) text container. Note that globalStyles adjusts the line spacing to
     * suit.
     */
    *:is(blockquote, ol, ul, p) {
      ${defaultWidths};
    }
  }

  &[data-justify='center'] {
    text-align: center;
    *:is(blockquote, ol, ul, p) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &[data-justify='right'] {
    text-align: right;
    *:is(blockquote, ol, ul, p) {
      margin-left: auto;
    }
  }

  /* Squeeze everything into the default container, not just the paragraph-like
   * elements.
   */
  &[data-variant='strict'] {
    ${defaultWidths};
  }
`
