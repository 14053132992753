import {useObjectHack} from 'quickstart/hooks'
import {ComponentProps, MouseEvent} from 'react'
import * as S from './styles'

export const UniversalLink = ({
  href,
  onClick,
  target,
  ...rest
}: ComponentProps<'a'>) => {
  const link = (
    <S.UniversalLink
      href={href || '#'}
      // https://web.dev/external-anchors-use-rel-noopener/
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      target={target}
      {...rest}
      onClick={
        onClick && href ? onClick
        : href ?
          undefined
        : (e: MouseEvent<HTMLAnchorElement>) => {
            onClick?.(e)
            e.preventDefault()
          }

      }
    />
  )
  return useObjectHack('a', link)
}
