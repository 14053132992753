import {useMemo} from 'react'
import {useLocation} from './useLocation'

/**
 * React hook for getting redemption query parameters from the router. If this
 * needs to return [params, setParams] eventually then refer to usePrefixedParams
 * for an example.
 */
export const useRedemptionParams = () => {
  const location = useLocation()
  const usp = new URLSearchParams(location.search)
  const redemptionCode = usp.get('redemptionCode') || ''
  const params = useMemo(() => ({redemptionCode}), [redemptionCode])
  return params
}
