import {Icon} from 'quickstart/components/content/Icon'
import {ComponentProps} from 'react'
import * as S from './styles'

type IconButtonProps = ComponentProps<typeof S.IconButton> & {
  icon: ComponentProps<typeof Icon>['icon']
  size?: string
  notify?: boolean
}

export const IconButton = ({
  icon,
  size = 'xs',
  notify,
  ...rest
}: IconButtonProps) => {
  return (
    <S.IconButton size={size} variant="tertiary" {...rest}>
      <Icon icon={icon} size="100%" notify={notify} />
    </S.IconButton>
  )
}
