import {themeGetter} from 'quickstart/styled-components/system'

const getIconButtons = (theme: any) => {
  const th = themeGetter(theme)

  return {
    secondary: {
      borderWidth: th('borderWidths.sm'),
    },
  }
}

export default getIconButtons
