import * as A from '../admin'

export interface RegisterUserBlockConfig extends A.BlockConfig {
  heading: string
  message: string
}

export const defaultConfig: RegisterUserBlockConfig = {
  heading: 'Create Account',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export const RegisterUserAdmin = () => (
  <>
    <A.Group title="Content">
      <A.MarkdownEditor label="Custom text" name="message" />
    </A.Group>
    <p>
      Global sign-in configuration can be found in{' '}
      <A.NavOut tab="Design" subtab="features" />.
    </p>
  </>
)
