import {useApi} from 'quickstart/hooks/useApi'
import {logger} from 'tizra'

const log = logger('blocks/admin/hooks/usePropValues')

const EMPTY = [] as string[]

export const usePropValues = (prop: string, metaType = 'AdminTagged') => {
  const {data} = useApi.propValues({
    metaType,
    propNames: [prop],
  })
  let values = data?.[prop]
  if (values && prop === 'AdminTags') {
    values = values.filter(t => !t.includes(';')) // ;agilepdf_crawlers;
  }
  return values || EMPTY
}
