import {cssVars, dataProps, transient} from 'quickstart/theme'
import {UnprefixStyledProps} from 'quickstart/types'
import {ComponentProps} from 'react'
import * as S from './styles'

const _Digest = (props: ComponentProps<typeof S.Digest>) => (
  <S.Digest {...props} />
)

interface GroupProps
  extends Partial<UnprefixStyledProps<ComponentProps<typeof S.Group>>> {
  linkColor?: string
}

const Group = ({
  background,
  foreground,
  linkColor,
  size = 'large',
  ...props
}: GroupProps) => (
  <S.Group
    {...props}
    {...dataProps({size})}
    {...transient({background, foreground})}
    style={{
      ...(linkColor && {[cssVars.colors.link]: linkColor}),
      ...props.style,
    }}
  />
)

export const Digest = Object.assign(_Digest, {
  Heading: S.Heading,
  Snippet: S.Snippet,
  Group,
})
