import {themeGetter} from 'quickstart/styled-components/system'

const getImages = (theme: any) => {
  const th = themeGetter(theme)
  return {
    caption: {
      ...th('fonts.heading'),
      ...th('prose.cardHead'),
      marginTop: '0.75em',
    },
    shadowed: {
      boxShadow: th('shadows.v2'),
    },
    bordered: {
      border: th('borders.fine'),
    },
    hoverable: {
      ...th('hovers.raise'),
    },
  }
}

export default getImages
