import {Shape} from 'quickstart/components/layout/Shape'
import styled, {
  breakpoints,
  css,
  lastChildSelector,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {cssVars} from 'quickstart/styled-components/theme/base/fonts'

type TileSize = 'small' | 'medium' | 'large'

type GroupProps = {$size?: TileSize}

export const Group = styled.div.attrs<GroupProps>(
  ({$size = 'large'}) =>
    ({
      'data-size': $size,
    }) as any,
)<GroupProps & SystemProps>`
  display: grid;
  gap: ${th('gaps.sideNav')};

  &[data-size='small'] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    ${breakpoints({
      sm: css`
        grid-template-columns: repeat(3, minmax(0, 1fr));
      `,
      md: css`
        grid-template-columns: repeat(4, minmax(0, 1fr));
      `,
      lg: css`
        grid-template-columns: repeat(5, minmax(0, 1fr));
      `,
    })};
  }

  &[data-size='medium'] {
    grid-template-columns: minmax(0, 1fr);
    ${breakpoints({
      sm: css`
        grid-template-columns: repeat(2, minmax(0, 1fr));
      `,
      md: css`
        grid-template-columns: repeat(3, minmax(0, 1fr));
      `,
      lg: css`
        grid-template-columns: repeat(4, minmax(0, 1fr));
      `,
    })};
  }

  &[data-size='large'] {
    grid-template-columns: minmax(0, 1fr);
    ${breakpoints({
      sm: css`
        grid-template-columns: repeat(2, minmax(0, 1fr));
      `,
      xl: css`
        grid-template-columns: repeat(3, minmax(0, 1fr));
      `,
    })};
  }

  ${system};
`

type TileProps = {
  $clickable?: boolean
  $stretch?: boolean
}

export const Tile = styled.div<TileProps>(
  ({$clickable, $stretch}) => css`
    display: flex; // for <Tile as={UniversalLink} />
    overflow: hidden;
    background-size: cover;
    background-position: center;
    ${th('tiles.default.tile')};
    ${$clickable && {cursor: 'pointer'}};
    ${!$stretch && {alignSelf: 'start'}};
  `,
)

export const Cover = styled(Shape)<SystemProps>`
  width: 100%;
  ${th('tiles.default.cover')};
  ${system};
`

export const Body = styled.div<SystemProps>`
  word-break: break-word;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  ${lastChildSelector} {
    margin-bottom: ${th('space.xl')};
  }
  ${system};
`

export const Label = styled.div<SystemProps>`
  ${th('tiles.default.label')};
  ${system};
`

export const Heading = styled.div<SystemProps>`
  text-wrap: balance;
  ${th('tiles.default.heading')};
  ${Group}[data-size='small'] & {
    ${th('tiles.small.heading')};
  }
  ${Group}[data-size='medium'] & {
    ${th('tiles.medium.heading')};
  }
  &:first-child {
    margin-top: calc(0.75 * var(${cssVars.lineHeight}));
  }
  ${system};
`

export const Meta = styled.div<SystemProps>`
  ${th('tiles.default.meta')};
  ${system};
`

export const Description = styled.div<SystemProps>`
  ${th('tiles.default.description')};
  &:first-child {
    margin-top: var(${cssVars.lineHeight});
  }
  ${system};
`

export const Footer = styled.div<SystemProps>`
  word-break: break-word;
  ${lastChildSelector} {
    margin-bottom: ${th('space.xl')};
  }
  ${system};
`

export const CallToAction = styled.div<SystemProps>`
  ${th('tiles.default.callToAction')};
  ${system};
`
