import {Grid} from 'quickstart/components/layout/Grid'
import {SideNavContent} from 'quickstart/components/layout/SideNav/styles'
import {breakpoints, withTheme} from 'quickstart/theme'
import {atBreakpoints, styled, theme} from 'quickstart/theme/linaria'

// XXX Grid drops theme so we don't need dropTheme here, since putting dropTheme
// here breaks Linaria somehow.
export const BrowseGalleryGrid = withTheme(styled(Grid)`
  // Must increase specificity when wrapping styled components.
  && {
    gap: ${theme.gaps.sideNav};

    ${atBreakpoints({
      xs: {gridTemplateColumns: 'minmax(0, 1fr)'},
      md: {gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'},
    })};

    @media (min-width: ${breakpoints.lg}) {
      ${SideNavContent} & {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
`)
