import * as A from 'quickstart/blocks/admin/base'
import {FieldArray} from 'quickstart/blocks/admin/components/FieldArray'
import {metaCardSlots} from 'quickstart/components/tizra/MetaCard'
import {metaDigestSlots} from 'quickstart/components/tizra/MetaDigest'
import {metaHeadlineSlots} from 'quickstart/components/tizra/MetaHeadline'
import {
  metaTileSizes,
  metaTileSlotsPerSize,
  TileSize,
} from 'quickstart/components/tizra/MetaTile'
import {
  defaultSlots,
  ImageCropping,
  imageCroppings,
  ImageFocus,
  slotDefs,
  SlotName,
} from 'quickstart/components/tizra/MetaTile/common'
import * as R from 'rambdax'
import {ReactNode, useMemo} from 'react'
import {logger, meta} from 'tizra'

const log = logger('Browse/admin')

export interface BrowseFilterConfig {
  allOptionLabel: string
  prop: string
  reverse: boolean
}

const browseDisplays = [
  'cards',
  'digests',
  'headline',
  'tiles',
  'titles',
] as const

type BrowseDisplay = (typeof browseDisplays)[number]

export interface BrowseSpec {
  heading: string // for inclusion on the Content tab

  // What
  metaTypes: string[]
  filterCollectionId: string
  requiredAdminTags: string
  excludedAdminTags: string

  // Limit and order
  maxItems: string // so it can be blank
  moreLink: {
    show: boolean
    conditional: boolean
    customText: string
    customUrl: string
  }
  sortDirection: 'ascending' | 'descending'
  sortProp: string

  // Presentation
  display: BrowseDisplay
  size: TileSize
  slots: typeof defaultSlots
  background: string
  foreground: string
  linkColor: string
  imageCropping: ImageCropping
  imageFocus: ImageFocus

  // Filters
  filterMode: 'auto' | 'custom' | 'disabled'
  filterPropPrefix: string
  filters: BrowseFilterConfig[]

  // Advanced
  customBrowseConfigJson: string
}

export const defaultBrowseSpec: BrowseSpec = {
  heading: '',

  // Content, limit, and order
  metaTypes: ['Book', 'Video'],
  filterCollectionId: '_all',
  requiredAdminTags: '',
  excludedAdminTags: '',
  maxItems: '',
  moreLink: {
    show: false,
    conditional: true,
    customText: '',
    customUrl: '',
  },
  sortProp: 'Title',
  sortDirection: 'ascending',

  // Filtering
  filterMode: 'disabled',
  filterPropPrefix: 'Browse',
  filters: [
    {prop: '', allOptionLabel: '', reverse: false},
    {prop: '', allOptionLabel: '', reverse: false},
  ],

  // Presentation
  display: 'cards',
  slots: defaultSlots,
  size: 'large',
  background: '',
  foreground: '',
  linkColor: '',
  imageCropping: 'landscape',
  imageFocus: 'n',

  // Advanced
  customBrowseConfigJson: '',
}

const defaultInclude = {
  heading: false,
  filters: false,
  maxItems: true,
  customBrowseConfigJson: true,
} as const

const defaultConstrain = {
  display: ['cards', 'tiles', 'digests'] as BrowseSpec['display'][],
}

interface BrowseConfigTabProps {
  name: string
  named: (s: string) => string
  include: {[k in keyof typeof defaultInclude]: boolean}
  constrain: {
    display: BrowseSpec['display'][]
  }
}

interface BrowseConfigProps {
  name?: string
  constrain?: Partial<BrowseConfigTabProps['constrain']>
  include?: Partial<BrowseConfigTabProps['include']>
}

export const BrowseConfigContent = ({
  named: _,
  include,
}: BrowseConfigTabProps) => {
  const metaTypeOptions = A.useMetaTypeOptions()
  const sortPropOptions = A.usePropOptions({
    metaType: 'AdminTagged',
    publishedDate: true,
    spec: 'sub-prop-defs',
  })
  const availableCollections = A.useCollectionOptions()
  const collectionOptions = useMemo(
    () => [
      {value: '_all', label: 'Site wide'},
      {value: '', label: 'Current collection'},
      ...availableCollections,
    ],
    [availableCollections],
  )
  const adminTagOptions = A.easyOptions(A.usePropValues('AdminTags'))
  return (
    <>
      {include.heading && <A.Input label="Heading" name={_('heading')} />}
      <A.Select
        label="Collection"
        name={_('filterCollectionId')}
        options={collectionOptions}
      />
      <A.Input
        label="Required admin tags"
        name={_('requiredAdminTags')}
        options={adminTagOptions}
      />
      <A.Input
        label="Excluded admin tags"
        name={_('excludedAdminTags')}
        options={adminTagOptions}
      />
      <A.Checklist
        label="Meta-types to include"
        name={_('metaTypes')}
        options={metaTypeOptions}
      />
      <A.Row>
        <A.Select
          label="Sort by"
          name={_('sortProp')}
          noneOption={true}
          options={sortPropOptions}
          // Prevent blowout with long options.
          style={{maxWidth: '180px'}}
        />
        <A.Select
          name={_('sortDirection')}
          options={A.options({
            ascending: 'Ascending',
            descending: 'Descending',
          })}
        />
      </A.Row>
    </>
  )
}

export const BrowseConfigFiltering = ({
  name,
  named: _,
}: BrowseConfigTabProps) => {
  const v = A.useValueGetter(name)

  const filterPropOptions = A.usePropOptions({
    metaType: 'AdminTagged',
    spec: 'sub-prop-defs',
    types: A.FILTER_TYPES,
  })

  // Whenever one of the filter props changes, set the associated all option.
  const deriveAllOptionLabel = ({value}: any) => {
    if (!value) {
      return ''
    }
    const displayName = filterPropOptions.find(o => o.value === value)?.def
      ?.displayName
    return meta.any(displayName || value)
  }

  const fpp = v('filterPropPrefix') || 'Browse'

  return (
    <>
      <A.Radio
        label="Use filters:"
        name={_('filterMode')}
        options={A.options({
          auto: 'defined on collection',
          custom: 'explicitly configured here',
          disabled: 'disabled',
        })}
      />
      {v('filterMode') === 'custom' && (
        <FieldArray name={_('filters')}>
          {({fields}) =>
            fields.map((name, i) => {
              const _ = (s: string) => `${name}.${s}`
              const values = fields.value[i] as BrowseFilterConfig
              return (
                <>
                  {!!i && <br />}
                  <A.Select
                    label="User filter"
                    name={_('prop')}
                    options={filterPropOptions}
                    noneOption="(none)"
                  />
                  <A.Input
                    label='Text for "all" option'
                    name={_('allOptionLabel')}
                    hidden={!values.prop}
                    cascade={{from: _('prop'), derive: deriveAllOptionLabel}}
                  />
                  <A.Radio
                    label="Options sort direction"
                    name={_('reverse')}
                    options={A.options({
                      false: 'Ascending',
                      true: 'Descending',
                    })}
                    parse={(v: any) => v === 'true'}
                    format={(v: any) => `${v}`}
                    hidden={!values.prop}
                  />
                </>
              )
            })
          }
        </FieldArray>
      )}
      {v('filterMode') === 'auto' && (
        <A.Input
          label="Filtering property prefix"
          name={_('filterPropPrefix')}
          hint={`Browse will look for properties: ${fpp}Filter1, ${fpp}Filter2, ${fpp}Any1, and ${fpp}Any2.`}
        />
      )}
    </>
  )
}

const PropInput = ({propOptions, ...props}: any) => {
  const options = A.usePropOptions({
    metaType: 'AdminTagged',
    spec: 'sub-prop-defs',
    ...propOptions,
  })
  return <A.Input options={options} {...props} />
}

interface BrowseConfigMaxItemsProps extends BrowseConfigTabProps {
  moreLink?: boolean
}

const BrowseConfigMaxItems = (props: BrowseConfigMaxItemsProps) => {
  const {include, moreLink = false, named: _} = props
  const v = A.useValueGetter(props.name)
  return include.maxItems ?
      <>
        <A.Input
          label="Max items"
          name={_('maxItems')}
          hint={
            include.filters ? '(only applies without user filters)' : undefined
          }
        />
        {moreLink && !!parseInt(v('maxItems')) && (
          <>
            <A.CheckLeft
              name={_('moreLink.show')}
              label={'Enable "View all" link to collection home'}
            />
            {v('moreLink.show') && (
              <div style={{marginLeft: '20px'}}>
                <A.YesNo
                  name={_('moreLink.conditional')}
                  label="When to show"
                  yes="only when collection has more items than shown"
                  no="always, even when all items are shown"
                />
                <A.Input
                  label="Custom link text"
                  name={_('moreLink.customText')}
                  hint="Leave blank for automatic default"
                />
                <A.Input
                  label="Custom link destination"
                  name={_('moreLink.customUrl')}
                  hint="Leave blank for collection home"
                />
              </div>
            )}
          </>
        )}
      </>
    : null
}

export const BrowseConfigTitles = (props: BrowseConfigTabProps) => {
  const {named: _} = props
  return (
    <>
      <BrowseConfigMaxItems {...props} />
      <BrowseConfigSlots {...props} slots={{title: {force: true}}} />
    </>
  )
}

export const BrowseConfigHeadline = (props: BrowseConfigTabProps) => {
  return <BrowseConfigSlots {...props} slots={metaHeadlineSlots} />
}

interface BrowseConfigSlotsProps extends BrowseConfigTabProps {
  slots: {[k in SlotName]?: {force?: boolean}}
}

const BrowseConfigSlots = (props: BrowseConfigSlotsProps) => {
  const {named: _, slots} = props
  return (
    <>
      <A.Label display="block">Slots:</A.Label>
      <A.Table>
        <A.Thead>
          <A.Tr>
            <A.Th>slot</A.Th>
            <A.Th>property</A.Th>
          </A.Tr>
        </A.Thead>
        <A.Tbody>
          {Object.entries(slots)
            .filter(([name]) => slotDefs[name as SlotName])
            .map(([name, {force}]) => {
              const {label, propOptions} = slotDefs[name as SlotName]
              return (
                <A.Tr key={name}>
                  <A.Td>
                    <A.CheckLeft
                      name={_(`slots.${name}.enabled`)}
                      force={force}
                      label={label}
                    />
                  </A.Td>
                  <A.Td>
                    <PropInput
                      name={_(`slots.${name}.prop`)}
                      propOptions={propOptions}
                    />
                  </A.Td>
                </A.Tr>
              )
            })}
        </A.Tbody>
      </A.Table>
    </>
  )
}

export const BrowseConfigTiles = (props: BrowseConfigTabProps) => {
  const {named: _, name} = props
  const v = A.useValueGetter(name)
  return (
    <>
      <BrowseConfigMaxItems {...props} moreLink />
      <A.Radio
        label="Tile size"
        options={A.easyOptions(metaTileSizes)}
        name={_('size')}
        hint="Note that size affects which slots are available below."
      />
      <A.Radio
        label="Image cropping"
        options={A.easyOptions(imageCroppings)}
        name={_('imageCropping')}
        hidden={v('display') !== 'tiles'}
      />
      <A.Gravity
        label="Image focus"
        name={_('imageFocus')}
        hint="When a cover image is cropped to fit, what part is kept?"
        hidden={v('display') !== 'tiles' || v('imageCropping') === 'full'}
      />
      <BrowseConfigSlots
        {...props}
        slots={metaTileSlotsPerSize[v('size') as TileSize]}
      />
    </>
  )
}

export const BrowseConfigCards = (props: BrowseConfigTabProps) => {
  return (
    <>
      <BrowseConfigMaxItems {...props} moreLink />
      <BrowseConfigSlots {...props} slots={metaCardSlots} />
    </>
  )
}

export const BrowseConfigDigests = (props: BrowseConfigTabProps) => {
  const {named: _} = props
  return (
    <>
      <BrowseConfigMaxItems {...props} />
      <A.Input
        name={_('background')}
        label="Background color"
        hint="CSS color or background property shorthand"
      />
      <A.Input
        name={_('foreground')}
        label="Foreground color"
        hint="CSS color for text contrast on background"
      />
      <A.Input
        name={_('linkColor')}
        label="Link color"
        hint="CSS color for link contrast on background"
      />
      {/* <A.YesNo name={_('bleed')} label="Background width" no="fit in container" yes="full bleed" /> */}
      <BrowseConfigSlots {...props} slots={metaDigestSlots} />
    </>
  )
}

const browseConfigDisplays: {[k in BrowseDisplay]: typeof BrowseConfigTiles} = {
  cards: BrowseConfigCards,
  tiles: BrowseConfigTiles,
  digests: BrowseConfigDigests,
  headline: BrowseConfigHeadline,
  titles: BrowseConfigTitles,
}

export const BrowseConfigPresentation = (props: BrowseConfigTabProps) => {
  const {constrain, named: _, name} = props
  const v = A.useValueGetter(name)
  const _displayOptions: {[k in BrowseSpec['display']]: string} = {
    cards: 'cards',
    tiles: 'tiles',
    digests: 'text-only summaries',
    headline: 'headline',
    titles: 'titles only',
  }
  const displayOptions = A.options(
    // Use filter instead of pick so that order is maintained.
    R.filter(
      (_v, k) => constrain.display.includes(k as BrowseSpec['display']),
      _displayOptions,
    ),
  )
  const displayValue = v('display') as keyof typeof _displayOptions | undefined
  const DisplaySpecificConfig =
    displayValue && browseConfigDisplays[displayValue]
  return (
    <>
      <A.Radio
        label="Display as"
        name={_('display')}
        options={displayOptions}
      />
      {DisplaySpecificConfig && <DisplaySpecificConfig {...props} />}
    </>
  )
}

export const BrowseConfigAdvanced = ({named: _}: BrowseConfigTabProps) => {
  return (
    <>
      <A.Textarea
        label="Custom browse config JSON"
        name={_('customBrowseConfigJson')}
      />
    </>
  )
}

interface BrowseConfigTab {
  title: string
  content: ReactNode
}

export const useBrowseConfigTabs = (props: BrowseConfigProps) => {
  const include = {...defaultInclude, ...props.include}
  const constrain = {...defaultConstrain, ...props.constrain}
  const tabProps = {
    ...props,
    include,
    constrain,
    name: props.name || '',
    named: A.namely(props.name || ''),
  }
  return {
    content: {
      title: 'Content',
      content: <BrowseConfigContent {...tabProps} />,
    },
    ...(include.filters && {
      filtering: {
        title: 'Filtering',
        content: <BrowseConfigFiltering {...tabProps} />,
      },
    }),
    presentation: {
      title: 'Presentation',
      content: <BrowseConfigPresentation {...tabProps} />,
    },
    ...(include.customBrowseConfigJson && {
      advanced: {
        title: 'Advanced',
        content: <BrowseConfigAdvanced {...tabProps} />,
      },
    }),
  } as const satisfies Record<string, BrowseConfigTab>
}
