export const bps = ['sm', 'md', 'lg', 'xl', 'xxl'] as const

export type BP = (typeof bps)[number]

export const breakpoints = {
  sm: '568px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1440px',
} as const satisfies {[k in BP]: string}

export const bpx = ['_', 'xs', ...bps] as const

export type BPX = (typeof bpx)[number]
