import {Headline} from 'quickstart/components/content/Headline'
import {UniversalLink} from 'quickstart/components/content/UniversalLink'
import * as R from 'rambdax'
import {ComponentProps} from 'react'
import {MetaObject, deepMerge, logger} from 'tizra'
import {
  SlotConfig,
  SlotName,
  defaultSlots,
  useMetadata,
} from '../MetaTile/common'

const log = logger('MetaHeadline')

export const metaHeadlineSlots = {
  cover: {force: true},
  title: {},
  link: {},
}

type MetaHeadlineProps = ComponentProps<typeof Headline> & {
  metaObj?: MetaObject
  slots?: {[k in SlotName]?: SlotConfig}
}

const orNull = (v: any) => v || null

export const MetaHeadline = ({
  metaObj,
  slots: _slots,
  ...props
}: MetaHeadlineProps) => {
  const slots = R.pick(
    Object.keys(metaHeadlineSlots),
    deepMerge(defaultSlots)(_slots),
  ) as Exclude<typeof _slots, undefined>

  const {href, cover, title} = useMetadata({
    metaObj,
    slots,
    thumbProps: {cover: true, ratio: 3 / 2, position: 'center center'},
  })

  const asProps = orNull(href) && {as: UniversalLink, href}

  return (
    <Headline {...asProps} {...props}>
      <Headline.Cover>{cover}</Headline.Cover>
      <Headline.Body>
        {title && <Headline.Heading>{title}</Headline.Heading>}
      </Headline.Body>
    </Headline>
  )
}
