import {TextContainer} from 'quickstart/components/layout/TextContainer'
import {useRenderedValue} from 'quickstart/components/tizra/MetaValue'
import {useMarkdown} from 'quickstart/hooks/useMarkdown'
import {ComponentProps, RefObject} from 'react'

interface StaticContentProps {
  container?: ComponentProps<typeof TextContainer>['variant']
  staticType: string
  staticText: string
  ref?: RefObject<any>
}

export const StaticContent = ({
  ref,
  container = 'default',
  staticType,
  staticText,
}: StaticContentProps) => {
  const markdownHtml =
    useMarkdown(staticType === 'markdown' && staticText) || ''

  const displayType =
    staticType === 'markdown' || staticType === 'html' ? 'html' : 'text'
  const displayValue = staticType === 'markdown' ? markdownHtml : staticText

  const content = useRenderedValue({
    as: 'div',
    displayType,
    displayValue,
    variant: 'dangerous',
    reset: true,
    ref,
  })

  // TODO: configurable text container like markdown/abstract
  return <TextContainer variant={container}>{content}</TextContainer>
}
