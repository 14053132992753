import * as B from '../block'
import {useAuthConfig} from '../SignInBlock'
import {
  defaultConfig,
  ResetPasswordAdmin,
  ResetPasswordBlockConfig,
} from './admin'

export const ResetPasswordBlock = B.blockWithInfo(
  {
    name: 'reset-password',
    displayName: 'ResetPasswordBlock',
    title: 'Reset Password',
    Admin: ResetPasswordAdmin,
    defaultConfig,
  },
  ({config}: {config: ResetPasswordBlockConfig}) => {
    const authConfig = useAuthConfig({authAgent: 'tizra'})
    return (
      <B.Section>
        <B.Auth
          {...authConfig}
          {...config}
          modal={false}
          view="resetPassword"
        />
      </B.Section>
    )
  },
)
