import {useMoo} from './useMoo'

interface UseDeferredReturn<T> {
  promise: Promise<T>
  resolve: (value: T) => void
  reject: (reason?: any) => void
}

/**
 * React hook to make a Promise that can be resolved or rejected externally.
 * https://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
 */
export function useDeferred<T>(deps: any[] = []): UseDeferredReturn<T> {
  return useMoo(() => {
    const deferred = {} as UseDeferredReturn<T>
    deferred.promise = new Promise((resolve, reject) => {
      Object.assign(deferred, {resolve, reject})
    })
    return deferred
  }, [deps.length, ...deps])
}
