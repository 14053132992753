import {styled, theme} from 'quickstart/theme/linaria'
import {breakpoints, dropTheme, withProps, withTheme} from 'quickstart/theme'
import * as B from '../block'

export const NumberedHeading = withTheme(styled(
  withProps({variant: 'h3'})(B.Text),
)`
  display: flex;
  align-items: center;
  & > *:first-child {
    font-size: 1.5em;
    font-weight: 300;
    margin-right: ${theme.space.xs};
  }
`)

export const BorderedStack = withTheme(styled(dropTheme(B.Stack))`
  padding: ${theme.space.xl};
  border: ${theme.borders.fine};
`)

export const ButtonStack = styled(dropTheme(B.Stack))`
  @media (min-width: ${breakpoints.md}) {
    margin-top: 60px;
  }
`
