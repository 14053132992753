import {ComponentProps} from 'react'
import * as S from './styles'

export const Container = (props: ComponentProps<typeof S.InnerContainer>) => (
  <S.OuterContainer>
    <S.InnerContainer {...props} />
  </S.OuterContainer>
)

export {OuterContainer, InnerContainer} from './styles'
