import {logger} from 'tizra'
import * as B from '../block'
import {useBookshelfConfig} from '../BookshelfBlock'
import {useThemeConfig} from '../ThemeBlock'
import {
  defaultGlobalConfig,
  globalMigrate,
  NavAdmin,
  NavGlobalAdmin,
} from './admin'

const log = logger('NavBlock')

export const NavBlock = B.blockWithInfo(
  {
    name: 'nav',
    displayName: 'NavBlock',
    title: 'Nav',
    Admin: NavAdmin,
    GlobalAdmin: NavGlobalAdmin,
    defaultGlobalConfig,
    globalMigrate,
    dividers: false, // Nav component supplies its own
  },
  () => {
    const {links, profileMenu, ...config} = B.useGlobalConfig(NavBlock)
    const bookshelfConfig = useBookshelfConfig()
    const {logoUrl, logoLink} = useThemeConfig()
    return (
      <B.Nav
        {...config}
        bookshelfConfig={bookshelfConfig}
        logoUrl={logoUrl}
        logoLink={logoLink}
      />
    )
  },
)
