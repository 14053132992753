import {useHack, useMetaObjInfo} from 'quickstart/hooks'
import {
  DisplayProps,
  MetaProps,
  getPropDisplays,
  partitionMetaDisplayProps,
} from 'quickstart/utils'
import {ComponentProps} from 'react'
import {logger} from 'tizra'

const log = logger('MetaName')

interface MetaNameProps
  extends MetaProps,
    DisplayProps,
    ComponentProps<'span'> {
  as?: any
  prop?: string
}

export const MetaName = ({
  as: ElementType = 'span',
  prop,
  ..._rest
}: MetaNameProps) => {
  const [metaProps, displayProps, rest] = partitionMetaDisplayProps(_rest)
  const {metaObj, metaType, typeDefs} = useMetaObjInfo(metaProps)
  const br = useHack('br')
  const propDisplays = getPropDisplays(metaObj, typeDefs, {br, ...displayProps})

  if (prop === '_name') {
    prop = metaType && (typeDefs?.[metaType]?.namePropName || 'Title')
  }

  if (!prop) {
    return null
  }

  return (
    <ElementType {...rest}>
      {propDisplays[prop]?.displayName || prop}
    </ElementType>
  )
}
