import {equals} from 'rambdax'
import {nullish} from 'quickstart/types'
import {ValueParameterizedSearchField} from './config'

/**
 * Summarize field values for short-form presentation such as
 * filter tags.
 */
export function summaryList(
  field: ValueParameterizedSearchField,
  value: unknown,
) {
  const {abbrev, defaultValue, label, name, options} = field
  const {multi = Array.isArray(value)} = field
  const {test = (v, _f) => !equals(v, defaultValue)} = field

  if (nullish(value)) {
    return []
  }

  const summaryObj = <O extends {summary: string; removed: unknown[]}>(
    o: O,
  ) => ({
    name: field.name,
    label: typeof label === 'function' ? label(value, field) : label,
    key: `${name}:${o.summary}`,
    ...o,
  })

  // This is calling into user-supplied code.
  // Don't assume it's going to work...
  try {
    if (!test(value, field)) {
      return []
    }

    if (multi && Array.isArray(value)) {
      return value.map(v =>
        summaryObj({
          summary:
            abbrev === true ? v : (
              (abbrev && abbrev(v, field)) || optionText(field, v) || v
            ),
          removed: value.filter(vv => v !== vv),
        }),
      )
    }

    if (Array.isArray(options)) {
      // @ts-expect-error
      const found = options.find(o => o.value === value)
      // @ts-expect-error
      const others = options.filter(o => o.value !== value)
      if (found) {
        return [
          summaryObj({
            summary:
              abbrev === true ? value
                // @ts-expect-error
              : (abbrev && abbrev(value, field)) || found.text || value,
            // @ts-expect-error
            removed: others.length === 1 ? others[0].value : defaultValue,
          }),
        ]
      }
    }

    return [
      summaryObj({
        summary:
          (typeof abbrev === 'function' && abbrev(value, field)) || `${value}`,
        // @ts-expect-error
        removed: defaultValue,
      }),
    ]
  } catch (e) {
    console.error(`summaryList: ${e}`, field, value)
    return []
  }
}

export function summary(
  field: ValueParameterizedSearchField,
  value: unknown,
  separator = ', ',
) {
  return summaryList(field, value)
    .map(o => o.summary)
    .join(separator)
}

export function optionText(
  field: ValueParameterizedSearchField,
  value: unknown,
) {
  if (Array.isArray(field.options)) {
    // @ts-expect-error
    const found = field.options.find(o => o.value === value)
    // @ts-expect-error
    return found && found.text
  }
}
