import {
  NavLinkAccountPropsWithType,
  NavLinkBookshelfPropsWithType,
  NavLinkExpandoPropsWithType,
  NavLinkLinkPropsWithType,
  NavLinkMenuPropsWithType,
  NavLinkProps,
} from 'quickstart/components/layout/NavLink'
import {Opt, Req, truthy} from 'quickstart/types'
import {deepMerge, logger, tryJson} from 'tizra'
import * as A from '../admin'

const log = logger('NavBlock/admin')

export const NavAdmin = () => (
  <p>
    Nav is configured globally in <A.NavOut tab="Design" subtab="nav" />.
  </p>
)

export interface NavBlockGlobalConfig extends A.GlobalBlockConfig {
  includeSignIn: boolean
  includeCart: boolean
  quickSearchPlaceholder: string
  nestedNavConfigJson?: string
  nestedNavConfig: {
    items: NavLinkProps[]
    profileItems: NavLinkProps[]
  }
  // old
  links?: Array<{text: string; link: string}>
  profileMenu?: {
    includeAccount: boolean
    includeBookshelf: boolean
  }
}

export const defaultGlobalConfig: NavBlockGlobalConfig = {
  includeSignIn: true,
  includeCart: false,
  quickSearchPlaceholder: '',
  nestedNavConfig: {
    items: [{type: 'link', label: 'Home', href: '/'}],
    profileItems: [],
  },
  FREEMARKER: {
    // This will improve when freemarker.clj xglob supports **
    'nestedNavConfig.*.*.label': 'text',
    'nestedNavConfig.*.*.items.*.label': 'text',
    'nestedNavConfig.*.*.items.*.items.*.label': 'text',
    'nestedNavConfig.*.*.items.*.items.*.items.*.label': 'text',
    'nestedNavConfig.*.*.href': 'text',
    'nestedNavConfig.*.*.items.*.href': 'text',
    'nestedNavConfig.*.*.items.*.items.*.href': 'text',
    'nestedNavConfig.*.*.items.*.items.*.items.*.href': 'text',
  },
}

const oldDefaultGlobalConfig: Pick<
  Required<NavBlockGlobalConfig>,
  'links' | 'profileMenu'
> = {
  links: [
    {text: 'Home', link: '/'},
    {text: 'Resources', link: '/resources'},
    {text: 'Collections', link: '/collection/'},
  ],
  profileMenu: {
    includeAccount: false,
    includeBookshelf: false,
  },
}

// Utility type predicates that take into account older configs where "type"
// might be lacking in the JSON.
const isAccount = (
  item: Opt<NavLinkProps, 'type'>,
): item is NavLinkAccountPropsWithType => item.type === 'account'
const isBookshelf = (
  item: Opt<NavLinkProps, 'type'>,
): item is NavLinkBookshelfPropsWithType => item.type === 'bookshelf'
const isExpando = (
  item: Opt<NavLinkProps, 'type'>,
): item is Opt<NavLinkExpandoPropsWithType, 'type'> =>
  item.type === 'expando' || (!item.type && 'adminTag' in item)
const isMenu = (
  item: Opt<NavLinkProps, 'type'>,
): item is Opt<NavLinkMenuPropsWithType, 'type'> =>
  item.type === 'menu' || (!item.type && 'items' in item)
const isLink = (
  item: Opt<NavLinkProps, 'type'>,
): item is Opt<NavLinkLinkPropsWithType, 'type'> =>
  item.type === 'link' || (!item.type && !isMenu(item) && !isExpando(item))

export const globalMigrate: A.GlobalMigrate<NavBlockGlobalConfig> = ({
  globalConfig: unknownGlobalConfig,
  hasHack,
}) => {
  let globalConfig = {
    ...(unknownGlobalConfig as any),
  } as Partial<NavBlockGlobalConfig>

  // Attempt to migrate from JSON if available. The JSON will be cleared
  // whenever the configuration is saved without the hack enabled.
  globalConfig.nestedNavConfig =
    tryJson(globalConfig.nestedNavConfigJson, e => log.browser.error(e)) ||
    globalConfig.nestedNavConfig

  // If we still don't have a config, it's because this is an old links-based
  // configuration. Migrate it forward.
  if (!globalConfig.nestedNavConfig) {
    // Migrate from (really old) static four links to dynamic.
    if (!globalConfig.links && 'link0' in globalConfig) {
      const {text0, link0, text1, link1, text2, link2, text3, link3, ...rest} =
        globalConfig as any
      globalConfig = {
        ...rest,
        links: [
          {text: text0, link: link0},
          {text: text1, link: link1},
          {text: text2, link: link2},
          {text: text3, link: link3},
        ],
      }
    }

    // Migrate from links to nestedNavConfig.
    const {links, profileMenu} = {...oldDefaultGlobalConfig, ...globalConfig}
    globalConfig.nestedNavConfig = {
      items: links
        .map(
          ({text, link}) =>
            text && link && {type: 'link' as const, label: text, href: link},
        )
        .filter(truthy),
      profileItems: [
        profileMenu.includeAccount && {
          type: 'account' as const,
          label: 'My Account',
        },
        profileMenu.includeBookshelf && {
          type: 'bookshelf' as const,
          label: 'AUTO',
        },
      ].filter(truthy),
    }
  }

  // We have a guaranteed nestedNavConfig at this point. If the UI is disabled,
  // save back to JSON, otherwise clear the JSON so we stop migrating from it.
  if (!hasHack('nestedNavUi')) {
    globalConfig.nestedNavConfigJson ||= JSON.stringify(
      globalConfig.nestedNavConfig,
      null,
      2,
    )
  } else {
    delete globalConfig.nestedNavConfigJson
  }

  // Regardless where we came from, upgrade the tree to include types
  // everywhere so there's no ambiguity at the component level.
  globalConfig.nestedNavConfig.items &&= globalConfig.nestedNavConfig.items
    .map(addType)
    .filter(truthy)
  globalConfig.nestedNavConfig.profileItems &&=
    globalConfig.nestedNavConfig.profileItems.map(addType).filter(truthy)

  // Clear the old links-based config.
  delete globalConfig.links
  delete globalConfig.profileMenu

  return deepMerge(defaultGlobalConfig)(globalConfig)
}

function addType(item: Opt<NavLinkProps, 'type'>): NavLinkProps | undefined {
  if (isAccount(item)) {
    // We previously defaulted missing or empty label. Now we'll just default
    // missing, since Freemarker-expanded empty should indicate to omit.
    return {label: 'My Account', ...item}
  }
  if (isBookshelf(item)) {
    // We previously defaulted missing or empty label. Now we'll just default
    // missing, since Freemarker-expanded empty should indicate to omit.
    return {label: 'AUTO', ...item}
  }
  if (isExpando(item)) {
    return {...item, type: 'expando'}
  }
  if (isLink(item)) {
    // We accepted label/text and link/href synonyms in JSON.
    const {link, text, ...rest} = item as typeof item & {
      link?: string
      text?: string
    }
    return {href: link, label: text, ...rest, type: 'link'}
  }
  if (isMenu(item)) {
    return {
      ...item,
      type: 'menu',
      items: item.items?.map(addType).filter(truthy) || [],
    }
  }
  if (item.type) {
    return item as Req<typeof item, 'type'>
  }
  log.warn("can't migrate unknown item", item)
}

export const NavGlobalAdmin: A.Admin<NavBlockGlobalConfig> = ({values}) => {
  const hack = A.useHack('nestedNavUi')
  return (
    <>
      <A.Group>
        <A.CheckRight label="Include sign in" name="includeSignIn" />
        <A.CheckRight label="Include shopping cart" name="includeCart" />
      </A.Group>
      {!hack ?
        <A.Group title="Nested Nav JSON">
          <A.Textarea label="Config JSON" name="nestedNavConfigJson" />
        </A.Group>
      : <>
          <A.Group title="Navigation tree">
            <A.AdminField>
              <A.Hint>Freemarker supported in labels and hrefs.</A.Hint>
            </A.AdminField>
            <A.AdminField>
              <A.NavTree name="nestedNavConfig.items" type="main" />
            </A.AdminField>
          </A.Group>
          {values.includeSignIn && (
            <A.Group title="Profile menu">
              <A.AdminField>
                <A.Hint>Only displays when signed in.</A.Hint>
              </A.AdminField>
              <A.AdminField>
                <A.NavTree name="nestedNavConfig.profileItems" type="profile" />
              </A.AdminField>
            </A.Group>
          )}
        </>
      }
      <A.Group title="Quick search">
        <A.Input
          label="Custom placeholder text"
          name="quickSearchPlaceholder"
        />
      </A.Group>
    </>
  )
}
