import styled, {css, th} from 'quickstart/styled-components/system'

export const TagGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${th('space.xxs')};
`

interface TagProps {
  $color?: 'neutral' | 'accent'
  $inline?: boolean
  $variant?: 'default' | 'pill'
}

export const Tag = styled.div<TagProps>(
  ({onClick, $color = 'neutral', $inline = false, $variant = 'default'}) => {
    const pv = {default: '0.625em', pill: '0.25em'}[$variant]
    const ph = {default: '0.875em', pill: '0.5em'}[$variant]
    const bw = {default: '1px', pill: '1px'}[$variant]

    return css`
      display: inline-flex;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${th('fonts.ui')};
      ${th('ui.tag')}; /* size, weight, height, margin */
      line-height: 1; /* avoid cropped font */
      font-weight: ${th('fontWeights.regular')}; /* override bold */
      ${$inline &&
      css`
        font-size: inherit;
      `};

      column-gap: 0.625em; /* between text and x icon */
      padding: ${pv} ${ph};
      border-style: solid;
      border-width: ${bw};

      ${$variant === 'pill' &&
      css`
        border-radius: calc(1em + ${pv} + ${pv});
        ${$inline &&
        css`
          font-size: 0.85em;
        `};
      `};

      ${$color === 'neutral' ?
        css`
          border-color: ${th('colors.tagNeutralColor')};
          background-color: ${th('colors.tagNeutralColor')};
          color: ${th('colors.textColorMuted')};
        `
      : $color === 'accent' &&
        css`
          border-color: ${th('colors.textColorMuted')};
          background-color: ${th('colors.backgroundColorLight')};
          color: ${th('colors.textColorMuted')};
        `};

      ${!!onClick &&
      css`
        cursor: 'pointer';
        font-weight: ${th('fontWeights.bold')};
        transition: ${th('transitions.medium')};
        &:hover {
          background-color: ${th('colors.tagNeutralHover')};
          color: ${th('colors.textColor')};
        }
      `}
    `
  },
)

export const ActionIconWrapper = styled.div`
  display: inline-block;
  height: 1em;
  width: 1em;
  line-height: 1;
`
