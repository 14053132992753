import * as A from '../admin'

export interface Config extends A.BlockConfig {
  emptyMessage: string
  summaryMessage: string
}

export const defaultConfig: Config = {
  emptyMessage: '',
  summaryMessage: '',
  FREEMARKER: {
    emptyMessage: 'markdown',
    summaryMessage: 'markdown',
  },
}

export const Admin: A.Admin<Config> = () => (
  <A.Tabs>
    <A.Tab title="Empty cart">
      <A.MarkdownEditor
        name="emptyMessage"
        label="Message for empty cart"
        hint="Leave empty for default"
      />
    </A.Tab>
    <A.Tab title="Order summary">
      <A.Textarea
        name="summaryMessage"
        label="Custom message for order summary"
        hint="Default is no message"
      />
    </A.Tab>
  </A.Tabs>
)

export interface GlobalConfig {
  url: string
}

export const defaultGlobalConfig: GlobalConfig = {
  url: '/cart',
}

export const GlobalAdmin: A.Admin<GlobalConfig> = () => (
  <A.Group>
    <A.Input label="Path to static page for cart" name="url" />
  </A.Group>
)
