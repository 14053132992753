import {useAdminApi} from 'quickstart/hooks/useApi'
import * as R from 'rambdax'
import {AdminType, logger} from 'tizra'
import {makeOptions} from '../utils'

const log = logger('blocks/admin/hooks/useMetaTypeOptions')

interface UseMetaTypeOptionsProps {
  format?: (x: AdminType) => string
}

export const useMetaTypeOptions = ({
  format = x => `${x.displayNamePlural} (${x.name})`,
}: UseMetaTypeOptionsProps = {}) => {
  const metaTypes = R.filter(t => !t.isInternal, useAdminApi.types().data || {})
  return makeOptions({valueKey: 'name', labelKey: format})(
    Object.values(metaTypes),
  )
}
