import {classes, dataProps, textContainers} from 'quickstart/theme'
import * as S from './styles'
import {ComponentProps} from 'react'
import {cx} from 'quickstart/theme/linaria'

export const TextContainer = ({
  justify,
  variant = 'default',
  ...props
}: ComponentProps<typeof S.TextContainer> & {
  justify?: 'left' | 'center' | 'right'
  variant?: keyof typeof textContainers | 'strict'
}) => (
  <S.TextContainer
    {...dataProps({justify, variant})}
    {...props}
    className={cx(classes.textContainer, props.className)}
  />
)
