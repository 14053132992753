import {logger} from 'tizra'
import * as B from '../block'
import {PostHeader} from './PostHeader'
import {PublicationHeader} from './PublicationHeader'
import {HeaderAdmin, defaultConfig, migrate} from './admin'

export const log = logger('HeaderBlock')

export const HeaderBlock = B.blockWithInfo(
  {
    name: 'content-cover',
    displayName: 'HeaderBlock',
    title: 'Header',
    Admin: HeaderAdmin,
    defaultConfig,
    migrate,
  },
  ({config}) =>
    config.mode === 'publication' ?
      <PublicationHeader {...config} />
    : <PostHeader {...config.post} />,
)
