import {textComponent} from 'quickstart/components/content/Text'
import {toRem, withTheme} from 'quickstart/theme'
import {styled, theme} from 'quickstart/theme/linaria'

export const Key = withTheme(styled(textComponent('textSm'))`
  color: ${theme.colors.textColorLight};
  padding-left: calc(
    ${toRem(48)} - ${theme.space.xl}
  ); // Modal.Content padding minus Modal.Footer padding
`)
