import * as B from '../block'
import {AbstractAdmin, defaultConfig, migrate} from './admin'

export const AbstractBlock = B.blockWithInfo(
  {
    name: 'content-blurb',
    displayName: 'AbstractBlock',
    title: 'Abstract',
    Admin: AbstractAdmin,
    defaultConfig,
    migrate,
  },
  ({config: {container, heading, prop, background, justification}}) => {
    const {tizraId} = B.useBlockContext()
    const value =
      B.useMetaValue({
        as: 'div',
        tizraId,
        prop,
        html: true,
        reset: true, // apply font reset
      }) || null
    const content = value && (
      <B.TextContainer
        variant={container}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: B.flexAlign(justification),
          textAlign: justification,
        }}
      >
        {heading && <B.Section.Heading>{heading}</B.Section.Heading>}
        {value}
      </B.TextContainer>
    )

    return (
      <B.Scene>
        {content && <B.Background content={content} background={background} />}
      </B.Scene>
    )
  },
)
