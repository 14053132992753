import {styled, theme} from 'quickstart/theme/linaria'
import {withTheme} from 'quickstart/theme'

export const Wrapper = withTheme(styled.label<{
  'data-disabled'?: boolean
}>`
  --radio-size: 1.125em;
  display: grid;
  grid-template-columns: var(--radio-size) auto;
  gap: ${theme.space.xs}; // TODO? .5em would scale
  transition: ${theme.transitions.medium};
  color: ${theme.colors.textColor};
  &[data-disabled] {
    color: ${theme.colors.textColorMuted};
  }
`)

export const RadioColumn = styled.div`
  height: 0;
  width: 0;
  overflow: visible;
`

export const Radio = withTheme(styled.input<{disabled?: boolean}>`
  appearance: none;

  /* For iOS < 15 to remove gradient background. */
  background-color: ${theme.colors.backgroundColorWhite};

  /* Not removed via appearance. */
  margin: 0;
  font: inherit;
  color: currentColor;

  /* Fit in container provided by Wrapper grid. */
  width: var(--radio-size);
  height: var(--radio-size);

  /* Attempt to line up with text label. This seems to work pretty well
   * regardless of line-height (varies between textMd and form).
   */
  display: inline-grid;
  vertical-align: baseline;
  transform: translateY(calc(${theme.borderWidths.md} * -0.5));

  /* Put the dot in the middle of the circle. */
  place-content: center;

  &::before {
    content: '';
    width: calc(var(--radio-size) * 0.55);
    height: calc(var(--radio-size) * 0.55);
    border-radius: 50%;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    box-shadow: inset 1em 1em ${theme.colors.buttonSolidFocus};
  }

  &:checked::before {
    transform: scale(1);
  }

  border: ${theme.borderWidths.md} solid ${theme.colors.borderColor};
  border-radius: 50%;
  &:checked {
    border-color: ${theme.colors.borderSelectedColor};
  }

  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`)

export const Label = withTheme(styled.div<{
  'data-checked'?: boolean
}>`
  &[data-checked] {
    font-weight: ${theme.fontWeights.bold};
  }
`)
