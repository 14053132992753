import {MetaObject} from 'tizra'
import * as B from '../block'
import {Config} from './admin'

type CoverAreaProps = Pick<Config, 'alignment'> & {
  metaObj: MetaObject | undefined
  onLoad?: () => void
  onError?: () => void
  singleColumn: boolean
}

export const Cover = ({
  alignment,
  metaObj,
  singleColumn,
  ...props
}: CoverAreaProps) => {
  const loading = 'eager'
  const [oEmbed] = B.useOEmbed({metaObj, loading})
  return (
    oEmbed.isPending ? null
    : oEmbed.isHtml ? <B.VideoPlayer oEmbed={oEmbed} />
    : <B.MetaThumb
        loading={loading}
        shadowed
        {...(singleColumn ?
          {
            position: alignment,
            minRatio: 1.78,
          }
        : {
            minRatio: 2 / 3,
          })}
        metaObj={metaObj}
        {...props}
      />
  )
}

Cover.displayName = 'HeaderBlock.Cover'
