import {iso31661} from 'iso-3166' // TODO: dynamic import
import * as R from 'rambdax'
import {magicSort} from 'tizra'

export const countries = magicSort(
  x => x.label,
  iso31661.map(({name, alpha3}) => ({label: name, value: alpha3})),
).sort((a, b) =>
  a.value === 'USA' ? -1
  : b.value === 'USA' ? 1
  : 0,
)

// https://github.com/Tizra/cubchicken/files/13606180/UMPH-Cokesbury.API.Documentation.pdf
const _states = {
  AUS: {
    CT: 'Australian Capital Territory',
    NS: 'New South Wales',
    NT: 'Northern Territory',
    QL: 'Queensland',
    SA: 'South Australia',
    TS: 'Tasmania',
    VT: 'Victoria',
    WA: 'Western Australia',
  },
  CAN: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon Territory',
  },
  USA: {
    AA: 'AA (Armed Forces Americas)',
    AE: 'AE (Armed Forces Europe)',
    AK: 'Alaska',
    AL: 'Alabama',
    AP: 'AP (Armed Forces Pacific)',
    AR: 'Arkansas',
    AS: 'American Samoa',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    FM: 'Federated States of Micronesia',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MP: 'Northern Mariana Islands',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    PW: 'Palau',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  },
}

type SK = keyof typeof _states

type Option = {label: string; value: string}

export const states = R.map(
  R.pipe(
    R.toPairs,
    R.map(([value, label]) => ({label, value}) as Option),
    magicSort(x => x.label),
  ),
  _states,
) as {[k in SK]: Option[]}
