import {themeGetter} from 'quickstart/styled-components/system'
import {bestContrast} from 'quickstart/utils'
import {partial} from 'rambdax'

export default (theme: any) => {
  const th = themeGetter(theme)

  const contrastingTextColor = partial(bestContrast, [
    [th('colors.textColor'), th('colors.textInvertedColor')],
  ])

  return {
    logoRow: {
      height: '64px',
      logoPadding: '16px',
    },
    menu: {
      // Instead of transparent, so it paints over the body underneath.
      backgroundColor: th('colors.backgroundColorWhite'),
    },
    searchRow: {
      backgroundColor: th('colors.linkColor'),
      color: contrastingTextColor(th('colors.linkColor')),
      height: '64px',
      inputPadding: '10px', // implied by input height of 44px
    },
  }
}
