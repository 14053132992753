import {SVGProps} from 'react'

interface SvgIconProps extends Omit<SVGProps<SVGElement>, 'children' | 'ref'> {
  label?: string
  mainColor?: string
  accentColor?: string
  textColor?: string
  size?: string
}

export const SvgIcon = ({
  label,
  mainColor,
  accentColor,
  textColor,
  size = '2em',
  ...props
}: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90.55 123.47"
      {...props}
      style={{height: size, width: 'auto'}}
    >
      <g id="icon_svg__g10" transform="translate(-43.76 -55.85)">
        <path
          d="M51.55 55.85c-4.14-.01-7.54 3.45-7.55 7.69l-.24 107.9c-.01 4.24 3.37 7.71 7.51 7.72l75.3.16c4.14.01 7.54-3.45 7.55-7.69l.18-83.47-30.05-32.2z"
          id="icon_svg__path6"
          fill={mainColor}
        />
        <path
          d="M111.72 88.23l22.59.05-30.05-32.31-.05 24.19c-.02 4.42 3.37 8.06 7.51 8.07"
          id="icon_svg__path8"
          fill={accentColor}
        />
      </g>
      {!!label && (
        <text
          fontWeight={600}
          fontSize={28}
          fill={textColor}
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {label}
        </text>
      )}
    </svg>
  )
}
