import {Nullish} from 'quickstart/types'
import {ChangeEvent, ComponentProps, ReactNode} from 'react'
import {logger} from 'tizra'
import {Field} from '../Field'
import * as S from './styles'

const log = logger('Checkbox')

type CheckboxProps = ComponentProps<typeof S.Checkbox> & {label: ReactNode}

/**
 * Standalone checkbox. This does not operate uncontrolled, it must be
 * controlled by Final.Field wrapper.
 */
export const Checkbox = ({label, checked, ...props}: CheckboxProps) => {
  return (
    <S.Item>
      <S.CheckField>
        <S.Checkbox checked={checked} {...props} />
        <S.Label checked={checked}>{label}</S.Label>
      </S.CheckField>
    </S.Item>
  )
}

Checkbox.type = 'Checkbox' // see Field/utils.js

type CheckboxGroupProps = Omit<
  ComponentProps<typeof Field.Group>,
  'children' | 'required'
> & {
  name: string
  onChange?: any
  options: Array<{label: ReactNode; value: any}>
  value?: any[] | Nullish
  // These should be via Field.Group but isn't TS yet
  label?: string
  hint?: string
  required?: boolean
}

/**
 * Group of checkboxes. This does not operate uncontrolled, it must be
 * controlled by Final.Field wrapper.
 */
const CheckboxGroup = ({
  name,
  onChange,
  options,
  value: _value,
  ...props
}: CheckboxGroupProps) => {
  log.assert(
    Array.isArray(_value) || !_value,
    'CheckboxGroup received invalid value',
  )
  const value = _value || []

  return (
    <Field.Group {...props}>
      {options.map((o, i) => (
        <Checkbox
          key={i}
          name={name}
          label={o.label}
          checked={value.includes(o.value)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange?.({
              target: {
                value: value
                  .filter(v => v !== o.value)
                  .concat(e.target.checked ? [o.value] : []),
              },
            })
          }}
        />
      ))}
    </Field.Group>
  )
}

Checkbox.Group = CheckboxGroup
