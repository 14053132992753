import {logger, semiSplit} from 'tizra'
import * as B from '../block'
import {SubnavAdmin, defaultConfig} from './admin'

const log = logger('SubnavBlock')

export const SubnavBlock = B.blockWithInfo(
  {
    name: 'subnav',
    displayName: 'SubnavBlock',
    title: 'Sub Nav',
    Admin: SubnavAdmin,
    defaultConfig,
    dividers: false,
  },
  ({config: {heading, match}}) => {
    const {tizraId} = B.useBlockContext()

    const {data} = B.useApi.search({
      any: semiSplit(match.any),
      all: ['metaType:SubjectCollection'].concat(semiSplit(match.all)),
      excluded: ['metaType:VirtualCollection;VirtualCollectionInstance'].concat(
        semiSplit(match.excluded),
      ),
    })

    return (
      <B.Section variant="abutting">
        <B.SideNav heading={heading}>
          {data?.result.map((metaObj, i) => (
            <B.Text ui variant="subnav" key={metaObj.tizraId}>
              <B.MetaLink
                metaObj={metaObj}
                variant={
                  metaObj.tizraId === tizraId ? 'sidebar-current' : 'sidebar'
                }
              />
            </B.Text>
          ))}
        </B.SideNav>
      </B.Section>
    )
  },
)
