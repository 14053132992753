import {useMergedRefs} from 'quickstart/hooks'
import {ComponentProps, useEffect, useRef} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('Input')

type InputProps = ComponentProps<typeof S.Input>

export const Input = ({ref: forwadedRef, autoFocus, ...rest}: InputProps) => {
  // Autofocus
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  // Merge refs for passing through
  const ref = useMergedRefs([forwadedRef, inputRef])

  return <S.Input {...rest} ref={ref} />
}
